<template>
  <div class="update-credit-card-button">
    <div class="card">
      <div class="text">כרטיס לחיוב</div>
      <div class="card-number">{{ newFormattedCardNumberMask }}</div>
    </div>
    <div class="small-button" @click="openUpdateCreditCardPopup" v-if="buttonSize === 'small'">לעדכון אמצעי תשלום</div>
    <riseup-button class="button" v-else :variant="'primary'" :title="'לעדכון אמצעי תשלום'" :action="openUpdateCreditCardPopup" :size="'slim'" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import UpdateCreditCardHandler from '../UpdateCreditCardHandler.vue';

export default {
  name: 'UpdateCreditCardButton',
  mixins: [UpdateCreditCardHandler],
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    buttonSize: {
      type: String,
      validator: val => ['big', 'small'].includes(val),
      default: 'big',
    },
  },
  async created() {
    this.startContentLoading(this.$options.name);
    await this.fetchCreditCardMask();
    this.doneContentLoading(this.$options.name);
  },
  computed: {
    ...mapGetters('subscription', ['newFormattedCardNumberMask']),
  },
  methods: {
    ...mapActions('subscription', ['fetchCreditCardMask']),
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../../../../../node_modules/@riseupil/base-ui/src/scss/riseup-colors";

  .update-credit-card-button {
    width: 100%;
    padding: 24px 0;

    .card {
      line-height: 1.5;
      color: $riseup_black;

      .text {
        font-size: 14px;
        font-family: SimplerPro;
      }

      .card-number {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 24px;
      }
    }

    .button {
      cursor: pointer;
    }

    .small-button {
      cursor: pointer;
      font-size: 14px;
      text-decoration: underline;
      color: $riseup_blue;
      margin-top: -16px;
    }
  }
</style>
