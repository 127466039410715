<script>
import Segment from '../../../../../Segment';
import router from '../../../../../router';

export default {
  name: 'SubscribeClickHandler',
  methods: {
    subscribeClicked() {
      Segment.trackUserInteraction('SubscriptionSettings_SubscribeButtonClicked', { subscriptionState: this.subscriptionState });
      router.push({ path: '/subscription' });
    },
  },
};
</script>
