<template>
   <div class="incomes">
     <circles-sums-visualizer
       :sumsByExpense="sums"
       :transactions="mergedTransactions"
       :expense-type="expenseType"
       :circle-size-thresholds="circleSizeThresholds"
       :month="month">
     </circles-sums-visualizer>
   </div>
</template>

<script>
import cashflowViewConsts from '@/constants/cashflow-view';
import Segment from '@/Segment';
import { mapState } from 'vuex';
import categorySumCircleVariants from '@/constants/category-sum-circle-variants';
import _ from 'lodash';
import { sumTransactionsByExpense, parseTransactions, markSmallSumTransactions } from '../parse-transactions';
import CirclesSumsVisualizer from '../components/CategoryCirclesSumsVisualizer';

export default {
  name: 'Incomes',
  components: { CirclesSumsVisualizer },
  props: {
    month: { type: String, required: true },
    circleSizeThresholds: { type: Object, required: true },
  },
  data() {
    return {
      sums: {},
      mergedTransactions: [],
      expenseType: categorySumCircleVariants.circleType.INCOME,
    };
  },
  created() {
    Segment.trackUserInteraction('Month_Review_Incomes_Shown');
    const transactions = this.extractTransactionsFromUITreeByExpense();
    this.sums = sumTransactionsByExpense(transactions);
    // Only after we have the sums we can tell which Expenses should be merged
    this.mergedTransactions = markSmallSumTransactions(this.sums, transactions);
    // Now that we merged the smaller Expenses, we need to recalculate the sums for our new groups
    this.sums = sumTransactionsByExpense(this.mergedTransactions);
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
  },
  methods: {
    extractTransactionsFromUITreeByExpense() {
      const incomesObject = this.cashflowUITree[`${cashflowViewConsts.cashflowTypes.income}`];
      const fixedIncomesCategoriesObject = incomesObject[`${cashflowViewConsts.CATEGORY_NAMES.FIXED_INCOME}`].categories;
      const variableIncomesCategoriesObject = incomesObject[`${cashflowViewConsts.CATEGORY_NAMES.VARIABLE_INCOME}`].categories;
      const fixedIncomesTransactions = _.reduce(
        fixedIncomesCategoriesObject,
        (transactions, category) => [...transactions, ..._.map(category.transactions, transaction => transaction.actual)],
        [],
      );
      const variableIncomesTransactions = _.reduce(
        variableIncomesCategoriesObject,
        (transactions, category) => [...transactions, ...category.transactions],
        [],
      );
      const incomesTransactions = [...fixedIncomesTransactions, ...variableIncomesTransactions];
      return parseTransactions(incomesTransactions);
    },
  },
};
</script>
