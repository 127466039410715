<template>
  <card class="riseup-goal container-padding" data-cy="riseup-goal"
        :has-footer-padding="false" :show-separator-on-footer="cashflowIsPositive || hasActivePlans">
    <div class="container-padding" :class="{ 'out-of-date': outOfDate }">
      <div :class="cashflowIsPositive ? 'margin-bottom-s' : 'margin-bottom-sm'">היעד החודשי</div>
      <div v-if="cardSubtitle" class="text" :class="{ 'out-of-date': outOfDate }" >
        <div>{{ cardSubtitle }}</div>
        <div class="flex-row space-between" :class="{ 'margin-top-s': cashflowIsPositive }">
          <div class="right-side">
            <div v-if="cashflowIsPositive" class="goal-amount" :class="{ 'out-of-date': outOfDate }">{{ formattedRiseupGoal }}</div>
            <card-action-button v-if="cashflowIsPositive" class="margin-top-sm" v-bind="actionButtonProps" @click="openEditGoalPopup"
                                :out-of-date="outOfDate"/>
          </div>
          <lottie-player v-if="cashflowIsPositive && !disableElsaAnimations"
                         class="coins-image"
                         :key="isGoalCustom"
                         :src="`/animations/coin-pile-${isGoalCustom ? 'after' : 'before'}.json`"
                         autoplay
                         :loop="!isGoalCustom"
                         intermission="2500" />
          <img v-else class="coins-image" :src="require('@/assets/coin-pile-drawing.png')" />
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <template v-if="shouldShowPlanAhead">
        <plans-section :out-of-date="outOfDate"/>
      </template>
    </template>
  </card>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import PlansSection
  from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/plans-card/plans-section/PlansSection';
import CardActionButton from '@/base-components/card-parts/CardActionButton';
import EventBus from '@/event-bus/event-bus';
import moneyUtils from '@/utils/money';
import Segment from '@/Segment';
import RiseupGoalUpdatedToaster from '../../../../toasters/RiseupGoalUpdatedToaster';
import EditMonthlyGoalAmount from '../../../current-month/components/cashflow-edit/edit-riseup-goal/EditMonthlyGoalAmount';

export default {
  name: 'CashflowTypeBaseMonthlyGoal',
  components: {
    CardActionButton,
    PlansSection,
    Card: BaseUI.Card,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('cashflowView', ['riseupGoal', 'predictedBalanceAtEndOfMonth', 'isGoalCustom']),
    ...mapState('planAhead', ['eligibleForPlanAhead', 'initializedPlans']),
    ...mapGetters('planAhead', ['hasActivePlans']),
    ...mapGetters('featureFlags', ['disableElsaAnimations']),
    ...mapState('cashflowView', ['cashflow']),
    cardSubtitle() {
      if (this.cashflowIsPositive) {
        return 'כמה אני רוצה להשאיר בצד בסוף החודש?';
      }
      if (this.eligibleForPlanAhead) {
        return 'אין כרגע אפשרות לשים כסף בצד החודש';
      }
      return null; // not supposed to show the riseup goal card in that case
    },
    shouldShowPlanAhead() {
      return this.eligibleForPlanAhead && !this.isPreviewMode && this.initializedPlans;
    },
    formattedRiseupGoal() {
      return this.blocked ? '***' : `${moneyUtils.formatAmountWithFraction(this.riseupGoal, 0)} ₪`;
    },
    cashflowIsPositive() {
      return this.predictedBalanceAtEndOfMonth >= 0;
    },
    actionButtonProps() {
      return this.isGoalCustom ? {
        text: 'לעריכה',
        textColor: BaseUI.Colors.riseupBlack,
        backgroundColor: BaseUI.Colors.riseupWhite,
      } : {
        text: 'בחירת יעד',
        textColor: BaseUI.Colors.riseupWhite,
        backgroundColor: BaseUI.Colors.riseupBlue,
        borderColor: 'transparent',
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openToaster() {
      EventBus.$emit('open-toaster', {
        component: RiseupGoalUpdatedToaster,
        props: {},
      });
    },
    openEditGoalPopup() {
      Segment.trackUserInteraction('CashflowTypeBaseMonthlyGoal_EditClicked', { hasGoal: this.isGoalCustom });
      this.openModal({
        component: EditMonthlyGoalAmount,
        props: {
          onSaveCb: this.openToaster,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '../../../../../../../../../scss/mixins';

.riseup-goal {
  color: $riseup_black;
  padding-top: 20px;
  position: relative;
  font-family: SimplerPro;

  .container-padding {
    &.out-of-date {
      color: $riseup_gray_2;
      .coins-image {
        opacity: 0.5;
      }
    }
  }
  .text {
    @include cashflow-h1;
    padding-bottom: $ri-spacing-s;
    &.out-of-date {
      color: $riseup_gray_2;
    }
    .coins-image {
      max-width: 30%;
    }

    .goal-amount {
      display: inline-block;
      margin-top: 5px;
      color: $riseup_yellow;
      font-size: 24px;
      &.out-of-date {
        color: $riseup_gray_2;
      }
    }
  }
}
</style>
