<template>
  <div class="create-plans-explanation-popup">
    <colored-popup class="primary" :close-action="closePopup">
      <template v-slot:top-content>
        <div class="popup-header">
          <div class="ri-body">התוכניות הגדולות שלי</div>
          <div class="ri-medium-bold-headline margin-bottom-s">איך זה עובד?</div>
        </div>
      </template>
      <template v-slot:bottom-content>
        <div class="popup-content">
          <bullet-block v-for="(section, i) of sections" :key="i" :title="section.title" :text="section.text" :bullet-text="i+1"/>
          <riseup-button class="margin-top-m"
                         :action="closePopup"
                         size="slim"
                         title="הבנתי, תודה"/>
        </div>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import Segment from '@/Segment';
import BaseUi from '@riseupil/base-ui';
import BulletBlock from '../../cashflow-app/cashflow-view/current-month/components/challenge-flow/BulletBlock';

export default {
  name: 'CreatePlansExplanationPopup',
  components: {
    BulletBlock,
    ColoredPopup: BaseUi.ColoredPopup,
    RiseupButton: BaseUi.RiseupButton,
  },
  data() {
    return {
      sections: [
        {
          title: 'מוסיפים תוכנית עתידית',
          text: 'נראה כל חודש כמה צריך לשים בצד כדי להגיע אליה מוכנים.',
        },
        {
          title: 'חוסכים כל חודש',
          text: 'בסוף כל חודש התזרים החיובי יסומן בתוכנית, ונוכל להבין כמה עוד נשאר כדי להגיע ליעד.',
        },
        {
          title: 'נהיה מוכנים כשההוצאה תגיע',
          text: 'הכסף כבר יהיה מוכן, ונממן אותה מתוך העו״ש. מומלץ לסווג את ההוצאה כלא תזרימית.',
        },
      ],
    };
  },
  created() {
    Segment.trackUserGot('PlanAhead_InfoPopup_Shown');
  },
  methods: {
    closePopup() {
      Segment.trackUserInteraction('PlanAhead_InfoPopup_Close');
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.create-plans-explanation-popup {
  .popup-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .popup-content {
    text-align: right;
  }
}

</style>
