<template>
  <popup class="mid-month" :close-action="close">
    <template v-slot:content>
      <div class="category" v-for="c in categories" :key="c.name">
        <div class="category-name">{{ c.name }}</div>
        <div class="amount">{{ c.amount }}</div>
        <div class="spend-less">{{ c.low }}</div>
        <div class="spend-more">{{ c.high }}</div>
        <div class="transactions">
          <div class="transaction" v-for="t in c.transactions" :key="t.transactionId">
            {{ t.businessName }} {{ t.billingAmount }}
          </div>
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
import * as _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import BenchmarkApi from '@/api/BenchmarkApi';

const MAIN_CATEGORY = 'telecom';

export default {
  name: 'MidMonthEvent',
  components: {
    Popup: BaseUI.Popup,
  },
  data() {
    return {
      benchmark: {},
    };
  },
  async created() {
    this.benchmark = await BenchmarkApi.getBenchmark(MAIN_CATEGORY);
    console.log(this.benchmark);
  },
  computed: {
    categories() {
      return _.map(this.benchmark.categories, c => {
        return {
          name: c.name,
          amount: c.amount,
          low: findLow(c.graph, c.amount),
          lowValue: c.graph[findLow(c.graph, c.amount)],
          high: findHigh(c.graph, c.amount),
          highValue: c.graph[findHigh(c.graph, c.amount)],
          transactions: _.chain(this.benchmark.transactions)
            .filter(({ subcategory }) => subcategory === c.name || c.name === MAIN_CATEGORY)
            .map(({ transaction }) => transaction)
            .value(),
        };
      });
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};

function findLow(array, value) {
  const index = _.findLastIndex(array, v => v <= value);
  if (index === -1) {
    return 0;
  }
  return index;
}

function findHigh(array, value) {
  const index = _.findIndex(array, v => v > value);
  if (index === -1) {
    return 99;
  }
  return index;
}

</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.mid-month {
  color: $riseup_black;
  .transactions {
    font-weight: bold;
    padding: 10px 0;
    .transaction {
      margin-bottom: 5px;
    }
  }
}

</style>
