<template>
  <toaster @close-toaster="close">
    <template v-slot:text>
      <span v-if="wasPaused">
        ההודעות הושתקו למשך
        <span class="ri-font-weight-bold">{{durationText}}</span>.
      </span>
      <span v-else>
        ההודעות הופעלו.
      </span>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../base-components/Toaster';

export default {
  name: 'PausedMessagesToaster',
  components: {
    Toaster,
  },
  props: {
    wasPaused: {
      type: Boolean,
      required: true,
    },
    duration: {
      type: String,
    },
  },
  computed: {
    durationText() {
      return this.duration === 'week'
        ? 'שבוע'
        : 'חודש';
    },
  },
  methods: {
    close() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style scoped>

</style>
