<template>
  <action-card-with-icon text="שמתי בצד לתוכניות הגדולות" :action="openPlanAheadModal" :out-of-date="outOfDate"
                         icon-name="tour" :additional-icon-props="iconProps"/>
</template>

<script>
import { mapActions } from 'vuex';
import ActionCardWithIcon from '@/base-components/cashflow/ActionCardWithIcon';
import PlanAheadPastMonthModal from './PlanAheadPastMonthModal';

export default {
  name: 'PlanAheadPastMonthCard',
  components: {
    ActionCardWithIcon,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconProps() {
      return {
        size: '18px',
        weight: 'bold',
        opticalSize: 24,
        fill: true,
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openPlanAheadModal() {
      this.openModal({
        component: PlanAheadPastMonthModal,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
