<template>
  <next-steps-base-card title="וידאו - איך להשתמש בתזרים"
                        button-text="לצפייה בוידאו"
                        description="15 דקות"
                        :action-key="actionKey"
                        @click="openVideo"/>
</template>

<script>
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';

export default {
  name: 'IntroToCashflowVideoNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.INTRO_TO_CASHFLOW_VIDEO,
    };
  },
  methods: {
    openVideo() {
      console.log('IntroToCashflowVideoNextStep: NOT IMPLEMENTED');
    },
  },
};
</script>

<style scoped>

</style>
