<template>
  <div :class="['create-subscription-flow-v2', {'hide-back': flowStep === SUBSCRIPTION_FLOW_STEPS.done }]">
    <header-page :header-props="headerPageProps">
      <div v-if="flowStep === SUBSCRIPTION_FLOW_STEPS.subscriptionType">
        <subscription-type-v2 mode="create" />
        <div class="padding-ml">
          <riseup-button :title="buttonText" @click="onSubmit" color="blue" />
        </div>

      </div>

      <template v-else-if="flowStep === SUBSCRIPTION_FLOW_STEPS.creditCard">
        <!--We use v-show and not v-if because the credit-card component cannot be re-rendered-->
        <!--The fields in it are mounted by payme making rerendering heavy and destroying previous context-->
        <credit-card-v2
          v-show="flowStep === SUBSCRIPTION_FLOW_STEPS.creditCard" />
      </template>
      <done-v2 v-else-if="flowStep === SUBSCRIPTION_FLOW_STEPS.done" mode="create" />
    </header-page>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { ACTION_TYPE } from '@riseupil/base-ui/src/components/ActionIcon/ActionIcon.consts';
import router from '@/router';
import BaseUI from '@riseupil/base-ui';
import SubscriptionTypeV2 from './SubscriptionTypeV2.vue';
import CreditCardV2 from './CreditCardV2.vue';
import DoneV2 from './DoneV2.vue';
import Segment from '../../../../../Segment';

export default {
  name: 'CreateSubscriptionFlowV2',
  components: {
    SubscriptionTypeV2,
    CreditCardV2,
    DoneV2,
    RiseupButton: BaseUI.RiseupButton,
    HeaderPage: BaseUI.Pages.HeaderPage,
  },
  data() {
    return {
      headerPageProps: {
        rightActions: [{
          type: ACTION_TYPE.BACK,
          onClick: this.onBack,
        }],
        showLogo: true,
        backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
        logoColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        textColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        bottomBorder: true,
      },
    };
  },
  created() {
    Segment.trackUserGot('CreatedSubscriptionEntered', { subscriptionVersion: 'v2' });
  },
  computed: {
    ...mapGetters('subscription', ['SUBSCRIPTION_FLOW_STEPS']),
    ...mapState('subscription', ['flowStep', 'type']),
    ...mapState('benefits', ['benefitId']),
    buttonText() {
      return this.benefitId ? 'לבחירה ומימוש ההטבה' : 'להמשך';
    },
  },
  methods: {
    ...mapMutations('subscription', ['setStep', 'setType']),
    onSubmit() {
      Segment.trackUserGot('CreateSubscriptionFlow_SubmitClicked', {
        selectedType: this.type,
      });
      this.setStep(this.SUBSCRIPTION_FLOW_STEPS.creditCard);
    },
    onBack() {
      Segment.trackUserGot('CreateSubscriptionFlow_BackClicked', { currFlowStep: this.flowStep, subscriptionVersion: 'v2' });
      if (this.flowStep === this.SUBSCRIPTION_FLOW_STEPS.subscriptionType) {
        router.push({ path: '/account-settings', query: this.$route.query });
      } else if (this.flowStep === this.SUBSCRIPTION_FLOW_STEPS.creditCard) {
        this.setStep(this.SUBSCRIPTION_FLOW_STEPS.subscriptionType);
      }
    },
    onClose() {
      Segment.trackUserGot('CreateSubscriptionFlow_CloseClicked', { currFlowStep: this.flowStep, subscriptionVersion: 'v2' });
      router.push({ path: '/account-settings', query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-subscription-flow-v2 {
  &.hide-back ::v-deep .page-header .actions-with-logo .actions {
    visibility: hidden;
  }
}
</style>
