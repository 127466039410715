<template>
  <sticky-cta-page :ctaProps="ctaProps" @click="buttonAction">
    <div class="card-tour flex-1">
      <div class="body">
        <div class="card" >
          <slot name="card"></slot>
        </div>
        <div class="explanation">
          <div class="title ri-large-headline ri-alte"> {{ title }} </div>
          <div class="content ri-large-body"> {{ explanation }}</div>
        </div>
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>

import { mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'CardTour',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    explanation: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    buttonTitle: {
      type: String,
      required: true,
    },
    buttonAction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    ...mapGetters('session', ['householdName']),
    ctaProps() {
      return {
        buttonProps: {
          title: this.buttonTitle,
        },
        backgroundColor: BaseUI.Colors.riseupWhite,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

@keyframes fade
{
  from {opacity: 0;}
  to { opacity: 1;}
}

@keyframes enter
{
  from {top: 100px;}
  to { top: 0px;}
}

.card-tour {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $riseup_white;
    padding: 24px 30px 0px;

    .labeled-line {
      width: 100%;
      padding-top: 12px;
      position: relative;
      display: inline-block;

      .positive {
        background-color: $riseup_green;
      }

      .zero {
        background-color: $riseup_yellow;
      }

      .negative {
        background-color: $riseup_danger_red;
      }

      .label {
        height: 18px;
        line-height: 15px;
        font-size: 14px;
        padding: 2px 4px 2px 4px;
        display: inline-block;
        color: $riseup_white;
        font-weight: bold;
        position: absolute;
        right: 0;
        top: -3px;
      }

      .line {
        height: 3px;
      }

      .circle {
        position: absolute;
        left: -6px;
        top: 5px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: inline-block;
      }
    }

    .card {
      min-height: 184px;
      flex-grow: 1;
    }

    .explanation {
      margin-top: 40px;
      margin-bottom: 14px;
      color: $riseup_dark_green;
      text-align: center;

      .title {
        animation: fade 1.3s ease-in;
      }

      .content {
        margin: 16px 0;
        position: relative;
        animation: enter 0.8s ease-out, fade 1.5s ease-out;
      }
    }
  }

}

</style>
