<template>
  <div class="action-list-block">
    <div class="header">
      <circular-progress :percent="actionsCompletedPercentage"/>
      <span class="title">{{ title }}</span>
    </div>
    <separator/>
    <div v-for="(action, i) in actions" :key="action.type">
      <expandable-summary-row text-style="medium"
                              :with-shadow=false
                              :is-expandable="actionsMap[action.type].isExpandable"
                              @expanded="sendEvent(action.type);">
        <template v-slot:header>
          <div class="checkbox-title-wrapper">
            <div class="checkbox-wrapper" :class="{ 'checked': action.checked }">
              <img class="checkbox" src="@/assets/icons/small_check.svg">
            </div>
            <span class="action-title" :class="{ 'checked': action.checked }">{{ actionsMap[action.type].title }}</span>
          </div>
        </template>
        <template v-slot:content>
          <div v-if="actionsMap[action.type].isExpandable" class="expandable-action">
            <div class="explanation-text"
                 :class="{ 'checked': action.checked }">{{ actionsMap[action.type].explanationText }}</div>
            <riseup-button v-if="!(action.checked && [actionsTypes.SETUP_ACCOUNTS, actionsTypes.ADD_CATEGORIES].includes(action.type))"
                           :title="actionsMap[action.type].buttonText"
                           :action="() => goToAction(action.type)"
                           :size="'slim'"/>
          </div>
        </template>
      </expandable-summary-row>
      <separator v-if="i < actions.length - 1"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
// noinspection LongLine
import ExpandableSummaryRow
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/ExpandableSummaryRow';
import ACTIONS
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/action-list/actions.consts.ts';
import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';
import router from '@/router';
import cashflowViewConsts from '@/constants/cashflow-view';
// noinspection LongLine
import Intercom from '@/Intercom';
import { mapActions, mapGetters, mapState } from 'vuex';
import PapaMergeQuestionsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/PapaMergeQuestionsMixin';
import PapaMergeQuestionsPopupsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/PapaMergeQuestionsPopupsMixin.vue';
import TrackingCategoriesFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/tracking-categories/TrackingCategoriesFlow';
import dateUtils from '@/utils/dates';
import AddPlanFlow from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/plans-flow/AddPlanFlow';
import EditMonthlyGoalAmount from '../cashflow-edit/edit-riseup-goal/EditMonthlyGoalAmount';
import EditPredictedVariableIncomePopup from '../cashflow-edit/edit-predicted-variable-income/EditPredictedVariableIncomePopup';
import CircularProgress from './CircularProgress';
import PlanAheadIntroductionPopup from '../../../../../plan-ahead/PlanAheadIntroductionPopup';

export default {
  name: 'actionListBlock',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    Separator: BaseUI.Separator,
    ExpandableSummaryRow,
    CircularProgress,
  },
  mixins: [PapaMergeQuestionsMixin, PapaMergeQuestionsPopupsMixin],
  props: {
    actions: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('insights', ['filteredInsights', 'INSIGHT_NAMES']),
    ...mapGetters('featureFlags', ['showPlanAheadIntroPopup']),
    ...mapGetters('planAhead', ['hasPlans']),
    ...mapState('cashflowView', ['cashflowStartDay', 'cashflow']),
    actionsMap() {
      // noinspection LongLine
      return {
        [ACTIONS.SETUP_ACCOUNTS]: {
          title: 'חיברתי את החשבונות',
          isExpandable: true,
          action: this.openAllClassifiedMergeInvestigations,
          explanationText: 'נחבר את כל כרטיסי האשראי שבשימוש, כדי לקבל פירוט הוצאות מדויק ונבין לאן הכסף הולך.',
          buttonText: 'לחיבור כרטיסי אשראי',
        },
        [ACTIONS.FIRST_CF]: {
          title: 'קיבלתי תזרים חודשי ראשון!',
          isExpandable: false,
        },
        [ACTIONS.PREDICTED_VARIABLE_INCOME_CHECK]: {
          title: 'עדכנתי צפי הכנסות משתנות',
          explanationText:
            `אם ידוע על הכנסות משתנות שצפויות להיכנס החודש${this.cashflowEndDate}, מומלץ לעדכן כבר עכשיו כדי לקבל תזרים יותר מדויק.`,
          isExpandable: true,
          action: this.openEditPredictedVariableIncomePopup,
          buttonText: 'לעדכן צפי הכנסות',
        },
        [ACTIONS.FIXED_CHECK]: {
          title: 'מצאתי הוצאות מיותרות',
          explanationText: 'נבדוק אילו מההוצאות הקבועות שלנו מיותרות או ניתנות לביטול כמו כפל ביטוחים או חלק מעמלות הבנק והאשראי לדוגמא.',
          isExpandable: true,
          action: this.goToFixedExpenses,
          buttonText: 'להוצאות הקבועות',
        },
        [ACTIONS.ADD_CATEGORIES]: {
          title: 'הוספתי קטגוריות חדשות',
          explanationText: 'מתוך הקטגוריות שהמערכת זיהתה, נבחר באילו מהן נרצה להתמקד בתזרים.',
          isExpandable: true,
          action: this.openChooseCategoriesFlow,
          buttonText: 'לבחירת הקטגוריות',
        },
        [ACTIONS.SET_CHALLENGE]: {
          title: 'החלטתי איפה אחסוך החודש',
          explanationText: 'נבחר את אחת הקטגוריות בכחול - אלו הוצאות שכדאי לעקוב אחריהן. נחליט על אחת שננסה לצמצם החודש ונערוך את הסכום שלה בהתאם.',
          isExpandable: true,
          action: this.goToCategories,
          buttonText: 'לבחירת קטגוריה לצמצום',
        },
        [ACTIONS.SET_RISEUP_GOAL]: {
          title: 'הגדרתי כמה אשים בצד',
          // eslint-disable-next-line max-len
          explanationText: 'התזרים חיובי ויש לנו כרגע כסף פנוי שכדאי לחסוך. כדי לא לבזבז אותו נוכל לקבוע לנו סכום אותו  נרצה להשאיר בצד החודש.',
          isExpandable: true,
          action: this.openRiseupGoalPopup,
          buttonText: 'להגדרת הסכום',
        },
        [ACTIONS.OPEN_PLAN_AHEAD_PLAN]: {
          title: 'הוספתי תוכניות לעתיד',
          // eslint-disable-next-line max-len
          explanationText: 'התזרים חיובי ויש לנו כרגע כסף להסתכל קדימה. כדי לתכנן מראש, נפתח תוכניות שנרצה לחסוך עבורן כל חודש, וננסה להתאים את התזרים כך שישאר סכום לכל התוכניות.',
          isExpandable: true,
          action: this.openPlanAheadPopup,
          buttonText: 'לפתיחת תוכנית',
        },
        [ACTIONS.INVITE_MEMBER]: {
          title: 'צירפתי שותפ.ה לתזרים',
          explanationText: 'הרבה יותר קל לצמוח ביחד. אפשר להזמין את בן או בת הזוג להצטרף לתזרים ולנצח את החודש ביחד איתך.',
          isExpandable: true,
          action: this.addPartner,
          buttonText: 'להזמנת שותפ.ה לתזרים',
        },
        [ACTIONS.GO_TO_CHAT]: {
          title: 'התייעצתי עם הצוות בצ׳אט',
          explanationText: 'צוות המומחים של רייזאפ כאן בשבילך לאורך כל השירות ויעזור בכל שאלה טכנית, הבנה של התזרים או התלבטות כלכלית.',
          isExpandable: true,
          action: this.openChat,
          buttonText: 'לצ׳אט עם הצוות',
        },
        [ACTIONS.GO_TO_COMMUNITY]: {
          title: 'קיבלתי כח מהקהילה',
          explanationText: 'הקהילה של רייזאפ היא מקום מעולה להתלבט, להתייעץ, לדבר על הרגעים המאתגרים ולקבל בוסט של אופטימיות ותמיכה.',
          isExpandable: true,
          action: this.goToCommunity,
          buttonText: 'לקהילה של רייזאפ',
        },
      };
    },
    actionsCompletedPercentage() {
      return (this.actions.filter(action => action.checked).length / this.actions.length) * 100;
    },
    actionsTypes() {
      return ACTIONS;
    },
    cashflowEndDate() {
      return this.cashflowStartDay !== 1 ? ` (עד ה־${dateUtils.endOfMonthText(this.cashflow.budgetDate, this.cashflowStartDay)})` : '';
    },
  },
  methods: {
    ...mapActions('actions', ['setActionCheckedState']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('planAhead', ['addPlan']),
    async goToAction(type) {
      await this.setActionCheckedState({ type, checked: true });
      this.actionsMap[type].action();
      this.$emit('action-clicked', type);
    },
    sendEvent(type) {
      Segment.trackUserInteraction('ActionList_ItemExpanded', { type });
    },
    goToFixedExpenses() {
      EventBus.$emit('expand-to-edit', { type: cashflowViewConsts.cashflowTypes.fixedExpense });
      this.$emit('close');
    },
    goToCategories() {
      EventBus.$emit('expand-to-edit', { type: cashflowViewConsts.cashflowTypes.trackingCategory });
      this.$emit('close');
    },
    openChooseCategoriesFlow() {
      const insight = this.filteredInsights.find(insight => insight.insightName === this.INSIGHT_NAMES.TrackingCategoriesSelectionInsight);
      this.openModal({
        component: TrackingCategoriesFlow,
        props: { insight },
      });
    },
    openRiseupGoalPopup() {
      this.openModal({
        component: EditMonthlyGoalAmount,
      });
    },
    openPlanAheadPopup() {
      this.$emit('close');
      Segment.trackUserGot('PlanAhead_IntroductionPopup_Test',
        { showIntro: this.showPlanAheadIntroPopup, hasPlans: this.hasPlans, source: 'TrialChecklist' });
      if (this.showPlanAheadIntroPopup) {
        Segment.trackUserGot('PlanAhead_IntroductionPopup_Open', { source: 'TrialChecklist' });
        this.openModal({
          component: PlanAheadIntroductionPopup,
          popupAlignment: 'full-screen',
        });
      } else {
        router.push({ path: '/plan-ahead' });
        this.openModal({
          component: AddPlanFlow,
          props: {
            onDone: this.addPlan,
          },
          popupAlignment: 'full-screen',
        });
      }
    },
    addPartner() {
      router.push({ path: '/invite-member', query: this.$route.query });
      this.$emit('close');
    },
    openChat() {
      Intercom.show();
      this.$emit('close');
    },
    goToCommunity() {
      window.open('https://www.facebook.com/groups/riseuptogethergroup', '_blank');
    },
    openEditPredictedVariableIncomePopup() {
      Segment.trackUserInteraction('EditPredictedVariableIncome_ActionList_Clicked');
      this.openModal({ component: EditPredictedVariableIncomePopup });
    },
  },
};
</script>

<style scoped lang="scss">
.action-list-block {

  .header {
    display: flex;
    height: 80px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    padding: 25px 18px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    .title {
      position: relative;
      top: 3px;
      padding-right: 16px;
    }
  }

  ::v-deep .expanded .action-title {
    font-weight: 700;
  }

  .action-title {
    flex: 1;
    padding-right: 10px;
    text-align: start;
    font-size: 18px;
    line-height: 24px;

    &.checked {
      color: #9B9B9B;
    }
  }
  .checkbox-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
  }

  .checkbox-wrapper {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #F1F1F1;

    &.checked {
      background-color: #00C070;
    }

    .checkbox {
      padding: 6px;
    }
  }

  .expandable-action {
    margin-left: 20px;
    margin-right: 69px;
    text-align: right;

    .explanation-text {
      margin-bottom: 24px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;

      &.checked {
        color: #9B9B9B;

      }
    }

    .riseup-button {
      margin-bottom: 24px;

      ::v-deep .button-title {
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
}

</style>
