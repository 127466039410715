<script>
import Segment from '@/Segment';
import _ from 'lodash';
import EventBus from '@/event-bus/event-bus';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'TransactionAndBudgetDateParamHandler',
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.budgetDate !== from.params.budgetDate) {
      // The budget date in the url has changed
      await this.updatePresentedBudgetDate(to.params.budgetDate);
    }
    if (to.query.transactionId && to.query.transactionId !== from.query.transactionId) {
      // There is a transaction in the URL, but it wasn't there before
      this.$nextTick(() => this.openTransaction(to.query.transactionId));
    }
    if (!to.query.transactionId && from.query.transactionId) {
      // There is no transaction in the URL, but there was one before
      this.closeTransaction();
    }
    return next();
  },
  methods: {
    ...mapActions('cashflowView', ['updatePresentedBudgetDate']),
    openTransaction(transactionId, retries = 0, fromDeepLink = false) {
      if (retries > 100) {
        Segment.trackUserGot('TransactionDeepLink_TimedOut', { transactionId });
        return;
      }
      const uiIsReady = !_.isEmpty(this.cashflowUITree);
      if (!uiIsReady) {
        setTimeout(() => {
          this.openTransaction(transactionId, retries + 1, fromDeepLink);
        }, 250);
        return;
      }
      // using setImmediate to make sure the DOM settles before attempting to open the transaction
      setImmediate(() => EventBus.$emit('open-transaction', { transactionId, fromDeepLink }));
    },
    closeTransaction() {
      EventBus.$emit('close-transaction');
    },
  },
};

</script>
