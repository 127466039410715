<template>
  <div v-if="!loading" class="subscription-settings">
    <component v-bind:is="stateToComponentMapping[subscriptionState]"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TrialSettings from './TrialSettings.vue';
import DormantAfterTrialSettings from './DormantAfterTrialSettings.vue';
import SubscribedSettings from './SubscribedSettings.vue';
import CanceledSettings from './CanceledSettings.vue';
import FreeSubscriptionSettings from './FreeSubscriptionSettings.vue';
import OnboardingSettings from './OnboardingSettings.vue';
import Segment from '../../../../../Segment';
import { SUBSCRIPTION_STATES } from '../../../../../constants/subscription-state';

export default {
  name: 'SubscriptionSettings',
  components: {
    TrialSettings,
    DormantAfterTrialSettings,
    SubscribedSettings,
    CanceledSettings,
    FreeSubscriptionSettings,
    OnboardingSettings,
  },
  data() {
    return {
      stateToComponentMapping: {
        [SUBSCRIPTION_STATES.FREE]: FreeSubscriptionSettings,
        [SUBSCRIPTION_STATES.PRE_TRIAL]: OnboardingSettings,
        [SUBSCRIPTION_STATES.TRIAL]: TrialSettings,
        [SUBSCRIPTION_STATES.SUBSCRIBED]: SubscribedSettings,
        [SUBSCRIPTION_STATES.CANCELED]: CanceledSettings,
        [SUBSCRIPTION_STATES.DORMANT_AFTER_TRIAL]: DormantAfterTrialSettings,
        [SUBSCRIPTION_STATES.DORMANT_AFTER_CANCELED]: CanceledSettings,
      },
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    Segment.trackUserGot('SubscriptionSettingsEntered', { subscriptionState: this.subscriptionState });
    await this.fetchCreditCardMask();
    this.loading = false;
  },
  computed: {
    ...mapState('subscriptionState', ['subscriptionState']),
  },
  methods: {
    ...mapActions('subscription', ['fetchCreditCardMask']),
  },
};
</script>

<style scoped lang="scss">

  @import "~@riseupil/base-ui/src/scss/riseup-colors";

  .subscription-settings {
    background-color: $riseup_white;
    margin-bottom: 14px;
  }

</style>
