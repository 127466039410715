<template>
  <div class="next-payment-section-v2">
    <div v-if="isPercentageDiscountBenefit">
      <property class="margin-bottom-m" :name="monthString" :value="`${ currentPrice } בחודש`" :strike-value="`${this.pricing.monthly} ₪`"/>
      <div v-if="isYearlySubscription" class="margin-bottom-m">
        <property class="margin-bottom-xs" :name="`חודש ${benefitAmount + 1}`" :value="paymentAmount"/>
        <discount-note :text="`הנחה של ${yearlyDiscountPercentageAmount}%`" />
      </div>
    </div>
    <div v-else>
      <div class="margin-bottom-m">
        <property :class="{ 'margin-bottom-xs': isYearlySubscription }" name="סך הכל לתשלום" :value="paymentAmount"/>
        <discount-note v-if="isYearlySubscription" :text="`הנחה של ${yearlyDiscountPercentageAmount}%`" />
      </div>
    </div>

  </div>
</template>

<script>
import Property from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/Property.vue';
import BenefitMixin from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitMixin.vue';
import { mapGetters, mapState } from 'vuex';
import DiscountNote from '@/pages/responsive-pages/authenticated/subscription/DiscountNote.vue';
import money from '@/utils/money';
import { SUBSCRIPTION_TYPES } from '@/constants/subscription-state';

export default {
  name: 'NextPaymentSectionV2',
  mixins: [BenefitMixin],
  components: { DiscountNote, Property },
  computed: {
    ...mapGetters('subscription', ['yearlySavingAgainstMonthlyPercentage']),
    ...mapState('subscription', ['price']),
    ...mapState('benefits', ['benefitAmount']),
    ...mapState('subscription', ['pricing', 'type']),
    paymentAmount() {
      return this.isYearlySubscription
        ? `${this.pricing.annual} ₪`
        : `${this.pricing.monthly} ₪ בחודש`;
    },
    yearlyDiscountPercentageAmount() {
      return money.formatAmountWithFraction(this.yearlySavingAgainstMonthlyPercentage, 0);
    },
    isYearlySubscription() {
      return this.type === SUBSCRIPTION_TYPES.YEARLY;
    },
    currentPrice() {
      return `${this.price / 100} ₪`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
 .next-payment-section-v2 {

  }
</style>
