<script >
/*
This file was copied from Mamadmin with some reductions. Once the Excel is deployed in Elsa should be deleted from Mamadmin
 */
import _ from 'lodash';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';
const currencySymbols = { USD: '$', EUR: '€', GBP: '£', ILS: '₪' };

export default {
  name: 'ExportAnlAsCsv',
  methods: {
    exportCsv({
      balances,
      mislakaAssets,
      savingsAccounts,
      loans,
      mortgages,
      securities,
    }) {
      return this.getAnlRows({ balances, mislakaAssets, savingsAccounts, loans, mortgages, securities });
    },
    parseRow(row, type) {
      const {
        sourceIdentifier,
        financialAccountNumber,
        product,
        status,
        name,
        interests,
        details,
        isCreditLimitIncluded,
        contractAvailabilityDate,
        balanceReferenceDate,
      } = row;
      const parsedDetails = this.isJSONParsable(details) ? JSON.parse(details || '{}') : '';
      const childLoan = type === 'loan' && sourceIdentifier === 'poalim' && !!parsedDetails.parentLoanResourceId;
      return {
        sourceIdentifier,
        financialAccountNumber,
        product: childLoan ? `${product} מסלולים` : product,
        status,
        name,
        balanceAmount: this.formatAmount(row.balanceAmount),
        contractStartDate: this.formatDate(row.contractStartDate),
        contractEndDate: this.formatDate(row.contractEndDate),
        type,
        interestRate: this.extractInterest(interests),
        extraDetails: {
          interests,
          details,
          isCreditLimitIncluded,
          contractAvailabilityDate,
          balanceReferenceDate,
        },
      };
    },
    isJSONParsable(text) {
      try {
        JSON.parse(text);
        return true;
      } catch (error) {
        return false;
      }
    },
    extractInterest(interests) {
      const rates = _.chain(interests)
        .map(i => {
          const rate = i.rate && i.rate.length === 1 ? Number(i.rate[0].percentage) : 'N/A';
          return [[i.type], rate];
        })
        .fromPairs()
        .value();
      if (typeof rates.FIXD === 'number' && typeof rates.INDE === 'number') {
        return rates.FIXD + rates.INDE;
      }
      if (typeof rates.FIXD === 'number' && !rates.INDE) {
        return rates.FIXD;
      }
      return null;
    },
    parseSecurities(row) {
      const {
        sourceIdentifier,
        product,
        status,
        name,
        positions,
      } = row;
      return {
        sourceIdentifier,
        financialAccountNumber: name,
        product,
        status,
        name,
        type: 'securities',
        balanceAmount: this.formatAmount(row.balances[0]?.balanceAmount),
        extraDetails: {
          positions,
        },
      };
    },
    parsedMislakaAssets(mislakaAssets) {
      return [
        ...this.parseMislakaAssets({ assets: mislakaAssets.educationFunds, type: 'קרן השתלמות' }),
        ...this.parseMislakaAssets({ assets: mislakaAssets.gemelInvest, type: 'קופת גמל להשקעה' }),
        ...this.parseMislakaAssets({ assets: mislakaAssets.savingsPolicies, type: 'פוליסת חסכון' }),
        ...this.parseMislakaAssets({ assets: mislakaAssets.childrenSavings, type: 'חסכון לכל ילד' }),
      ];
    },
    formatDate(date) {
      return moment(date).format(DATE_FORMAT);
    },
    formatAmount(balance) {
      return balance ? `${currencySymbols[balance.currency]}${balance.amount}` : '?';
    },
    parseMislakaAssets({ assets, type }) {
      return _.map(assets, fund => {
        return {
          balanceAmount: this.formatAmountWithFraction(fund.balance),
          type,
          contractEndDate: this.formatDate(fund.liquidityDate),
          sourceIdentifier: fund.companyName,
          feesFromDeposit: fund.fees.find(f => f.typeId === '1')?.value,
          feesFromBalance: fund.fees.find(f => f.typeId === '2')?.value,
          extraDetails: _.orderBy(fund.monthlyDeposits, ['date'], ['desc']),
        };
      });
    },
    formatAmountWithFraction(value, fractionDigits = 1) {
      const amount = Math.abs(Number(value));
      const result = amount.toLocaleString(undefined, { maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits });
      return result.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    getAnlRows({ balances, mislakaAssets, savingsAccounts, loans, mortgages, securities }) {
      const parsedSavingsAccounts = _.map(savingsAccounts, row => this.parseRow(row, 'savings-account'));
      const parsedLoans = _.map(loans, row => this.parseRow(row, 'loan'));
      const parsedMortagages = _.map(mortgages, row => this.parseRow(row, 'mortgage'));
      const parsedSecurities = _.map(securities, row => this.parseSecurities(row));
      const parsedBalances = _.map(balances, balance => {
        return { sourceIdentifier: balance.source, balanceAmount: this.formatAmountWithFraction(balance.balance), type: 'עו״ש' };
      });

      return [...parsedSavingsAccounts, ...parsedLoans, ...parsedMortagages,
        ...parsedSecurities, ...parsedBalances, ...this.parsedMislakaAssets(mislakaAssets)];
    },
  },
};
</script>
