<template>
  <altshuler-signup-step :cta-props="pageCtaProps" :current-step-index="currentStepIndex" current-step-name="העלאת תעודה מזהה"
                         :total-steps="totalSteps" :wizard-state="wizardState" :title="pageTitle" @next="nextStep">
    <upload-image class="margin-top-ml"
                  @click="uploadClicked(1)"
                  @select="imageSelected1"
                  @delete="removedSelectedImage1"
                  :existing-image="savedImage1" />
    <upload-image v-if="isBiometric" class="margin-top-ml"
                  @click="uploadClicked(2)"
                  @select="imageSelected2"
                  @delete="removedSelectedImage2"
                  :existing-image="savedImage2"/>
  </altshuler-signup-step>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import * as AltshulerApi from '@/api/AltshulerApi';
import AltshulerSignupStep from './AltshulerSignupStep';

export default {
  name: 'AltshulerSignupIdUpload',
  components: {
    AltshulerSignupStep,
    UploadImage: BaseUI.UploadImage,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedImage1: undefined,
      selectedImage2: undefined,
      loading: false,
      iconColor: BaseUI.Colors.riseupGreen,
      savedImage1: undefined,
      savedImage2: undefined,
    };
  },
  created() {
    this.savedImage1 = !!(this.wizardState.idDocument?.idImagePathFront || this.wizardState.idDocument?.idImagePath);
    this.savedImage2 = !!this.wizardState.idDocument?.idImagePathBack;
  },
  computed: {
    isBiometric() {
      return this.wizardState.idDocument.idType === 'biometricId';
    },
    imageExists() {
      if (this.isBiometric) {
        return this.savedImage1 && this.savedImage2;
      }
      return this.savedImage1;
    },
    pageTitle() {
      if (this.isBiometric) {
        return 'העלאת תעודת זהות ביומטרית';
      }
      if (this.wizardState.idDocument.idType === 'passport') {
        return 'העלאת דרכון';
      }
      if (this.wizardState.idDocument.idType === 'driversLicense') {
        return 'העלאת רשיון נהיגה';
      }

      return 'העלאת תעודת זהות';
    },
    nextDisabled() {
      if (this.loading) {
        return true;
      }
      if (this.imageExists) {
        return false;
      }
      if (this.isBiometric) {
        return !this.selectedImage1 || !this.selectedImage2;
      }
      return !this.selectedImage1;
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    uploadClicked(imageNumber) {
      Segment.trackUserInteraction('Altshuler_SignupIdUpload_UploadClicked', { imageNumber });
    },
    removedSelectedImage1() {
      this.selectedImage1 = undefined;
      this.savedImage1 = undefined;
    },
    removedSelectedImage2() {
      this.selectedImage2 = undefined;
      this.savedImage2 = undefined;
    },
    imageSelected1(image) {
      Segment.trackUserInteraction('Altshuler_SignupIdUpload_UploadSelected', { imageNumber: 1 });
      this.selectedImage1 = image;
    },
    imageSelected2(image) {
      Segment.trackUserInteraction('Altshuler_SignupIdUpload_UploadSelected', { imageNumber: 2 });
      this.selectedImage2 = image;
    },
    async nextStep() {
      Segment.trackUserInteraction('Altshuler_SignupIdUpload_NextClicked',
        { imageExists: this.imageExists, isBiometric: this.isBiometric });
      this.loading = true;
      if (this.imageExists) {
        this.$emit('next', { idDocument: this.wizardState.idDocument });
        return;
      }
      const idDocument = { idType: this.wizardState.idDocument.idType };
      if (this.isBiometric) {
        const [res1, res2] = await Promise.all([
          AltshulerApi.uploadSocialId(this.selectedImage1),
          AltshulerApi.uploadSocialId(this.selectedImage1),
        ]);
        idDocument.idImagePathFront = res1.path;
        idDocument.idImagePathBack = res2.path;
      } else {
        const { path } = await AltshulerApi.uploadSocialId(this.selectedImage1);
        idDocument.idImagePath = path;
      }
      Segment.trackUserInteraction('Altshuler_SignupIdUpload_UploadedSuccessfully', { isBiometric: this.isBiometric });
      this.$emit('next', { idDocument });
    },
  },
};
</script>

<style scoped>

</style>
