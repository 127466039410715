<script>

import _ from 'lodash';
import { mapState } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import moneyUtils from '@/utils/money';

export default {
  name: 'SavingCategory',
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
    savingExpenses() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.fixedSaving];
    },
    oneTimeSaving() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.oneTimeSaving][0];
    },
    oneTimeSavingCategories() {
      return this.oneTimeSaving?.categories || [];
    },
    oneTimeSavingCategory() {
      return this.oneTimeSavingCategories[0];
    },
    anyFixedSaving() {
      return this.savingExpenses.categories.length > 0;
    },
    anyOneTimeSaving() {
      return _.defaultTo(this.oneTimeSavingCategory?.transactions?.length, 0) > 0;
    },
    allSavingTransactions() {
      const recommendedOrExpectedAmount = this.savingExpenses.recommendedOrExpectedAmount
        + _.defaultTo(this.oneTimeSavingCategory?.recommendedAmount, 0);
      const upToNowAmount = this.savingExpenses.upToNowAmount + _.defaultTo(this.oneTimeSavingCategory?.actualsSum, 0);
      const categories = [...this.savingExpenses.categories, ...this.oneTimeSavingCategories];
      return {
        categories,
        recommendedOrExpectedAmount,
        upToNowAmount,
      };
    },
    hasAnySavings() {
      return this.anyFixedSaving || this.anyOneTimeSaving;
    },
    savingAmount() {
      return moneyUtils.formatAmountWithFraction(this.allSavingTransactions.upToNowAmount, 0);
    },
  },
};

</script>
