import { render, staticRenderFns } from "./PlanAheadPageMenu.vue?vue&type=template&id=24e07da0&scoped=true&"
import script from "./PlanAheadPageMenu.vue?vue&type=script&lang=js&"
export * from "./PlanAheadPageMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24e07da0",
  null
  
)

export default component.exports