<template>
  <next-steps-base-card title="לקבוע כמה כסף ישאר לך בצד בסוף החודש"
                        button-text="לקביעת יעד חודשי"
                        description="2 דקות, פעולות קבועות בתזרים"
                        :action-key="actionKey"
                        :disabled="isGoalCustom"
                        @click="setMonthlyGoal"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';
import EditMonthlyGoalAmount from '../../../cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-riseup-goal/EditMonthlyGoalAmount';
import RiseupGoalUpdatedToaster from '../../../cashflow-app/toasters/RiseupGoalUpdatedToaster';

export default {
  name: 'SetMonthlyGoalNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.SET_MONTHLY_GOAL,
    };
  },
  computed: {
    ...mapGetters('cashflowView', ['isGoalCustom']),
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    setMonthlyGoal() {
      this.openModal({
        component: EditMonthlyGoalAmount,
        props: {
          onSaveCb: this.onSave,
        },
      });
    },
    onSave() {
      Segment.trackUserInteraction('MyProgress_NextStep_SetMonthlyGoal_Saved');
      EventBus.$emit('open-toaster', {
        component: RiseupGoalUpdatedToaster,
      });
    },
  },
};
</script>

<style scoped>

</style>
