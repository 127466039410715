<template>
  <next-steps-base-card title="וובינר: מה זה בעצם תזרים ואיך להתשמש בו בצורה הכי נכונה"
                        button-text="להרשמה לוובינר"
                        description="שעה, מפגש אונליין"
                        :action-key="actionKey"
                        @click="openWebinarSignup"/>
</template>

<script>
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';

export default {
  name: 'WebinarNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.WEBINAR,
    };
  },
  methods: {
    openWebinarSignup() {
      window.open('https://riseup.vp4.me/welcome_webinar', '_blank');
    },
  },
};
</script>

<style scoped>

</style>
