<template>
  <bottom-sheet block-content @close="closeMenu">
    <template v-slot:menuItems>
      <bottom-sheet-menu-item key="editOrder" @click="changePrioritiesModal" text="תיעדוף תוכניות"
                              :icon-props="{ iconName: 'toc'}" :disabled="ongoingPlans.length < 2"/>
    </template>
  </bottom-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Segment from '@/Segment';
import BaseUi from '@riseupil/base-ui';
import EditPrioritiesModal from './active-plans/EditPrioritiesModal';

export default {
  name: 'PlanAheadPageMenu',
  components: {
    BottomSheet: BaseUi.BottomSheet,
    BottomSheetMenuItem: BaseUi.BottomSheetMenuItem,
  },
  computed: {
    ...mapGetters('planAhead', ['ongoingPlans']),
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    changePrioritiesModal() {
      this.closeMenu();
      Segment.trackUserInteraction('PlanAheadDashboard_ActivePlansMenu_EditPriorities_Clicked');
      this.openModal({
        component: EditPrioritiesModal,
      });
    },
    closeMenu() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
