<template>
  <div class="current-month-cashflow-view">
    <div class="current-cashflow-view-container">
      <action-list-badge v-if="shouldShowActionsBadge"/>
      <cashflow-type-base-summary-amount :out-of-date="outOfDate" :showOshButtonOnSummaryAmount="true" class="card-wrapper"/>
      <cashflow-type-base-monthly-goal v-if="showMonthlyGoal" :out-of-date="outOfDate" class="card-wrapper"/>
      <cashflow-type-base-variable-expense :out-of-date="outOfDate" :time-frame="'weekly'" class="card-wrapper"/>
      <cashflow-type-base-economy :out-of-date="outOfDate" class="card-wrapper"/>
      <transition-group name="list">
        <div v-for="subtree in trackingCategories" :key="subtree.categoryId">
          <cashflow-type-base-tracking-category :out-of-date="outOfDate" :subtree=subtree class="card-wrapper" />
        </div>
        <tracking-category-creation-section v-if="!outOfDate" :key="'create-section'"/>
      </transition-group>
      <cashflow-type-base-saving-category :out-of-date="outOfDate" class="card-wrapper" />
      <cashflow-type-base-fixed-expense :out-of-date="outOfDate" class="card-wrapper"/>
      <cashflow-type-total-expenses-so-far :out-of-date="outOfDate" class="card-wrapper"/>
      <separator class="divider"/>
      <cashflow-type-base-income :out-of-date="outOfDate" class="card-wrapper"/>
    </div>
    <div class="current-cashflow-view-container">
      <div class="divider" v-if="this.hasExcluded" />
      <cashflow-type-base-excluded v-if="this.hasExcluded" :out-of-date="outOfDate" class="card-wrapper"/>
    </div>
    <div v-if="show100DaysCard" class="current-cashflow-view-container">
      <div class="divider" />
      <im-on-it100-days id="im-on-it100-days" class="card-wrapper" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import BalanceMoneyCalculatorStep
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/balance-money/BalanceMoneyCalculatorStep';
import MonthOpeningExperienceInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/MonthOpeningExperienceInsightPopup.vue';
import EventBus from '@/event-bus/event-bus';
import DormantAfterOnItChallengePopup
  from '@/pages/responsive-pages/authenticated/dormant-after-on-it-challenge/DormantAfterOnItChallengePopup.vue';
import ImOnIt100Days from './components/im-on-it-100-days/ImOnIt100Days.vue';
import Segment from '../../../../../../../Segment';
import cashflowViewConsts from '../../../../../../../constants/cashflow-view';
import TrackingCategoryCreationSection from './components/cashflow-edit/edit-reccuring/TrackingCategoryCreationSection.vue';
import CashflowTypeBaseEconomy from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseEconomy';
import CashflowTypeBaseExcluded from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseExcluded';
import CashflowTypeBaseFixedExpense from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseFixedExpense';
import CashflowTypeBaseIncome from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseIncome';
import CashflowTypeBaseTrackingCategory from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseTrackingCategory';
import CashflowTypeBaseSavingCategory from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseSavingCategory';
import CashflowTypeBaseVariableExpense from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseVariableExpense';
import CashflowTypeBaseMonthlyGoal from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseMonthlyGoal';
import CashflowTypeBaseSummaryAmount from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseSummaryAmount';
import CashflowTypeTotalExpensesSoFar from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeTotalExpensesSoFar';
import ActionListBadge from './components/action-list/ActionListBadge';
import {
  RECOGNIZED_FIXED_EXPENSE_TREND_PAGES,
  RECOGNIZED_TRACKING_CATEGORY_TREND_PAGES,
  TREND_COMPONENTS,
} from '../cashflow-trend/cashflow-trends';
import TrackingCategoryCashflowTrend from '../cashflow-trend/TrackingCategoryCashflowTrend';

export default {
  name: 'CurrentMonthCashflowView',
  components: {
    ImOnIt100Days,
    TrackingCategoryCreationSection,
    CashflowTypeBaseEconomy,
    CashflowTypeBaseExcluded,
    CashflowTypeBaseFixedExpense,
    CashflowTypeBaseIncome,
    CashflowTypeBaseTrackingCategory,
    CashflowTypeBaseSavingCategory,
    CashflowTypeBaseVariableExpense,
    CashflowTypeBaseMonthlyGoal,
    CashflowTypeBaseSummaryAmount,
    CashflowTypeTotalExpensesSoFar,
    Separator: BaseUI.Separator,
    ActionListBadge,
  },
  async created() {
    Segment.trackUserGot('CurrentCashflowViewEntered');
    await Promise.all([
      this.fetchActiveChallenge(),
      this.fetchSubscription(),
      this.fetchActions(),
      this.initPlans(),
      this.initEouDismissed(),
    ]);

    if (this.$route.query.trendView) {
      if ([...RECOGNIZED_FIXED_EXPENSE_TREND_PAGES, ...RECOGNIZED_TRACKING_CATEGORY_TREND_PAGES].includes(this.$route.query.trendView)) {
        this.openTrendView(this.$route.query.trendView);
      }
    } else if (this.$route.query.balanceMoneyCalculator) {
      this.openBalanceMoneyCalculator();
    } else if (this.$route.query.openMonth) {
      this.openOpenMonthPopup();
    } else {
      this.showDormantPopupIfNeeded();
    }
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    performanceService.markStart(PerformanceMarks.DESTROY_CURRENT_CF_VIEW);
  },
  destroyed() {
    performanceService.markEndAndLog(PerformanceMarks.DESTROY_CURRENT_CF_VIEW);
  },
  computed: {
    ...mapState('cashflowView', ['finishLoadingAllBudgets', 'cashflowUITree']),
    ...mapState('planAhead', ['eligibleForPlanAhead']),
    ...mapGetters('actions', ['shouldShowActionsBadge']),
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth', 'hasExcluded']),
    ...mapGetters('imOnIt', ['show100DaysCard']),
    ...mapGetters('planAhead', ['hasActivePlans']),
    ...mapGetters('subscriptionState', ['isDormant']),
    ...mapGetters('imOnIt', ['imOnItChallengeId', 'hasChallengeEnded', 'is100DaysProgram']),
    ...mapGetters('dormantAfterOnItChallengePopup', ['shouldShowPopup']),

    isPredictedCashflowPositive() {
      return this.predictedBalanceAtEndOfMonth >= 0;
    },
    trackingCategories() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.trackingCategory];
    },
    showMonthlyGoal() {
      return (this.eligibleForPlanAhead && this.hasActivePlans) || this.isPredictedCashflowPositive;
    },
  },
  methods: {
    ...mapActions('challenge', ['fetchActiveChallenge']),
    ...mapActions('actions', ['fetchActions']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('planAhead', ['initPlans']),
    ...mapActions('subscriptionState', ['initEouDismissed']),
    ...mapActions('subscription', ['fetchSubscription']),
    ...mapActions('dormantAfterOnItChallengePopup', ['setIsShownTrue']),

    openTrendView(pageName) {
      // wait until this.finishLoadingAllBudgets is true to invoke openModal
      if (!this.finishLoadingAllBudgets) {
        setTimeout(() => {
          this.openTrendView(pageName);
        }, 100);
        return;
      }
      // eslint-disable-next-line no-unused-vars
      const { trendView, ...query } = this.$route.query;
      this.$router.replace({ path: this.$route.path, query });
      if (RECOGNIZED_FIXED_EXPENSE_TREND_PAGES.includes(trendView)) {
        this.openModal({
          component: TREND_COMPONENTS.fixedExpense,
          props: { deepLinkToPage: pageName },
        });
      } else {
        const { categoryId } = _.find(this.cashflowUITree.trackingCategory, ({ categoryName }) => categoryName === pageName) || {};
        if (categoryId) {
          this.openModal({
            component: TrackingCategoryCashflowTrend,
            props: { categoryId },
          });
        }
      }
    },
    openBalanceMoneyCalculator() {
      this.openModal({
        component: BalanceMoneyCalculatorStep,
        props: { deepLink: true, stepName: 'deep-linked-OshCalculator' },
      });
      // eslint-disable-next-line no-unused-vars
      const { balanceMoneyCalculator, ...query } = this.$route.query;
      this.$router.replace({ path: this.$route.path, query });
    },
    openOpenMonthPopup() {
      this.openModal({
        component: MonthOpeningExperienceInsightPopup,
      });
      // eslint-disable-next-line no-unused-vars
      const { openMonth, ...query } = this.$route.query;
      this.$router.replace({ path: this.$route.path, query });
    },
    openTransaction(transactionId, retries = 0) {
      if (retries > 100) {
        Segment.trackUserGot('TransactionDeepLink_TimedOut', { transactionId });
        return;
      }
      const uiIsReady = !_.isEmpty(this.cashflowUITree);
      if (!uiIsReady) {
        setTimeout(() => {
          this.openTransaction(transactionId, retries + 1);
        }, 250);
        return;
      }
      // using setImmediate to make sure the DOM settles before attempting to open the transaction
      setImmediate(() => EventBus.$emit('open-transaction', { transactionId }));
    },
    // show DormantAfterOnItChallenge popup if customer is dormant and has ended the 100 days program
    showDormantPopupIfNeeded() {
      if (this.shouldShowPopup && this.isDormant && this.imOnItChallengeId && this.is100DaysProgram && this.hasChallengeEnded) {
        this.setIsShownTrue();
        this.openModal({
          component: DormantAfterOnItChallengePopup,
        });
      }
    },
  },
  // The on-it store init is async, so we need to watch for changes in imOnItChallengeId
  watch: {
    async imOnItChallengeId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.showDormantPopupIfNeeded();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../../../scss/mixins';

@keyframes shake {
  0% { transform: translate(2px, 0); }
  50% { transform: translate(-2px, 0); }
  100% { transform: translate(2px, 0); }
}

.current-month-cashflow-view {
  padding-bottom: 80px;

  @media (min-width: 430px) {
    padding-bottom: 20px;
  }

  .current-cashflow-view-container {
    @include responsive-padding;
    font-family: SimplerPro;
    text-align: right;
    width: 100%;
    margin: 0 auto;

    .card-wrapper {
      @include cashflow-card-margin;
    }

    .divider {
      height: 1px;
      width: 100%;
      background: $riseup_gray_0;
      margin: 25px 0;

      &.tight {
        margin-bottom: 0;
      }
    }

    .list-enter-active, .list-leave-active {
      transition: all 1s;
    }
    .list-enter, .list-leave-to {
      opacity: 0;
    }
    .list-enter-to {
      opacity: 1;
    }
  }
}
</style>
