<template>
  <next-steps-base-card title="מה הצעדים הראשונים כדי להבטיח את השקט שלנו בעזרת כרית ביטחון"
                        button-text="לקריאת המאמר"
                        description="7 דקות, מאמר"
                        :action-key="actionKey"
                        @click="openBlogPost"/>
</template>

<script>
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';

export default {
  name: 'EmergencyFundBlogPostNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.EMERGENCY_FUND_BLOG_POST,
    };
  },
  methods: {
    openBlogPost() {
      // eslint-disable-next-line max-len
      window.open('https://intercom.riseup.co.il/he/articles/8637213-%D7%90%D7%99%D7%9A-%D7%9C%D7%91%D7%A0%D7%95%D7%AA-%D7%9B%D7%A8%D7%99%D7%AA-%D7%91%D7%99%D7%98%D7%97%D7%95%D7%9F', '_blank');
    },
  },
};
</script>

<style scoped>

</style>
