<template>
  <next-steps-base-card title="לבחור קטגוריה שהכי נרצה לצמצם בה הוצאות ולהציב לה יעד"
                        button-text="קביעת יעד לקטגוריה"
                        description="2 דקות, פעולות קבועות בתזרים"
                        :action-key="actionKey"
                        :disabled="hasActiveChallenge"
                        @click="setCategoryGoal"/>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import ChallengeFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/challenge-flow/ChallengeFlow';
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';

export default {
  name: 'SetCategoryGoalNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.SET_CATEGORY_GOAL,
    };
  },
  computed: {
    ...mapState('challenge', ['activeChallenge']),
    hasActiveChallenge() {
      return !_.isEmpty(this.activeChallenge);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    setCategoryGoal() {
      this.openModal({
        component: ChallengeFlow,
        props: { sourceComponent: this.$options.name },
      });
    },
  },
};
</script>

<style scoped>

</style>
