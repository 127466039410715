<template>
  <popup class="cancel-subscription-popup" :close-action="closePopup" :back-action="shouldShowBackButton">
    <template v-slot:content>
      <churn-step1 v-if="step === 1 && !moneyBack" :next-step="switchMoneyBack" :close="close" :variant="variant"/>
      <churn-step1-money-back v-else-if="step === 1 && moneyBack" :next-step="nextStep" :close="close"/>
      <cancel-step-approve v-else-if="step === 2" :next-step="cancelSubscription"/>
    </template>
    <template v-slot:footer>
      <div class="cancel" v-on:click="close">
        <span class="delete-text">ביטול</span>
      </div>
    </template>
  </popup>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import router from '@/router';
import Segment from '@/Segment';
import ChurnStep1MoneyBack from '@/pages/responsive-pages/authenticated/churn/ChurnStep1MoneyBack';
import ChurnSurveyFlow from '@/pages/responsive-pages/non-authenticated/churn/ChurnSurveyFlow.vue';
import CustomersApi from '@/api/CustomersApi';
import ChurnStep1 from './ChurnStep1.vue';
import CancelStepApprove from './CancelStepApprove.vue';

export default {
  name: 'CancelSubscriptionPopup',
  components: {
    Popup: BaseUI.Popup,
    ChurnStep1,
    ChurnStep1MoneyBack,
    CancelStepApprove,
  },
  props: {
    memberInviteImmediateChurn: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      step: 0,
      moneyBack: false,
      churnSurveyStep: false,
      isLoadingAfterCancel: false,
      versionDonePromiseResolver: null,
      variant: 'cancelSubscription',
    };
  },
  created() {
    Segment.trackUserInteraction('ChurnPopup_Shown', { variant: this.variant });
    this.saveChurnSurveyDetails();
    this.openChurnSurvey();
    this.churnSurveyStep = true;
    this.setCustomerIntent('cancelled subscription');
  },
  computed: {
    ...mapGetters('subscriptionState', ['isMbgEnded', 'hasAcceptedMoneyBackOffer']),
    ...mapState('websocket', ['socketMessage']),
    moneyBackStep() {
      return this.step === 1 && this.moneyBack;
    },
    shouldShowBackButton() {
      return (this.step === 1 && !this.moneyBackStep) || this.step === 2 ? null : this.goBack;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('churn', ['churnCustomer', 'churnThroughMemberInvite']),
    ...mapActions('churnSurvey', ['saveChurnSurveyDetails', 'setCustomerIntent']),
    closePopup() {
      Segment.trackUserInteraction('ChurnPopup_CloseClicked', { variant: this.variant });
      this.close();
    },
    close() {
      this.$emit('close');
    },
    goBack() {
      if (this.churnSurveyStep) {
        this.close();
      } else if (this.step === 1) {
        this.switchMoneyBack();
      } else {
        this.step--;
      }
    },
    switchMoneyBack() {
      if (this.hasAcceptedMoneyBackOffer && !this.isMbgEnded) {
        this.moneyBack = !this.moneyBack;
      } else {
        this.nextStep();
      }
    },
    nextStep() {
      this.step++;
    },
    openChurnSurvey() {
      this.openModal({
        component: ChurnSurveyFlow,
        popupAlignment: 'full-screen',
        props: {
          onClose: this.nextStep,
          onBack: this.goBack,
          continueButtonText: 'להפסיק את המנוי',
        },
      });
    },
    async cancelSubscription() {
      await CustomersApi.cancelSubscription();
      this.versionDonePromiseResolver = () => {};
      await new Promise(this.versionDonePromiseResolver);
    },
  },
  watch: {
    async socketMessage(newValue, oldValue) {
      if (this.versionDonePromiseResolver && newValue !== oldValue) {
        this.versionDonePromiseResolver(null);
        await router.push({ path: 'subscription-cancelled' });
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.loader {
  padding: 60px 0;
}

.cancel-subscription-popup.popup {
  ::v-deep .top .content {
    margin-bottom: 0;
  }

  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .delete-text {
      color: $riseup_black;
      font-size: 18px;
      font-weight: normal;
    }
  }
}
</style>
