<template>
  <next-steps-base-card title="לבחור לאן להעביר כסף שנצטרך זמין לתקופה הקרובה"
                        button-text="לחיסכון לטווח קצר"
                        description="7 דקות, קריאה"
                        :action-key="actionKey"
                        @click="goToShortTermSaving"/>
</template>

<script>
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';

export default {
  name: 'ShortTermSavingNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.SHORT_TERM_SAVING,
    };
  },
  methods: {
    goToShortTermSaving() {
      this.$router.push({ path: '/balance-money' });
    },
  },
};
</script>

<style scoped>

</style>
