<template>
  <popup :close-action="closeActionClicked" :buttons="buttons" class="dormant-after-on-it-challenge-popup">
    <template v-slot:content>
      <div>
        <div class="header-image">
        <img class="on-it-image margin-top-m margin-bottom-sm" alt="flower background"  src="/images/im-on-it/on-it-together-popup-flower-bg.svg"/>
          <img v-if="lowEngagement" class="on-it-logo" alt="on it logo"  src="/images/im-on-it/on-it-together-logo.svg"/>
          <div v-else>
            <img class="on-it-together-logo" alt="on it logo"  src="/images/im-on-it/on-it-together-logo.svg"/>
            <img v-if="mediumEngagement" class="on-it-badge" alt="on it medium badge"  src="/images/im-on-it/medium-engagement-badge.svg"/>
            <img v-if="hightEngagement" class="on-it-badge" alt="on it high badge"  src="/images/im-on-it/high-engagement-badge.svg"/>
            <div class="header-text">
            <div class="ri-large-bold-body">היית</div>
            <div class="large-text ri-alte-bold">{{imOnItClicks}}</div>
            <div class="ri-large-bold-body">ימים על זה!</div>
          </div>
          </div>
        </div>
        <div class="on-it-content margin-top-l">
          <div class="ri-large-headline">{{titleFirstLine}}</div>
          <div class="ri-large-headline">{{titleSecondLine}}</div>
          <div class="margin-top-s ri-large-body margin-bottom-xs">
            התוכנית נגמרה, אבל אפשר להמשיך לצמוח איתנו! כדי שהתזרים ימשיך להתעדכן, רק צריך לבחור סוג מינוי ולעדכן את פרטי התשלום.</div>
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters } from 'vuex';
import Segment from '@/Segment';

const Engagement = {
  Low: 'low',
  Medium: 'medium',
  High: 'High',
};
export default {
  name: 'DormantAfterOnItChallengePopup',
  components: {
    Popup: BaseUI.Popup,
  },
  created() {
    Segment.trackUserInteraction('DormantAfterOnItChallengePopup_Shown');
  },
  computed: {
    ...mapGetters('session', ['activeMemberFirstName']),
    ...mapGetters('imOnIt', ['imOnItClicks']),
    engagement() {
      if (this.imOnItClicks < 34) {
        return Engagement.Low;
      }
      if (this.imOnItClicks < 64) {
        return Engagement.Medium;
      }
      return Engagement.High;
    },
    lowEngagement() {
      return this.engagement === Engagement.Low;
    },
    mediumEngagement() {
      return this.engagement === Engagement.Medium;
    },
    hightEngagement() {
      return this.engagement === Engagement.High;
    },
    titleFirstLine() {
      return this.hightEngagement ? `${this.activeMemberFirstName}, וואו.` : `${this.activeMemberFirstName}, כל הכבוד.`;
    },
    titleSecondLine() {
      return this.lowEngagement ? 'סיימת את התוכנית!' : 'ממש היית על זה!';
    },
    buttons() {
      return [{
        title: 'לבחירת מינוי',
        action: () => {
          Segment.trackUserInteraction('DormantAfterOnItChallengePopup_ChooseSubscriptionButtonClicked');
          this.$router.push({ path: '/subscription' });
          this.$nextTick(() => {
            this.popupDismissed();
          });
        },
        variant: 'primary',
        size: 'slim',
      }];
    },
  },
  methods: {
    ...mapActions('dormantAfterOnItChallengePopup', ['setIsShownTrue']),

    closeActionClicked() {
      Segment.trackUserInteraction('DormantAfterOnItChallengePopup_CloseButtonClicked');
      this.popupDismissed();
    },
    popupDismissed() {
      this.setIsShownTrue();
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';

.dormant-after-on-it-challenge-popup.popup {
  ::v-deep .action-icon.icon{
    color: $riseup_white;
  }

  border-radius: $popup-border-radius;
  background: linear-gradient(to bottom, $riseup_green 327px, $riseup_beige 294px);

  .header-image {
    position: relative;
    width: 100%;

    .on-it-image {
      width: 100%;
      height: 249px;
    }

    .on-it-logo {
      position: absolute;
      top: 27%;
      left: 17%;
      width: 203px;
      height: 145px;
    }

    .on-it-together-logo {
      position: absolute;
      top: -2%;
      right: -2%;
      width: 71px;
      height: 50.65px;
    }

    .on-it-badge {
      position: absolute;
      top: 15%;
      left: 9%;
      width: 87px;
      height: 87px;
    }

    .header-text {
      position: absolute;
      top: 27%;
      left: 32%;
      color: $riseup_beige;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .large-text {
        font-size: 100px;
        line-height: 100px;
        font-weight: 800;
        height: 85px;
      }
    }
  }
  .on-it-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
</style>
