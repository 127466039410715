<template>
   <div>
     <monthly-table v-if="cashflowReady"
       :total-income="income"
       :fixed-expenses="this.fixedExpenses"
       :tracked-expenses="this.trackingSum"
       :totalExpenses="this.totalExpenses"
       :variableExpenses="this.variableExpenses"/>
   </div>
  </template>

<script>
import _ from 'lodash';
import Segment from '@/Segment';
import { mapGetters, mapState } from 'vuex';
import CashflowAnalytics from '../../../../generic-components/CashflowAnalytics.vue';
import MonthlyTable from './MonthlyTable';

export default {
  name: 'MonthlySummary',
  mixins: [CashflowAnalytics],
  components: {
    MonthlyTable,
  },
  created() {
    Segment.trackUserGot('TourFlow_MonthlySummary_Shown');
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
    ...mapGetters('cashflowView', ['trackingCategories']),
    cashflowReady() {
      return this.cashflowUITree && !_.isEmpty(this.cashflowUITree);
    },
    income() {
      return (this.cashflowUITree.income.recommendedOrExpectedAmount) * -1;
    },
    variableExpenses() {
      return this.cashflowUITree.variableExpense.upToNowAmount;
    },
    fixedExpenses() {
      return this.cashflowUITree.fixedExpense.recommendedOrExpectedAmount;
    },
    trackingSum() {
      if (!this.trackingCategories) {
        return null;
      }
      const trackedSum = this.trackingCategories && _.chain(this.trackingCategories)
        .map('recommendedOrExpectedAmount')
        .sum()
        .value();
      return trackedSum;
    },
    totalExpenses() {
      return this.variableExpenses + this.fixedExpenses + this.trackingSum;
    },
  },
};
</script>
