<template>
  <div>
    <div v-if="!ready" class="margin-horizontal-ml margin-bottom-ml margin-top-ml">
      {{ startupError || 'רק רגע, אוספים את המידע שלך...' }}
    </div>
    <div v-else class="margin-bottom-ml">
      <div class="welcome">
        <h3 class="margin-bottom-ml">
        המידע הפיננסי שלך, ב-csv או אקסל!
        </h3>
        <div>
          ייצוא של המידע הפיננסי שלך לתוך קובץ אקסל מוכן מראש.
          אפשר לקרוא עוד פרטים
          <a :href="notionPage" target="_blank">כאן</a>.
        </div>
        <div class="margin-top-s">
          <a :href="exampleTemplateLink" target="_blank">כאן</a>
          אפשר להוריד טמפלייט אקסל לדוגמא.
        </div>
      </div>
      <div class="margin-horizontal-ml margin-top-m">
        <h4>
          כאן טוענים את הטמפלייט (xltx או xlsx):
        </h4>
        <br>
        <input type="file" @change="handleFileUpload" class="margin-bottom-ml" accept=".xltx, .xlsx">
        <check-box :label="'לכלול פקדונות והלוואות'" v-model="includeAnlData" :initially-checked="true" />
        <div v-if="includeAnlData" class="known-issue-warning">
          ⚠️ באופן זמני, כשכוללים פקדונות והלוואות (שזה כולל עו״ש, חסכונות וכו׳) האקסל עלול לצאת שבור.
          <a :href="notionPageErrors" target="_blank">(מידע נוסף)</a>.
        </div>
        <div class="spacer margin-top-ml" />
        <account-setting-button :title="'הורדת התזרים כ-csv'" :action="downloadCsv" :icon="'fa-download'"
                                :icon-color="'green'" :loading="downloading"/>
        <account-setting-button :title="'הורדה כקובץ אקסל פשוט'" :action="downloadExcelBasic" :icon="'fa-download'"
                                :icon-color="'green'" :loading="downloading3"/>
        <template v-if="!!excelTemplate">
          <account-setting-button :title="'הורדה כקובץ אקסל עם טמפלייט'" :action="downloadExcel" :icon="'fa-download'"
                                  :icon-color="'green'" :loading="downloading2" :party-button="true"/>
        </template>
        <div v-else class="margin-bottom-ml margin-top-ml">יש לטעון טמפלייט ואז יופיע פה כפתור!</div>
        <div v-if="excelError" class="margin-bottom-ml margin-top-ml">
          <h3>שגיאה בהמרה לאקסל עם טמפלייט:</h3>
          {{ excelError }}
        </div>
        <div v-if="createDataError" class="margin-bottom-ml margin-top-ml">
          <h3>שגיאה ביצירת הקובץ:</h3>
          {{ createDataError }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSettingButton
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/components/AccountSettingButton';
import cashflowHistory from '@/store/utilities/cashflow-history';
import cashflowToExcel from '@/store/utilities/cashflowForExcel';
import workbookManager from '@microsoft/connected-workbooks';
import { mapActions, mapState } from 'vuex';
import ExportAnlAsCsv from '@/pages/responsive-pages/authenticated/navigation-pages/experimental-pages/anl-handling/ExportAnlAsCsv.vue';
import BaseUi from '@riseupil/base-ui';
import * as _ from 'lodash';
import Segment from '../../../../../Segment';

export default {
  name: 'account-settings',
  components: {
    AccountSettingButton,
    CheckBox: BaseUi.CheckBox,
  },
  data() {
    return {
      downloading: false,
      downloading2: false,
      downloading3: false,
      isYearlySubscriptionPaid: false,
      showPauseMessageBottomSheet: false,
      excelTemplate: null,
      excelError: null,
      createDataError: null,
      queryMashup: null,
      ready: false,
      startupError: null,
      includeAnlData: true,
      allBudgets: [],
      notionPage: 'https://www.notion.so/riseupil/Download-Cashflow-data-to-Excel-'
          + '2bc3d62e22d34803a7802b9ba286a276',
      notionPageErrors: 'https://www.notion.so/riseupil/Download-Cashflow-data-to-Excel-'
          + '2bc3d62e22d34803a7802b9ba286a276?pvs=4#11eb7495f7354b56ba8783ca142e8699',
      exampleTemplateLink: 'https://www.notion.so/riseupil/Download-Cashflow-data-to-Excel-'
          + '2bc3d62e22d34803a7802b9ba286a276?pvs=4#a68accb5b6d5475592021e42acd1f747',
      anlCsvKeys: [
        'actions',
        'status',
        'interestRate',
        'balanceAmount',
        'product',
        'name',
        'contractStartDate',
        'contractEndDate',
        'type',
        'financialAccountNumber',
        'sourceIdentifier',
        'feesFromDeposit',
        'feesFromBalance',
      ],
    };
  },
  async created() {
    try {
      await Promise.all([
        this.fetchAssetsAndLiabilities(),
        this.initBudgets(),
      ]);
      this.ready = true;
    } catch (error) {
      this.startupError = error.message;
    }
  },
  mixins: [ExportAnlAsCsv],
  computed: {
    ...mapState('assetsAndLiabilities', ['savingsAccounts', 'loans', 'mortgages', 'securities', 'educationFunds', 'gemelLeashkaa',
      'savingsPolicies', 'childSavings', 'oshBalances']),
  },
  methods: {
    ...mapActions('assetsAndLiabilities', ['fetchAssetsAndLiabilities']),
    async initBudgets() {
      this.allBudgets = await cashflowHistory.getAllBudgets();
    },
    async downloadCsv() {
      try {
        Segment.trackUserInteraction('DownloadExcelClicked', { type: 'csv' });
        this.downloading = true;
        const cashflowsCsv = cashflowToExcel.convertToCsv(this.allBudgets);
        await this.saveCsv(cashflowsCsv);
      } catch (error) {
        this.createDataError = error.message;
      }
      this.downloading = false;
    },
    async downloadExcel() {
      try {
        Segment.trackUserInteraction('DownloadExcelClicked', { type: 'excelTemplate' });
        this.downloading2 = true;
        const csvData = await this.getCsvData();
        await this.saveExcel(csvData);
      } catch (error) {
        this.createDataError = error.message;
      }
      this.downloading2 = false;
    },
    async downloadExcelBasic() {
      try {
        Segment.trackUserInteraction('DownloadExcelClicked', { type: 'excelBasic' });
        this.downloading3 = true;
        const csvData = await this.getCsvData();
        await this.saveExcel(csvData, false);
      } catch (error) {
        this.createDataError = error.message;
      }
      this.downloading3 = false;
    },
    async getCsvData() {
      const assets = {
        savingsAccounts: this.savingsAccounts,
        loans: this.loans,
        mortgages: this.mortgages,
        securities: this.securities,
        balances: this.oshBalances,
        mislakaAssets: {
          educationFunds: this.educationFunds,
          gemelInvest: this.gemelInvest,
          savingsPolicies: this.savingsPolicies,
          childrenSavings: this.childSavings,
        },
      };
      const parsedAnlRows = this.exportCsv(assets);
      const assetsCsv = this.assetsToCsv(parsedAnlRows);
      return cashflowToExcel.getArrayDataWithAnl(this.allBudgets, assetsCsv);
    },
    async saveCsv(content) {
      const bom = '\uFEFF';
      const format = 'data:text/csv; charset=utf-8,';
      const a = document.createElement('a');
      a.setAttribute('href', format + bom + encodeURIComponent(bom + content));
      a.setAttribute('download', 'transactions.csv');
      a.click();
    },
    assetsToCsv(parsedAnlRows) {
      if (!this.includeAnlData) {
        return [[]];
      }
      const headerRow = this.anlCsvKeys;
      const csvRows = _.map(parsedAnlRows, row => _.map(this.anlCsvKeys, key => row[key]));
      return [headerRow, ...csvRows];
    },
    async saveExcel(content, useTemplate = true) {
      this.excelError = null;
      try {
        const grid = {
          config: { promoteHeaders: true, adjustColumnNames: true },
          data: content,
        };
        console.log('Saving Excel', { useTemplate, grid, template: this.excelTemplate?.name, includeAnlData: this.includeAnlData });
        const blob = await workbookManager.generateTableWorkbookFromGrid(
          grid,
          useTemplate ? {
            templateFile: this.excelTemplate,
            docProps: {
              title: 'My RiseUp exported data',
              subject: 'Financial data',
              createdBy: 'RiseUp',
            },
          } : undefined,
        );
        const suffix = useTemplate ? this._getTemplateSuffix() : 'xlsx';
        workbookManager.downloadWorkbook(blob, `MyTable.${suffix}`);
      } catch (error) {
        console.log('Error saving Excel', JSON.stringify(error), error, error.message);
        this.excelError = error.message;
      }
    },
    _getTemplateSuffix() {
      const templateName = this.excelTemplate.name;
      return _.last(templateName.split('.'));
    },
    handleFileUpload(event) {
      this.excelError = null;
      const file = event.target.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },
    async uploadFile(file) {
      this.excelTemplate = file;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../../../scss/mixins';

@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.debug-code-header, .debug-code {
  text-align: left;
  direction: ltr;
}

.debug-code {
  font-family: "Courier New";
  white-space: break-spaces;
  font-size: 15px;
  width: 100%;
  overflow-x: scroll;
  border: 1px solid black;
  padding: 10px 10px 0;
}

.known-issue-warning {
  font-size: 12px;
}

.welcome {
  line-height: 20px;
  background: linear-gradient(45deg, #eeffff, #ffe9ff);
  background-size: 400% 400%;
  animation: gradient-shift 3s ease infinite;
  padding: 18px 24px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(32, 33, 37, 0.13);
}
</style>
