<template>
  <share-frame class="consecutive-positive-months-asset" :background-color="backgroundColor" logoColor="white">
    <consecutive-positive-months-core :months="months" :household-name="householdName" shared-asset/>
  </share-frame>
</template>

<script>

import ConsecutivePositiveMonthsCore
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/consecutive-positive-months/ConsecutivePositiveMonthsCore';
import ShareFrame from '@/base-components/share/ShareFrame';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ConsecutivePositiveMonthsAsset',
  components: {
    ConsecutivePositiveMonthsCore,
    ShareFrame,
  },
  props: {
    months: {
      type: Number,
      required: true,
    },
    householdName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupGreen,
    };
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.consecutive-positive-months-asset {
  width: 500px;
  .consecutive-positive-months {
    background-color: $riseup_white;
    padding: 24px;
  }
}

</style>
