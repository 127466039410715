<template>
  <div class="split-review-mode">
    <colored-popup :class="categoryToVariant[selectedCategory.cashflowCategory]">

      <template v-slot:top-content>
          <span class="name"> {{title}} </span>
        <div class="split-question"> {{ splitQuestion }} </div>
      </template>

      <template v-slot:bottom-content>

        <div v-if="actionType === 'create'">
          <div class="info ri-bold-title">
            זאת ההוצאה שתופיע בתזרים שלך:
          </div>
          <slice-review :amount="amount(transaction)"
                        :category="selectedCategory"
                        :transaction="transaction"
                        :comment="transaction.customerComment"
                        :last="true"/>
        </div>
        <div v-else>
          <div class="info">
            אלו ההוצאות שיופיעו בתזרים שלך:
          </div>
          <div  v-for="(existingSplitTransaction, index) in existingSplitTransactions" :key="index">
            <slice-review
              :amount="amount(existingSplitTransaction)"
              :category="category(existingSplitTransaction, availableCategories)"
              :transaction="transaction"
              :comment="existingSplitTransaction.customerComment"
              :last="index === slices.length-1" />
          </div>
        </div>

        <riseup-button class="done" :action="onClose" :variant="'primary'" :title="buttonText" size="small"/>
        <div class="back">
          <div class="text" v-on:click="back">רגע, אני רוצה לשנות משהו</div>
        </div>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { findCategory, transactionAmount } from '@/utils/splitTransactionUtils';
import { mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import SliceReview from './SliceReview';

export default {
  name: 'SplitTransactionCancelMode',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    SliceReview,
  },
  props: {
    back: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState('editTransaction', ['transaction']),
    ...mapState('splitTransaction', ['slices', 'actionType', 'existingSplitTransactions']),
    ...mapGetters('editTransaction', ['selectedCategory', 'categoryToVariant', 'availableCategories']),
    title() {
      if (this.actionType === 'create') {
        return 'לבטל את הפיצול?';
      }
      return 'לבטל את העריכה';
    },
    splitQuestion() {
      return this.actionType === 'update' ? 'העריכה של הפיצול לא תישמר. לבטל בכל זאת?' : 'ההוצאות המפוצלות לא יישמרו, לבטל בכל זאת?';
    },
    buttonText() {
      return 'כן, אני רוצה לבטל';
    },
  },
  methods: {
    category(splitTransaction, availableCategories) {
      return findCategory(splitTransaction, availableCategories);
    },
    amount(transaction) {
      return transactionAmount(transaction);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.split-review-mode{
  text-align: right;
  .colored-top {
    .name {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
    }
    .split-question {
      margin-top: 22px;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }
  }
  .info {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .done {
    //height: 75px;
    width: 100%;
  }
  .back {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    height: 56px;
    font-size: 18px;
    color: $riseup_black;

    .text {
      cursor: pointer;
    }
  }
}
</style>
