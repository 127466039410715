<template>
  <sticky-cta-page :cta-props="ctaProps" @click="$emit('next')">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <div class="progress-text">שלב {{ currentStepIndex + 1 }} מתוך {{ totalSteps }}
        <span class="ri-font-weight-bold"> · {{ currentStepName }}</span></div>
      <div class="ri-large-headline ri-alte">{{ title }}</div>
      <slot :wizard-state="wizardState"/>
    </div>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export const StepBaseProps = {
  wizardState: {
    type: Object,
    required: true,
  },
  currentStepIndex: {
    type: Number,
    required: true,
  },
  currentStepName: {
    type: String,
    required: true,
  },
  totalSteps: {
    type: Number,
    required: true,
  },
  ctaProps: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
};

export default {
  name: 'AltshulerSignupStep',
  props: StepBaseProps,
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.progress-text {
  color: $riseup_blue;
  margin-bottom: $ri-spacing-ml;
}
</style>
