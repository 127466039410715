<template>
  <eom-celebration-generic-popup class="growing-months-celebration-popup" :segment-extra-props="segmentExtraProps" :cashflow-date="cashflowDate"
                                 :share-component="shareComponent" :share-component-props="shareComponentProps">
    <template v-slot>
      <consecutive-positive-months-core :household-name="householdName" :months="months" ></consecutive-positive-months-core>
    </template>
  </eom-celebration-generic-popup>
</template>

<script>

import { mapGetters } from 'vuex';
import EomCelebrationGenericPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/EomCelebrationGenericPopup';
import ConsecutivePositiveMonthsCore
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/consecutive-positive-months/ConsecutivePositiveMonthsCore';
import ConsecutivePositiveMonthsAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/consecutive-positive-months/ConsecutivePositiveMonthsAsset';

export default {
  name: 'ConsecutivePositiveMonthsCelebrationPopup',
  components: {
    ConsecutivePositiveMonthsCore,
    EomCelebrationGenericPopup,
  },
  props: {
    months: {
      type: Number,
      required: true,
    },
    cashflowDate: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      shareComponent: ConsecutivePositiveMonthsAsset,
      shareComponentProps: {},
    };
  },
  created() {
    this.shareComponentProps = {
      months: this.months,
      householdName: this.householdName,
    };
  },
  computed: {
    ...mapGetters('session', ['householdName']),
    segmentExtraProps() {
      return {
        celebrationType: 'ConsecutivePositiveMonthsCelebrationPopup',
        months: this.months,
      };
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.consecutive-positive-months-celebration-popup {
}

</style>
