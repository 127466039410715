<template>
  <div class="referrals-competetion-tool-tip">
    <div class="tool-tip-padding">
      <div class="icon-wrapper">
        <img :src="require('@/assets/icons/heart-icon.svg')">
      </div>
      <div class="text-wrapper ri-label">
        <span class="bold">שאלו אותך מה זה רייזאפ, ולא ידעת מאיפה להתחיל?</span>
        <span>הקהילה הכינה ארגז כלים עם כל מה שיכול לעזור לך לספר את הסיפור שלך עם רייזאפ. </span><br>
        <span class="link bold" @click="goToToolbox">לארגז הכלים</span>
      </div>
    </div>
  </div>
</template>

<script>
import Segment from '@/Segment';

export default {
  name: 'ReferralsCompetetionToolTip',
  methods: {
    goToToolbox() {
      Segment.trackUserInteraction('ReferralsCֹompetition_ToolTipClicked');
      window.open('https://www.events.riseup.co.il/ambassadorsexplain', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../scss/mixins';

.referrals-competetion-tool-tip {
  width: 100%;

  .tool-tip-padding{
    display: flex;
    justify-content: space-between;
    padding: 19px;
    border-radius: 8px;
    background-color: $riseup_light_blue;
  }

  .icon-wrapper {
    padding-top: 4px;
    margin-left: 15px;
  }

  .text-wrapper {
    flex-grow: 1;
    .bold {
      font-weight: bold;
    }
    .link {
      font-weight: bold;
      color: $riseup_blue;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
