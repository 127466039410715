<template>
  <div class="at-least-one-positive" :class="{ shared: sharedAsset }">
    <img v-if="sharedAsset" class="full-width" src="/images/celebration/at-least-one-positive.png">
    <div v-else class="animation-container">
      <img class="background" src="/images/celebration/at-least-one-positive-animation-background.svg" />
      <lottie-player class="full-width animation" src="/animations/celebration/at-least-one-positive.json" autoplay loop />
    </div>

    <div class="ri-large-body margin-top-s">
      <div class="flex-column ri-large-headline ri-alte margin-bottom-s text-center">
        <span>חודש חדש,</span>
        <span>הזדמנות חדשה</span>
      </div>
      <div>
        <span>{{ thisMonth }}</span>
        הסתיים בתזרים שלילי, אבל היו גם חודשים חיוביים בחצי שנה אחרונה.
      </div>
    </div>
  </div>
</template>

<script>

import cashflowHistory from '@/store/utilities/cashflow-history';

export default {
  name: 'AtLeastOnePositiveCore',
  props: {
    cashflowDate: {
      type: String,
      required: true,
    },
    sharedAsset: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nextMonth() {
      return cashflowHistory.getFullMonthName(cashflowHistory.getNextBudgetDate(this.cashflowDate));
    },
    thisMonth() {
      return cashflowHistory.getFullMonthName(this.cashflowDate);
    },
  },
};

</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '~@riseupil/base-ui/src/scss/spacings';

.at-least-one-positive {
  text-align: center;

  &.shared {
    border-radius: $ri-border-radius-medium;
  }

  .animation-container {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .background {
      height: 200px;
      position: absolute;
      z-index: 1;
    }

    .animation {
      position: absolute;
      z-index: 2;
    }
  }
}

</style>
