<template>
    <div class="days-line">
      <day v-for="(dayDateString, index) in daysDates" :key="index"
           :dateString="dayDateString"
           :visited="isDateVisited(dayDateString)"
           :disabled="isDateOutOfRange(dayDateString)"  />
    </div>
</template>

<script>

import dateUtils from '@/utils/dates';
import { mapGetters } from 'vuex';
import Day from './Day.vue';

export default {
  name: 'DaysView',
  components: {
    Day,
  },
  props: {
    displayedWeekSundaysDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('imOnIt', ['imOnItStartDate', 'imOnItEndDate', 'dailyTaskDates']),
    daysDates() {
      return dateUtils.getConsecutiveDaysFromDate(this.displayedWeekSundaysDate, 7);
    },
  },
  methods: {
    isDateOutOfRange(dateString) {
      return dateUtils.isDateBefore(dateString, this.imOnItStartDate);
    },
    isDateVisited(dateString) {
      return !!this.dailyTaskDates[dateString];
    },
  },

};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/spacings';

    .days-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

</style>
