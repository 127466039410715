<template>
  <div class="months-navigation-bar">
    <button @click="debouncedShowPrevMonth" class="prev-button" :disabled="!prevMonthEnabled">
      <img v-show="prevMonthEnabled" :src="require('@/assets/icons/arrow_enabled_right.svg')" />
      <img v-show="!prevMonthEnabled" :src="require('@/assets/icons/arrow_disabled_right.svg')" />
    </button>
    <div v-if="!contentLoading" class="current-month-description">
      <span class="month">{{ fullMonthName }}</span>
      <span class="year" :class="{'bold': showCashflowMonthDatesRange }">{{ year }}</span>
      <span v-if="showCashflowMonthDatesRange">({{cashflowMonthDatesRange}})</span>
    </div>
    <button @click="debouncedShowNextMonth" class="next-button" :disabled="!nextMonthEnabled">
      <img v-show="nextMonthEnabled" :src="require('@/assets/icons/arrow_enabled_left.svg')" />
      <img v-show="!nextMonthEnabled" :src="require('@/assets/icons/arrow_disabled_left.svg')" />
    </button>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import dateUtils from '@/utils/dates';
import cashflowHistory from '@/store/utilities/cashflow-history';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import cashflowContants from '@/constants/cashflow-view';

export default {
  name: 'MonthsNavigationBar',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debouncedShowNextMonth: _.debounce(this.showNextMonth, 1000, { leading: true }),
      debouncedShowPrevMonth: _.debounce(this.showPrevMonth, 1000, { leading: true }),
    };
  },
  computed: {
    ...mapState('cashflowView', ['presentedBudgetDate', 'finishLoadingAllBudgets', 'cashflowStartDay']),
    ...mapGetters('cashflowView', ['allBudgetDates', 'currentBudgetDate']),
    ...mapGetters('contentLoader', ['contentLoading']),
    fullMonthName() {
      return cashflowHistory.getFullMonthName(this.presentedBudgetDate);
    },
    isLastCashflow() {
      return this.presentedBudgetDate === _.min(this.allBudgetDates) && this.allBudgetDates.length > 1;
    },
    isFirstCashflow() {
      return this.presentedBudgetDate === this.currentBudgetDate;
    },
    prevMonthEnabled() {
      return !this.disabled && !this.isLastCashflow && !this.contentLoading && this.finishLoadingAllBudgets;
    },
    nextMonthEnabled() {
      return !this.disabled && !this.isFirstCashflow && !this.contentLoading;
    },
    year() {
      return dateUtils.getYear(this.presentedBudgetDate);
    },
    cashflowMonthDatesRange() {
      return cashflowHistory.getCashflowMonthDatesRange(this.presentedBudgetDate);
    },
    showCashflowMonthDatesRange() {
      return this.cashflowStartDay && this.cashflowStartDay !== cashflowContants.DEFAULT_CASHFLOW_START_DAY;
    },
  },
  methods: {
    ...mapActions('cashflowView', ['moveToNextBudgetMonth', 'moveToPrevBudgetMonth']),
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    async showPrevMonth() {
      if (this.prevMonthEnabled) {
        performanceService.markStart(PerformanceMarks.NAVIGATE_TO_MONTH);
        await this.startContentLoading(this.$options.name);
        await this.moveToPrevBudgetMonth();
        await this.doneContentLoading(this.$options.name);
        this.$nextTick(() => {
          this.$nextTick(() => {
            performanceService.markEndAndLog(PerformanceMarks.NAVIGATE_TO_MONTH);
          });
        });
      }
    },
    async showNextMonth() {
      if (this.nextMonthEnabled) {
        performanceService.markStart(PerformanceMarks.NAVIGATE_TO_MONTH);
        await this.startContentLoading(this.$options.name);
        await this.moveToNextBudgetMonth();
        await this.doneContentLoading(this.$options.name);
        this.$nextTick(() => {
          this.$nextTick(() => {
            performanceService.markEndAndLog(PerformanceMarks.NAVIGATE_TO_MONTH);
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/global-variables';
  @import '@/scss/mixins';
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .months-navigation-bar {
    color: $riseup_black;
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    box-shadow: 0 0.5px 0 $riseup_gray_0;
    background-color: $riseup_white;

    .current-month-description {
      .month{
        font-weight: bold;
      }
    }

    .next-button, .prev-button {
      cursor: pointer;
      padding: 10px 30px;
      border: none;
      background-color: transparent;
      outline: none;
    }
}
</style>
