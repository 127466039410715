<template>
  <div class="less-negative" :class="{ shared: sharedAsset }">
    <img v-if="sharedAsset" class="full-width" src="/images/celebration/less-negative.png">
    <div v-else class="animation-container">
      <img class="background" src="/images/celebration/lessnegative-animation-background.svg" />
      <lottie-player class="full-width animation" src="/animations/celebration/lessnegative.json" autoplay loop />
    </div>

    <div class="ri-large-body margin-top-ml">
      <div class="ri-large-headline ri-alte margin-bottom-s text-center">
        <span>שיפור של</span>
        <span>{{ formatAmountWithFractionAndCurrency(amount) }}</span>
      </div>
      <div>
        <span>{{ thisMonth }}</span>
        הסתיים בתזרים שלילי, אבל החדשות הטובות הן שהוא הסתיים טוב יותר מהחודש הקודם.
      </div>
    </div>
  </div>
</template>

<script>

import cashflowHistory from '@/store/utilities/cashflow-history';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
// import BaseUI from '@riseupil/base-ui';

export default {
  name: 'LessNegativeCore',
  components: {
    // ColoredSvg: BaseUI.ColoredSvg,
  },
  mixins: [TransactionDisplayUtils],
  props: {
    amount: {
      type: Number,
      required: true,
    },
    cashflowDate: {
      type: String,
      required: true,
    },
    householdName: {
      type: String,
      required: true,
    },
    sharedAsset: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    thisMonth() {
      return cashflowHistory.getFullMonthName(this.cashflowDate);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.less-negative {
  text-align: center;

  .animation-container {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .background {
      width: 100%;
      position: absolute;
      z-index: 1;
    }

    .animation {
      position: absolute;
      z-index: 2;
    }
  }

  &.shared {
    border-radius: $ri-border-radius-medium;
  }
}

</style>
