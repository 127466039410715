<template>
  <div
    class="day"
    :class="[variant]"
  >
    <div class="day-text">
      {{ dayText }}
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import dateUtils from '@/utils/dates';

export default {
  name: 'Day',
  components: {
  },
  props: {
    dateString: {
      type: String,
      required: true,
    },
    visited: {
      type: Boolean,
      required: true,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      VariantsEnum: ['disabledDate', 'currentDate', 'passedDate', 'futureDate', 'visited'],
    };
  },
  computed: {
    variant() {
      if (this.disabled) { return 'disabledDate'; }
      if (this.visited) { return 'visited'; }
      if (moment(this.dateString).isSame(moment(), 'day')) {
        return 'currentDate';
      } if (moment(this.dateString).isAfter(moment(), 'day')) {
        return 'futureDate';
      }
      return 'passedDate';
    },
    dayText() {
      return dateUtils.getHebrewDayOfWeek(this.dateString);
    },

  },
  methods: {

  },

};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import "~@riseupil/base-ui/src/scss/spacings";

.day {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  .day-text {
    color: $riseup_blue;
    font-size: 18px;
    font-family: SimplerPro;
  }

  &.currentDate {
    border: 2px solid $riseup_blue;
    .day-text {
      font-weight: bold;
    }
  }
  &.futureDate {
    border: 2px solid $riseup_light_blue;
  }
  &.visited {
    background-color: $riseup_green;
    .day-text {
      color: $riseup_white;
    }
  }
  &.passedDate, &.disabledDate {
    border: 2px solid $riseup_light_blue;
    .day-text {
      color: $riseup_light_blue;
    }

  }
}

</style>
