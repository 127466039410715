<template>
  <sticky-cta-page :cta-props="ctaProps" @click="nextClicked">
    <dashboard-page :image-header-props="imageHeaderProps">
      <template v-slot:dashboard>
        <div class="padding-horizontal-ml padding-vertical-l">
          <highlights :highlights="highlights"/>
        </div>
      </template>
      <div class="full-width padding-horizontal-ml padding-top-l">
        <explanation-box title="למה אלטשולר שחם?">
          <div>
            רצינו ליצור דרך לאפשר ללקוחות שלנו לחסוך כסף וגם להרוויח, בלי להרגיש שזה מורכב או דורש מחשבה אם להשאיר את
            הכסף בעו״ש או לא.
          </div>
          <div>
            יצרנו שיתוף פעולה עם חברת ׳אלטשולר שחם פיננשייל סרביסס׳, שאיפשרה לנו ליצור חוויה פשוטה וקלה עבור הלקוחות
            שלנו, בתנאים מעולים.
          </div>
          <div>
            לרייזאפ אין רווחים משיתוף הפעולה הזה, ונשמח שהלקוחות שלנו ימשיכו לצמוח ולהרוויח (בתנאים טובים).
          </div>
        </explanation-box>
        <riseup-button class="margin-top-ml" @click="qnaClicked" variant="secondary" title="שאלות ותשובות נפוצות"
                       :icon-props="{ iconName: 'article', fill: true }"/>
      </div>
    </dashboard-page>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import ExplanationBox from '@/base-components/ExplanationBox.vue';
import moneyUtils from '@/utils/money';
import Segment from '@/Segment';
import { DEPOSIT_MAXIMUM_AMOUNT, DEPOSIT_MINIMUM_AMOUNT } from '../altshuler.consts';
import Highlights from '../Highlights.vue';

export default {
  name: 'AltshulerInformation',
  components: {
    ExplanationBox,
    Highlights,
    DashboardPage: BaseUI.Pages.DashboardPage,
    RiseupButton: BaseUI.RiseupButton,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  data() {
    const depositMin = moneyUtils.formatAmountWithFraction(DEPOSIT_MINIMUM_AMOUNT, 0);
    const depositMax = moneyUtils.formatAmountWithFraction(DEPOSIT_MAXIMUM_AMOUNT, 0);
    return {
      highlights: [
        {
          primary: 'ריבית מעולה',
          secondary: '3.75% על הכסף שלך\n(P-2.5%) ריבית משתנה לפי הפריים',
        },
        {
          primary: 'הכסף זמין תמיד',
          secondary: 'הפקדה ומשיכה מתי שרוצים (ביצוע הפעולה יכול לקחת עד 5 ימי עסקים)',
        },
        {
          primary: 'הכל דרך רייזאפ',
          secondary: 'כל הפעולות והמידע בקלות ובמהירות דרך השירות',
        },
        {
          primary: 'בלי דמי ניהול',
          secondary: 'אין דמי ניהול בכלל, בלי אותיות קטנות',
        },
        {
          primary: 'מתאים לסכומים קטנים',
          secondary: `מינימום הפקדה של ${depositMin} ₪ ועד ${depositMax} ₪ בחצי שנה`,
        },
      ],
      ctaProps: { buttonProps: { title: 'אני רוצה כזה פקדון' } },
    };
  },
  created() {
    Segment.trackUserGot('Altshuler_Details_Shown');
  },
  computed: {
    imageHeaderProps() {
      return {
        title: 'רגע לפני,\nאלו התנאים',
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE,
        // eslint-disable-next-line global-require
        imgSrc: require('@/assets/altshuler/dashboard-logo.svg'),
        bottomSeparator: false,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
      };
    },
  },
  methods: {
    nextClicked() {
      Segment.trackUserInteraction('Altshuler_Details_NextClicked');
      this.$emit('click');
    },
    qnaClicked() {
      Segment.trackUserInteraction('Altshuler_Details_QNA_Clicked');
      // TODO: open QNA
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.header {
  color: $riseup_black;

  .text {
    text-align: right;
    display: flex;
    align-items: flex-start;
    max-width: 70%;
  }
}

</style>
