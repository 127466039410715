<template>
  <lottie-player v-if="showConfetti" class="confetti-animation"
                 ref="lottiePlayer"
                 :preserveAspectRatio="'xMidYMax'"
                 :autoplay="true"
                 @complete="disconnectLottie"
                 :loop="false"/>
</template>

<script>

export default {
  name: 'ConfettiAnimation',
  data() {
    return {
      showConfetti: false,
    };
  },
  beforeDestroy() {
    this.disconnectLottie();
  },
  methods: {
    startConfettiAnimation() {
      this.showConfetti = true;
    },
    disconnectLottie() {
      this.showConfetti = false;
      this.$refs.lottiePlayer?.disconnectedCallback();
    },
  },
};
</script>

<style scoped lang="scss">
  .confetti-animation {
    position: absolute;
    width: 85%;
    height: fit-content;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    pointer-events: none; // to prevent the animation from blocking the click event on the expandable section
  }
</style>
