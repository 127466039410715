<template>
  <logo-and-back-page :background-color-variant="bgColorVariant" :text-color="textColor">
    <step-one v-if="stepOne" @click="next" />
    <step-two v-else-if="stepTwo" @click="next" />
    <step-three v-else-if="stepThree" @click="next" />
  </logo-and-back-page>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import LogoAndBackPage from '@/base-components/app-header/LogoAndBackPage.vue';
import StepOne from './StepOne.vue';
import StepTwo from './StepTwo.vue';
import StepThree from './StepThree.vue';

export default {
  name: 'AltshulerIntroduction',
  components: {
    LogoAndBackPage,
    StepOne,
    StepTwo,
    StepThree,
  },
  data() {
    return {
      pageIndex: 0,
      bgColorVariant: BaseUi.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE,
      textColor: BaseUi.Colors.riseupDarkBlue,
    };
  },
  computed: {
    stepOne() {
      return this.pageIndex === 0;
    },
    stepTwo() {
      return this.pageIndex === 1;
    },
    stepThree() {
      return this.pageIndex === 2;
    },
  },
  methods: {
    next() {
      if (this.pageIndex === 0 || this.pageIndex === 1) {
        ++this.pageIndex;
      } else {
        this.$router.push({ path: '/xnes-join' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
