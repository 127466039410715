<template>
  <popup :close-action="closePopup" title="שינוי סדר ותיעדוף תוכניות" remove-content-padding>
    <template v-slot:content>
      <div class="edit-priorities-modal">
        <div class="description ri-body">
          סדר התוכניות יעזור לך לקבוע לאן מיועד הכסף שנשאר בצד כל סוף חודש
        </div>
        <plan-priorities :plans-draft="plansPrioritiesDraft" @priorities-changed="updatePlansPrioritiesDraft"/>
        <div class="update-button">
          <riseup-button
            :action="save"
            title="עדכון"
            variant="primary"
            size="slim"
            :loading="isLoading"
          />
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions, mapState } from 'vuex';
import PlanPriorities from './PlanPriorities';

export default {
  name: 'EditPrioritiesModal',
  components: {
    PlanPriorities,
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      isLoading: false,
      plansPrioritiesDraft: [],
    };
  },
  created() {
    this.plansPrioritiesDraft = [...this.plans.filter(plan => plan.state === 'ongoing')];
  },
  computed: {
    ...mapState('planAhead', ['plans']),
  },
  methods: {
    ...mapActions('planAhead', ['savePlansPrioritiesDraft']),
    closePopup() {
      Segment.trackUserInteraction('PlanAhead_EditPrioritiesModal_Close', { plansCount: this.plans.length });
      this.$emit('close');
    },
    updatePlansPrioritiesDraft(plans) {
      this.plansPrioritiesDraft = plans;
    },
    async save() {
      this.isLoading = true;
      Segment.trackUserInteraction('PlanAhead_EditPrioritiesModal_Save', { plansCount: this.plans.length });
      await this.savePlansPrioritiesDraft(this.plansPrioritiesDraft);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-priorities-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .description {
    padding: 0 24px;
    margin-bottom: 24px;
  }
  .update-button {
    margin: 32px 24px 0;
  }
}
</style>
