<template>
  <sticky-cta-page :cta-props="ctaProps" @click="nextClicked">
    <dashboard-page :image-header-props="imageHeaderProps">
      <template v-slot:dashboard>
        <div class="padding-horizontal-ml padding-vertical-l">
          <div class="ri-large-headline margin-bottom-m">
            החשבון יפתח ב ״מערכת מסחר משולבת״ של <span class="xnes-name">אקסלנס טרייד</span>
          </div>
          <highlights :highlights="highlights" :icon-shape="require('@/assets/xnes/checkmark-blue-round-background.svg')"
            shape-size="small"/>
        </div>
      </template>
      <div class="full-width padding-horizontal-ml padding-top-m">
      </div>
    </dashboard-page>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';
import Highlights from '../Highlights.vue';

export default {
  name: 'StepTwo',
  components: {
    Highlights,
    DashboardPage: BaseUI.Pages.DashboardPage,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  data() {
    return {
      highlights: [
        {
          primary: 'מתאימה לכל הרמות',
          secondary: 'מתאימה למגוון רחב של סוחרים, משקיעים ומשקיעות',
          shapeType: 'img',
          // eslint-disable-next-line global-require
          src: require('@/assets/xnes/checkmark-blue-round-background.svg'),
          'shape-size': 'small',
        },
        {
          primary: 'כלים שיעזרו לך לקבל החלטות',
          secondary: 'תחזיות אנליסטים ואפליקציית מסחר מתקדמת שמאפשרת מסחר משולב בארץ ובחו״ל',
          shapeType: 'img',
          // eslint-disable-next-line global-require
          src: require('@/assets/xnes/checkmark-blue-round-background.svg'),
          'shape-size': 'small',
        },
        {
          primary: 'מערכת מסחר ידידותית - Extrade Pro',
          secondary: 'פלטפורמה אחת למסחר בארץ ובארה״ב',
          shapeType: 'img',
          // eslint-disable-next-line global-require
          src: require('@/assets/xnes/checkmark-blue-round-background.svg'),
          'shape-size': 'small',
        },
        {
          primary: 'הבחירה של 3 מתוך 4 לקוחות אקסלנס טרייד!',
          secondary: 'ועדיין, זה גמיש - אפשר בהמשך לשנות לחבילה מורכבת יותר אם יש צורך',
          shapeType: 'img',
          // eslint-disable-next-line global-require
          src: require('@/assets/xnes/checkmark-blue-round-background.svg'),
          'shape-size': 'small',
        },
      ],
      ctaProps: { buttonProps: { title: 'מה התנאים שאקבל?' } },
    };
  },
  created() {
    EventBus.$emit('reset-scroll');
    Segment.trackUserGot('XnesIntro_StepTwo_Entered');
  },
  computed: {
    imageHeaderProps() {
      return {
        title: 'איפה\nהחשבון יפתח?',
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE,
        // eslint-disable-next-line global-require
        imgSrc: require('@/assets/xnes/dashboard-logo.svg'),
        bottomSeparator: false,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
      };
    },
  },
  methods: {
    nextClicked() {
      Segment.trackUserInteraction('XnesIntro_StepTwo_NextClicked');
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.xnes-name {
  color: $riseup_danger_red;
}

</style>
