<template>
  <card class="cashflow-type-total-expenses-so-far">
    <div class="ri-large-headline" :class="{ 'out-of-date': outOfDate }">סך כל ההוצאות עד עכשיו</div>
    <div class="text" :class="{ 'out-of-date': outOfDate }">{{ totalSpentSoFar }} ₪</div>
  </card>

</template>

<script>
import { mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import moneyUtils from '../../../../../../../../../utils/money';

export default {
  name: 'CashflowTypeTotalExpensesSoFar',
  components: {
    Card: BaseUI.Card,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('cashflowView', ['totalExpense']),
    totalSpentSoFar() {
      return moneyUtils.formatAmount(Math.ceil(this.totalExpense));
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.cashflow-type-total-expenses-so-far {
  padding: 25px 0px 30px;

  .title {
    &.out-of-date {
      color: $riseup_gray_2;
    }
  }

  .text {
    display: inline-block;
    margin-top: 5px;
    color: $riseup_danger_red;
    font-size: 24px;
    font-weight: bold;
    line-height: 12px;
    &.out-of-date {
      color: $riseup_gray_2;
    }
  }
}
</style>
