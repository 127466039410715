<template>
  <next-steps-base-card v-if="!loading && !this.memberHasFund"
                        title="לפתוח חיסכון דינאמי דרך  רייזאפ"
                        button-text="לחיסכון לטווח ארוך"
                        description="5 דקות, פתיחת קופת גמל להשקעה"
                        :action-key="actionKey"
                        @click="goToLongTermSaving"/>
  <next-steps-base-card v-else-if="!loading && this.memberHasFund"
                        title="להגדיל את החיסכון שלך ב'מיטב' בקלות"
                        button-text="להפקיד לקופה"
                        description="2 דקות, הפקדה לקופת הגמל להשקעה"
                        :action-key="actionKey"
                        @click="goToLongTermSaving"/>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';

export default {
  name: 'LongTermSavingNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      loading: true,
      actionKey: ActionKeys.LONG_TERM_SAVING,
    };
  },
  async created() {
    try {
      await this.initSavingZone();
    } catch (err) {
      // empty
    }
    this.loading = false;
  },
  computed: {
    ...mapState('savingZone', ['funds']),
    memberHasFund() {
      return this.funds.find(fund => fund.my);
    },
  },
  methods: {
    ...mapActions('savingZone', ['initSavingZone']),
    goToLongTermSaving() {
      this.$router.push({ path: '/saving-zone' });
    },
  },
};
</script>

<style scoped>

</style>
