<template>
  <sticky-cta-page :cta-props="ctaProps" @click="nextClicked">
    <dashboard-page :image-header-props="imageHeaderProps">
      <template v-slot:dashboard>
        <div class="padding-horizontal-ml padding-vertical-l">
          <div class="ri-large-headline margin-bottom-m">
            החשבון יפתח ב ״מערכת מסחר משולבת״ של <span class="xnes-name">אקסלנס טרייד</span>
          </div>
          <highlights :highlights="highlights" />
        </div>
      </template>
      <div class="full-width padding-horizontal-ml padding-top-l">
        <explanation-box :shape-color="explanationShapeColor">
          <div class="ri-bold-title">למה דווקא אקסלנס טרייד</div>
          <div class="margin-bottom-m">כל מיני סיבות</div>

          <div class="ri-bold-title">למה דרך רייזאפ...?</div>
          <div>כל מיני סיבות</div>
        </explanation-box>
        <riseup-button class="margin-top-ml" @click="qnaClicked" variant="secondary" title="שאלות ותשובות נפוצות"
                       :icon-props="{ iconName: 'article', fill: true }"/>
      </div>
    </dashboard-page>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import ExplanationBox from '@/base-components/ExplanationBox.vue';
import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';
import Highlights from '../Highlights.vue';

export default {
  name: 'StepThree',
  components: {
    ExplanationBox,
    Highlights,
    DashboardPage: BaseUI.Pages.DashboardPage,
    RiseupButton: BaseUI.RiseupButton,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  data() {
    return {
      highlights: [
        {
          primary: 'תנאים מיוחדים ללקוחות רייזאפ',
          secondary: 'דמי ניהול חודשיים כך וכך, שירות מדהים וכו׳. עמלת קנייה ומכירה',
          shapeType: 'shape',
          // eslint-disable-next-line global-require
          src: '/images/shapes/eight-leaves-flower.svg',
          shapeSize: 'big',
          color: BaseUI.Colors.riseupDangerRed,
        },
        {
          primary: 'כדאי להביא ת.ז. או רישיון נהיגה',
          secondary: 'כדי שהתהליך יהיה מהיר יותר, כדאי כבר עכשיו להביא ת.ז. או רישיון לזיהוי',
          shapeType: 'shape',
          // eslint-disable-next-line global-require
          src: '/images/shapes/flower.svg',
          shapeSize: 'big',
        },
        {
          primary: 'פתיחת חשבון עכשיו, הפקדה ומסחר אח״כ',
          secondary: 'ההרשמה לוקחת כמה דקות, אם כל הפרטים שמילאת תקינים,'
              + ' תוך יום יפתח לך חשבון מסחר באקסלנס טרייד ואז אפשר להתחיל להפקיד ולבצע פעולות ',
          shapeType: 'shape',
          // eslint-disable-next-line global-require
          src: '/images/shapes/flower.svg',
          shapeSize: 'big',
        },
        {
          primary: 'גובה הפקדה ראשונה',
          secondary: 'הסכום המינימלי שאיתו אפשר להתחיל לסחור הוא 15,000 ₪',
          shapeType: 'shape',
          // eslint-disable-next-line global-require
          src: '/images/shapes/flower.svg',
          shapeSize: 'big',
        },
      ],
      explanationShapeColor: BaseUI.Colors.riseupDangerRed,
      ctaProps: { buttonProps: { title: 'אני רוצה להתחיל!' } },
    };
  },
  created() {
    EventBus.$emit('reset-scroll');
    Segment.trackUserGot('XnesIntro_StepThree_Entered');
  },
  computed: {
    imageHeaderProps() {
      return {
        title: 'מה כדאי לדעת\nלפני פתיחת\nהחשבון?',
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE,
        // eslint-disable-next-line global-require
        imgSrc: require('@/assets/xnes/dashboard-logo.svg'),
        bottomSeparator: false,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
      };
    },
  },
  methods: {
    nextClicked() {
      Segment.trackUserInteraction('XnesIntro_StepThree_NextClicked');
      this.$emit('click');
    },
    qnaClicked() {
      Segment.trackUserInteraction('XnesIntro_StepThree_QNA_Clicked');
      // TODO: open QNA
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
