<template>
  <Popup
    :closeAction="closeModal"
    :buttons="[{
          title: 'שליחה',
          action: updateId,
          size: 'slim',
        }]">
    <template v-slot:title>
      <div class="header">
        <img :src="require('@/assets/ambassadors/ambassadors-header-color.svg')"/>
      </div>
    </template>
    <template v-slot:content>
      <div class="content">
        <span class="content-text">
          כדי להעביר לך את התשלום על תוכנית השגרירים אנחנו משתמשים בPaybox, ובשביל זה אנחנו צריכים את מספר תעודת הזהות שלך.
        </span>
        <TextInput
          :class="{'id-input': true, error: invalidId}"
          :propagateEvents="true"
          labelText="תעודת זהות"
          inputType="number"
          :value="id"
          :error="invalidId"
          @input="onIdChange"
        />
        <span v-if="errorMessage" class="error-text">{{errorMessage}}</span>

      </div>
    </template>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUi from '@riseupil/base-ui';
import SumsumApi from '@/api/SumsumApi';
import Segment from '@/Segment';

// When the entered id is too short or too long we get an error, to identify it we use this text
// from the error message (since the code is 400).
const ID_ERROR_PARTIAL = 'length must be';

export default {
  name: 'AmbassadorIdPopUp',
  components: {
    Popup: BaseUi.Popup,
    TextInput: BaseUi.TextInput,
  },
  data() {
    return {
      id: '',
      invalidId: false,
      errorMessage: null,
    };
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    onIdChange(event) {
      this.clearError();
      this.id = event.target.value;
    },
    setError(message) {
      this.invalidId = true;
      this.errorMessage = message;
    },
    clearError() {
      this.invalidId = false;
      this.errorMessage = null;
    },
    async updateId() {
      this.clearError();
      try {
        await SumsumApi.updateId(this.id);
        Segment.trackUserInteraction('AmbassadorIdEnterForm_submitId');
        this.$emit('submit');
      } catch (e) {
        if (e.response?.data?.message?.includes(ID_ERROR_PARTIAL)) {
          this.setError('אנחנו צריכים 9 ספרות');
        }
      }
    },
  },
  created() {
    Segment.trackUserGot('AmbassadorIdEnterForm_shown');
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.header {
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content-text {
    text-align: center;
    font-size: 18px;
    line-height: 140%;
    margin: 40px 0;
  }

  .id-input {
    margin-bottom: 10px;

    &.error {
      margin-bottom: 0;
    }
  }

  .error-text {
    align-self: flex-start;
    font-size: 10px;
    color: $riseup_danger_red;
  }
}
</style>
