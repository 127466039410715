<template>
  <div class="nps">
    <div class="page-content">
      <div>
        <div class="header">
          <div class="scale-text">1 מתוך 5</div>
          <div class="question-text">באיזו מידה היית ממליצ.ה על השירות לחבר.ה?</div>
        </div>
        <selectable-scale maxLegendText="10 - במידה רבה מאד"
                          minLegendText="לא אמליץ - 0"
                          :setScore="setNPS"/>
        <div v-if="npsAnswered">
          <div class="free-text-container">מה הסיבה העיקרית לבחירה שלך?</div>
          <input class="text-input" placeholder="כמה מילים..."
                            v-on:input="setTextAnswer"/>
        </div>
      </div>
        <div class="button-container">
          <riseup-button class="next-question" :title="buttonText" :action="saveAnswer" :size="'small'" :disabled="!npsAnswered"/>
        </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import SelectableScale from '../../../../../base-components/survey/SelectableScale.vue';

export default {
  name: 'NPS',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    SelectableScale,
  },
  props: {
    buttonText: {
      type: String,
      required: true,
    },
  },
  created() {
    this.$emit('new-survey-question', { questionName: this.name });
  },
  data() {
    return {
      nps: null,
      textAnswer: '',
    };
  },
  computed: {
    npsAnswered() { return Number.isInteger(this.nps); },
  },
  methods: {
    saveAnswer() {
      this.$emit('next-clicked', { answer: this.nps, textAnswer: this.textAnswer });
      this.answer = null;
      this.textAnswer = null;
    },
    setNPS(nps) {
      this.nps = nps;
    },
    setTextAnswer(event) {
      this.textAnswer = event.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import 'src/scss/mixins';

  .nps {
    @include white-page-frame;
    height: 100%;

    .page-content {
      @include responsive-padding;
      margin-top: 40px;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .header {
        color: $riseup_blue;
        .scale-text {
          margin-bottom: 5px;
          font-size: 16px;
        }

        .question-text {
          margin-bottom: 30px;
          font-weight: bold;
          font-size: 22px;
        }
      }

      .free-text-container {
        font-weight: bold;
        margin: 40px 0 14px;
      }

      .selectable-scale {
        height: 40%;
      }

      .text-input {
        padding: 0 0 3px;;
        width: 100%;
        text-align: right;
        outline: 0;
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid $riseup_light_blue;
        font-size: 16px;
        font-family: SimplerPro;

        &:focus {
          border-bottom: 2px solid $riseup_light_blue;
        }
      }

      .button-container {
        @include responsive-padding-top-bottom;
        .next-question {
          width: 100%;
          white-space: nowrap;
        }
      }
    }
  }

</style>
