<template>
  <action-card-with-icon text="סיכום הוצאות חודשיות" :action="openExpenseSummary" :out-of-date="outOfDate"
                         icon-name="category" :additional-icon-props="iconProps"/>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ActionCardWithIcon from '@/base-components/cashflow/ActionCardWithIcon';
import ExpenseSummary from '../../expense-summary/ExpenseSummary';

export default {
  name: 'ExpenseSummaryCard',
  components: {
    ActionCardWithIcon,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('cashflowView', ['presentedBudgetDate']),
    iconProps() {
      return {
        size: '18px',
        weight: 'bold',
        fill: true,
        opticalSize: 24,
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openExpenseSummary() {
      this.openModal({
        component: ExpenseSummary,
        props: {
          initialBudgetDate: this.presentedBudgetDate,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
