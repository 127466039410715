<template>
  <div>
    <split-transaction-edit-mode v-if="isEdit" :next="goToReview" :on-close="cancel" :on-delete="onDelete"/>
    <split-transaction-review-mode v-if="isReview" :next="onSave" :on-close="cancel" :back="back"/>
    <split-transaction-cancel-mode v-if="isCancel" :on-close="close" :back="back"/>
    <split-transaction-delete-mode v-if="isDelete" :on-close="deleteSplit" :back="back"/>
  </div>
</template>

<script>
import * as HamsterApi from '@/api/HamsterApi';
import cashflowNormalizer from '@/store/utilities/cashflow-normalizer';
import serial from '@/utils/serial';
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import DDLogs from '@/DDLogs';
import { transactionAmount } from '@/utils/splitTransactionUtils';
import cashflowViewConsts from '@/constants/cashflow-view';
import Segment from '../../../../../../../../../../../Segment';
import SplitTransactionEditMode from './SplitTransactionEditMode';
import SplitTransactionReviewMode from './SplitTransactionReviewMode';
import SplitTransactionCancelMode from './SplitTransactionCancelMode';
import SplitTransactionDeleteMode from './SplitTransactionDeleteMode';

export const steps = {
  EDIT: 'edit',
  REVIEW: 'review',
  CANCEL: 'cancel',
  DELETE: 'delete',
};

export default {
  name: 'SplitTransactionPopup',
  components: {
    SplitTransactionDeleteMode,
    SplitTransactionEditMode,
    SplitTransactionReviewMode,
    SplitTransactionCancelMode,
  },
  created() {
    this.initializeSplitTransaction({
      transactionsInCurrentCashflow: this.transactionsInCurrentCashflow,
      transaction: this.transaction,
      initialCategory: this.selectedCategory,
      availableCategories: this.splitAvailableCategories,
    });
    this.sendSegmentEvent('SplitTransaction_Shown', { actionType: this.actionType });
  },
  data() {
    return {
      currentStep: steps.EDIT,
    };
  },
  computed: {
    ...mapState('editTransaction', ['transaction']),
    ...mapState('splitTransaction', ['slices', 'splitFromTransaction', 'actionType', 'changeMade', 'removedSlices']),
    ...mapGetters('editTransaction', ['selectedCategory', 'splitAvailableCategories', 'ENRICHMENT_INPUT_TYPES']),
    ...mapGetters('cashflowView', ['transactionsInCurrentCashflow']),
    ...mapGetters('splitTransaction', ['lastSlice', 'isIncome']),
    isEdit() {
      return this.currentStep === steps.EDIT;
    },
    isReview() {
      return this.currentStep === steps.REVIEW;
    },
    isCancel() {
      return this.currentStep === steps.CANCEL;
    },
    isDelete() {
      return this.currentStep === steps.DELETE;
    },
  },
  methods: {
    ...mapActions('editCashflow', ['splitTransaction', 'setComment', 'moveTransaction', 'excludeExpense']),
    ...mapActions('splitTransaction', ['initializeSplitTransaction', 'setLoading', 'removeSlice']),
    async deleteSplit() {
      this.sendSegmentEvent('SplitTransaction_DeleteClicked', { isIncome: this.isIncome });
      const slicesWithNoComments = _.chain(this.slices)
        .filter(slice => slice.amount > 0)
        .map(slice => {
          return { transactionId: slice.transactionId, amount: 0 };
        })
        .value();
      const removedSlices = _.map(this.removedSlices, slice => {
        return {
          transactionId: slice.transactionId,
          amount: 0,
        };
      });
      await this.splitTransaction({
        transactionId: this.splitFromTransaction.transactionId,
        actionType: 'delete',
        slices: [...slicesWithNoComments, ...removedSlices],
      });
      this.$emit('close');
    },

    async onSave() {
      this.sendSegmentEvent('SplitTransactionReview_SaveClicked', { isIncome: this.isIncome });
      this.setLoading({ loading: true });
      const savedSlices = _.filter(this.slices, slice => slice.amount > 0);
      const slicesWithNoComments = _.chain(savedSlices)
        .map(slice => {
          return { transactionId: slice.transactionId, amount: slice.amount };
        })
        .value();
      const removedSlices = this.actionType === 'update' ? _.map(this.removedSlices, slice => {
        return {
          transactionId: slice.transactionId,
          amount: 0,
        };
      }) : [];
      try {
        await this.splitTransaction({
          transactionId: this.splitFromTransaction.transactionId,
          actionType: this.actionType,
          slices: [...slicesWithNoComments, ...removedSlices],
        });
        this.sendSegmentEvent('SavingSplitTransactionInputSuccess', { isIncome: this.isIncome });
      } catch (error) {
        this.sendSegmentEvent('ErrorSavingSplitTransactionInput', { isIncome: this.isIncome });
        DDLogs.error('Error while saving split transaction input', { error });
        throw (error);
      }

      await serial.serialFor(savedSlices, async slice => {
        const sliceIsSaving = slice.category.isSaving;
        const isExcludedExpense = slice.category.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE;
        const modifiedComment = this.modifySavingComment(slice.comment, sliceIsSaving && isExcludedExpense);

        await this.setComment({ transactionId: slice.transactionId, comment: modifiedComment });

        const hamsterUpdateFn = sliceIsSaving
          ? HamsterApi.markAsSavingTransaction
          : HamsterApi.unmarkAsSavingTransaction;
        await this.markOrUnMarkAsSavings(slice.transactionId, hamsterUpdateFn);

        if (
          slice.category.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE
          || slice.category.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.EXCLUDED_INCOME
        ) {
          await this.excludeExpense(slice);
        } else if (!this.isIncome) { // The move happens only expenses since incomes can only stay variable or excluded
          await this.moveTransaction({
            originalCategory: this.selectedCategory,
            selectedCategory: slice.category,
            transaction: {
              transactionId: slice.transactionId,
              businessName: this.transaction.businessName,
            },
            categoryRuleType: this.ENRICHMENT_INPUT_TYPES.TRANSACTION_ID,
            valueForPredicate: slice.transactionId,
          });
        }
      });
      this.setLoading({ loading: false });
      this.$emit('close');
    },
    modifySavingComment(currentComment, isSavingTransaction) {
      const commentPrefix = 'חיסכון 💰';
      const cleanedCustomerComment = _.replace(currentComment, commentPrefix, '');

      return isSavingTransaction ? `${commentPrefix} ${cleanedCustomerComment}` : cleanedCustomerComment;
    },
    async markOrUnMarkAsSavings(transactionId, markOrUnmarkFn) {
      const updatedSavingTransactionIds = (await markOrUnmarkFn(transactionId))
        .map(t => t.transactionId);
      cashflowNormalizer.setSavingTransactions(updatedSavingTransactionIds);
    },
    sendSegmentEvent(eventName, otherProp) {
      Segment.trackUserInteraction(eventName, {
        businessName: this.transaction.businessName,
        originalCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
        originalSum: transactionAmount(this.transaction),
        isIncome: this.isIncome,
        ...otherProp,
      });
    },
    goToReview() {
      const lastSliceIsZero = this.lastSlice.amount === 0;
      if (lastSliceIsZero) {
        this.removeSlice({ index: this.slices.indexOf(this.lastSlice) });
      }
      this.sendSegmentEvent('SplitTransaction_ContinueClicked', { LeftOverIsZero: lastSliceIsZero });
      this.currentStep = steps.REVIEW;
    },
    cancel() {
      if (this.changeMade) {
        this.currentStep = steps.CANCEL;
      } else {
        this.close();
      }
    },
    onDelete() {
      if (this.actionType === 'create' || this.changeMade) {
        this.cancel();
      } else {
        this.currentStep = steps.DELETE;
      }
    },
    close() {
      this.sendSegmentEvent('DeleteSplitTransaction_DeleteClicked');
      this.$emit('close');
    },
    back() {
      this.sendSegmentEvent('SplitTransactionReview_BackClicked');
      this.currentStep = steps.EDIT;
    },
  },
};
</script>
