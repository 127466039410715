<template>
  <dashboard-page class="plan-ahead-page" :image-header-props="imageHeaderProps">
    <template v-slot:dashboard>
      <plans-total-data-section :plans="ongoingPlans"/>
    </template>
    <template v-slot:default>
      <div class="ongoing-plans-menu-container">
        <span class="ri-font-weight-bold">תוכניות פעילות</span>
        <bottom-sheet-overflow-button :bottom-sheet-component="editPlanComponent"
                                      :on-menu-opened="menuOpened"/>
      </div>
      <div class="plans-container">
        <transition-group name="list">
          <plan class="plan" v-for="plan in ongoingPlans"
                :plan="plan"
                :key="plan._id"
                @edit-plan="openDetailsPage"
                @edit-plan-flow="openEditPlanFlow"/>
        </transition-group>
        <div class="add-plan-button-container" @click="() => openPlanFlow()">
          <icon icon-name="add_circle" :color="addPlanColor" fill></icon>
          <span class="text">הוספת תוכנית</span>
        </div>
      </div>
      <div v-if="completedPlans.length > 0" class="completed-plans">
        <div class="separator-container">
          <separator/>
        </div>
        <div class="completed-plans-menu">
          <span class="ri-font-weight-bold">תוכניות שהסתיימו</span>
        </div>
        <div class="plans-container">
          <transition-group name="list">
            <plan class="plan" v-for="plan in completedPlans" :plan="plan" :key="plan._id"
                  @edit-plan="openDetailsPage"/>
          </transition-group>
        </div>
      </div>
    </template>
  </dashboard-page>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import Segment from '@/Segment';
import AddPlanFlow from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/plans-flow/AddPlanFlow';
import BaseUi from '@riseupil/base-ui';
import PlansTotalDataSection
  from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/PlansTotalDataSection';
import casfhlowHistory from '@/store/utilities/cashflow-history';
import EventBus from '@/event-bus/event-bus';
import PreloadCashflowMixing from '@/mixins/PreloadCashflowMixin';
import PlanAheadPageMenu from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/PlanAheadPageMenu';
import BottomSheetOverflowButton from '@/base-components/BottomSheetOverflowButton';
import Plan from './Plan';
import PlanDetailsPage from './PlanDetailsPage';
import EditPrioritiesModal from './active-plans/EditPrioritiesModal';
import CreatePlansExplanationPopup from './plans-card/CreatePlansExplanationPopup';
import PlanAheadMixin from './PlanAheadMixin';
import EditPlanFlow from './plans-flow/EditPlanFlow';

const baseImageHeaderProps = {
  imgSrc: '/images/plan-ahead/my-plans.svg',
  backgroundPosition: BaseUi.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
  backgroundColor: BaseUi.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE,
  textColor: BaseUi.Colors.riseupDarkGreen,
  bottomSeparator: false,
  actionButtonProps: {
    text: 'איך זה עובד?',
    overrideIconProps: {
      iconName: 'info',
      fill: true,
      size: '14px',
      opticalSize: 24,
    },
    showLeftArrow: false,
    backgroundColor: 'transparent',
    borderColor: BaseUi.Colors.riseupBlack,
  },
};

export default {
  name: 'PlanAheadPage',
  mixins: [PlanAheadMixin, PreloadCashflowMixing],
  components: {
    Plan,
    Separator: BaseUi.Separator,
    PlansTotalDataSection,
    Icon: BaseUi.Icon,
    DashboardPage: BaseUi.Pages.DashboardPage,
    BottomSheetOverflowButton,
  },
  created() {
    this.init();
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUi.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE,
      textColor: BaseUi.Colors.riseupDarkGreen,
      logoColor: BaseUi.Colors.riseupDarkGreen,
      sticky: false,
    });
  },
  data() {
    return {
      dataLoaded: false,
      addPlanColor: BaseUi.Colors.riseupBlue,
      headerBgColor: BaseUi.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE,
      headerTextColor: BaseUi.Colors.riseupDarkGreen,
      editPlanComponent: PlanAheadPageMenu,
    };
  },
  mounted() {
    EventBus.$emit('reset-scroll');
    if (this.cashflowTabsEnabled) {
      this.$nextTick(this.preloadCashflow);
    }
  },
  computed: {
    ...mapState('planAhead', ['plans']),
    ...mapGetters('planAhead', ['hasPlans']),
    ...mapGetters('cashflowView', ['riseupGoal']),
    ...mapGetters('featureFlags', ['cashflowTabsEnabled']),
    ...mapGetters('session', ['householdName']),
    completedPlans() {
      return this.plans.filter(plan => plan.state === 'done');
    },
    totalPlansMonthlyAmount() {
      return _.sumBy(this.ongoingPlans, plan => plan.monthlyTarget);
    },
    imageHeaderProps() {
      return {
        ...baseImageHeaderProps,
        title: `התוכניות הגדולות של ${this.householdName}`,
        actionButtonProps: {
          ...baseImageHeaderProps.actionButtonProps,
          onClick: this.openExplanationPopup,
        },
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('planAhead', ['addPlan', 'initPlans', 'initPlansSuggestions', 'updatePlan', 'updateCurrentPlan']),
    ...mapActions('cashflowView', ['initBudgets']),
    ...mapActions('appHeader', ['setAppHeaderProps']),
    async init() {
      await this.initPlans();
      await this.initPlansSuggestions();
      if (!this.riseupGoal) {
        await this.initBudgets({ budgetDate: casfhlowHistory.getCurrentBudgetDate() });
      }

      Segment.trackUserGot('PlanAheadDashboard_Shown', {
        totalMonthlyAmount: this.totalPlansMonthlyAmount,
        numberOfPlans: this.plans.length,
        numberOfNotOnTrackPlans: this.ongoingPlans.filter(plan => !plan.isOnTrack).length,
        source: this.$route.query.fromIntercomBot,
      });
    },
    openPlanFlow() {
      Segment.trackUserInteraction('PlanAheadDashboard_AddPlan_Clicked');
      this.openModal({
        component: AddPlanFlow,
        props: {
          onDone: this.addNewPlan,
        },
        popupAlignment: 'full-screen',
      });
    },
    openDetailsPage(plan) {
      this.openModal({
        component: PlanDetailsPage,
        props: {
          plan,
        },
        popupAlignment: 'full-screen',
      });
    },
    async openEditPlanFlow(plan) {
      Segment.trackUserInteraction('PlanAheadDashboard_Edit_Clicked');
      this.openModal({
        component: EditPlanFlow,
        props: {
          onDone: this.updatePlan,
          plan,
        },
        popupAlignment: 'full-screen',
      });
    },
    async addNewPlan() {
      await this.addPlan();
    },
    menuOpened() {
      Segment.trackUserInteraction('PlanAheadDashboard_ActivePlansMenu_Opened');
    },
    changePrioritiesModal() {
      Segment.trackUserInteraction('PlanAheadDashboard_ActivePlansMenu_EditPriorities_Clicked');
      this.openModal({
        component: EditPrioritiesModal,
      });
    },
    openExplanationPopup() {
      Segment.trackUserInteraction('PlanAheadDashboard_ActivePlansMenu_MoreInfo_Clicked');
      this.openModal({
        component: CreatePlansExplanationPopup,
      });
    },
  },
  watch: {
    plans() {
      if (!this.dataLoaded) {
        this.dataLoaded = true;
        return;
      }
      Segment.trackUserGot('PlanAheadDashboard_PlansChanged', {
        totalMonthlyAmount: this.totalPlansMonthlyAmount,
        numberOfPlans: this.plans.length,
        numberOfNotOnTrackPlans: this.plans.filter(plan => !plan.isOnTrack).length,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/typography';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/border-radius';

.plan-ahead-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ongoing-plans-menu-container {
    height: 54px;
    margin: 48px 24px 4px 16px;
    display: flex;
    position: relative;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    .ongoing-plans-menu {
      margin-left: 0;
    }
  }

  .completed-plans-menu {
    height: 54px;
    margin-right: 24px;
    display: flex;
    position: relative;
    align-content: center;
    align-items: center;
  }

  .plans-container {
    margin-top: 4px;
    flex: 1;
    padding-left: $ri-spacing-ml;
    padding-right: $ri-spacing-ml;

    .plan {
      margin-bottom: 24px;
    }
  }

  .data-place-holder {
    height: 75px;
  }

  .separator-container {
    margin: 0 10px;
  }

  .text-container {
    margin: 30px 30px 20px;
  }

  .add-plan-button-container {
    height: 150px;
    border: dashed 2px #dce3f5; // Color here is hardcoded because it is our riseup_blue with opacity. No opacity can be specified on border.
    border-radius: $card-border-radius;
    margin-bottom: $ri-spacing-ml;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    .text {
      padding-right: 10px;
      color: $riseup_blue;
    }
  }

  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }
  .list-enter, .list-leave-to {
    opacity: 0;
  }
  .list-enter-to {
    opacity: 1;
  }
}
</style>
