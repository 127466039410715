<template>
  <div class="subscription-flow-v2" v-if="!asyncLoading">
   <update-subscription-flow-v2 v-if="mode === 'update'"/>
   <create-subscription-flow-v2 v-else />
  </div>
</template>

<script>
import CreateSubscriptionFlowV2
  from '@/pages/responsive-pages/authenticated/subscription/V2/CreateSubscriptionFlowV2.vue';
import UpdateSubscriptionFlowV2
  from '@/pages/responsive-pages/authenticated/subscription/V2/UpdateSubscriptionFlowV2.vue';
import { SUBSCRIPTION_TYPES } from '@/constants/subscription-state';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SubscriptionFlowV2',
  components: {
    UpdateSubscriptionFlowV2,
    CreateSubscriptionFlowV2,
  },
  data() {
    return {
      asyncLoading: true,
    };
  },
  computed: {
    ...mapGetters('subscription', ['SUBSCRIPTION_FLOW_STEPS']),
  },
  props: {
    mode: {
      type: String,
      default: 'create',
    },
  },
  async created() {
    await this.fetchFirstBillingDate();
    await this.fetchSubscription();
    await this.fetchPricing();
    await this.fetchCreditCardMask();
    this.setStep(this.SUBSCRIPTION_FLOW_STEPS.subscriptionType);
    this.setType(SUBSCRIPTION_TYPES.YEARLY);
    this.asyncLoading = false;
  },
  methods: {
    ...mapActions('subscription', ['fetchFirstBillingDate', 'fetchPricing', 'fetchCreditCardMask', 'fetchSubscription']),
    ...mapMutations('subscription', ['setType', 'setStep']),
  },
};
</script>

<style lang="scss" scoped>
.subscription-flow-v2 {
  height: 100%;
  width: 100%;
}
</style>
