<template>
  <div class="ri-label">
    <div>
      רייזאפ עוזרת לך להתכונן לתוכניות העתידיות שלך: להבין כמה צריך לשים בצד כל חודש כדי להגשים אותן, ולעקוב אחרי ההתקדמות בקלות.
    </div>
    <a class="link-to-plans ri-bold-label" @click="onButtonClick" @keyup.enter="onButtonClick" tabindex="0">
      להוספת תוכניות גדולות
      <icon class="chevron-icon" icon-name="arrow_back_ios_new" size="12px" weight="bold" />
    </a>
  </div>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters } from 'vuex';
import PlanAheadIntroductionPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/PlanAheadIntroductionPopup';
import AddPlanFlow from '../plans-flow/AddPlanFlow';

export default {
  name: 'PlansButton',
  components: {
    Icon: BaseUI.Icon,
  },
  created() {
    Segment.trackUserGot('PlanAhead_AddPlans_Shown', { cashflowIsPositive: this.cashflowIsPositive });
  },
  computed: {
    ...mapGetters('planAhead', ['hasPlans']),
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    ...mapGetters('featureFlags', ['showPlanAheadIntroPopup']),
    cashflowIsPositive() {
      return this.predictedBalanceAtEndOfMonth >= 0;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('planAhead', ['addPlan']),
    onButtonClick() {
      Segment.trackUserInteraction('PlanAhead_AddPlans_Clicked');
      Segment.trackUserGot('PlanAhead_IntroductionPopup_Test',
        { showIntro: this.showPlanAheadIntroPopup, hasPlans: this.hasPlans, source: 'PlansCardCta' });
      this.$router.push({ path: '/plan-ahead' });
      if (!this.hasPlans) {
        if (this.showPlanAheadIntroPopup) {
          Segment.trackUserGot('PlanAhead_IntroductionPopup_Open', { source: 'PlansCardCta' });
          this.openModal({
            component: PlanAheadIntroductionPopup,
            props: { cashflowIsPositive: this.cashflowIsPositive },
            popupAlignment: 'full-screen',
          });
        } else {
          this.openModal({
            component: AddPlanFlow,
            props: {
              onDone: this.addPlan,
            },
            popupAlignment: 'full-screen',
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
.link-to-plans {
  display: flex;
  align-items: center;
  color: $riseup-blue;
  font-weight: bold;
  margin-top: $ri-spacing-sm;
  cursor: pointer;
  width: fit-content;
  .chevron-icon {
    margin-right: $ri-spacing-xxs;
  }
}
</style>
