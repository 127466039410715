<template>
  <next-steps-base-card title="איך לקבוע צפי לקטגוריה, ואיך להתייחס לחודשים הקודמים"
                        button-text="לצפייה בסטורי הסבר"
                        description="5 דקות, סטורי, פעולות בתזרים"
                        :action-key="actionKey"
                        @click="openCategoriesStory"/>
</template>

<script>
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';

export default {
  name: 'CategoriesStoryNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.CATEGORIES_STORY,
    };
  },
  methods: {
    openCategoriesStory() {
      this.$router.push({ path: '/st/CX-Categories' });
    },
  },
};
</script>

<style scoped>

</style>
