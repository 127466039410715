<template>
  <my-progress-base-filter
      title="מה מוצג בגרף?"
      :filter-options="filterOptions"
      :selected-option="selectedOption"
      @filters-changed="filterSelected"
      @close="$emit('close')"
  />
</template>

<script>
import _ from 'lodash';
import Segment from '@/Segment';
import MyProgressBaseFilter from './MyProgressBaseFilter.vue';

export default {
  name: 'MyProgressDataSectionsFilter',
  components: {
    MyProgressBaseFilter,
  },
  props: {
    filterOptions: {
      type: Array,
      required: true,
    },
    initialSelection: {
      type: Object,
      required: true,
    },
    supportedFilters: {
      type: Object,
      default: () => { return { cashflow: true, both: true }; },
      validator: value => _.each(_.keys(value), filter => ['cashflow', 'excluded', 'both'].includes(filter)),
    },
  },
  data() {
    return {
      selectedSections: null,
    };
  },
  created() {
    this.selectedSections = this.initialSelection;
  },
  computed: {
    selectedOption() {
      return _.find(this.filterOptions,
        option => option.cashflows === this.selectedSections.cashflows && option.excluded === this.selectedSections.excluded);
    },
  },
  methods: {
    filterSelected(option) {
      if (option === this.selectedOption) {
        return;
      }
      Segment.trackUserInteraction('MyProgress_Data_Filter_Selected', { selectedOption: this.selectedOption });
      this.selectedSections = option;
      this.$emit('filters-changed', option);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
