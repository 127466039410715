<template>
  <div v-if="rangedDataset && rangedDataset.length" class="my-progress-graph" ref="pageRef">
    <div class="sticky-filters">
      <slot name="filters"/>
    </div>
    <dashboard-page :image-header-props="imageHeaderProps" class="padding-bottom-xl">
      <template v-slot:header>
        <div class="flex-column full-width full-height flex-1">
          <slot name="header" :ranged-dataset="rangedDataset"/>
        </div>
      </template>
      <template v-slot:dashboard>
        <div class="padding-bottom-m padding-horizontal-xs">
          <slot name="graph" :ranged-dataset="rangedDataset" :label-formatter="valueLabelFormatter" :activation-date-visible="activationDateVisible"
                :x-tick-formatter="formantTickX" :y-tick-formatter="formatTickY" />
          <div class="full-width padding-top-ml padding-horizontal-sm">
            <img class="full-width" src="/images/past-editable-month/arrow-left.svg">
          </div>
          <div v-if="activationDateVisible" class="activation-indicator ri-small-body">
            <img src="/images/shapes/six-pointed-star.svg" />
            הצטרפת לרייזאפ
          </div>
          <div class="touch-info ri-small-body">
            <icon icon-name="info" />
            פירוט הסכום בכל חודש מופיע בנגיעה בעמודה הרלוונטית
          </div>
        </div>
      </template>
      <slot />
    </dashboard-page>
  </div>
  <div v-else class="loader-container">
    <spinner/>
    <div class="margin-top-sm">מחשבים את ההתקדמות שלך...</div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import dateUtils from '@/utils/dates';
import moment from 'moment';
import { mapState } from 'vuex';
import _ from 'lodash';
import moneyUtils from '@/utils/money';

export default {
  name: 'MyProgressGraph',
  components: {
    Icon: BaseUI.Icon,
    Spinner: BaseUI.Spinner,
    DashboardPage: BaseUI.Pages.DashboardPage,
  },
  props: {
    filters: {
      type: Object, // dataSections, dates
      required: true,
    },
    headerSize: {
      type: Number,
      required: true,
    },
    rangedDataset: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('applicationState', ['activationDate']),
    imageHeaderProps() {
      return {
        imgHeight: this.headerSize,
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BEIGE,
        bottomSeparator: false,
      };
    },
    activationDateVisible() {
      return this.activationDate && moment(this.activationDate)
        .isBetween(this.filters.dates.start, this.filters.dates.end, 'month', '[]');
    },
  },
  methods: {
    valueLabelFormatter(value) {
      const fractionDigits = value < 1 && value > -1 ? 1 : 0;
      return `${moneyUtils.formatAmountWithFraction(value, fractionDigits)}${value < 0 ? '-' : ''} ₪`;
    },
    formatMonth(cashflowMonth) {
      return dateUtils.getShortHebrewMonth(cashflowMonth);
    },
    formatTickY(value) {
      if (value === 0) {
        return '0 ₪';
      }
      if (value < 1000 && value > -1000) {
        return `${moneyUtils.formatAmountWithFraction(value, 0)} ₪`;
      }
      const rounded = Math.ceil(Math.abs(value) / 1000);
      return `${rounded > 1 ? rounded : ''}${Math.sign(value) === -1 ? '-' : ''} אלף`;
    },
    formantTickX({ cashflowMonth, ...otherValues }) {
      return {
        disabled: _.isEmpty(otherValues),
        value: this.formatMonth(cashflowMonth),
        mark: moment(cashflowMonth)
          .isSame(this.activationDate, 'month'),
      };
    },
  },
  watch: {
    rangedDataset() {
      this.$nextTick(() => {
        this.$refs.pageRef?.scrollIntoView({ block: 'start' });
      });
    },
  },
};

</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/riseup-z-index';
@import '@/scss/global-variables.scss';

.overflow-x {
  overflow-x: auto;
}
.my-progress-graph {
  scroll-margin-top: $app_header_height;
}

.sticky-filters {
  position: sticky;
  top: $app_header_height;
  background: $riseup_beige;
  z-index: $app-header;
  padding: $ri-spacing-sm $ri-spacing-ml;
}
.activation-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
  padding: $ri-spacing-xs $ri-spacing-sm 0 $ri-spacing-sm;
  margin-bottom: $ri-spacing-xs;
}

.touch-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 4px;
  padding: $ri-spacing-xs $ri-spacing-sm 0 $ri-spacing-sm;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: $riseup_beige;
}
</style>
