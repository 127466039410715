<template>
  <next-steps-base-card title="איך כדאי לחסוך לטווח קצר"
                        button-text="לצפייה בסרטונים"
                        description="10 דקות, סרטונים"
                        :action-key="actionKey"
                        @click="openSavingsVideo"/>
</template>

<script>
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';

export default {
  name: 'SavingsVideoNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.SAVINGS_VIDEO,
    };
  },
  methods: {
    openSavingsVideo() {
      console.log('SavingsVideoNextStep: NOT IMPLEMENTED');
    },
  },
};
</script>

<style scoped>

</style>
