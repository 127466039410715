import { render, staticRenderFns } from "./AltshulerOpenAccountErrorPage.vue?vue&type=template&id=50d66609&scoped=true&"
import script from "./AltshulerOpenAccountErrorPage.vue?vue&type=script&lang=js&"
export * from "./AltshulerOpenAccountErrorPage.vue?vue&type=script&lang=js&"
import style0 from "./AltshulerOpenAccountErrorPage.vue?vue&type=style&index=0&id=50d66609&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50d66609",
  null
  
)

export default component.exports