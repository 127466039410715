<template>
  <popup
    :close-action="closeActionClicked"
    class="update-credit-card-popup"
    :title="'עדכון פרטי כרטיס'">
    <template v-slot:content>
      <div class="personal-info">
        <div class="personal-info-title">פרטי בעל הכרטיס</div>
        <personal-info-form/>
      </div>
      <credit-card-details-form @init-failed="creditCardFormInitFailed"/>
      <div class="button-container">
        <div class="result-container" v-if="processingCompleted">
          <div class="top">
            <div class="icon">
              <img class="icon" :src="getImageUrl()">
            </div>
            <div class="message" :class="{error: processingCompletedWithError, success: !processingCompletedWithError}">
              {{ resultMessage }}
            </div>
          </div>
          <div class="cta" v-if="processingCompletedWithError">
            אפשר לרענן את העמוד ולנסות להשתמש בכרטיס אחר.
          </div>
        </div>
        <riseup-button v-else class="update-button" :loading="processingInProgress" :disabled="!validPersonalInfo"
                       title="עדכון פרטי כרטיס" :action="updateCreditCardClicked"/>
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { SUBSCRIPTION_STATES } from '@/constants/subscription-state';
import DDLogs from '@/DDLogs';
import PaymentHelper from '@/pages/responsive-pages/authenticated/payment/PaymentHelper';
import MetricsApi from '@/api/MetricsApi';
import Segment from '../../../../../Segment';
import PersonalInfoForm from './components/PersonalInfoForm.vue';
import CreditCardDetailsForm from '../../payment/CreditCardDetailsForm.vue';
import PaymeInterface from '../../../../../Payme';

const PAYMENT_PROCESSING_STATUSES = {
  NOT_STARTED: 'notStarted',
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed',
};

const DELAY_BEFORE_POPUP_CLOSE = 2000;

export default {
  name: 'UpdateCreditCardPopup',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    PersonalInfoForm,
    CreditCardDetailsForm,
    Popup: BaseUI.Popup,
  },
  mixins: [PaymentHelper],
  async created() {
    Segment.trackUserInteraction('UpdateCreditCardPopupShown');
  },
  beforeDestroy() {
    PaymeInterface.teardown();
    this.reset();
  },
  data() {
    return {
      processingStatus: PAYMENT_PROCESSING_STATUSES.NOT_STARTED,
    };
  },
  computed: {
    ...mapState('subscription', ['tokenizationError', 'creditCardIsValid']),
    ...mapGetters('subscription', ['validPersonalInfo']),
    ...mapState('websocket', ['socketMessage']),
    ...mapState('subscriptionState', ['subscriptionState']),
    resultMessage() {
      return this.processingCompletedWithError ? 'לא הצלחנו לחייב את הכרטיס' : 'פרטי הכרטיס החדש עודכנו בהצלחה';
    },
    processingCompleted() {
      return this.processingStatus === PAYMENT_PROCESSING_STATUSES.COMPLETED;
    },
    processingCompletedWithError() {
      return this.processingCompleted && this.tokenizationError;
    },
    processingInProgress() {
      return this.processingStatus === PAYMENT_PROCESSING_STATUSES.IN_PROGRESS;
    },
  },
  methods: {
    ...mapActions('subscription', ['updateCreditCard', 'reset', 'fetchSubscription']),
    ...mapActions('subscriptionState', ['fetchSubscriptionState']),
    ...mapMutations('subscriptionState', ['setSubscriptionState']),
    closeActionClicked() {
      Segment.trackUserInteraction('UpdateCreditCardPopupClosed');
      this.$emit('close');
    },
    getImageUrl() {
      const iconType = this.processingCompletedWithError ? 'alert' : 'success';
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/icons/${iconType}-icon.svg`);
    },
    async updateCreditCardClicked() {
      if (this.processingStatus === PAYMENT_PROCESSING_STATUSES.IN_PROGRESS) {
        return;
      }
      MetricsApi.sendCounter('subscription.paymentButton.clicked', { type: 'update' });
      Segment.trackUserInteraction('UpdateCreditCardPopup_UpdateClicked');
      this.processingStatus = PAYMENT_PROCESSING_STATUSES.IN_PROGRESS;
      DDLogs.log('Updating credit card');
      await this.updateCreditCard();
      if (this.tokenizationError) {
        this.processingStatus = PAYMENT_PROCESSING_STATUSES.COMPLETED;
        return;
      }
      if (!this.creditCardIsValid) {
        this.processingStatus = PAYMENT_PROCESSING_STATUSES.NOT_STARTED;
        return;
      }
      await this.setSubscriptionState(SUBSCRIPTION_STATES.SUBSCRIBED);
      this.processingStatus = PAYMENT_PROCESSING_STATUSES.COMPLETED;
      await this.fetchSubscription();
      this.closePopupDelayed();
    },
    closePopupDelayed() {
      setTimeout(() => {
        this.$emit('close');
      }, DELAY_BEFORE_POPUP_CLOSE);
    },
  },
};

</script>

<style scoped lang="scss">

  @import "~@riseupil/base-ui/src/scss/riseup-colors";

  .personal-info {
    margin-top: 30px;
    margin-bottom: 40px;
    font-family: SimplerPro;

    .personal-info-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  .button-container {
    margin-top: 20px;

    .update-button {
      width: 100%;
    }

    .result-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 18px 16px;
      border-radius: 3px;
      background-color: rgba($riseup_gray_0, 0.4);

      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        font-size: 20px;
        color: $riseup_danger_red;
        margin-left: 20px;

        .icon {
          margin-left: 11px;
        }

        .message {
          font-size: 20px;
          font-weight: bold;

          &.error {
            color: $riseup_danger_red;
          }

          &.success {
            color: $riseup_green;
          }
        }
      }

      .cta {
        margin-top: 5px;
        font-family: SimplerPro;
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

</style>
