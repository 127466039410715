<template>
    <div class="upcoming-challenge">
      <div class="message margin-top-xxl">
        <div class="ri-bold-title">נתחיל בעוד {{daysTillStart}} ימים!</div>
        <div class="subtitle ri-title">בינתיים, אפשר להצטרף לקבוצת הוואטסאפ.</div>
      </div>
      <riseup-button @click="shareInWAGroup"
                     title=""
                     variant="primary"
                     :color="buttonColor"
                     class="action-button">
        <div class="whatsapp">
          <span>לקבוצת על זה ביחד</span>
          <colored-svg class="whatsapp-logo"
                       :svg-src="require('@/assets/icons/whatsapp-icon.svg')"
                       :color="whatsappLogoColor"/>
        </div>
      </riseup-button>
    </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapGetters } from 'vuex';

export default {
  name: 'UpcomingView',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    ColoredSvg: BaseUI.ColoredSvg,
  },
  props: {
    daysTillStart: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      whatsappLogoColor: BaseUI.Colors.riseupWhite,
      buttonColor: BaseUI.LayoutConsts.COLOR_VARIANT.GREEN,
    };
  },
  computed: {
    ...mapGetters(
      'imOnIt',
      ['whatsAppGroupLink'],
    ),
  },
  methods: {
    shareInWAGroup() {
      Segment.trackUserInteraction('ImOnItUpcomingProgramCard_WhatsAppClicked', { challengeId: this.imOnItChallengeId });
      window.open(this.whatsAppGroupLink, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import "~@riseupil/base-ui/src/scss/border-radius";
@import "~@riseupil/base-ui/src/scss/spacings";

    .upcoming-challenge {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .action-button {

        .whatsapp {
          color: $riseup_white;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 7px;

          span {
            text-wrap: nowrap;
          }

          .whatsapp-logo {
            width: 24px;
            height: 24px;
          }
        }
      }

    }

</style>
