<template>
  <altshuler-signup-step :cta-props="pageCtaProps" :current-step-index="currentStepIndex" current-step-name="סכום הפקדה"
                         :total-steps="totalSteps" :wizard-state="wizardState" title="כמה להעביר לפיקדון החדש?"
                         @next="nextStep">
    <div class="ri-large-body">
      יקח כמה ימים עד שהכסף יעבור, ותמיד אפשר למשוך את הכסף בחזרה תוך כמה ימים
    </div>
    <amount-input
      class="margin-top-sm"
      label="סכום ההפקדה"
      :value="depositAmount"
      :error-message="depositErrorMessage || depositWarning"
      @input="setDeposit"
      :hint="`מינימום ${depositMin} ₪ ומקסימום ${depositMax} ₪ לחצי שנה`"
    />
  </altshuler-signup-step>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import moneyUtils from '@/utils/money';
import { mapGetters } from 'vuex';
import Segment from '@/Segment';
import AltshulerSignupStep from './AltshulerSignupStep';
import { DEPOSIT_MINIMUM_AMOUNT, DEPOSIT_MAXIMUM_AMOUNT } from './altshuler.consts';

export default {
  name: 'AltshulerSignupSelectDepositAmount',
  components: {
    AltshulerSignupStep,
    AmountInput: BaseUI.AmountInput,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      depositAmount: undefined,
      depositMin: moneyUtils.formatAmountWithFraction(DEPOSIT_MINIMUM_AMOUNT, 0),
      depositMax: moneyUtils.formatAmountWithFraction(DEPOSIT_MAXIMUM_AMOUNT, 0),
    };
  },
  created() {
    this.depositAmount = this.wizardState.depositAmount ?? undefined;
  },
  computed: {
    ...mapGetters('balance', ['totalOshBalance']),
    balance() {
      return moneyUtils.formatAmount(Math.round(this.totalOshBalance));
    },
    depositErrorMessage() {
      if (_.isNil(this.depositAmount)) {
        return undefined;
      }

      if (_.isNaN(this.depositAmount)) {
        return 'סכום ההפקדה חייב להיות מספר';
      }

      if (this.depositAmount < DEPOSIT_MINIMUM_AMOUNT) {
        return `סכום ההפקדה המינימלי הוא ${DEPOSIT_MINIMUM_AMOUNT} ₪`;
      }

      if (this.depositAmount > 50000) {
        return 'סכום ההפקדה המקסימלי הוא 50,000 ₪ בחצי שנה';
      }

      return undefined;
    },
    depositWarning() {
      if (this.depositAmount > this.totalOshBalance) {
        return 'הסכום יותר גבוה מהיתרה בעו״ש כרגע';
      }
      return undefined;
    },
    nextDisabled() {
      return !this.depositAmount || !!this.depositErrorMessage;
    },
    pageCtaProps() {
      return {
        ...this.ctaProps,
        buttonProps: {
          ...this.ctaProps.buttonProps,
          disabled: this.nextDisabled,
        },
      };
    },
  },
  methods: {
    setDeposit(value) {
      this.depositAmount = value;
    },
    nextStep() {
      const data = { depositAmount: this.depositAmount };
      Segment.trackUserInteraction('Altshuler_SelectDepositAmount_NextClicked',
        { depositAmount: this.depositAmount, depositWarning: !!this.depositWarning });
      this.$emit('next', data);
    },
  },
};
</script>
