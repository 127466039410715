<template>
<div class="plan-section">
  <plan-summary-line :name="plan.name" :amount="plan.predictedAmountThisMonth" :date="dateAndMonthlyTarget"
                     show-menu-item @plan-menu-clicked="onMenuClicked" line-type="item"/>
</div>
</template>

<script>
import dateUtils from '@/utils/dates';
import Segment from '@/Segment';
import { mapActions } from 'vuex';
import SimpleBottomSheetMenu from '@/base-components/SimpleBottomSheetMenu';
import moneyUtils from '@/utils/money';
import PlanSummaryLine
  from './PlanSummaryLine';

export default {
  name: 'PlanSection',
  components: {
    PlanSummaryLine,
  },
  data() {
    return {
      showEditPlanSection: false,
    };
  },
  props: {
    plan: {
      type: Object,
      require: true,
    },
  },
  computed: {
    date() {
      return dateUtils.getShortMonthAndYear(this.plan.deadline);
    },
    dateAndMonthlyTarget() {
      return `${moneyUtils.formatAmountWithFraction(this.plan.monthlyTarget, 0)} ₪ בחודש · ${this.date}`;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    onMenuClicked() {
      this.openModal({
        component: SimpleBottomSheetMenu,
        props: {
          menuItems: [
            {
              text: 'לצפיה בתוכנית',
              iconProps: {
                iconName: 'visibility',
              },
              onClick: () => {
                this.$emit('edit-plan-click');
              },
            },
          ],
        },
        popupAlignment: 'menu',
      });
      Segment.trackUserInteraction('PlanAhead_Section_MenuClicked', { isOpen: this.showEditPlanSection });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.plan-section {
  border-bottom: 1px solid $riseup_gray_0;

  .edit-plan-section {
    cursor: pointer;
    height: 60px;
    display: flex;
    align-items: center;
    padding-right: 28px;
    background: $riseup_yellow;
    line-height: 7px;

    .edit-icon {
      padding-left: 22px;
      cursor: pointer;
      font-size: 24px
    }

    .text {
      color: $riseup_white;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
</style>
