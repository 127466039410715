<template>
  <dashboard-page :image-header-props="imageHeaderProps">
    <template v-slot:dashboard>
      <div class="margin-ml">
        <property class="margin-bottom-m" :name="'סוג מינוי'" :value="'מינוי לא פעיל'"/>
        <property class="margin-bottom-m" :name="'סיום תקופת הניסיון'" :value="trialEndDateFormatted"/>
        <div v-if="benefitId">
          <benefit-notification-box-v2 :flavor="flavors.accountSettings" />
        </div>
      </div>

      <separator />
      <div class="margin-ml">
        <riseup-button :action="subscribeClicked" :size="'slim'"
                       :title="'לבחור סוג מינוי'"/>
      </div>
    </template>
  </dashboard-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapState } from 'vuex';
import BenefitNotificationBoxV2
  from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitNotificationBox2.vue';
import BenefitMixin from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitMixin.vue';
import AccountSettingsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/AccountSettingsMixin.vue';
import dates from '../../../../../utils/dates';
import Property from './Property.vue';
import SubscribeClickHandler from './SubscribeClickHandler.vue';

export default {
  name: 'DormantAfterTrialSettings',
  components: {
    BenefitNotificationBoxV2,
    Property,
    RiseupButton: BaseUI.RiseupButton,
    DashboardPage: BaseUI.Pages.DashboardPage,
  },
  mixins: [SubscribeClickHandler, BenefitMixin, AccountSettingsMixin],
  computed: {
    ...mapState('subscriptionState', ['since']),
    ...mapState('benefits', ['benefitId']),
    trialEndDateFormatted() {
      return dates.shortDateFormat(this.since);
    },
  },
};
</script>

<style scoped lang="scss">

  @import "~@riseupil/base-ui/src/scss/riseup-colors";
  @import "./settings";

  @include properties;
</style>
