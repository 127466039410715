import { client } from '../http';

async function markAsDone() {
  const response = await client.post('/api/cashflow-tour-done');
  return response.data;
}

export default {
  markAsDone,
};
