export default [
  {
    insightId: 'personalizedInsight-newCardFee',
    insightName: 'personalizedInsight',
    budgetDate: '2024-01',
    topic: 'newCardFee',
    config: {
      insightName: 'newCardFee',
      insightImage: 'fees_insights.svg',
      insightBody: [{ type: 'text', text: 'המערכת זיהתה שהתחילו לחייב אותך לאחרונה בדמי כרטיס, בדרך כלל אפשר לבטל את זה בקלות.', classes: [] }],
      insightButtonText: 'ספרו לי עוד',
      popupTitle: [{ type: 'text', text: 'העמלה שמחכה שתבטלו אותה', classes: [] }],
      popupBody: [
        // eslint-disable-next-line
        { type: 'text', text: 'בכרטיס שמסתיים בספרות ${instance.accountNumberPiiId} זיהינו חיוב', classes: [] },
        { type: 'text', text: 'חדש', classes: ['bold'] },
        { type: 'text', text: 'של', classes: [] },
        { type: 'amount', variableName: 'instance.amount' },
        { type: 'text', text: 'בעמלת דמי כרטיס.', classes: [] },
        { type: 'text', text: 'אלפי לקוחות שלנו הפסיקו לשלם על דמי כרטיס, וכל מה שהם עשו זה לשלוח את ההודעה הזאת באפליקציה.', classes: [] },
        { type: 'lineBreak' },
        { type: 'link', url: 'https://youtu.be/svOr3yxJ1UA', text: 'קחו אותי להודעה שלכם' },
      ],
      data: {
        instance: {
          accountNumberPiiId: 'asd234rda',
          accountNumberPiiValue: '8923',
          amount: 19.00,
        },
      },
    },
  },
  {
    insightId: 'personalizedInsight-duplicatedTransactions',
    insightName: 'personalizedInsight',
    budgetDate: '2024-01',
    topic: 'duplicatedTransactions',
    config: {
      insightName: 'duplicatedTransactions',
      insightImage: 'template-notice-insight.svg',
      // eslint-disable-next-line
      insightBody: [{ type: 'text', text: 'זיהינו חיוב כפול ב-"${instance.businessName}" ואנחנו חושבים שכדאי לבדוק את זה  ', classes: [] }],
      insightButtonText: 'ספרו לי עוד',
      popupTitle: [{ type: 'text', text: 'חשד לחיוב כפול ', classes: [] }],
      popupBody: [
        { type: 'text', text: 'ראינו שבחשבון שלך יש חיוב כפול בסכום של  ', classes: [] },
        { type: 'amount', variableName: 'instance.amount' },
        // eslint-disable-next-line
        { type: 'text', text: 'מ-"${instance.businessName}"', classes: [] },
        // eslint-disable-next-line
        { type: 'text', text: ' ב-${instance.transactionDate}. אם זה נראה כמו טעות, כדאי לבדוק מול בית העסק או חברת האשראי.', classes: [] },
      ],
      popupTitleClass: 'pink',
      data: {
        instance: {
          businessName: 'מסעדת הגג',
          amount: 150.00,
          transactionDate: '9/3',
        },
      },
    },
  },
  {
    insightId: 'personalizedInsight-fees',
    insightName: 'personalizedInsight',
    budgetDate: '2024-01',
    topic: 'fees',
    config: {
      insightName: 'fees',
      insightImage: 'fees_insights.svg',
      insightBody: [
        {
          type: 'text',
          text: 'זיהינו עמלות שמגיעות עד',
          classes: [],
        },
        {
          type: 'amount',
          variableName: 'feesYearlySum',
        },
        {
          type: 'text',
          text: 'בשנה. אפשר לבטל אותן וברגע לחסוך בהוצאות הקבועות!',
          classes: [],
        },
      ],
      insightButtonText: 'ספרו לי עוד',
      popupTitle: [
        {
          type: 'text',
          text: '🏦 צמצום עמלות לבנק',
          classes: [],
        },
      ],
      popupBody: [
        {
          type: 'text',
          text: 'כדאי לצלצל לבנק ולחברת האשראי ולבקש לבטל את העמלות האלו:',
          classes: [],
        },
        {
          type: 'transactions',
          variableName: 'feesLastMonthTransactions',
        },
        {
          type: 'text',
          text: 'לבטל עמלות זה אפשרי ואפילו פשוט! מספיק להקדיש לזה 15 דקות ולהתחיל לחסוך. אפשר לקרוא עוד על איך לבטל עמלות',
          classes: [],
        },
        {
          type: 'link',
          url: 'https://www.facebook.com/groups/riseuptogethergroup/permalink/2846521145653247',
          text: 'בפוסט הזה בקהילה שלנו.',
        },
      ],
      lastUpdated: '2023-10-19T09:00:17.057Z',
      visibleToCustomers: true,
      created: '2023-10-19T09:00:17.057Z',
      data: {
        feesYearlySum: 517,
        feesLastMonthTransactions: [
          {
            transactionId: '123',
            amount: 9.5,
            businessName: 'מזרחי דמי כרטיס',
          },
          {
            transactionId: '124',
            amount: 19,
            businessName: 'דמי כרטיס',
          },
          {
            transactionId: '125',
            amount: 14.6,
            businessName: 'עמלת פעולה בערוץ ישיר',
          },
        ],
      },
    },
  },
  {
    insightId: 'personalizedInsight-subscriptionPriceChange',
    insightName: 'personalizedInsight',
    budgetDate: '2024-03',
    topic: 'subscriptionPriceChange',
    config: {
      insightName: 'subscriptionPriceChange',
      insightImage: 'template-notice-insight.svg',
      insightBody: [
        // eslint-disable-next-line
        { type: 'text', text: 'המערכת זיהתה שהחיוב החודשי ב-״${subscriptionPriceChangeBusinessName}״ התייקר', classes: [] },
      ],
      insightButtonText: 'ספרו לי עוד',
      popupTitle: [
        { type: 'text', text: 'נראה שהמחיר עלה', classes: [] },
      ],
      popupTitleClass: 'pink',
      popupBody: [
        // eslint-disable-next-line
        { type: 'text', text: 'החיוב החודשי ב-״${subscriptionPriceChangeBusinessName}״ עלה מ-', classes: [] },
        { type: 'amount', variableName: 'subscriptionPriceChangePriceBefore' },
        { type: 'text', text: ' ל- ', classes: [] },
        { type: 'amount', variableName: 'subscriptionPriceChangePriceAfter' },
        {
          type: 'text',
          // eslint-disable-next-line
          text: '. יכול להיות שנגמרה תקופת הניסיון או שהחברה עדכנה את המחיר. אם זה לא מה שסיכמו איתך או שזה נראה לך כמו טעות, כדאי לפנות לחברה ולברר. ',
          classes: [],
        },
      ],
      multipleInsight: null,
      data: {
        subscriptionPriceChangeBusinessName: 'בזק הוראת קבע',
        subscriptionPriceChangePriceBefore: 150,
        subscriptionPriceChangePriceAfter: 175,
      },
    },
  },
];
