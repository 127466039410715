<template>
  <altshuler-signup-step :cta-props="pageCtaProps" :current-step-index="currentStepIndex"
                         current-step-name="אישורים והצהרות"
                         :total-steps="totalSteps" :wizard-state="wizardState" title="נשארו רק כמה חלקים לקרוא, ולאשר."
                         @next="nextStep">
    <card class="margin-bottom-l margin-top-ml padding-vertical-ml">
      <div class="ri-bold-title margin-bottom-ml">
        צריך לאשר את תנאי הפיקדון (התנאים זהים למה שראית בהתחלה, תמיד כדאי לקרוא שוב)
      </div>
      <check-box :initially-checked="approveTermsAndConditions"
                 v-model="approveTermsAndConditions" alignment="top">
        <template v-slot:title>
          <div class="ri-body checkbox-text">
            קראתי ואישרתי את
            <a class="agreement-link"
               href="/pdfs/AltshulerTermsAndConditions.pdf"
               target="_blank"
               @click="termsAndConditionsClicked"
               @keyup.enter="termsAndConditionsClicked">הסכם התנאים</a>
            המלא של 'אלטשולר שחם פיננשיאל סרביסס'.
          </div>
        </template>
      </check-box>
    </card>

    <card class="margin-bottom-l padding-vertical-ml">
      <div class="ri-bold-title margin-bottom-ml">
        כדי להעביר כסף לפיקדון, צריך לאשר לאלטשולר שחם להעביר כסף מהחשבון שלך
      </div>
      <check-box v-model="approvePaymentInitiation"
                 :initially-checked="approvePaymentInitiation" alignment="top">
        <template v-slot:title>
          <div class="flex-column checkbox-text">
            <div class="ri-body">
              אני מאשר.ת ל'אלטשולר שחם פיננשייל סרביסס׳ להקים
              <a class="agreement-link"
                 href="/pdfs/AltshulerChargingConfirmation.pdf"
                 target="_blank"
                 @click="chargingConfirmationClicked"
                 @keyup.enter="chargingConfirmationClicked">הרשאה לחיוב החשבון</a>
              שלי כדי שאוכל להעביר כסף לפיקדון באמצעות רייזאפ.
            </div>
            <div class="ri-label">
              <a class="agreement-link" tabindex="0" @click="openLimitApprovalScopePopup">להגביל את סכום החיוב או
                התאריך</a> (לא חובה)
            </div>
          </div>
        </template>
      </check-box>
    </card>

    <card class="margin-bottom-l padding-vertical-ml">
      <div class="ri-bold-title margin-bottom-ml">
        עוד כמה סעיפים שצריך לאשר
      </div>

      <check-box v-model="approveDataTransferFromRiseUp"
                 :initially-checked="approveDataTransferFromRiseUp" alignment="top">
        <template v-slot:title>
          <div class="ri-body checkbox-text">
            אני מאשר.ת לרייזאפ להעביר את
            <a class="agreement-link"
               @click="openTransferredInformationPopup"
               @keyup.enter="openTransferredInformationPopup">המידע הרלוונטי</a>
            לצורכי פתיחת הפיקדון והפעולות בו ל׳אלטשולר שחם פיננשייל סרביסס׳.
          </div>
        </template>
      </check-box>
      <check-box v-model="approveDataTransferToRiseUp"
                 :initially-checked="approveDataTransferToRiseUp" alignment="top">
        <template v-slot:title>
          <div class="ri-body checkbox-text">
            הבנתי שהפיקדון והכספים בו מנוהלים ע״י ׳אלטשולר שחם פיננשיאל סרביסס׳. השימוש והצפייה בפיקדון מתבצעים דרך
            רייזאפ.
          </div>
        </template>
      </check-box>
      <check-box v-model="approveMarketingFromPartner"
                 :initially-checked="approveMarketingFromPartner" alignment="top">
        <template v-slot:title>
          <div class="ri-body checkbox-text">
            אשמח לקבל הצעות נוספות מ׳אלטשולר שחם פיננשיאל סרביסס׳.
          </div>
        </template>
      </check-box>
    </card>

    <card class="padding-vertical-ml">
      <div class="ri-bold-title margin-bottom-ml">
        החתימה היא אישור לכל הסעיפים שמופיעים כאן ותופיע בתור החתימה שלך בטופס הרשאה לחיוב חשבון והתנאים
      </div>
      <signature class="margin-bottom-m" height="145px" @signed="setSignature"/>
    </card>

  </altshuler-signup-step>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import AltshulerSignupStep from './AltshulerSignupStep';
import LimitApprovalScopePopup from './LimitApprovalScopePopup';
import TransferredInformationPopup from './TransferredInformationPopup';

export default {
  name: 'AltshulerSignupSignAgreement',
  components: {
    AltshulerSignupStep,
    Signature: BaseUI.Signature,
    Card: BaseUI.Card,
    CheckBox: BaseUI.CheckBox,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      signature: '',
      approveTermsAndConditions: false,
      approvePaymentInitiation: false,
      approveDataTransferFromRiseUp: false,
      approveDataTransferToRiseUp: false,
      approveMarketingFromPartner: false,
      dateLimit: undefined,
      sumLimit: undefined,
    };
  },
  created() {
    this.approveTermsAndConditions = this.wizardState.approveTermsAndConditions ?? false;
    this.approvePaymentInitiation = this.wizardState.approvePaymentInitiation ?? false;
    this.approveDataTransferFromRiseUp = this.wizardState.approveDataTransferFromRiseUp ?? false;
    this.approveDataTransferToRiseUp = this.wizardState.approveDataTransferToRiseUp ?? false;
    this.approveMarketingFromPartner = this.wizardState.approveMarketingFromPartner ?? false;
    this.dateLimit = this.wizardState.dateLimit;
    this.sumLimit = this.wizardState.sumLimit;
  },
  computed: {
    nextDisabled() {
      return _.isEmpty(this.signature)
        || !this.approveTermsAndConditions
        || !this.approvePaymentInitiation
        || !this.approveDataTransferFromRiseUp
        || !this.approveDataTransferToRiseUp;
    },
    pageCtaProps() {
      return {
        ...this.ctaProps,
        buttonProps: {
          ...this.ctaProps.buttonProps,
          disabled: this.nextDisabled,
        },
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    termsAndConditionsClicked() {
      Segment.trackUserInteraction('Altshuler_SignAgreement_TermsAndConditions_Clicked');
    },
    chargingConfirmationClicked() {
      Segment.trackUserInteraction('Altshuler_SignAgreement_ChargingConfirmation_Clicked');
    },
    openLimitApprovalScopePopup() {
      Segment.trackUserInteraction('Altshuler_SignAgreement_LimitApprovalScope_Clicked');
      this.openModal({
        component: LimitApprovalScopePopup,
        props: {
          dateLimit: this.dateLimit,
          sumLimit: this.sumLimit,
        },
        eventHandlers: {
          save: this.saveLimitApprovalScope,
        },
      });
    },
    openTransferredInformationPopup() {
      Segment.trackUserInteraction('Altshuler_SignAgreement_TransferredInformation_Clicked');
      this.openModal({
        component: TransferredInformationPopup,
      });
    },
    saveLimitApprovalScope({ limitSum, limitDate }) {
      this.dateLimit = limitDate;
      this.sumLimit = limitSum;
      Segment.trackUserInteraction('Altshuler_SignAgreement_LimitApprovalScope_Saved', {
        limitSum,
        limitDate,
      });
    },
    setSignature(value) {
      this.signature = value;
    },
    nextStep() {
      const data = {
        signature: this.signature,
        approveTermsAndConditions: this.approveTermsAndConditions,
        approvePaymentInitiation: this.approvePaymentInitiation,
        approveDataTransferFromRiseUp: this.approveDataTransferFromRiseUp,
        approveDataTransferToRiseUp: this.approveDataTransferToRiseUp,
        approveMarketingFromPartner: this.approveMarketingFromPartner,
        dateLimit: this.dateLimit,
        sumLimit: this.sumLimit,
      };
      Segment.trackUserInteraction('Altshuler_SignAgreement_NextClicked',
        { limitApprovalScope: !!this.dateLimit || !!this.sumLimit });
      this.$emit('next', data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.headline {
  color: $riseup_blue;
}

.checkbox-text {
  margin-right: 10px;
}

.agreement-link {
  color: $riseup_blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
