<script>
import { mapActions } from 'vuex';

export default {
  name: 'PreloadCashflowMixin',
  methods: {
    ...mapActions('insights', ['initInsights']),
    ...mapActions('mergeInvestigationQuestions', ['fetchMergeQuestions']),
    ...mapActions('balance', ['initBalance']),
    ...mapActions('challenge', ['fetchActiveChallenge']),
    preloadCashflow() {
      if (this.cashflowTabsEnabled) {
        Promise.all([
          /**
           * #############################
           * Stores added here must be "smart" stores!
           * Store that have an "initialized" state property, and that will only init once.
           * #############################
           */
          this.initInsights(),
          this.fetchMergeQuestions(),
          this.initBalance(),
          this.fetchActiveChallenge(),
        ]);
      }
    },
  },
};

</script>
