<template>
  <card :has-header-padding="false" class="on-it-100-days-card" custom-menu>
    <template v-slot:header>
      <img class="logo" src="/images/im-on-it/im-on-it-card-header-background.svg"/>
    </template>
    <template v-slot:default>
      <upcoming-view :days-till-start="daysTillStart" v-if="isUpcomingChallenge"/>
      <div v-else>
        <div class="days-section">
          <div class="days-left-and-week-number">
            <div class="days-counter" v-if="hasChallengeEnded">
              <span class="ri-body">{{ imOnItDaysCounter }} ימים</span>
              <span class="ri-small-body">על זה</span>
            </div>
            <div class="days-left" v-else>
              <span class="ri-body">{{ imOnItDaysLeft }} ימים</span>
              <span class="ri-small-body">נשארו לתוכנית</span>
            </div>
            <div class="week-number" v-if="!hasChallengeEnded">
              <span class="week-tag ri-small-body">שבוע {{ imOnItWeek }}</span>
            </div>
            <overflow-menu v-else>
              <template v-slot:menuItems>
                 <overflow-menu-item item-key="remove100Days" text="מחיקת כרטיסיה" icon-name="close" :on-item-click="openRemoveCardPopup" />
              </template>
            </overflow-menu>
          </div>
          <days-view class="margin-top-sm"
                     :displayedWeekSundaysDate="displayedWeekSundaysDate"
          />
        </div>
        <div class="c-t-a-section">
          <div class="label ri-medium-bold-headline">
            <span v-if="!amIOnItToday">אז איך הולך היום?</span>
            <span v-else v-html="imOnItLabel"/>
          </div>
        </div>
        <lottie-player v-if="showConfetti"
                       class="confetti"
                       src="/animations/100DaysConfetti.json"
                       preserveAspectRatio="xMidYMax slice"
                       speed="1"
                       @complete="showConfetti = false"
                       autoplay/>
        <riseup-button @click="buttonClick"
                       :loading="loading"
                       title=""
                       variant="primary"
                       class="action-button">
          <div class="whatsapp">
            <div :class="['color-animator', {'full-width': amIOnItToday}]" :style="{backgroundColor: buttonColor}"/>
            <span v-if="!amIOnItToday">היום אני על זה!</span>
            <span v-if="amIOnItToday">לשתף בקבוצת על זה ביחד</span>
            <colored-svg v-if="amIOnItToday"
                         class="whatsapp-logo"
                         :svg-src="require('@/assets/icons/whatsapp-icon.svg')"
                         :color="whatsappLogoColor"/>
          </div>
        </riseup-button>
      </div>
    </template>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import dateUtils from '@/utils/dates';
import Segment from '@/Segment';
import _ from 'lodash';
import UpcomingView from './UpcomingView.vue';
import HundredDaysBottomPopup from './HundredDaysBottomPopup';
import DaysView from './days-view/DaysView.vue';
import Remove100DaysCardPopup from './Remove100DaysCardPopup';

export default {
  name: 'ImOnIt100Days',
  components: {
    UpcomingView,
    DaysView,
    Card: BaseUI.Card,
    RiseupButton: BaseUI.RiseupButton,
    ColoredSvg: BaseUI.ColoredSvg,
    OverflowMenuItem: BaseUI.OverflowMenuItem,
    OverflowMenu: BaseUI.OverflowMenu,
  },
  data() {
    return {
      displayedWeekSundaysDate: dateUtils.getSundayDateBeforeNow(),
      whatsappLogoColor: BaseUI.Colors.riseupWhite,
      showConfetti: false,
      observer: undefined,
    };
  },
  created() {
    Segment.trackUserGot('ImOnItProgramCard', { challengeId: this.imOnItChallengeId });
  },
  mounted() {
    if (this.hasChallengeEnded) {
      return;
    }
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => (entry.isIntersecting || this.amIOnItToday || this.hasChallengeEnded
        ? this.clearBottomCorner() : this.showCornerPopup()));
    });

    const summaryAmountCard = document.getElementsByClassName('summary-amount-card')[0];
    this.observer.observe(this.$el);
    this.observer.observe(summaryAmountCard);
  },
  beforeDestroy() {
    this.observer?.disconnect();
    this.clearBottomCorner(false);
  },
  computed: {
    ...mapState('imOnIt', ['loading']),
    ...mapGetters(
      'imOnIt',
      ['imOnItWeek', 'imOnItDaysLeft', 'amIOnItToday', 'imOnItChallengeId', 'whatsAppGroupLink',
        'currentStreakLength', 'isTodayConfettiDay', 'isTodayWeeklySummaryDay', 'pastWeekDaysOnIt',
        'imOnItDaysCounter', 'isUpcomingChallenge', 'daysTillStart', 'hasChallengeEnded'],
    ),
    buttonColor() {
      return this.amIOnItToday ? BaseUI.Colors.riseupGreen : BaseUI.Colors.riseupBlue;
    },
    imOnItLabel() {
      const encourageWord = _.sample(['מדהים', 'איזה יופי', 'אין עליך']);

      if (this.isTodayWeeklySummaryDay) {
        if (this.pastWeekDaysOnIt === 7) {
          return `${encourageWord},<br/>השבוע על זה כל יום!`;
        }
        return `${encourageWord},<br/>השבוע כבר ${this.daysToString(this.pastWeekDaysOnIt)} על זה!`;
      }

      if (this.currentStreakLength > 1) {
        return `${encourageWord}!<br/>כבר ${this.daysToString(this.currentStreakLength)} ברצף`;
      }

      return 'היום על זה,<br/>כל הכבוד!';
    },
  },
  methods: {
    ...mapActions('imOnIt', ['updateParticipantDailyTaskDone']),
    ...mapActions('bottomCornerPopup', ['setBottomCorner', 'clearBottomCorner']),
    ...mapActions('modalRootStore', ['openModal']),
    daysToString(numberOfDays) {
      if (numberOfDays === 1) {
        return 'יום אחד';
      }
      if (numberOfDays === 2) {
        return 'יומיים';
      }
      return `${numberOfDays} ימים`;
    },
    showCornerPopup() {
      this.setBottomCorner({
        component: HundredDaysBottomPopup,
        props: {
          bounce: false,
        },
      });
    },
    async buttonClick() {
      if (!this.amIOnItToday) {
        await this.clickImOnIt();
      } else {
        this.shareInWAGroup();
      }
    },
    async clickImOnIt() {
      Segment.trackUserInteraction('ImOnItProgramCard_ImOnItClicked', { challengeId: this.imOnItChallengeId });
      await this.updateParticipantDailyTaskDone();
      this.showConfetti = this.isTodayConfettiDay && this.currentStreakLength > 1;
    },
    shareInWAGroup() {
      Segment.trackUserInteraction('ImOnItProgramCard_WhatsAppClicked', { challengeId: this.imOnItChallengeId });
      window.open(this.whatsAppGroupLink, '_blank');
    },
    async openRemoveCardPopup() {
      this.openModal({
        component: Remove100DaysCardPopup,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import "~@riseupil/base-ui/src/scss/border-radius";
@import "~@riseupil/base-ui/src/scss/spacings";

.on-it-100-days-card {
  margin: 0 0 16px;
  background-color: $riseup_white;
  position: relative;

  .logo {
    width: 100%;
  }

  ::v-deep.card-body {
    position: relative;
    height: 310px;
    padding: $ri-spacing-ml;
    display: flex;
    flex-direction: column;
    gap: $ri-spacing-ml;
  }

  .days-section {
    .days-left-and-week-number {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      ::v-deep.overflow-menu-container {
        top: $ri-spacing-xs;
      }

      .days-left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .days-counter {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .week-tag {
        background-color: $riseup_light_blue;
        color: $riseup_gray_80;
        border-radius: $label-border-radius;
        padding: 2px 6px;
      }
    }
  }

  .c-t-a-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: $ri-spacing-ml;

    .label {
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .action-button {
    position: relative;
    overflow: hidden;

    .color-animator {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      border-radius: 50%;
      transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
      transform: translate(-50%, -50%);

      &.full-width {
        width: 100%;
        height: 500px;
      }
    }

    & :not(.color-animation) {
      z-index: 1;
    }

    .whatsapp {
      color: $riseup_white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 7px;

      span {
        text-wrap: nowrap;
      }

      .whatsapp-logo {
        width: 24px;
        height: 24px;
      }
    }
  }

  .confetti {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $card-border-radius;
    overflow: hidden;
  }
}
</style>
