<template>
  <cashflow-type-card
      :is-preview-mode="isPreviewMode"
      :card-type-id="CASHFLOW_TYPE"
      :sub-tree="allExcludedTransactions"
      :can-edit-category="false"
      :out-of-date="outOfDate"
      :previous-month="previousMonth"
      :card-title="'לא בתזרים'">
    <template v-slot:bottom>
      <expandable-cashflow-transactions v-if="hasExcludedIncome"
                                        :class="{ 'disabled': isPreviewMode }"
                                        :card-type-id="CASHFLOW_TYPE"
                                        :category="categories.excludedIncome.categories[0]"
                                        :category-name="categoryNameExcludedIncome"
                                        :out-of-date="outOfDate"
                                        :arrow-size="'medium'"
                                        :summary-row-title="'פירוט הכנסות לא תזרימיות'"/>
      <expandable-cashflow-transactions :class="{ 'border-wrapper' : hasExcludedIncome, 'disabled': isPreviewMode }"
                                        v-if="hasExcludedExpense"
                                        :card-type-id="CASHFLOW_TYPE"
                                        :category="categories.excludedExpense.categories[0]"
                                        :category-name="categoryNameExcludedExpense"
                                        :out-of-date="outOfDate"
                                        :arrow-size="'medium'"
                                        :summary-row-title="'פירוט הוצאות לא תזרימיות'"/>
    </template>
  </cashflow-type-card>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import cashflowViewConsts from '../../../../../../../../../constants/cashflow-view';
import ExpandableCashflowTransactions from './ExpandableCashflowTransactions';
import CashflowTypeCard from './CashflowTypeCard';

export default {
  name: 'CashflowTypeBaseExcluded',
  components: {
    CashflowTypeCard,
    ExpandableCashflowTransactions,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
    CASHFLOW_TYPE() {
      return cashflowViewConsts.cashflowTypes.excluded;
    },
    categories() {
      return this.cashflowUITree[this.CASHFLOW_TYPE];
    },
    hasExcludedExpense() {
      return _.sumBy(this.categories.excludedExpense.categories, c => c.transactions.length) > 0;
    },
    hasExcludedIncome() {
      return _.sumBy(this.categories.excludedIncome.categories, c => c.transactions.length) > 0;
    },
    categoryNameExcludedExpense() {
      return cashflowViewConsts.CATEGORY_NAMES.EXCLUDED_EXPENSE;
    },
    categoryNameExcludedIncome() {
      return cashflowViewConsts.CATEGORY_NAMES.EXCLUDED_INCOME;
    },
    allExcludedTransactions() {
      return {
        categories: [
          {
            transactions: [
              ...this.categories.excludedIncome.categories[0].transactions,
              ...this.categories.excludedExpense.categories[0].transactions,
            ],
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../../../../../../../scss/category-color-mixins';
@import "cashflow-type-base.styles";

.border-wrapper {
  border-top: 1px solid $riseup_gray_1;
}
</style>
