<template>
  <cashflow-type-card
      v-if="economyExpenses"
      :is-preview-mode="isPreviewMode"
      :card-type-id="CASHFLOW_TYPE"
      :can-edit-category="false"
      :card-title="'הוצאות סופר'"
      :sub-tree="economyExpenses"
      :cashflow-category="cashflowCategory"
      :blocked="blocked"
      :previous-month="previousMonth"
      :show-warning-if-exceeding-budget="!previousMonth"
      :out-of-date="outOfDate">
    <template v-slot:bottom>
      <expandable-category-details :cashflow-category="cashflowCategory"
                                   :card-type-id="CASHFLOW_TYPE"
                                   :class="{ 'disabled' : isPreviewMode }"
                                   :category-name="categoryName"
                                   :category-type="CASHFLOW_TYPE"
                                   :time-frame="'weekly'"
                                   :blocked="blocked"
                                   :use-category-id="true"
                                   :categories="economyExpenses.categories"
                                   :previous-month="previousMonth"
                                   :has-update="economyExpenses.hasUpdate"
                                   :out-of-date="outOfDate"/>
    </template>
  </cashflow-type-card>
</template>

<script>
import { mapState } from 'vuex';
import cashflowViewConsts from '../../../../../../../../../constants/cashflow-view';
import CashflowTypeCard from './CashflowTypeCard';
import ExpandableCategoryDetails from './ExpandableCategoryDetails';

export default {
  name: 'CashflowTypeBaseEconomy',
  components: {
    CashflowTypeCard,
    ExpandableCategoryDetails,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
    CASHFLOW_TYPE() {
      return cashflowViewConsts.cashflowTypes.economy;
    },
    economyExpenses() {
      return this.cashflowUITree[this.CASHFLOW_TYPE];
    },
    categoryName() {
      return cashflowViewConsts.cashflowTypes.trackingCategory;
    },
    cashflowCategory() {
      return cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING;
    },
  },
};
</script>

<style scoped lang="scss">
@import "cashflow-type-base.styles";
</style>
