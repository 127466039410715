<template>
  <next-steps-base-card title="להחליט כמה אפשר להעביר לחסכונות וכמה כדאי להשאיר בעו״ש"
                        button-text="למחשבון העו״ש"
                        description="2 דקות, מחשבון"
                        :action-key="actionKey"
                        @click="openBalanceMoneyCalculator"/>
</template>

<script>
import { mapActions } from 'vuex';
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';
import BalanceMoneyCalculatorStep from '../../../cashflow-app/personalized-savings/balance-money/BalanceMoneyCalculatorStep';

export default {
  name: 'BalanceMoneyCalculatorNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.BALANCE_MONEY_CALCULATOR,
    };
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openBalanceMoneyCalculator() {
      this.openModal({
        component: BalanceMoneyCalculatorStep,
        props: { deepLink: true, stepName: 'deep-linked-OshCalculator' },
      });
    },
  },
};
</script>

<style scoped>

</style>
