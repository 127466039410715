<template>
  <div class="cashflow-type-base-summary-amount" :class="{ 'out-of-date': outOfDate }">
    <summary-amount-card
        :is-preview-mode="isPreviewMode"
        :intro="intro"
        :variant="variant"
        :use-animated-number="isPredictedCashflowPositive"
        :out-of-date="outOfDate"
        :blocked="blocked"
        :amount="amountToShow"
        :show-footer="showFooter"
        :showOshButton="showOshButtonOnSummaryAmount">
      <template v-slot:title>{{ title }}</template>
      <template v-slot:footer v-if="isPredictedCashflowPositive && showNextWeek">
        <div class="next-week-title">הוצאות משתנות של שבוע הבא</div>
        <div v-if="blocked" class="next-week-amount">***</div>
        <div v-else class="next-week-amount">{{ formatAmountWithFractionAndCurrency(nextWeekRemainingVariables)}}</div>
      </template>
      <template v-slot:footer v-else-if="!isPredictedCashflowPositive">
        <div class="eom-description" :class="{ 'out-of-date': outOfDate }">
          <div class="remaining-vars" :class="{ 'out-of-date': outOfDate }">נשארו 0 הוצאות משתנות</div>
          <div class="remaining-until">להוציא עד סוף {{monthFullName}}</div>
        </div>
      </template>
    </summary-amount-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SummaryAmountCard from '@/base-components/cashflow/SummaryAmountCard';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';

export default {
  name: 'CashflowTypeBaseSummaryAmount',
  mixins: [TransactionDisplayUtils],
  components: {
    SummaryAmountCard,
  },
  data() {
    return {
      showRemainingAmountCalculation: false,
    };
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
    showOshButtonOnSummaryAmount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('cashflowView', ['monthFullName', 'remainingWeeklyVariables', 'predictedBalanceAtEndOfMonth',
      'predictedBalanceAtEndOfMonth', 'hasNextWeek', 'nextWeekRemainingVariables']),
    ...mapGetters('session', ['householdName']),
    intro() {
      return `היי ${this.householdName}`;
    },
    showFooter() {
      return (this.isPredictedCashflowPositive && this.showNextWeek) || !this.isPredictedCashflowPositive;
    },
    isPredictedCashflowPositive() {
      return this.predictedBalanceAtEndOfMonth > 0;
    },
    variant() {
      if (this.isPredictedCashflowPositive) {
        return this.hasRemainingVariables ? 'green' : 'black';
      }
      return this.predictedBalanceAtEndOfMonth === 0 ? 'black' : 'red';
    },
    showNextWeek() {
      return this.hasNextWeek && !this.hasRemainingVariables;
    },
    hasRemainingVariables() {
      return this.remainingWeeklyVariables > 0;
    },
    title() {
      return this.isPredictedCashflowPositive
        ? 'כמה כסף נשאר להוציא על הוצאות משתנות השבוע?'
        : `איך צפוי להסתיים התזרים של ${this.monthFullName}?`;
    },
    amountToShow() {
      return this.isPredictedCashflowPositive ? this.remainingWeeklyVariables : this.predictedBalanceAtEndOfMonth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../../../../../scss/mixins';

.cashflow-type-base-summary-amount {
  color: $riseup_black;
  &.out-of-date {
    color: $riseup_gray_2;
  }

  &::v-deep .footer {
    padding-left: 24px;
    padding-right: 24px;
  }

  .eom-description {
    @include cashflow-h2;

    &.out-of-date {
      color: $riseup_gray_2;
    }

    .remaining-vars {
      font-weight: bold;
      color: $riseup_danger_red;
      &.out-of-date {
        color: $riseup_gray_2;
      }
    }
    .remaining-until {
      margin-top: 5px;
    }
  }

  .next-week-title {
    font-weight: bold;
  }
  .next-week-amount {
    margin-top: 5px;
  }
}
</style>
