<template>
  <div class="cashflow-app">
    <div class="bars-container">
      <months-navigation-bar :disabled="blocked"/>
      <cashflow-app-snack-bar/>
    </div>
    <time-limited-offer-timer-small v-if="showNoTrialDiscountBanner"
                                    title="לחסוך כבר מהיום הראשון"
                                    :offer-id="noTrialDiscountOfferId"
                                    :end-date="noTrialDiscountEndDate"
                                    :on-click="openNtdPopup"/>
    <div v-if="showNotification || showCarousel">
      <notification v-if="showNotification" class="notification-wrapper"/>
      <insights-carousel :key="insightsCarouselVisible" :insights="filteredInsights" v-else/>
      <separator/>
    </div>
    <div class="cashflow-container">
      <cashflow-view/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import MidMonthEvent
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/midmonth/MidMonthEvent';
import CashflowAppSnackBar
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/CashflowAppSnackBar';
import Intercom from '@/Intercom';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import NavigationBus from '@/event-bus/navigation-bus';
import EventBus from '@/event-bus/event-bus';
import NoTrialDiscountMixin from '@/mixins/NoTrialDiscountMixin.vue';
import NoTrialDiscountPopup
  from '@/pages/responsive-pages/authenticated/trial/no-trial-discount/NoTrialDiscountPopup.vue';
import BrowserInfo from '@/mixins/BrowserInfo.vue';
import DDLogs from '@/DDLogs';
import TransactionAndBudgetDateParamHandler from '@/mixins/TransactionAndBudgetDateParamHandler.vue';
import Segment from '../../../../../Segment';
import CashflowView from './cashflow-view/CashflowView.vue';
import MonthsNavigationBar from './cashflow-view/MonthsNavigationBar';

const ExpenseSummary = () => import('./cashflow-view/expense-summary/ExpenseSummary');
const Notification = () => import('./notification/Notification');
const InsightsCarousel = () => import('./notification/notification-types/InsightsCarousel');
const TimeLimitedOfferTimerSmall = () => import('@/pages/responsive-pages/authenticated/offers/time-limited-offers/TimeLimitedOfferTimerSmall.vue');
export default {
  name: 'CashflowApp',
  components: {
    CashflowView,
    Notification,
    InsightsCarousel,
    MonthsNavigationBar,
    CashflowAppSnackBar,
    Separator: BaseUI.Separator,
    TimeLimitedOfferTimerSmall,
  },
  data() {
    return {
      showToaster: false,
      currentToasterComponent: null,
      toasterProps: null,
      insightsCarouselVisible: false, // bug fix - force re-render of the carousel when tab reactivates / search closes
    };
  },
  mixins: [NoTrialDiscountMixin, BrowserInfo, TransactionAndBudgetDateParamHandler],
  async created() {
    performanceService.markStart(`${PerformanceMarks.COMPONENT}_CashflowApp`);
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
      textColor: BaseUI.Colors.riseupBlack,
      sticky: true,
      showLogo: true,
      bottomBorder: true,
      rightActions: [
        {
          type: BaseUI.HeaderActions.MENU,
          onClick: this.openNavBar,
        },
      ],
    });
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const { userAgent } = navigator;
    Segment.trackUserGot('CashflowViewEntered', {
      cashflowApp: true,
      userAgent,
      vw,
      vh,
      showNotification: this.showNotification,
      isActiveNotification: this.isActiveNotification,
      isPresentingCurrentMonth: this.isPresentingCurrentMonth,
    });
    this.startContentLoading(this.$options.name);
    await Promise.all(_.compact([
      this.fetchMergeQuestions(),
      !this.presentedBudget && this.initBudgets({ budgetDate: this.$route.params.budgetDate }),
    ]));
    this.initChallenges();
    this.initTimeLimitedOffers();
    this.initImOnInChallenge();
    if (this.notificationState === this.notificationTypes.ACCOUNT_QUESTIONS) {
      await this.fetchQuestions();
    }
    EventBus.$on('search-closed', this.forceRerenderCarousel);
    this.checkDeepLinks();
    this.doneContentLoading(this.$options.name);
    this.$nextTick(() => {
      performanceService.markEnd(`${PerformanceMarks.COMPONENT}_CashflowApp`);
    });
    Intercom.update(
      this.customerId, this.householdName, {
        cashflow: this.predictedBalanceAtEndOfMonth > 0 ? 'Positive' : 'Negative',
        ...this.cashflowUITree.income && {
          hasFixedIncome: !!this.cashflowUITree.income.fixedIncome,
          zeroIncomeForecast: _.isEqual(this.cashflowUITree.income.recommendedOrExpectedAmount, 0),
        },

      },
      this.cashflowTabsEnabled,
    );
    if (this.$route.query.promoCode) {
      const { promoCode } = this.$route.query;
      await this.fetchPromotion(promoCode);
      await this.createBenefit(promoCode);
      Segment.trackUserGot('PromotionAfterActivation', { promoCode });
    }

    if (this.incognitoModeDetection) {
      const isIncognitoMode = await this.isIncognitoMode();
      DDLogs.info('Incognito mode detection test', {
        isIncognitoMode,
        browser: this.browser,
        userOnMobile: this.userOnMobile,
        userPlatform: this.userPlatform,
      });
    }
  },
  beforeDestroy() {
    EventBus.$off('search-closed', this.forceRerenderCarousel);
  },
  activated() {
    this.forceRerenderCarousel();
  },
  computed: {
    ...mapGetters('session', ['householdName']),
    ...mapState('session', ['customerId']),
    ...mapGetters('cashflowView', ['presentedBudget', 'isPresentingCurrentMonth', 'isPresentingLastMonth', 'predictedBalanceAtEndOfMonth']),
    ...mapState('cashflowView', ['cashflowUITree', 'presentedBudgetDate']),
    ...mapState('applicationState', ['notificationState', 'cashflowState']),
    ...mapGetters('applicationState', ['notificationTypes', 'isActiveNotification', 'cashflowTypes']),
    ...mapGetters('insights', ['filteredInsights']),
    ...mapGetters('featureFlags', ['cashflowTabsEnabled', 'incognitoModeDetection']),
    ...mapGetters('timeLimitedOffers', ['noTrialDiscountEndDate']),
    ...mapGetters('subscriptionState', ['inTrial']),
    showNoTrialDiscountBanner() {
      return this.inTrial && this.noTrialDiscountEndDate;
    },
    showNotification() {
      return this.isActiveNotification && (this.isPresentingCurrentMonth || this.isPresentingLastMonth);
    },
    showCarousel() {
      return !this.blocked && this.isPresentingCurrentMonth && this.anyCarouselCards;
    },
    anyCarouselCards() {
      return this.filteredInsights.length > 0;
    },
    blocked() {
      return this.cashflowState === this.cashflowTypes.BLOCKED;
    },
  },
  methods: {
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('applicationState', ['initApplicationState']),
    ...mapActions('cashflowView', ['initBudgets']),
    ...mapActions('accountInvestigationQuestions', ['fetchQuestions']),
    ...mapActions('mergeInvestigationQuestions', ['fetchMergeQuestions']),
    ...mapActions('insights', ['initInsights']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('challenges', ['initChallenges']),
    ...mapActions('imOnIt', ['initImOnInChallenge']),
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('timeLimitedOffers', ['initTimeLimitedOffers']),
    ...mapActions('benefits', ['createBenefit']),
    ...mapActions('promotions', ['fetchPromotion']),
    openNavBar() {
      NavigationBus.$emit('open');
      Segment.trackUserInteraction('HamburgerMenuClicked');
    },
    openNtdPopup() {
      this.openModal({
        component: NoTrialDiscountPopup,
      });
    },
    forceRerenderCarousel() {
      this.insightsCarouselVisible = !this.insightsCarouselVisible;
    },
    checkDeepLinks() {
      if (this.$route.query.expenses) {
        this.openModal({
          component: ExpenseSummary,
          props: {
            initialBudgetDate: this.presentedBudgetDate,
          },
        });
      }
      if (this.$route.query.midmonth) {
        this.openModal({ component: MidMonthEvent });
      }
      if (this.$route.query.transactionId) {
        this.openTransaction(this.$route.query.transactionId, 0, true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/riseup-z-index';
@import '../../../../../scss/mixins';
@import '../../../../../scss/global-variables';

.cashflow-app {
  flex: 1;
  ::v-deep {
    .offer-timer {
      margin-right: 30px;
      margin-left: 30px;
    }
  }

  .aboveCarousel {
    background: $riseup_gray_-1;
  }
  .eoy {
    background-color: $riseup_gray_-1;
  }

  font-family: SimplerPro;

  .bars-container {
    position: sticky;
    top: $app_header_height;
    z-index: $app-header;
  }

  .notification-wrapper {
    @include responsive-padding;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: $riseup_gray_-1;
  }

  .cashflow-container {
    border-top: 1px solid $riseup_gray_0;
    height: 100%;
    padding-top: 30px;
    background-color: $riseup_white;
  }
}
</style>
