<template>
  <div class="pa-past-month-transaction ri-label">
    <span class="plan-name">{{ name }}</span>
    <div>
      <span class="ri-bold-label">₪{{ amount }}</span>
      /
      <span>₪{{ total }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PlanAheadPastMonthTransaction',
  props: {
    name: {
      type: String,
      required: true,
    },
    total: {
      type: String,
      required: true,
    },
    amount: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.pa-past-month-transaction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;

  .plan-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
  }
}
</style>
