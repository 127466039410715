<template>
  <bottom-sheet variant="white" :disabled="loading" @close="closeSheet">
    <template v-slot:header>
      <div class="header ri-large-body">
        {{ headerText }}
      </div>
    </template>
    <template v-slot:menuItems>
      <bottom-sheet-menu-item v-if="isCurrentlyPaused" text="להפעיל את ההודעות עכשיו" :icon-props={} @click="resumeMessages()"/>
      <bottom-sheet-menu-item v-if="!isCurrentlyPaused" text="להשתיק לשבוע" :icon-props={} @click="pauseMessages('week')"/>
      <bottom-sheet-menu-item v-if="!isCurrentlyPaused" text="להשתיק לחודש" :icon-props={} @click="pauseMessages('month')"/>
    </template>
  </bottom-sheet>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';
import PausedMessagesToaster from './PausedMessagesToaster';

export default {
  name: 'PauseMessagesBottomSheet',
  components: {
    BottomSheetMenuItem: BaseUI.BottomSheetMenuItem,
    BottomSheet: BaseUI.BottomSheet,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    memberCommunicationState: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState('session', ['activeMember']),
    isCurrentlyPaused() {
      return !!this.memberCommunicationState;
    },
    pauseExpirationDate() {
      if (this.isCurrentlyPaused) {
        return this.memberCommunicationState.pauseExpirationDate;
      }
      return undefined;
    },
    headerText() {
      const formattedDate = moment(this.pauseExpirationDate).format('DD.MM.YY');
      return this.isCurrentlyPaused
        ? `ההודעות הושתקו, הן יתחדשו אוטומטית ב${formattedDate}`
        : 'אפשר להשתיק זמנית את הודעות הוואטסאפ. הן יתחדשו אוטומטית בסוף התקופה.';
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('communicationState', ['updateAutoSendStateForMember', 'getCommunicationState']),
    async closeSheet() {
      await this.getCommunicationState();
      this.closeModal();
    },
    async updateCommunicationStateForMemberMember(pauseAutoSend, pauseDuration) {
      this.loading = true;
      const params = { pauseAutoSend };
      if (pauseAutoSend) {
        params.pauseExpirationDate = moment().add(1, pauseDuration).toDate();
        params.reason = 'customer settings update';
      }

      await this.updateAutoSendStateForMember(params);
      this.loading = false;
    },
    async pauseMessages(duration) {
      await this.updateCommunicationStateForMemberMember(true, duration);
      Segment.trackUserInteraction(
        'SnoozeMemberNotification_Snooze',
        { duration, memberId: this.activeMember._id },
      );
      EventBus.$emit('open-toaster', {
        component: PausedMessagesToaster,
        props: { wasPaused: true, duration },
      });

      await this.closeSheet();
    },
    async resumeMessages() {
      await this.updateCommunicationStateForMemberMember(false);
      Segment.trackUserInteraction('SnoozeMemberNotification_SnoozeBackOn', { memberId: this.activeMember._id });
      EventBus.$emit('open-toaster', {
        component: PausedMessagesToaster,
        props: { wasPaused: false },
      });

      await this.closeSheet();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  text-align: start;
}
</style>
