<template>
  <div class="split-review-mode">
    <colored-popup :class="categoryToVariant[selectedCategory.cashflowCategory]" :close-action="onClose">

      <template v-slot:top-content>
          <span class="name">מעולה!</span>
        <div class="split-question"> {{ title }} </div>
      </template>

      <template v-slot:bottom-content>
        <div v-for="(slice, index) in nonZeroSlices" :key="index">
          <slice-review :amount="slice.amount"
                        :category="slice.category"
                        :transaction="transaction"
                        :comment="slice.comment"
                        :last="index === nonZeroSlices.length-1" />
        </div>

        <riseup-button class="done" :action="next" :variant="'primary'" :title="'סיימתי'" size="small" :loading="loading"/>
        <div class="back">
          <div class="text" v-on:click="back">רגע, אני רוצה לשנות משהו</div>
        </div>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import * as _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import SliceReview from './SliceReview';

export default {
  name: 'SplitTransactionReviewMode',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    SliceReview,
  },
  created() {
    Segment.trackUserInteraction('SplitTransactionReview_Shown', { numOfSplitTransaction: this.nonZeroSlices.length });
  },
  props: {
    next: {
      type: Function,
      required: true,
    },
    back: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState('editTransaction', ['transaction']),
    ...mapState('splitTransaction', ['slices', 'loading']),
    ...mapGetters('editTransaction', ['selectedCategory', 'categoryToVariant']),
    ...mapGetters('splitTransaction', ['splitLabelPlural']),
    title() {
      return `אלו ה${this.splitLabelPlural} שיופיעו בתזרים שלך:`;
    },
    nonZeroSlices() {
      return _.filter(this.slices, slice => slice.amount > 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.split-review-mode{
  text-align: right;
  .colored-top {
    .name {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
    }
    .split-question {
      margin-top: 22px;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }
  }
  .done {
    width: 100%;
  }
  .back {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    height: 56px;
    font-size: 18px;
    color: $riseup_black;

    .text {
      cursor: pointer;
    }
  }
}
</style>
