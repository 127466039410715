<template>
  <div class="plans-section">
    <expandable-summary-row text-style="medium"
                            :with-shadow="hasActivePlans"
                            :is-expandable="true"
                            :out-of-date="outOfDate"
                            :initially-expanded="initiallyOpened"
                            @expanded="onExpand">
      <template v-slot:header>
        <div class="text-container" :class="{ 'out-of-date': outOfDate }">
          {{ sectionTitle }}
        </div>
      </template>
      <template v-slot:content>
        <template v-if="hasActivePlans">
          <div class="plans-header ri-bold-label">
            <div>התוכנית</div>
            <div>אשים החודש</div>
          </div>
          <div class="plans-container">
            <plan-section v-for="plan in ongoingPlans" :plan="plan" :key="plan._id"
                          @edit-plan-click="() => openEditPage(plan)"/>
            <plan-summary-line v-if="goalRemainder > 0" name="נשאר עוד" :amount="goalRemainder" line-type="item"/>
          </div>
          <div class="total-summary margin-top-sm">
            <plan-status-note class="margin-horizontal-ml" v-bind="footerNoteProps"
                              :cashflowIsNegative="cashflowIsNegative"/>
            <card-action-button class="margin-ml" text="למסך התוכניות" @click="goToPlanAheadPage"/>
          </div>
        </template>
        <plans-button v-else class="margin-horizontal-ml margin-bottom-ml margin-top-xs"/>
      </template>
    </expandable-summary-row>
  </div>
</template>

<script>
import Segment from '@/Segment';
import * as _ from 'lodash';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import moneyUtils from '@/utils/money';
import router from '@/router';
import BaseUi from '@riseupil/base-ui';
import PlanStatusNote from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/PlanStatusNote';
import PlanDetailsPage from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/PlanDetailsPage';
import CardActionButton from '@/base-components/card-parts/CardActionButton';
import PlanSection from './PlanSection';
import PlanSummaryLine from './PlanSummaryLine';
import ExpandableSummaryRow
  from '../../../cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/ExpandableSummaryRow';
import PlanAheadMixin from '../../PlanAheadMixin';
import PlansButton from '../PlansButton';

export default {
  name: 'PlansSection',
  mixins: [PlanAheadMixin],
  components: {
    PlansButton,
    CardActionButton,
    PlanStatusNote,
    PlanSection,
    ExpandableSummaryRow,
    PlanSummaryLine,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    Segment.trackUserGot('PlanAhead_Section_Shown', { isOnPlansTrack: this.isOnPlansTrack });
    this.initiallyOpened = this.showPlansInSection;
    this.setShowPlansInSection(false);
  },
  data() {
    return {
      checkIconColor: BaseUi.Colors.riseupGreen,
      errorIconColor: BaseUi.Colors.riseupDangerRed,
      initiallyOpened: false,
    };
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapMutations('planAhead', ['setShowPlansInSection']),
    openEditPage(plan) {
      Segment.trackUserInteraction('PlanAhead_Section_GoToPlanPage');
      this.openModal({
        component: PlanDetailsPage,
        props: {
          plan,
        },
        popupAlignment: 'full-screen',
      });
    },
    goToPlanAheadPage() {
      Segment.trackUserInteraction('PlanAhead_Section_GoToPlansDashboard');
      router.push({ path: '/plan-ahead' });
    },
    onExpand() {
      Segment.trackUserInteraction('PlanAhead_Section_Expanded');
    },
  },
  computed: {
    ...mapGetters('cashflowView', ['riseupGoal', 'predictedBalanceAtEndOfMonth']),
    ...mapState('planAhead', ['showPlansInSection']),
    ...mapGetters('planAhead', ['totalPlansMonthlyAmount', 'hasActivePlans']),
    cashflowIsPositive() {
      return this.predictedBalanceAtEndOfMonth >= 0;
    },
    sectionTitle() {
      if (this.hasActivePlans) {
        return 'פירוט התוכניות';
      }
      return this.cashflowIsPositive && this.riseupGoal >= 100 ? 'מה אעשה עם הכסף' : 'לתכנן קדימה';
    },
    isPlansOnTrackThisMonth() {
      return Math.round(this.riseupGoal) >= Math.round(this.totalPlansMonthlyAmount);
    },
    isAllPlansOnTrackPastMonths() {
      return _.every(this.ongoingPlans, plan => plan.onTrack);
    },
    onTrackPlansGap() {
      return moneyUtils.formatAmountWithFraction(this.totalPlansMonthlyAmount - this.riseupGoal, 0);
    },
    goalRemainder() {
      return this.riseupGoal - this.totalPlansMonthlyAmount;
    },
    noteText() {
      if (this.cashflowIsNegative) {
        return 'בתזרים החיובי הבא נוודא יחד שהוא מיועד לתכניות והחלומות שלך';
      }
      if (this.isPlansOnTrackThisMonth && this.isAllPlansOnTrackPastMonths) {
        return 'נראה שהכל בכיוון הנכון';
      }
      if (!this.isPlansOnTrackThisMonth) {
        return `חסרים ${this.onTrackPlansGap} ₪ כדי לעמוד בתיכנון החודש`;
      }
      return 'נראה שלא כל התוכניות יושלמו במועדים שרצית. מומלץ לערוך אותן בהתאם'; // !this.isAllPlansOnTrackPastMonths
    },
    headerNoteProps() {
      return {
        isOnTrack: this.isPlansOnTrackThisMonth && this.isAllPlansOnTrackPastMonths,
        size: 'regular',
      };
    },
    footerNoteProps() {
      return {
        ...this.headerNoteProps,
        text: this.noteText,
      };
    },
    cashflowIsNegative() {
      return this.predictedBalanceAtEndOfMonth < 0;
    },
  },
  watch: {
    async isOnPlansTrack(newValue) {
      Segment.trackUserGot('PlanAhead_Section_OnTrackChanged', { isOnPlansTrack: this.isOnPlansTrack });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.plans-section {
  font-size: 14px;

  .plans-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $ri-spacing-sm $ri-spacing-ml;
  }

  .plans-container {
    background: $riseup_gray_01;
  }

  .text-container {
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 50px;
    padding-right: 7px;

    &.out-of-date {
      color: $riseup_gray_2;
    }

    .text {
      width: 100%;
    }

    .header-note {
      margin-right: $ri-spacing-xs;
    }

  }
  .total-summary {
    .total-line {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .edit-plans-button {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    .edit-plans-text {
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      margin: 18px 0;

      .edit-arrow {
        margin-right: 5px;
      }
    }
  }
}
</style>
