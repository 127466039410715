<template>
  <div class="split-transaction-edit">
    <colored-popup :class="categoryToVariant[selectedCategory.cashflowCategory]" :close-action="close">

      <template v-slot:top-content>
        <div class="transaction-title"> {{ selectedCategory.label }} </div>
        <transaction-details :transaction="splitFromTransaction"/>
        <div id="split-question" class="question"> {{ question }} </div>
      </template>

      <template v-slot:bottom-content>
        <div v-for="(slice, index) in slices" :key="index">
          <div class="sep" v-if="index > 0 && index !== slicesLength - 1"/>
          <split-transaction-slice v-if="index !== slicesLength - 1" :index="index"/>
        </div>
        <div class="sep"/>
        <add-transaction-slice-section :disabled="lastSlice.amount === 0"/>
        <div class="sep"/>
        <split-transaction-slice :index="slicesLength-1"/>
        <riseup-button :action="next" :variant="'primary'" :title="'סיימתי'" :disabled="isSaveDisabled" size="small"/>
        <div class="delete">
          <div class="text" v-on:click="onDelete">{{ cancelTitle }}</div>
        </div>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import effectsUtils from '@/store/utilities/effects';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import TransactionDetails from '../TransactionDetails.vue';
import Segment from '../../../../../../../../../../../Segment';
import SplitTransactionSlice from './SplitTransactionSlice';
import AddTransactionSliceSection from './AddTransactionSliceSection';

export default {
  name: 'SplitTransactionEditMode',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    TransactionDetails,
    RiseupButton: BaseUI.RiseupButton,
    SplitTransactionSlice,
    AddTransactionSliceSection,
  },
  props: {
    next: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
  },
  created() {
    effectsUtils.scrollToElement('split-question');
  },
  computed: {
    ...mapState('editTransaction', ['transaction']),
    ...mapState('splitTransaction', ['slices', 'splitFromTransaction', 'actionType', 'changeMade']),
    ...mapGetters('editTransaction', ['selectedCategory', 'categoryToVariant']),
    ...mapGetters('splitTransaction', ['lastSlice', 'splitLabel']),
    cancelTitle() {
      if (this.actionType === 'update' && !this.changeMade) {
        return 'מחק את הפיצול';
      }
      return 'ביטול';
    },
    question() {
      return `איך לפצל את ה${this.splitLabel}?`;
    },
    slicesLength() {
      return this.slices.length;
    },
    isSaveDisabled() {
      return _.some(_.map(this.slices.slice(0, -1), 'amount'), amount => amount === 0)
        || _.some(this.slices, slice => slice.errorMessage)
        || (_.filter(this.slices, s => s.amount > 0)).length < 2;
    },
  },
  methods: {
    close() {
      this.sendSegmentEvent('SplitTransaction_EditCloseClicked');
      this.onClose();
    },
    sendSegmentEvent(eventName) {
      Segment.trackUserInteraction(eventName, {
        businessName: this.transaction.businessName,
        originalCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.split-transaction-edit {
  text-align: right;
  .colored-top {

    .transaction-title {
      margin-bottom: 15px;
      font-weight: bold;
    }
    .question {
      margin-top: 25px;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }
  }
  .sep{
    height: 19px;
    margin: 20px -24px;
    background-color: $riseup_gray_disabled;
    border: 1px solid $riseup_gray_0;
  }

  .riseup-button {
    margin-top: 20px;
    width: 100%;
  }
  .delete {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    height: 56px;
    font-size: 18px;
    color: $riseup_black;

    .text {
      cursor: pointer;
    }
  }
}

</style>
