var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('subscription-type-button',{attrs:{"selected":_vm.selected,"subscription-type":_vm.SUBSCRIPTION_TYPES.YEARLY,"main-text":'מינוי שנתי',"sub-text":'חיוב אחד בשנה',"extra-text":_vm.yearlyDiscountText},on:{"subscription-type-selected":function () {
	var ref;

	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];
	return (ref = this$1).$emit.apply(ref, [ 'subscription-type-selected' ].concat( args ));
}},scopedSlots:_vm._u([{key:"price",fn:function(){return [_c('span',{staticClass:"ri-font-24"},[_vm._v(_vm._s(_vm.pricing.annual))]),_vm._v(" "),_c('span',{staticClass:"ri-font-20"},[_vm._v("₪")])]},proxy:true},{key:"sub-price",fn:function(){return [_c('div',{staticClass:"ri-font-14"},[_c('span',[_vm._v("("+_vm._s(_vm.monthlyPrice)+" ₪ לחודש)")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }