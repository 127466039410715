<template>
  <div class="selectable-scale">
    <div class="button-wrapper">
      <div class="selectable-buttons-container">
        <div class="top" >
          <div v-for="score in [5, 4, 3, 2, 1, 0]" :key="score" @click="selectScore(score)"
                            class="selectable-button" :class="{ 'button-clicked': score === selectedScore }">
            <div>{{ score }}</div>
          </div>
        </div>
        <div class="bottom" >
          <div v-for="score in [10, 9, 8, 7, 6]" :key="score" @click="selectScore(score)"
                            class="selectable-button" :class="{ 'button-clicked': score === selectedScore }">
            <div>{{ score }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="legend">
      <div>{{ maxLegendText }}</div>
      <div>{{ minLegendText }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SelectableScale',
  data() {
    return {
      selectedScore: null,
    };
  },
  props: {
    maxLegendText: { type: String, required: true },
    minLegendText: { type: String, required: true },
    setScore: { type: Function, required: true },
  },
  methods: {
    selectScore(score) {
      this.setScore(score);
      this.selectedScore = score;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .selectable-scale {
    .button-wrapper {
      .selectable-buttons-container {
        width: 323px;
        margin: 0 auto 18px;
        .top {
          display: flex;
          justify-content: center;
          margin-bottom: 8px;
        }

        .bottom {
          display: flex;
          justify-content: center;
        }

        .selectable-button {
          &:not(:last-child) {
            margin-left: 10px;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          height: 43px;
          width: 43px;
          border-radius: 50%;
          text-align: center;
          transition: all 0.3s;
          border: solid 1px $riseup_gray_3;
          &.button-clicked {
            background-color: $riseup_blue;
            color: $riseup_white;
          }
        }
      }
    }

    .legend {
      display: flex;
      justify-content: space-between;
      color: $riseup_gray_1;
      margin-bottom: 60px;
    }
  }

</style>
