<template>
  <div class="flex-column">
    <div class="flex-row highlight" v-for="(content, index) in highlights" :key="index">

      <colored-svg v-if="content.shapeType === 'shape'" :svg-src="content.src" :color="content.color || colorBlue "
                   :class="shapeClass(content.shapeSize)" />
      <img v-else-if="content.shapeType === 'img'" :src="content.src" :class="shapeClass(content.shapeSize)" />
      <div class="flex-column justify-content-center margin-right-s">
        <span v-if="!!content.primary" class="ri-bold-label highlight-title"
              :style="{ color: content.color || colorBlue }" >{{content.primary}}</span>
        <span class="ri-body" v-if="!!content.secondary">{{content.secondary}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'Highlights',
  components: {
    ColoredSvg: BaseUI.ColoredSvg,
  },
  props: {
    highlights: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      colorBlue: BaseUI.Colors.riseupBlue,
    };
  },
  methods: {
    shapeClass(shapeSize) {
      if (shapeSize === 'big') {
        return 'item-shape-big';
      }
      return 'item-shape-small';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/riseup-colors';
$small-shape-width: 20px;
$small-shape-height: 20px;
$shape-width: 30px;
$shape-height: 30px;

.highlight {
  white-space: pre-wrap;
  &:not(:last-child) {
    margin-bottom: $ri_spacing-l;
  }
  .highlight-title {
    //color: $riseup_blue;
  }

  .item-shape-big {
    width: $shape-width;
    height: $shape-height;
    min-width: $shape-width;
    min-height: $shape-height;
    margin-top: $ri-spacing-xxs
  }

  .item-shape-small {
    width: $small-shape-width;
    height: $small-shape-height;
    min-width: $small-shape-width;
    min-height: $small-shape-height;
    margin-top: $ri-spacing-xxs
  }
}

</style>
