<template>
  <div class="loading" v-if="this.cashflowGraphHistory.length < 1">
    <loader/>
  </div>
  <component v-else :is="popup.component" v-bind="popup.props"></component>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import DDLogs from '@/DDLogs';
import Loader from '@/base-components/Loader';
import GrowingMonthsCelebrationPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/growting-months/GrowingMonthsCelebrationPopup';
import ConsecutivePositiveMonthsCelebrationPopup
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/consecutive-positive-months/ConsecutivePositiveMonthsCelebrationPopup';
import TurnOverCelebrationPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/turn-over/TurnOverCelebrationPopup';
import LessNegativeCelebrationPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/less-negative/LessNegativeCelebrationPopup';
import AtLeastOnePositiveCelebrationPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/at-least-one-positive/AtLeastOnePositiveCelebrationPopup';
import * as celebrationUtils from '@/utils/celebration-utils';

export default {
  name: 'EomCelebrationPopup',
  components: {
    Loader,
    GrowingMonthsCelebrationPopup,
    ConsecutivePositiveMonthsCelebrationPopup,
    TurnOverCelebrationPopup,
    LessNegativeCelebrationPopup,
    AtLeastOnePositiveCelebrationPopup,
  },
  computed: {
    ...mapGetters('cashflowView', ['cashflowGraphHistory']),
    popup() {
      const totals = _.map(this.cashflowGraphHistory, h => h.total);
      const type = celebrationUtils.getCelebrationType(totals);
      const { cashflowDate } = this.cashflowGraphHistory[0];

      switch (type) {
      case celebrationUtils.CELEBRATION_TYPES.GrowingMonths:
        return {
          component: GrowingMonthsCelebrationPopup,
          props: {
            months: celebrationUtils.getConsecutiveGrowingMonths(totals),
            cashflowDate,
          },
        };
      case celebrationUtils.CELEBRATION_TYPES.ConsecutivePositiveMonths:
        return {
          component: ConsecutivePositiveMonthsCelebrationPopup,
          props: {
            months: celebrationUtils.getConsecutivePositiveMonths(totals),
            cashflowDate,
          },
        };
      case celebrationUtils.CELEBRATION_TYPES.TurnOver:
        return {
          component: TurnOverCelebrationPopup,
          props: {
            amount: celebrationUtils.getTurnOverLastMonth(totals),
            cashflowDate,
          },
        };
      case celebrationUtils.CELEBRATION_TYPES.LessNegative:
        return {
          component: LessNegativeCelebrationPopup,
          props: {
            amount: celebrationUtils.getLessNegativeImprovement(totals),
            cashflowDate,
          },
        };
      case celebrationUtils.CELEBRATION_TYPES.AtLeastOnePositive:
        return {
          component: AtLeastOnePositiveCelebrationPopup,
          props: {
            cashflowDate,
          },
        };
      default:
        DDLogs.error('Don\'t know what popup to show, this shoul\'nt happen');
        return null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loading {
  padding: 50px;
}
</style>
