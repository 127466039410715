<template>
  <share-frame class="less-negative-asset" :background-color="backgroundColor" logoColor="white">
    <less-negative-core :amount="amount" :household-name="householdName" :cashflow-date="cashflowDate" shared-asset/>
  </share-frame>
</template>

<script>

import lessNegativeCore
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/less-negative/LessNegativeCore';
import ShareFrame from '@/base-components/share/ShareFrame';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'LessNegativeAsset',
  components: {
    ShareFrame,
    lessNegativeCore,
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    householdName: {
      type: String,
      required: true,
    },
    cashflowDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupBlue,
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.less-negative-asset {
  width: 500px;
  .less-negative {
    background-color: $riseup_white;
    padding: 24px;
  }
}

</style>
