<template>
  <share-frame class="at-least-one-positive-asset" :background-color="backgroundColor"  logoColor="white">
    <at-least-one-positive-core :cashflow-date="cashflowDate" shared-asset />
  </share-frame>
</template>

<script>

import AtLeastOnePositiveCore
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/at-least-one-positive/AtLeastOnePositiveCore';
import ShareFrame from '@/base-components/share/ShareFrame';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'GrowingMonthsAsset',
  components: {
    AtLeastOnePositiveCore,
    ShareFrame,
  },
  props: {
    cashflowDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupDangerRed,
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.at-least-one-positive-asset {
  width: 500px;
  .at-least-one-positive {
    background-color: $riseup_white;
    padding: 24px;
  }
}

</style>
