<template>
  <eom-celebration-generic-popup class="at-least-one-positive-celebration-popup" :segment-extra-props="segmentExtraProps"
                                 :cashflow-date="cashflowDate" :share-component="shareComponent"
                                 :share-component-props="shareComponentProps" cta="לשתף ולהתייעץ">
    <template v-slot>
      <at-least-one-positive-core :cashflow-date="cashflowDate" ></at-least-one-positive-core>
    </template>
  </eom-celebration-generic-popup>
</template>

<script>

import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
import EomCelebrationGenericPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/EomCelebrationGenericPopup';
import cashflowHistory from '@/store/utilities/cashflow-history';
import AtLeastOnePositiveAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/at-least-one-positive/AtLeastOnePositiveAsset';
import AtLeastOnePositiveCore
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/at-least-one-positive/AtLeastOnePositiveCore';

export default {
  name: 'AtLeastOnePositiveCelebrationPopup',
  mixins: [TransactionDisplayUtils],
  components: {
    AtLeastOnePositiveCore,
    EomCelebrationGenericPopup,
  },
  props: {
    cashflowDate: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      shareComponent: AtLeastOnePositiveAsset,
      shareComponentProps: {},
    };
  },
  created() {
    this.shareComponentProps = {
      cashflowDate: this.cashflowDate,
    };
  },
  computed: {
    nextMonth() {
      return cashflowHistory.getFullMonthName(cashflowHistory.getNextBudgetDate(this.cashflowDate));
    },
    thisMonth() {
      return cashflowHistory.getFullMonthName(this.cashflowDate);
    },
    segmentExtraProps() {
      return {
        celebrationType: 'AtLeastOnePositiveCelebrationPopup',
        cashflowDate: this.cashflowDate,
      };
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.at-least-one-positive-celebration-popup {
}

</style>
