<template>
  <popup class="expense-summary-container" :close-action="close">
    <template v-slot:content>
      <expense-summary-core :presented-month="presentedMonth" :all-expenses="allExpenses">
        <div class="months-navigation-bar">
          <div class="prev-month-button" @click="prevMonth()">
            <img v-if="prevMonthEnabled" :src="require('@/assets/icons/arrow_enabled_right.svg')" />
            <img v-else :src="require('@/assets/icons/arrow_disabled_right.svg')" />
          </div>
          <div class="current-month-description">
            <span class="month">{{ monthName }}</span>
            <span class="year">{{ yearName }}</span>
          </div>
          <div class="next-month-button" @click="nextMonth()">
            <img v-if="nextMonthEnabled" :src="require('@/assets/icons/arrow_enabled_left.svg')" />
            <img v-else :src="require('@/assets/icons/arrow_disabled_left.svg')" />
          </div>
        </div>
      </expense-summary-core>
      <share-button filename="expense-summary" share-name="ExpenseSummary"
                    :share-component="shareComponent" :share-component-props="shareComponentProps"
                    :segment-extra-props="segmentExtraProps" v-slot="slotProps"
      :show-popup="false">
        <riseup-button variant="primary"
                       size="slim"
                       title="לשתף ולהתייעץ"
                       :action="slotProps.onClick"
                       :loading="slotProps.loading"/>
      </share-button>
    </template>
  </popup>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import moment from 'moment';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import Segment from '@/Segment';
import cashflowNormalizer from '@/store/utilities/cashflow-normalizer';
import cashflowViewConsts from '@/constants/cashflow-view';
import cashflowHistory from '@/store/utilities/cashflow-history';
import ShareButton from '@/base-components/share/ShareButton';
import ExpenseSummaryCore
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/expense-summary/ExpenseSummaryCore';
import ExpenseSummaryAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/expense-summary/ExpenseSummaryAsset';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
import { DateLocales } from '@riseupil/common-utils';

export default {
  name: 'ExpenseSummary',
  mixins: [TransactionDisplayUtils],
  components: {
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
    ExpenseSummaryCore,
    ShareButton,
  },
  props: {
    initialBudgetDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSharing: false,
      shareAssetPromise: null,
      shareAsset: null,
      presentedMonth: null,
      shareComponent: ExpenseSummaryAsset,
    };
  },
  created() {
    Segment.trackUserInteraction('ExpenseSummaryOpenedV2', { initialBudgetDate: this.initialBudgetDate });
    this.presentedMonth = this.initialBudgetDate;
  },
  computed: {
    ...mapState('cashflowView', ['allCashflows']),
    shareComponentProps() {
      return {
        allExpenses: this.allExpenses,
        presentedMonth: this.presentedMonth,
      };
    },
    segmentExtraProps() {
      return {
        month: this.presentedMonth,
      };
    },
    prevBudgetDate() {
      return cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate());
    },
    allExpenses() {
      return _.chain(this.allCashflows)
        .reject(({ budgetDate }) => budgetDate === cashflowHistory.getCurrentBudgetDate())
        .map(cashflow => {
          const normalizedCashflow = cashflowNormalizer.normalizeCashflow(reactiveCashflow.createCashflow(cashflow, DateLocales.EN_IL));
          return {
            budgetDate: cashflow.budgetDate,
            fixed: _.map(normalizedCashflow[cashflowViewConsts.cashflowTypes.fixedExpense].categories, c => {
              return {
                name: c.label,
                icon: labelToIcon[c.label] || defaultIcon,
                amount: c.actualsSum,
              };
            }),
            tracking: _.map(normalizedCashflow[cashflowViewConsts.cashflowTypes.trackingCategory], c => {
              return {
                name: c.categoryName,
                icon: BaseUI.V2CategoryIcon(c),
                amount: c.upToNowAmount,
              };
            }),
          };
        }).value();
    },
    monthName() {
      return cashflowHistory.getFullMonthName(this.presentedMonth);
    },
    yearName() {
      return moment(this.presentedMonth).locale('he').format('YYYY');
    },
    prevMonthEnabled() {
      return _.find(this.allExpenses, e => e.budgetDate === cashflowHistory.getPrevBudgetDate(this.presentedMonth));
    },
    nextMonthEnabled() {
      return _.find(this.allExpenses, e => e.budgetDate === cashflowHistory.getNextBudgetDate(this.presentedMonth));
    },
  },
  methods: {
    prevMonth() {
      if (this.prevMonthEnabled) {
        this.presentedMonth = cashflowHistory.getPrevBudgetDate(this.presentedMonth);
        Segment.trackUserInteraction('ExpenseSummaryScroll', { month: this.presentedMonth });
      }
    },
    nextMonth() {
      if (this.nextMonthEnabled) {
        this.presentedMonth = cashflowHistory.getNextBudgetDate(this.presentedMonth);
        Segment.trackUserInteraction('ExpenseSummaryScroll', { month: this.presentedMonth });
      }
    },
    close() {
      Segment.trackUserInteraction('ExpenseSummaryClosed');
      this.$emit('close');
    },
  },
};

const defaultIcon = 'receipt_long';
const labelToIcon = {
  ארנונה: 'apartment',
  ביטוח: 'lock',
  'ביטוח לאומי': 'verified_user',
  גז: 'gas_meter',
  דיגיטל: 'sensors',
  דיור: 'domain',
  'הוצאה לפועל': 'all_out',
  'החזר טכני': 'redo',
  הלוואה: 'credit_score',
  חינוך: 'school',
  חשמל: 'bolt',
  כללי: 'receipt_long',
  לחסכון: 'show_chart',
  'השקעה וחיסכון': 'show_chart',
  מים: 'water_drop',
  'מכירת ניירות ערך או מטח': 'trending_up',
  מנויים: 'card_membership',
  'מס הכנסה': 'Description',
  משכנתא: 'home',
  'סיוע בשכר דירה': 'real_estate_agent',
  עמלות: 'account_balance',
  קצבאות: 'toll',
  תקשורת: 'smartphone',
  תרומה: 'volunteer_activism',
  תשלומים: 'pie_chart',
  העברות: 'exit_to_app',
  שיק: 'universal_currency_alt',
  מזומן: 'payments',
  אחר: 'receipt_long',
};

</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../../../scss/mixins';

.expense-summary-container {
  color: $riseup_black;

  &.popup .top .text-wrapper .title {
    margin-bottom: 0;
  }

  .share-button {
    margin-top: 22px;
  }

  .months-navigation-bar {
    color: $riseup_black;
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    border-top: 1px solid $riseup_gray_0;
    border-bottom: 1px solid $riseup_gray_0;
    background-color: $riseup_white;
    // margin is to get the borders all the way to the sides,
    // than padding aligns the content where we actually want it
    margin: 0 -24px;
    padding: 0 24px;

    .prev-month-button, .next-month-button {
      cursor: pointer;
    }

    .current-month-description {
      .month{
        font-weight: bold;
      }
    }
  }

  &.popup .top .text-wrapper .content {
    margin-bottom: 0;
  }
}

</style>
