<template>
  <bar-graph class="past-months-bar-graph" :class="{ 'out-of-date': outOfDate }" :graph-height="graphHeight" :bar-values="barValues"></bar-graph>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'PastMonthsGraph',
  components: {
    BarGraph: BaseUI.BarGraph,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
    barValues: {
      type: Array,
      required: true,
    },
    graphHeight: {
      type: Number,
      default: 102,
    },
  },
};

</script>

<style lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.past-months-bar-graph.bar-graph {
  .graph-drawing .bars {
    .current .value {
      font-weight: bold;
    }

    &.positive {
      .bar {
        background-color: $riseup_light_green;
      }

      .current .bar {
        background-color: $riseup_green;
      }
    }

    &.negative {
      .bar {
        background-color: $riseup_danger_light_red;
      }
      .current .bar {
        background-color: $riseup_danger_red
      }
    }
  }

  .month-names {
    .month-name.current {
      font-weight: bold;
      color: $riseup_black
    }
  }

  &.out-of-date {
    .graph-drawing .bars {
      .value {
        color: $riseup_gray_2
      }

      &.positive {
        .bar {
          background-color: $riseup_gray_1;
        }

        .current .bar {
          background-color: $riseup_gray_2;
        }
      }

      &.negative {
        .bar {
          background-color: $riseup_gray_1;
        }
        .current .bar {
          background-color: $riseup_gray_2
        }
      }
    }
    .month-names {
      .month-name.current {
        color: $riseup_gray_2
      }
    }
  }
}
</style>
