<template>
  <my-progress-graph :ranged-dataset="rangedDataset" :filters="filters" :header-size="120">
    <template v-slot:filters>
      <my-progress-variables-filters v-if="oldestCfMonth" class="full-width" :filters="filters"
                                  :oldest-cf-month="oldestCfMonth" @filters-changed="filtersChanged"/>
    </template>
    <template v-slot:header>
      <div class="my-progress-category-header ri-large-body">
        בין החודשים {{ startDate | getMonthAndShortYear }} - {{ endDate | getMonthAndShortYear }}
        <div>
          אלה היו ההוצאות המשתנות שלך:
        </div>
      </div>
    </template>
    <template v-slot:graph="graphSlotProps">
      <div>
        <div class="margin-top-m margin-right-sm">
          <div class="ri-medium-headline">
            {{ average | signedFormatAmountWithFraction(0) | shekel }}
          </div>
          <div class="ri-label">ממוצע חודשי</div>
        </div>
        <bar-graph-v2 v-bind="getBarGraphProps(graphSlotProps)"/>
      </div>
    </template>
  </my-progress-graph>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import BarGraphV2 from '@/base-components/bar-graph-v2/BarGraphV2';
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import moment from 'moment';
import dateUtils from '@/utils/dates';
import { getCashflowMonthFromMonthsAgo, getCurrentCashflowMonth } from '@riseupil/common-utils';
import { DATE_FILTERS_STRINGS } from '../../my-progress-filters.consts';
import MyProgressVariablesFilters from './MyProgressVariablesFilters.vue';
import MyProgressGraph from '../../MyProgressGraph';

export default {
  name: 'MyProgressFixedPage',
  components: {
    MyProgressVariablesFilters,
    MyProgressGraph,
    BarGraphV2,
  },
  data() {
    return {
      filters: {
        dates: {
          filterKey: 'last-6-months',
          ...DATE_FILTERS_STRINGS['last-6-months'],
          start: null,
          end: null,
        },
      },
      barStyles: {
        width: '12px',
        borderRadius: '10px',
        backgroundColor: BaseUI.Colors.riseupYellow,
      },
    };
  },
  async created() {
    Segment.trackUserGot('VariablesProgressPage_Entered');
    await this.initCustomerProgress();
    this.filters.dates.end = getCashflowMonthFromMonthsAgo(this.currentCashflowMonth, 1);
    this.filters.dates.start = getCashflowMonthFromMonthsAgo(this.currentCashflowMonth, 6);
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BEIGE,
      textColor: BaseUI.Colors.riseupDarkGreen,
      logoColor: BaseUI.Colors.riseupDarkGreen,
    });
  },
  computed: {
    ...mapState('customerProgress', ['cashflowTrends']),
    ...mapState('cashflowView', ['cashflowStartDay']),
    ...mapState('applicationState', ['activationDate']),
    imageHeaderProps() {
      return {
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BEIGE,
        bottomSeparator: false,
        textColor: BaseUI.Colors.riseupDarkGreen,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.TOP_LEFT,
        imgHeight: 180,
      };
    },
    variablesGraphData() {
      return this.cashflowTrends?.variables ?? []; // { amount: number; cashflowMonth: string; }[]
    },
    oldestCfMonth() {
      return _.minBy(this.variablesGraphData, entry => entry.cashflowMonth)?.cashflowMonth;
    },
    rangedDataset() {
      if (!this.variablesGraphData) {
        return null;
      }
      const monthsInRange = moment(this.filters.dates.end).diff(this.filters.dates.start, 'months') + 1;
      const cashflowMonths = _.times(monthsInRange, i => dateUtils.formatCashflowDate(moment(this.filters.dates.start)
        .add(i, 'months')));
      return cashflowMonths.map(month => this.variablesGraphData.find(({ cashflowMonth }) => cashflowMonth === month) || { cashflowMonth: month });
    },
    currentCashflowMonth() {
      return getCurrentCashflowMonth(this.cashflowStartDay);
    },
    startDate() {
      if (this.oldestCfMonth > this.filters.dates.start) {
        return this.oldestCfMonth;
      }
      return this.filters.dates.start;
    },
    endDate() {
      return _.min([this.filters.dates.end, getCashflowMonthFromMonthsAgo(this.currentCashflowMonth, 1)]);
    },
    average() {
      return _.chain(this.rangedDataset)
        .filter(month => month && !_.isNil(month.amount))
        .meanBy('amount')
        .value();
    },
  },
  methods: {
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('customerProgress', ['initCustomerProgress']),
    filtersChanged(filters) {
      this.filters = filters;
    },
    valueFormatter({ amount = null }) { // { amount: number; cashflowMonth: string; }
      return { value: amount };
    },
    getBarGraphProps({ rangedDataset, labelFormatter, xTickFormatter, yTickFormatter }) {
      return {
        rawValues: rangedDataset,
        valueFormatter: this.valueFormatter,
        labelFormatter,
        xTickFormatter,
        yTickFormatter,
        barStyles: this.barStyles,
        barEdgeMarks: false,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.my-progress-category-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $riseup_black;
  height: 100%;
  padding-top: $ri-spacing-xs;
  text-align: right;
}
</style>
