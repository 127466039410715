<template>
  <div>
    <ambassadorIdFormPopUp v-if="showForm" @submit="onIdUpdate"/>
    <ambassadorIdEnteredPopUp v-if="!showForm"/>
  </div>
</template>

<script>
import ambassadorIdFormPopUp
  from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/AmbassadorIdFormPopUp';
import ambassadorIdEnteredPopUp
  from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/AmbassadorIdEnteredPopUp';

export default {
  name: 'AmbassadorIdPopUp',
  components: {
    ambassadorIdFormPopUp,
    ambassadorIdEnteredPopUp,
  },
  data() {
    return {
      showForm: true,
    };
  },
  methods: {
    onIdUpdate() {
      this.showForm = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
