<template>
  <div class="split-review-mode">
    <colored-popup :class="categoryToVariant[selectedCategory.cashflowCategory]">
      <template v-slot:top-content>
          <span class="name"> {{title}} </span>
        <div class="split-question"> {{ splitQuestion }} </div>
      </template>
      <template v-slot:bottom-content>
        <div class="info">
          {{info}}
        </div>
          <slice-review :amount="amount"
                        :category="category(splitFromTransaction, availableCategories)"
                        :transaction="splitFromTransaction"
                        :comment="splitFromTransaction.customerComment"
                        :last="true"/>
        <riseup-button class="done" :action="onClose" :variant="'primary'" :title="buttonText" size="small"/>
        <div class="back">
          <div class="text" v-on:click="back">אני לא רוצה למחוק</div>
        </div>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { findOriginalParentCategory, transactionAmount } from '@/utils/splitTransactionUtils';
import { mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import SliceReview from './SliceReview';

export default {
  name: 'SplitTransactionDeleteMode',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    SliceReview,
  },
  props: {
    back: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('DeleteSplitTransaction_Shown');
  },
  computed: {
    ...mapState('editTransaction', ['transaction']),
    ...mapState('splitTransaction', ['splitFromTransaction']),
    ...mapGetters('editTransaction', ['selectedCategory', 'categoryToVariant', 'availableCategories']),
    ...mapGetters('splitTransaction', ['splitLabelPlural', 'splitLabel']),
    title() {
      return 'למחוק את הפיצול';
    },
    info() {
      return ` זאת ה${this.splitLabel} שתופיע בתזרים שלך:`;
    },
    splitQuestion() {
      return `ה${this.splitLabelPlural} המפוצלות לא יישמרו, למחוק בכל זאת?`;
    },
    buttonText() {
      return 'כן, אני רוצה להשאיר רק אותה';
    },
    amount() {
      return transactionAmount(this.splitFromTransaction);
    },
  },
  methods: {
    category(splitTransaction, availableCategories) {
      return findOriginalParentCategory(splitTransaction, availableCategories);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.split-review-mode{
  text-align: right;
  .colored-top {
    .name {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
    }
    .split-question {
      margin-top: 22px;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }
  }
  .info {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 32px;
  }
  .done {
    width: 100%;
  }
  .back {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    height: 56px;
    font-size: 18px;
    color: $riseup_black;

    .text {
      cursor: pointer;
    }
  }
}
</style>
