<template>
  <eom-celebration-generic-popup class="turn-over-celebration-popup" :segment-extra-props="segmentExtraProps" :cashflow-date="cashflowDate"
                                 :share-component="shareComponent" :share-component-props="shareComponentProps">
    <template v-slot>
      <turn-over-core :cashflow-date="cashflowDate" :amount="amount" :household-name="householdName" ></turn-over-core>
    </template>
  </eom-celebration-generic-popup>
</template>

<script>

import { mapGetters } from 'vuex';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
import EomCelebrationGenericPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/EomCelebrationGenericPopup';
import cashflowHistory from '@/store/utilities/cashflow-history';
import TurnOverAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/turn-over/TurnOverAsset';
import TurnOverCore
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/turn-over/TurnOverCore';

export default {
  name: 'TurnOverCelebrationPopup',
  mixins: [TransactionDisplayUtils],
  components: {
    EomCelebrationGenericPopup,
    TurnOverCore,
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    cashflowDate: {
      type: String,
      require: true,
    },
  },
  computed: {
    ...mapGetters('session', ['householdName']),
    shareComponent() {
      return TurnOverAsset;
    },
    shareComponentProps() {
      return {
        amount: this.amount,
        householdName: this.householdName,
        cashflowDate: this.cashflowDate,
      };
    },
    lastMonth() {
      return cashflowHistory.getFullMonthName(cashflowHistory.getPrevBudgetDate(this.cashflowDate));
    },
    thisMonth() {
      return cashflowHistory.getFullMonthName(this.cashflowDate);
    },
    segmentExtraProps() {
      return {
        celebrationType: 'TurnOverCelebrationPopup',
        amount: this.amount,
        cashflowDate: this.cashflowDate,
      };
    },
  },
};
</script>

<style lang="scss">

.turn-over-celebration-popup {
}

</style>
