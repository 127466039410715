import SetMonthlyGoalNextStep from './steps/SetMonthlyGoalNextStep';
import SetCategoryGoalNextStep from './steps/SetCategoryGoalNextStep';
import TrialActionsNextStep from './steps/TrialActionsNextStep';
import ShortTermSavingNextStep from './steps/ShortTermSavingNextStep';
import LongTermSavingNextStep from './steps/LongTermSavingNextStep';
import BalanceMoneyCalculatorNextStep from './steps/BalanceMoneyCalculatorNextStep';
import WebinarNextStep from './steps/WebinarNextStep';
import NegativeCashflowBlogPostNextStep from './steps/NegativeCashflowBlogPostNextStep';
import CategoriesStoryNextStep from './steps/CategoriesStoryNextStep';
import EmergencyFundBlogPostNextStep from './steps/EmergencyFundBlogPostNextStep';
import SavingsVideoNextStep from './steps/SavingsVideoNextStep';
import IntroToCashflowVideoNextStep from './steps/IntroToCashflowVideoNextStep';
import PlanAheadNextStep from './steps/PlanAheadNextStep';

export const ProgressStatus = {
  TRIAL: 'TRIAL',
  SAVE_MORE: 'SAVE_MORE',
  START_SAVING: 'START_SAVING',
  NEGATIVE_CASHFLOWS_POSITIVE_OSH: 'NEGATIVE_CASHFLOWS_POSITIVE_OSH',
  NEGATIVE_CASHFLOWS_NEGATIVE_OSH: 'NEGATIVE_CASHFLOWS_NEGATIVE_OSH',
  POSITIVE_CASHFLOWS_NEGATIVE_OSH: 'POSITIVE_CASHFLOWS_NEGATIVE_OSH',
  POSITIVE_CASHFLOWS_POSITIVE_OSH: 'POSITIVE_CASHFLOWS_POSITIVE_OSH',
};

export const ActionKeys = {
  SET_MONTHLY_GOAL: 'setMonthlyGoal',
  SET_CATEGORY_GOAL: 'setCategoryGoal',
  TRIAL_ACTIONS: 'trialActions',
  SHORT_TERM_SAVING: 'shortTermSaving',
  LONG_TERM_SAVING: 'longTermSaving',
  BALANCE_MONEY_CALCULATOR: 'balanceMoneyCalculator',
  WEBINAR: 'webinar',
  NEGATIVE_CASHFLOW_BLOG_POST: 'negativeCashflowBlogPost',
  CATEGORIES_STORY: 'categoriesStory',
  EMERGENCY_FUND_BLOG_POST: 'emergencyFundBlogPost',
  SAVINGS_VIDEO: 'savingsVideo',
  INTRO_TO_CASHFLOW_VIDEO: 'introToCashflowVideo',
  PLAN_AHEAD: 'planAhead',
};

export const ActionTypes = {
  CASHFLOW_ACTION: 'פעולות בתזרים',
  SAVINGS: 'חסכונות',
  KNOWLEDGE: 'הרחבת ידע',
};

export const ActionTypesOrder = [ActionTypes.SAVINGS, ActionTypes.CASHFLOW_ACTION, ActionTypes.KNOWLEDGE];

export const actionKeyToComponent = {
  [ActionKeys.SET_MONTHLY_GOAL]: {
    actionType: ActionTypes.CASHFLOW_ACTION,
    cardComponent: SetMonthlyGoalNextStep,
  },
  [ActionKeys.SET_CATEGORY_GOAL]: {
    actionType: ActionTypes.CASHFLOW_ACTION,
    cardComponent: SetCategoryGoalNextStep,
  },
  [ActionKeys.TRIAL_ACTIONS]: {
    actionType: ActionTypes.CASHFLOW_ACTION,
    cardComponent: TrialActionsNextStep,
  },
  [ActionKeys.PLAN_AHEAD]: {
    actionType: ActionTypes.CASHFLOW_ACTION,
    cardComponent: PlanAheadNextStep,
  },
  [ActionKeys.SHORT_TERM_SAVING]: {
    actionType: ActionTypes.SAVINGS,
    cardComponent: ShortTermSavingNextStep,
  },
  [ActionKeys.LONG_TERM_SAVING]: {
    actionType: ActionTypes.SAVINGS,
    cardComponent: LongTermSavingNextStep,
  },
  [ActionKeys.BALANCE_MONEY_CALCULATOR]: {
    actionType: ActionTypes.SAVINGS,
    cardComponent: BalanceMoneyCalculatorNextStep,
  },
  [ActionKeys.WEBINAR]: {
    actionType: ActionTypes.KNOWLEDGE,
    cardComponent: WebinarNextStep,
  },
  [ActionKeys.NEGATIVE_CASHFLOW_BLOG_POST]: {
    actionType: ActionTypes.KNOWLEDGE,
    cardComponent: NegativeCashflowBlogPostNextStep,
  },
  [ActionKeys.CATEGORIES_STORY]: {
    actionType: ActionTypes.KNOWLEDGE,
    cardComponent: CategoriesStoryNextStep,
  },
  [ActionKeys.EMERGENCY_FUND_BLOG_POST]: {
    actionType: ActionTypes.KNOWLEDGE,
    cardComponent: EmergencyFundBlogPostNextStep,
  },
  [ActionKeys.INTRO_TO_CASHFLOW_VIDEO]: {
    actionType: ActionTypes.KNOWLEDGE,
    cardComponent: IntroToCashflowVideoNextStep,
  },
  [ActionKeys.SAVINGS_VIDEO]: {
    actionType: ActionTypes.KNOWLEDGE,
    cardComponent: SavingsVideoNextStep,
  },
};

export const progressStatusToActionKeys = {
  [ProgressStatus.TRIAL]: [
    ActionKeys.TRIAL_ACTIONS,
    // ActionKeys.INTRO_TO_CASHFLOW_VIDEO, // TODO - add back when video is ready
    ActionKeys.SET_MONTHLY_GOAL,
    ActionKeys.SET_CATEGORY_GOAL,
  ],
  [ProgressStatus.SAVE_MORE]: [
    ActionKeys.SHORT_TERM_SAVING,
    ActionKeys.LONG_TERM_SAVING,
    ActionKeys.BALANCE_MONEY_CALCULATOR,
    ActionKeys.EMERGENCY_FUND_BLOG_POST,
    ActionKeys.PLAN_AHEAD,
  ],
  [ProgressStatus.START_SAVING]: [
    ActionKeys.SHORT_TERM_SAVING,
    ActionKeys.LONG_TERM_SAVING,
    ActionKeys.BALANCE_MONEY_CALCULATOR,
    // ActionKeys.SAVINGS_VIDEO, // TODO - add back when video is ready
    ActionKeys.PLAN_AHEAD,
  ],
  [ProgressStatus.NEGATIVE_CASHFLOWS_POSITIVE_OSH]: [
    ActionKeys.SET_MONTHLY_GOAL,
    ActionKeys.SET_CATEGORY_GOAL,
    ActionKeys.NEGATIVE_CASHFLOW_BLOG_POST,
    ActionKeys.CATEGORIES_STORY,
  ],
  [ProgressStatus.NEGATIVE_CASHFLOWS_NEGATIVE_OSH]: [
    ActionKeys.SET_MONTHLY_GOAL,
    ActionKeys.SET_CATEGORY_GOAL,
    ActionKeys.NEGATIVE_CASHFLOW_BLOG_POST,
    ActionKeys.CATEGORIES_STORY,
  ],
  [ProgressStatus.POSITIVE_CASHFLOWS_POSITIVE_OSH]: [
    ActionKeys.SET_MONTHLY_GOAL,
    ActionKeys.BALANCE_MONEY_CALCULATOR,
    ActionKeys.EMERGENCY_FUND_BLOG_POST,
    ActionKeys.CATEGORIES_STORY,
    ActionKeys.PLAN_AHEAD,
  ],
  [ProgressStatus.POSITIVE_CASHFLOWS_NEGATIVE_OSH]: [
    ActionKeys.SET_MONTHLY_GOAL,
    ActionKeys.SET_CATEGORY_GOAL,
    ActionKeys.CATEGORIES_STORY,
  ],
};
