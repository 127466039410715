<template>
  <logo-and-back-page :text-color="headerTextColor" :background-color-variant="headerBgColor" :on-back="onBack">
    <image-header-page :image-header-props="imageHeaderProps">
      <template v-slot:overlayContent>
        <component v-for="(card, index) in cards" :key="index" :is="card.component" v-bind="card.props"
                   class="card-margins"/>
      </template>
      <template>
        <div class="content">
          <div class="tool-tip">
            <referrals-competetion-tool-tip/>
          </div>

          <div class="steps-section">
            <div class="ri-bold-title">איך זה עובד בדיוק?</div>
            <div class="steps">
              <step description="שלחו הטבה לחברה או חבר, החודש הראשון עלינו ובנוסף 50% הנחה על החודשיים שאחרי בעזרת "
                    :link="{description:'הלינק', copyToClipboard:this.copyToClipboard}" number="1"></step>
              <step
                description="אם הם מחברים את חשבון הבנק לתזרים ומתחילים להתנסות במוצר, אתם מקבלים 50₪"
                number="2"
              />
              <step description="אם הם נשארים ועושים מנוי אתם מקבלים עוד ₪100" number="3"/>
              <competition-bullet v-if="isDuringCompetition"/>
            </div>
            <div class="regulations-link ri-bold-body">
              <a href="https://www.riseup.co.il/ambassadorsregulations" target="_blank">לתקנון
                המלא</a>
              <img class='image' :src="require('@/assets/regulations-arrow.svg')">
            </div>
          </div>
        </div>
      </template>
    </image-header-page>
  </logo-and-back-page>
</template>

<script>
import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';
import { mapActions, mapGetters, mapState } from 'vuex';
import AmbassadorIdPopUp from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/AmbassadorIdPopUp';
import TextCopiedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/TextCopiedToaster.vue';
import BaseUI from '@riseupil/base-ui';
import PaymentDetailsSavedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/PaymentDetailsSavedToaster.vue';
import effectsUtils from '@/store/utilities/effects';
import GetPaymentDetailsPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/GetPaymentDetailsPopup.vue';
import PromotionsApi from '@/api/PromotionsApi';
import ReferralsCompetetionToolTip
  from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/ReferralsCompetetionToolTip';
import LogoAndBackPage from '@/base-components/app-header/LogoAndBackPage';
import CompetitionBullet
  from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/CompetitionBullet.vue';
import Step from './Step';

export default {
  name: 'AmbassadorsPage',
  components: {
    CompetitionBullet,
    Loader: BaseUI.TinyLoader,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
    ReferralsCompetetionToolTip,
    Step,
    LogoAndBackPage,
  },
  props: {
    showIdPopup: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPaymentPopup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      hasHeverBenefit: false,
      headerTextColor: BaseUI.Colors.riseupBeige,
    };
  },
  computed: {
    ...mapState('session', ['activeMember']),
    ...mapState('referrals', ['loadReferrals', 'shouldAskForPaymentDetails']),
    ...mapState('ambassadors', ['link', 'loading', 'hasCreationError']),
    ...mapGetters('referrals', ['getCardsToDisplay', 'activationEvents', 'firstPaymentEvents', 'hasPaymentDetails', 'isDuringCompetition']),
    ...mapGetters('ambassadors', ['icrc', 'heverLink']),
    cards() {
      return this.getCardsToDisplay(this.hasHeverBenefit);
    },
    headerBgColor() {
      return this.isDuringCompetition
        ? BaseUI.LayoutConsts.COLOR_VARIANT.BLUE
        : BaseUI.LayoutConsts.COLOR_VARIANT.DARK_GREEN;
    },
    imageHeaderProps() {
      if (this.isDuringCompetition) {
        return {
          title: '',
          imgSrc: 'images/ambassadors/ambassadors-competition-header.png',
          imgHeight: 330,
          bottomSeparator: false,
          textColor: BaseUI.Colors.riseupLightGreen,
          backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.TOP,
          backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
          class: 'contain-image',
        };
      }
      return {
        title: 'תוכנית\nהשגרירים',
        imgSrc: 'images/ambassadors/ambassadors-page-header.png',
        imgHeight: 240,
        bottomSeparator: false,
        textColor: BaseUI.Colors.riseupLightGreen,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.DARK_GREEN,
        class: 'contain-image',
      };
    },
  },
  async created() {
    Segment.trackUserGot('JoinAmbassadorPage_shown');
    await this.init();

    if (this.showIdPopup && !this.activeMember.idNumberPiiId) {
      // Open id modal when member doesn't have an id in db
      this.openModal({
        component: AmbassadorIdPopUp,
      });
    } else if (this.showPaymentPopup) {
      this.openPaymentPopup();
    }
  },
  async mounted() {
    this.hasHeverBenefit = await this.isBenefitInSource(PromotionsApi.SPECIAL_PROMOTION_1);
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('referrals', ['init']),
    ...mapActions('benefits', ['isBenefitInSource']),
    onBack() {
      const { fromApp } = this.$route.query;
      if (fromApp) {
        window.location.href = 'https://static.letsriseup.com';
      }
      this.$router.push({ path: '/sr' });
    },
    openPaymentPopup(isEditMode) {
      this.openModal({
        component: GetPaymentDetailsPopup,
        popupAlignment: 'full-screen',
        props: {
          afterSave: this.afterSave,
          isEditMode,
        },
      });
    },
    afterSave() {
      EventBus.$emit('open-toaster', {
        component: PaymentDetailsSavedToaster,
        props: {},
      });
      effectsUtils.scrollToElement('payment-details');
    },
    async copyToClipboard() {
      try {
        await this.$copyText(this.link);
        Segment.trackUserInteraction('JoinAmbassadorPage_Steps_linkCopied');
        EventBus.$emit('open-toaster', {
          component: TextCopiedToaster,
          props: {},
        });
      } catch (e) {
        this.$emit('copyFailed', e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";

.header-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;

  .header-content {
    width: 50%;
    text-align: right;
    color: $riseup_light_green;
  }
}

.content {
  margin: 0 $ri-spacing-ml $ri-spacing-xl $ri-spacing-ml;
}

.card-margins {
  margin-bottom: $ri-spacing-ml;
  background-color: $riseup_white;
  color: $riseup_black;

  &:first-child {
    margin-bottom: $ri-spacing-ml;
  }

  &.ambassadors-link-card {
    margin-bottom: $ri-spacing-sm;
  }
}

.status {
  margin-top: $ri-spacing-ml;

  .status-card {
    padding-top: 26px;

    .status-header {
      font-size: 20px;
      color: $riseup_black;

      .icon-and-text {
        display: flex;
        align-items: center;
        padding: 0;
      }
    }

    .loading-referrals {
      display: flex;
      justify-content: center;
    }
  }
}

.tool-tip {
  margin-top: $ri-spacing-ml;
}

.steps-section {
  margin-top: $ri-spacing-ml;

  .steps {
    display: flex;
    flex-direction: column;
    gap: $ri-spacing-ml;
    margin-top: $ri-spacing-ml;
  }

  .regulations-link {
    margin-top: $ri-spacing-ml;

    a {
      color: $riseup-blue;
      text-decoration: none;
    }

  }
}

::v-deep .contain-image {
  .image {
    background-size: contain;
  }
}
</style>
