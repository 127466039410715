<template>
  <div class="accuracy-survey-flow">
    <riseup-header/>
    <nps v-if="!NPSdone" @next-clicked="nextClicked" :button-text="'שלח'"/>
    <alert-page v-else
                :img="require('@/assets/generic-success.png')"
                :title="'תודה!'"
                :showButton="false">
      <template v-slot:content>התשובות שלך עוזרות לנו להמשיך ולבנות את השירות הטוב ביותר בשבילך</template>
    </alert-page>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Segment from '@/Segment';
import RiseupHeader from '../../../../../base-components/app-header/RiseupHeader.vue';
import AlertPage from '../../../../../base-components/AlertPage.vue';
import nps from './NPS.vue';

export default {
  name: 'AccuracySurveyFlow',
  components: {
    RiseupHeader,
    AlertPage,
    nps,
  },
  data() {
    return {
      NPSdone: false,
    };
  },
  created() {
    Segment.trackUserInteraction('NPSEntered', {});
  },
  computed: {
    ...mapState('session', ['customerId']),
  },
  methods: {
    nextClicked({ answer, textAnswer }) {
      Segment.trackUserInteraction('NPSAnswerClicked', { answer, textAnswer });
      Segment.identifyCustomer(this.customerId, { NPS: answer });
      this.NPSdone = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/mixins';

.accuracy-survey-flow {
  @include white-page-frame;
  height: 100%;
}
</style>
