<template>
  <dashboard-page :image-header-props="imageHeaderProps">
    <template v-slot:dashboard>
      <div class="margin-ml">
        <property class="margin-bottom-m" :name="'סוג מינוי'" :value="'פעיל'"/>
        <property class="margin-bottom-m" :name="'מצב המינוי'" :value="'חינם'"/>
      </div>
    </template>
  </dashboard-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import AccountSettingsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/AccountSettingsMixin.vue';
import Property from './Property.vue';

export default {
  name: 'FreeSubscriptionSettings',
  components: {
    Property,
    DashboardPage: BaseUI.Pages.DashboardPage,
  },
  mixins: [AccountSettingsMixin],
};
</script>

<style scoped lang="scss">
  @import "./settings";

  @include properties;

</style>
