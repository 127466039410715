<template>
  <div>
    <div v-for="(transaction, i) in transactions" :key="i" class="transaction-rows">
      <div class="transaction-row">
        <div class="date">
          {{ formatDate(transaction.date) }}
        </div>
        <div class="transaction-values">
                  <span class="amount">
                    <span class="currency-sign">₪</span><decimal-number :value="transaction.amount" decimal-size="large"/>
                  </span>
          <span class="business-name">{{transaction.businessName}}</span>
        </div>
      </div>
      <separator/>
    </div>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import DecimalNumber from '../../../../../../base-components/DecimalNumber.vue';
import dateUtils from '../../../../../../utils/dates';

export default {
  name: 'Explanation',
  components: {
    Separator: BaseUI.Separator,
    DecimalNumber,
  },
  props: {
    transactions: { type: Array, required: true },
  },
  methods: {
    formatDate(dateString) {
      return dateUtils.shortDateFormat(dateString);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.transaction-rows {
  margin: 0 30px 20px;
  background-color: $riseup_white;

  .transaction-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .date {
      margin: 0 0 0 15px;
      font-size: 16px;
    }

    .transaction-values {
      display: flex;
      flex-direction: column;
      text-align: right;

      .amount {
        font-size: 18px;
        font-weight: bold;

        .currency-sign {
          font-size: 14px;
        }
      }

      .business-name {
        font-size: 18px;
        margin-top: 6px;
      }

    }
  }
}
</style>
