var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('subscription-type-button',{attrs:{"selection-disabled":_vm.disabled,"selected":_vm.selected,"subscription-type":_vm.SUBSCRIPTION_TYPES.MONTHLY,"main-text":'מינוי חודשי',"sub-text":'חיוב כל חודש'},on:{"subscription-type-selected":function () {
	var ref;

	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];
	return (ref = this$1).$emit.apply(ref, [ 'subscription-type-selected' ].concat( args ));
}},scopedSlots:_vm._u([{key:"price",fn:function(){return [_c('div',[_c('span',{staticClass:"ri-font-20 margin-right-xxs"},[_vm._v("₪")]),_c('span',{staticClass:"ri-font-24"},[_vm._v(_vm._s(_vm.pricing.monthly))]),_vm._v(" "),(_vm.abTestAnnualSubscriptionCopy)?_c('span',{staticClass:"ri-font-14"},[_vm._v(" בחודש")]):_vm._e()])]},proxy:true},{key:"sub-price",fn:function(){return [_c('div',{staticClass:"ri-font-14"},[_vm._v("\n      ("+_vm._s(_vm.totalMonthlyPricingPerYear)+" ₪ לשנה)\n    ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }