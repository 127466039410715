<template>
  <div>
    <div class="circle">
      <colored-svg class="riseup-shape" :color="shapeColor" svg-src="/images/shapes/eight-leaves-flower.svg"/>
    </div>
    <div class="explanation-container padding-l flex-1 flex-column">
        <div class="ri-bold-title" v-if="title">
          {{ title }}
        </div>
        <div class="ri-large-body margin-top-s">
          <slot />
        </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ExplanationBox',
  components: {
    ColoredSvg: BaseUI.ColoredSvg,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    shapeColor: {
      type: String,
      default: BaseUI.Colors.riseupDarkGreen,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';

.explanation-container {
  background-color: $riseup_light_blue;
  border-radius: $ri-border-radius-large;
}
.circle {
  background-color: $riseup_light_blue;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-right: 30px;
  margin-bottom: -18px;

  .riseup-shape {
    width: 20px;
    height: 20px;
  }
}

</style>
