<template>
  <popup class="ambassadors-touch-point">
    <template v-slot:header>
      <img class="ambassadors-header" :src="require('@/assets/ambassadors/ambassadors-popup-header.svg')"/>
    </template>
    <template v-slot:content>
      <div class="popup-text">
        <div class="touch-point-text">{{ touchPointText }}</div>
        <div class="description">יש לך חברים וחברות שיוכלו לעשות שינוי כלכלי דרך רייזאפ?
          זו הזדמנות מעולה לשלוח הטבה של <span class="bold">חודש ראשון בחינם ובנוסף 50% הנחה על החודשיים שאחרי, ועל הדרך להרוויח כסף.</span>
        </div>
      </div>
      <riseup-button class="margin-top-sm" :title="'ספרו לי עוד'" :action="goToAmbassadorPage"/>
      <riseup-button class="margin-top-sm" :title="'לא הפעם'" :action="notNowAction" :variant="'secondary'"/>
    </template>
  </popup>
</template>

<script>
import _ from 'lodash';
import BaseUi from '@riseupil/base-ui';
import router from '@/router';
import Segment from '@/Segment';
import { mapActions } from 'vuex';

const CASHFLOW_PATH = 'home';

export default {
  name: 'AmbassadorsTouchPoint',
  props: {
    touchPointText: {
      type: String,
      required: true,
    },
    query: {
      type: Object,
      required: false,
    },
    onCloseCallback: {
      type: Function,
      required: false,
    },
  },
  components: {
    RiseupButton: BaseUi.RiseupButton,
    Popup: BaseUi.Popup,
  },
  created() {
    Segment.trackUserGot('AmbassadorsPromotionPopupShown', { touchPointText: this.touchPointText });
    this.updateLastShown();
  },
  methods: {
    ...mapActions('ambassadorsTouchPoints', ['updateLastShown']),
    goToAmbassadorPage() {
      Segment.trackUserInteraction('AmbassadorsPromotionPopupClicked', { touchPointText: this.touchPointText });
      router.push({ path: '/ambassadors' });
      this.closePopup();
    },
    notNowAction() {
      Segment.trackUserInteraction('AmbassadorsPromotionPopupNotNowClicked', { touchPointText: this.touchPointText });
      this.goToCashflow();
      this.closePopup();
    },
    closePopup() {
      if (this.onCloseCallback) {
        this.onCloseCallback();
      }
      this.$emit('close');
    },
    goToCashflow() {
      if (_.includes(router.currentRoute.path, CASHFLOW_PATH)) {
        return;
      }
      if (this.query) {
        router.push({ path: '/sr', query: this.query });
      } else {
        router.push({ path: '/sr' });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ambassadors-touch-point {
  font-family: SimplerPro;
  .ambassadors-header {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
  .popup-text {
    margin-bottom: 8px;
    .touch-point-text {
      margin-bottom: 8px;
      line-height: 140%;
      font-size: 24px;
      font-weight: bold;
    }
    .description {
      line-height: 140%;
      font-size: 18px;
      .bold {
        font-weight: bold;
      }
    }
  }
}
</style>
