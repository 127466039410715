<template>
  <div :style="styles" :class="classes">
    <div class="column-title ri-body">
      {{text}}
    </div>
  </div>
</template>
<script>

export default {
  name: 'TableColumn',
  props: {
    color: {
      type: String,
    },
    height: {
      type: Number,
    },
    text: {
      type: String,
    },
  },
  computed: {
    classes() {
      return `column ${this.color}`;
    },
    styles() {
      return {
        height: `${this.height}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '@/scss/mixins';
.column {
  margin-top: 6px;
  &.red {
    background-color: $riseup_red;
  }
  &.blue {
    background-color: $riseup_blue;
  }
  &.green {
    background-color: $riseup_green;
  }
  &.yellow {
    background-color: $riseup_yellow;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $ri-border-radius-large;
  width: 130px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

  .column-title {
    width: 70%;
    color: white;
    text-align: center;
  }
}
</style>
