<template>
  <upsell-page title="פיקדון ברגע"
               headline="להרוויח וגם לשמור על הכסף זמין"
               cta-text="לפתיחת פיקדון בכמה דקות"
               :img-src="require('@/assets/altshuler/into-page-logo.svg')"
               :pageBackgroundColor="backgroundColor"
               @click="nextClicked">
    <div>
      מעכשיו זה קל לשים כסף בצד בשביל השקט הכלכלי שלך, בלי להתלבט ובלי כל הטופסולוגיה.
      אפשר למשוך או להפקיד מתי שרוצים ולקבל ריבית משתלמת והכל ישירות מהתזרים!
    </div>
  </upsell-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'AltshulerUpsell',
  components: {
    UpsellPage: BaseUI.Pages.UpsellPage,
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupLightBlue,
    };
  },
  created() {
    Segment.trackUserGot('Altshuler_Intro_Shown');
  },
  methods: {
    nextClicked() {
      Segment.trackUserInteraction('Altshuler_Intro_NextClicked');
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
