<template>
  <div class="turn-over" :class="{ shared: sharedAsset }">
    <img v-if="sharedAsset" class="full-width" src="/images/celebration/turnover.png">
    <div v-else class="animation-container">
      <img class="background" src="/images/celebration/turnover-animation-background.svg" />
      <lottie-player class="animation" src="/animations/celebration/turnover.json" autoplay loop />
    </div>

    <div class="ri-large-body margin-top-ml">
      <div class="ri-large-headline ri-alte margin-bottom-s">
        <span>{{ householdName }},</span>
        <span>וואו!</span>
      </div>

      <span>מתזרים שלילי</span>
      <span>ב{{ lastMonth }}</span>
      <span>לתזרים חיובי של</span>
      <span class="amount">{{ formatAmountWithFractionAndCurrency(amount) }}</span>
      <span>ב{{ thisMonth }}.</span>
      <span>כל הכבוד!</span>
    </div>
  </div>
</template>

<script>

import cashflowHistory from '@/store/utilities/cashflow-history';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';

export default {
  name: 'TurnOverCore',
  mixins: [TransactionDisplayUtils],
  props: {
    amount: {
      type: Number,
      required: true,
    },
    cashflowDate: {
      type: String,
      required: true,
    },
    householdName: {
      type: String,
      required: true,
    },
    sharedAsset: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lastMonth() {
      return cashflowHistory.getFullMonthName(cashflowHistory.getPrevBudgetDate(this.cashflowDate));
    },
    thisMonth() {
      return cashflowHistory.getFullMonthName(this.cashflowDate);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '~@riseupil/base-ui/src/scss/spacings';

.turn-over {
  text-align: center;

  .animation-container {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .background {
      width: 100%;
      position: absolute;
      z-index: 1;
    }

    .animation {
      margin-top: $ri-spacing-s;
      width: 90%;
      position: absolute;
      z-index: 2;
    }
  }

  &.shared {
    border-radius: $ri-border-radius-medium;
  }
}

</style>
