<template>
  <card id="graph" class="new-past-months-graph-card" :class="{'out-of-date': outOfDate}">
    <template v-slot:header v-if="!outOfDate">
      <div class="header">
        <tiny-share-button class="card-share-button" :share-component="shareComponent"
                           :share-component-props="shareComponentProps"
                           share-name="ShareEomGraph" filename="month-graph"
                           :share-impression="shareImpressionOptions.ON_CLICK"/>
      </div>
    </template>
    <div class="past-months-graph-content">
      <div class="ri-large-headline">{{ this.formattedTitle }}</div>
      <past-months-bar-graph :bar-values="barValues" :out-of-date="outOfDate"></past-months-bar-graph>
    </div>
    <template v-slot:footer>
      <div class="footer padding-vertical-m ri-large-body flex-row align-center space-between pointer"
           @click="pastCashflowsSummary" @keyup.enter="pastCashflowsSummary" tabindex="0">
        <div class="flex-row align-center">
          <icon class="margin-left-xs" icon-name="bar_chart" fill weight="bold" size="18px" :optical-size="24"/>
          לפירוט המלא
        </div>
        <icon icon-name="chevron_left" size="24px" :optical-size="24" :color="arrowColor"/>
      </div>
    </template>
  </card>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import TinyShareButton from '@/base-components/share/TinyShareButton';
import shareUtils from '@/utils/share';
import PastMonthsGraphAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/past-editable-month/components/PastMonthsGraphAsset';
import PastMonthsBarGraph
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/past-editable-month/components/PastMonthsBarGraph';
import Segment from '@/Segment';

const MONTHS_LENGTH = 5;

export default {
  name: 'PastMonthsGraph',
  components: {
    Card: BaseUI.Card,
    Icon: BaseUI.Icon,
    PastMonthsBarGraph,
    TinyShareButton,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('cashflowView', ['monthFullName', 'cashflowGraphHistory', 'balance']),
    ...mapState('cashflowView', ['presentedBudgetDate']),
    shareImpressionOptions() {
      return shareUtils.SHARE_IMPRESSION_OPTIONS;
    },
    formattedTitle() {
      return `${this.monthFullName} ביחס לחודשים אחרים`;
    },
    barValues() {
      const filledCashflowHistory = _padWithEmptyMonths(this.cashflowGraphHistory);
      return _.map(filledCashflowHistory, ({ total, cashflowDate }) => {
        const current = cashflowDate === this.presentedBudgetDate;
        const positive = total >= 0;
        const positiveColor = current ? BaseUI.Colors.riseupGreen : BaseUI.Colors.riseupLightGreen;
        const negativeColor = current ? BaseUI.Colors.riseupDangerRed : BaseUI.Colors.riseupDangerLightRed;
        return {
          cssClasses: {
            current,
          },
          color: positive ? positiveColor : negativeColor,
          name: this.formatMonthName(cashflowDate),
          value: total,
        };
      });
    },
    graphHeight() {
      return 102;
    },
    shareComponent() {
      return PastMonthsGraphAsset;
    },
    shareComponentProps() {
      return {
        barValues: this.barValues,
      };
    },
    arrowColor() {
      return this.outOfDate ? BaseUI.Colors.riseupGray2 : BaseUI.Colors.riseupBlack;
    },
  },
  methods: {
    formatMonthName(budgetDate) {
      return moment(budgetDate).locale('he').format('MMM');
    },
    pastCashflowsSummary() {
      if (!this.outOfDate) {
        Segment.trackUserInteraction('PastMonth_GoToCashflowsSummaryClicked');
        this.$router.push({ name: 'MyBalanceProgress' });
      }
    },
  },
};

function _padWithEmptyMonths(cashflowHistory) {
  const oldestCashflow = _.last(_.map(cashflowHistory, ({ cashflowDate }) => cashflowDate));
  const emptyPlaceholderMonths = _.times(MONTHS_LENGTH, i => {
    return { cashflowDate: moment(oldestCashflow).subtract(i + 1, 'month').format('YYYY-MM'), total: 0 };
  });
  return [...cashflowHistory, ...emptyPlaceholderMonths].slice(0, MONTHS_LENGTH);
}
</script>

<style lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";

.new-past-months-graph-card {
  padding-top: 10px;

  &.out-of-date {
    .title {
      color: $riseup_gray_2;
    }
    .footer {
      color: $riseup_gray_2;
    }
  }

  .header {
    text-align: left;
    margin: 0 -14px;
  }
  .past-cashflows-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: $ri-spacing-m 0;
    cursor: pointer;
  }
}
</style>
