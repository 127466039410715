<template>
  <div class="my-progress-share-frame">
    <div class="frame-title ri-large-headline">{{ title }}</div>
    <div class="card-container">
      <div class="flex-row align-center">
        <div class="flex-column margin-right-sm">
          <div class="ri-small-bold-body margin-bottom-xxs"> {{ formattedDatesRange }}</div>
          <div v-if="formattedDataSections" class="ri-small-body">{{ formattedDataSections }}</div>
          <div v-if="sum" class="ri-medium-headline"><span class="ri-body">₪</span>{{ sum | formatAmountWithFraction(0) }}</div>
        </div>
        <img v-if="withBadge" class="wow-badge" src="/images/my-progress/savings-graph.svg"/>
      </div>
      <bar-graph-v2 v-bind="barGraphProps" share-variant/>
      <div class="direction-arrow">
        <img class="full-width" src="/images/past-editable-month/arrow-left.svg">
      </div>
      <div v-if="activationDateVisible" class="activation-indicator ri-small-body">
        <img src="/images/shapes/six-pointed-star.svg"/>
        הצטרפת לרייזאפ
      </div>
    </div>
    <img class="logo" :src="require(`@/assets/logo-horizontal-green.svg`)"/>
  </div>
</template>

<script>
import BarGraphV2 from '@/base-components/bar-graph-v2/BarGraphV2';

export default {
  name: 'MyProgressShareAsset',
  components: {
    BarGraphV2,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    barGraphProps: {
      type: Object,
      required: true,
    },
    formattedDatesRange: {
      type: String,
      required: true,
    },
    sum: {
      type: Number,
      required: false,
    },
    formattedDataSections: {
      type: String,
      required: true,
    },
    withBadge: {
      type: Boolean,
      default: false,
    },
    activationDateVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUrl: null,
      headerText: null,
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '~@riseupil/base-ui/src/scss/spacings';

.my-progress-share-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $riseup_light_green;
  padding: $ri-spacing-sm;
  .frame-title {
    color: $riseup_dark_green;
    font-weight: 900;
    margin-top: $ri-spacing-xs;
    margin-right: $ri-spacing-xs;
    margin-bottom: $ri-spacing-sm;
    width: 60%;
  }
  .card-container {
    background: $riseup_white;
    border-radius: $card-border-radius;
    padding: $ri-spacing-sm 0;
    --graph-height: 200px;
  }
  .direction-arrow {
    padding: $ri-spacing-ml $ri-spacing-s $ri-spacing-xs;
  }
  .logo {
    height: 20px;
    width: 67px;
    margin-top: $ri-spacing-m;
    margin-bottom: $ri-spacing-xs;
    align-self: center;
  }
  .wow-badge {
    height: 42px;
    width: 42px;
    margin-right: $ri-spacing-xxs;
    margin-bottom: $ri-spacing-xs;
  }
  .activation-indicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    padding: 0 $ri-spacing-sm;
  }
}
</style>
