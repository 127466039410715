<template>
  <div/>
</template>

<script>

// Exists only for consistency with other subscription states
export default {
  name: 'OnboardingSettings',
};
</script>
