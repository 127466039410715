<template>
  <share-frame class="past-months-graph-asset ri-alte"
               headerText="החודשים האחרונים שלי עם רייזאפ"
               image-url="past-editable-month/share-positive-cf.svg">
    <past-months-bar-graph :graph-height="graphHeight" :bar-values="barValues"></past-months-bar-graph>
    <div class="bottom-arrow">
      <img src="/images/past-editable-month/arrow-left.svg">
    </div>
  </share-frame>
</template>

<script>
import PastMonthsBarGraph
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/past-editable-month/components/PastMonthsBarGraph';
import ShareFrame from '@/base-components/share/ShareFrame';

export default {
  name: 'PastMonthsGraphAsset',
  components: {
    PastMonthsBarGraph,
    ShareFrame,
  },
  props: {
    barValues: {
      type: Array,
      required: true,
    },
  },
  computed: {
    graphHeight() {
      return 102;
    },
  },
};

</script>

<style lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.past-months-graph-asset {
  width: 500px;
  .frame {
    padding: 40px;
    .bar-graph {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .bottom-arrow {
    margin-top: 10px;
    img {
      height: 16px;
      width: 320px;
    }
  }

}
</style>
