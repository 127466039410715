<template>
  <logo-and-back-page :background-color-variant="bgColorVariant" :text-color="textColor">
    <altshuler-upsell v-if="upsellPage" @click="next" />
    <altshuler-information v-else-if="conditionsPage" @click="next" />
  </logo-and-back-page>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import LogoAndBackPage from '@/base-components/app-header/LogoAndBackPage';
import AltshulerInformation from './lightup/AltshulerInformation.vue';
import AltshulerUpsell from './lightup/AltshulerUpsell.vue';

export default {
  name: 'AltshulerIntroduction',
  components: {
    LogoAndBackPage,
    AltshulerInformation,
    AltshulerUpsell,
  },
  data() {
    return {
      pageIndex: 0,
      bgColorVariant: BaseUi.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE,
      textColor: BaseUi.Colors.riseupDarkGreen,
    };
  },
  computed: {
    upsellPage() {
      return this.pageIndex === 0;
    },
    conditionsPage() {
      return this.pageIndex === 1;
    },
  },
  methods: {
    next() {
      if (this.pageIndex === 0) {
        ++this.pageIndex;
      } else {
        this.$router.push({ path: '/altshuler-signup' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
