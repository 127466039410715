<template>
  <div :style="styles" class="ve-column">
    <div class="un-used">
      <div class="ve-column-title">
        מה נשאר
        <div>להוציא</div>
      </div>
    </div>
    <div class="used" :style="usedStyles"/>
  </div>
</template>
<script>

export default {
  name: 'VariableExpenseColumn',
  props: {
    totalHeight: {
      type: Number,
    },
    usedHeight: {
      type: Number,
    },
  },
  computed: {
    styles() {
      return {
        height: `${this.totalHeight}px`,
      };
    },
    usedStyles() {
      return {
        height: `${this.usedHeight}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '@/scss/mixins';

.ve-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px dashed $riseup_black;
  border-radius: $ri-border-radius-medium;

  .un-used {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .ve-column-title {
      font-weight: bold;
      font-size: 16px;
      white-space: nowrap;
    }
  }

  .used {
    background-color: $riseup_yellow;
    border-bottom-left-radius: $ri-border-radius-medium;
    border-bottom-right-radius: $ri-border-radius-medium;
  }
}
</style>
