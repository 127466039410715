<template>
  <snack-bar class="cashflow-state-bar" v-if="barState"
             :variant="barVariant">
    <template v-slot:primary>
      <div class="bold">{{ barText }}</div>
    </template>
    <template v-slot:secondary>
      <div v-if="lastUpdatedText" class="bold primary">{{ lastUpdatedText }}</div>
    </template>
  </snack-bar>
</template>

<script>
import moment from 'moment';
import BaseUI from '@riseupil/base-ui';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'CashflowStateBar',
  components: {
    SnackBar: BaseUI.SnackBar,
  },
  props: {
    barState: {
      type: String,
      require: true,
    },
  },
  computed: {
    ...mapState('applicationState', ['cashflowState']),
    ...mapGetters('applicationState', ['barTypes', 'cashflowTypes']),
    ...mapGetters('cashflowView', ['budgetUpdatedAt']),

    formatedUpdateTime() {
      return moment(this.budgetUpdatedAt).format('DD.M.YY, H:mm');
    },
    barText() {
      if (this.barState === this.barTypes.TRIAL_EXPIRED) {
        return 'תקופת הנסיון הסתיימה';
      }
      if (this.barState === this.barTypes.OUT_OF_DATE) {
        return 'התזרים לא מעודכן';
      }
      if (this.barState === this.barTypes.NOT_ACCURATE) {
        return 'חשוב לשים לב, התזרים אינו מדויק';
      }
      if (this.barState === this.barTypes.NOT_AVAILABLE) {
        return 'התזרים לא זמין כעת';
      }
      if (this.barState === this.barTypes.PROCESSING) {
        return 'הנתונים בבדיקה, ייתכן והתזרים לא מדויק.';
      }
      return null;
    },
    lastUpdatedText() {
      return (this.cashflowState === this.barTypes.OUT_OF_DATE && this.budgetUpdatedAt) ? `עידכון אחרון: ${this.formatedUpdateTime}` : null;
    },
    barVariant() {
      if (this.barState === this.barTypes.PROCESSING) {
        return 'warning';
      }
      return 'danger';
    },
  },
};

</script>

<style scoped lang="scss">
  @import '../scss/mixins';

  .cashflow-state-bar {
    @include responsive-padding;

    .bold {
      font-weight: bold;
    }
  }
</style>
