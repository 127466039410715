<template>
  <popup :close-action="closePopup" :title="`התוכניות הגדולות ב${monthFullName}`">
    <template v-slot:content>
      <div class="description ri-body">
        {{ descriptionText }}
      </div>
      <card>
        <div class="card-content-padding space-between ri-large-body">
          <span>{{ totalText }}</span>
          <span :style="{ color: totalTextColor }">{{ formattedBalance }} ₪</span>
        </div>
        <template v-slot:footer>
          <div class="transactions-table">
            <plan-ahead-past-month-transaction v-for="transaction in transactionsInMonth" :key="transaction.name"
                                               v-bind="transaction"/>
            <div v-if="isMonthBalancePositive && remainingBalance > 0" class="ri-bold-label space-between margin-top-sm">
              <span >שמתי עוד בצד</span>
              <span >{{ formattedRemainingBalance }} ₪</span>
            </div>
          </div>
        </template>
      </card>
      <riseup-button
        class="cta"
        :action="editPlans"
        title="לעריכת התוכניות הגדולות"
        variant="primary"
        size="slim"/>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import moneyUtils from '@/utils/money';
import Segment from '@/Segment';
import router from '@/router';
import moment from 'moment';
import cashflowHistory from '@/store/utilities/cashflow-history';
import PlanAheadPastMonthTransaction from './PlanAheadPastMonthTransaction';

export default {
  name: 'PlanAheadPastMonthModal',
  components: {
    Card: BaseUI.Card,
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
    PlanAheadPastMonthTransaction,
  },
  created() {
    Segment.trackUserInteraction('PlanAhead_PastMonthModal_Opened', {
      budgetDate: this.presentedBudgetDate,
      isMonthBalancePositive: this.isMonthBalancePositive,
      plansCount: this.monthlyPlans.length,
    });
  },
  computed: {
    ...mapGetters('cashflowView', ['monthFullName', 'balance']),
    ...mapState('cashflowView', ['presentedBudgetDate']),
    ...mapGetters('planAhead', ['monthlyPlans']),
    isMonthBalancePositive() {
      return this.balance >= 0;
    },
    descriptionText() {
      return this.isMonthBalancePositive ? 'התוכניות הגדולות שלך התמלאו מהתזרים החיובי איתו סיימת את החודש:'
        : 'נראה שהחודש התוכניות לא התמלאו מהתזרים החודשי';
    },
    formattedBalance() {
      return `${moneyUtils.formatAmountWithFraction(this.balance, 0)}${this.isMonthBalancePositive ? '' : '-'}`;
    },
    remainingBalance() {
      return Math.round(this.balance - _.sumBy(this.transactionsInMonth, 'rawAmount'));
    },
    formattedRemainingBalance() {
      return moneyUtils.formatAmountWithFraction(this.remainingBalance, 0);
    },
    totalText() {
      return this.isMonthBalancePositive ? 'סה״כ תזרים חיובי' : 'סיכום תזרים';
    },
    totalTextColor() {
      return this.isMonthBalancePositive ? BaseUI.Colors.riseupGreen : BaseUI.Colors.riseupDangerRed;
    },
    transactionsInMonth() {
      const nextBudgetDate = cashflowHistory.getNextBudgetDate(this.presentedBudgetDate);
      return this.monthlyPlans.map(plan => {
        const totalAmountThisMonth = _.chain(plan.planTransactions)
          .filter(transaction => moment(transaction.created).isSame(nextBudgetDate, 'month'))
          .sumBy('amount')
          .defaultTo(0)
          .value();
        return {
          name: plan.name,
          total: moneyUtils.formatAmountWithFraction(plan.monthlyTarget, 0),
          amount: moneyUtils.formatAmountWithFraction(totalAmountThisMonth, 0),
          rawAmount: totalAmountThisMonth,
        };
      });
    },
  },
  methods: {
    ...mapActions('cashflowView', ['setBudgetDateToCurrentMonth']),
    closePopup() {
      Segment.trackUserInteraction('PlanAhead_PastMonthModal_Closed');
      this.$emit('close');
    },
    async editPlans() {
      this.closePopup();
      Segment.trackUserInteraction('PlanAhead_PastMonthModal_GoToMyPlansClicked');
      router.push({ path: '/plan-ahead' });
    },
  },
};
</script>

<style lang="scss" scoped>

.card-content-padding {
  padding: 24px 0;
}
.card-content-padding {
  padding: 16px 0;
}

.description {
  margin-bottom: 24px;
}

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.transactions-table {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 24px 0;
}

.cta {
  margin-top: 32px;
}
</style>
