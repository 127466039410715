<template>
  <div class="circles-sums-visualizer">
    <div
      v-for="expense in sumsByExpense"
      :key="expense.expense"
      class="categories-sum-circle-class" :class="`${getExpenseSize(expense.sum)} ${getExpenseSize(expense.sum)}-${expenseType}-color`"
      @click="buttonAction(expense.expense, expense.sum)">

      <div class="text-container">
        <div class="amount">
          <span class="currency-sign">₪</span><span class="sum">{{formattedSum(expense.sum)}}</span>
        </div>
        <div class="space"></div>
        <div class="expense">{{expense.expense}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import categorySumCircleVariants from '@/constants/category-sum-circle-variants';
import _ from 'lodash';
import { mapActions } from 'vuex';
import ExpandCategoryModal from './ExpandCategoryModal';
import moneyUtils from '../../../../../../utils/money';
import Segment from '../../../../../../Segment';

export default {
  name: 'CirclesSumsVisualizer',
  components: {
  },
  props: {
    sumsByExpense: { type: Array, required: true },
    transactions: { type: Array, required: true },
    month: { type: String, required: true },
    circleSizeThresholds: { type: Object, required: true },
    expenseType: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(categorySumCircleVariants.circleType).includes(value);
      },
    },
  },
  computed: {
    transactionsByExpense() {
      return _.groupBy(this.transactions, 'expense');
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction(expense, sum) {
      this.openModal(
        {
          component: ExpandCategoryModal,
          popupAlignment: 'full-screen',
          props: {
            expense,
            transactions: this.transactionsByExpense[expense],
            color: `${this.getExpenseSize(sum)}-${this.expenseType}-color`,
            sum,
            month: this.month,
          },
        },
      );
      Segment.trackUserInteraction('MonthReview_Category_Clicked', {
        expense,
        sum,
        expenseType: this.expenseType,
      });
    },
    getExpenseSize(amount) {
      if (amount > this.circleSizeThresholds.large) {
        return categorySumCircleVariants.circleSize.LARGE;
      }
      if (amount >= this.circleSizeThresholds.medium) {
        return categorySumCircleVariants.circleSize.MEDIUM;
      }
      return categorySumCircleVariants.circleSize.SMALL;
    },
    formattedSum(sum) {
      return moneyUtils.formatAmount(sum);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import '../../../../../../scss/month-review-circles-mixins';

@keyframes fade
{
  from {opacity: 0;}
  to { opacity: 1;}
}

@keyframes enter
{
  from {top: 100px;}
  to { top: 0;}
}

.circles-sums-visualizer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  animation: enter 1.5s ease-out, fade 1.5s ease-out;

  .categories-sum-circle-class {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 10px;
    color: $riseup_white;

    .text-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .amount{
        flex: 1 1 0;
      }

      .space{
        flex: 0 0 10px;
      }

      .expense {
        flex: 1 1 0;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
      }
    }

    .currency-sign {
      font-size: 14px;
    }

  }

  .large {
    height: 260px;
    width: 260px;
    font-size: 32px;
  }

  .medium {
    height: 200px;
    width: 200px;
    font-size: 28px;
  }

  .small {
    height: 150px;
    width: 150px;
    font-size: 24px;
  }

  @include circle-color;
}

</style>
