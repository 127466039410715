<template>
  <div class="past-editable-month-cashflow-view">
    <div class="past-cashflow-view-container">
      <closed-end-of-month :out-of-date="outOfDate" class="card-wrapper"/>
      <savings-past-month-card :out-of-date="outOfDate" class="card-wrapper"/>
      <past-months-graph :out-of-date="outOfDate" class="card-wrapper"/>
      <expense-summary-card :out-of-date="outOfDate" class="card-wrapper"/>
      <plan-ahead-past-month-card v-if="hasPlans" :out-of-date="outOfDate" class="card-wrapper"/>
      <cashflow-type-base-variable-expense :out-of-date="outOfDate" previous-month time-frame="monthly"
                                           class="card-wrapper" :key="`${presentedBudgetDate}-variable-expense`"/>
      <cashflow-type-base-economy :out-of-date="outOfDate" :previous-month="true" class="card-wrapper"/>
      <div v-for="subtree in trackingCategories" v-bind:key="subtree.categoryId">
        <cashflow-type-base-tracking-category :out-of-date="outOfDate" class="card-wrapper" previous-month :subtree="subtree"/>
      </div>
      <cashflow-type-base-saving-category :out-of-date="outOfDate" :previous-month="true" class="card-wrapper" />
      <cashflow-type-base-fixed-expense :out-of-date="outOfDate" :previous-month="true" class="card-wrapper"/>
      <cashflow-type-base-income :out-of-date="outOfDate" :previous-month="true" class="card-wrapper"/>
    </div>
    <div class="past-cashflow-view-container">
      <hr class="divider" v-if="this.hasExcluded" />
      <cashflow-type-base-excluded v-if="this.hasExcluded" :out-of-date="outOfDate" previous-month class="card-wrapper"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import dateUtils from '@/utils/dates';
import * as celebrationUtils from '@/utils/celebration-utils';
import EomCelebrationPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/EomCelebrationPopup';
import { SUBSCRIPTION_STATES } from '@/constants/subscription-state';
import Segment from '../../../../../../../Segment';
import ClosedEndOfMonth from './components/ClosedEndOfMonth.vue';
import PastMonthsGraph from './components/PastMonthsGraph.vue';
import cashflowViewConsts from '../../../../../../../constants/cashflow-view';
import CashflowTypeBaseEconomy from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseEconomy';
import CashflowTypeBaseExcluded
  from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseExcluded';
import CashflowTypeBaseFixedExpense
  from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseFixedExpense';
import CashflowTypeBaseIncome from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseIncome';
import CashflowTypeBaseTrackingCategory
  from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseTrackingCategory';
import CashflowTypeBaseSavingCategory from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseSavingCategory';
import CashflowTypeBaseVariableExpense
  from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseVariableExpense';
import PlanAheadPastMonthCard from '../../../plan-ahead/past-month/PlanAheadPastMonthCard';
import ExpenseSummaryCard from './components/ExpenseSummaryCard';
import SavingsPastMonthCard from './components/SavingsPastMonthCard';

export default {
  name: 'PastEditableMonthCashflowView',
  components: {
    ClosedEndOfMonth,
    SavingsPastMonthCard,
    PastMonthsGraph,
    CashflowTypeBaseEconomy,
    CashflowTypeBaseExcluded,
    CashflowTypeBaseFixedExpense,
    CashflowTypeBaseIncome,
    CashflowTypeBaseTrackingCategory,
    CashflowTypeBaseSavingCategory,
    CashflowTypeBaseVariableExpense,
    PlanAheadPastMonthCard,
    ExpenseSummaryCard,
  },
  async created() {
    this.toggleLoading();
    Segment.trackUserGot('PastEditableCashflowViewEntered', {
      month: this.monthAndYear,
    });
    await this.showCelebrationPopup();
    await this.initPlans();
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree', 'presentedBudgetDate']),
    ...mapGetters('cashflowView', ['balance', 'hasExcluded', 'cashflowGraphHistory']),
    ...mapState('share', ['celebrationDismissed']),
    ...mapGetters('cashflowView', ['presentedBudget']),
    ...mapState('subscriptionState', ['subscriptionState']),
    ...mapGetters('planAhead', ['monthlyPlans']),
    monthAndYear() {
      return dateUtils.getMonthAndYear(this.presentedBudgetDate);
    },
    isMonthBalancePositive() {
      return this.balance > 0;
    },
    isMonthBalanceNegative() {
      return this.balance < 0;
    },
    trackingCategories() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.trackingCategory];
    },
    hasPlans() {
      return this.monthlyPlans.length > 0;
    },
  },
  methods: {
    ...mapActions('share', ['fetchCelebrationDismissed']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('planAhead', ['initPlans']),
    async showCelebrationPopup() {
      // If the cashflow graph is not loaded yet, do nothing
      if (!this.cashflowGraphHistory.length || this._isDormant() || !await this._shouldShowCelebration() || this._noCelebrationToShow()) {
        return;
      }

      this.openModal({
        component: EomCelebrationPopup,
      });
    },
    async _shouldShowCelebration() {
      if (this.$route.query.celebrate) {
        return true;
      }
      this.fetchCelebrationDismissed();
      const closed = this.celebrationDismissed && (moment(this.celebrationDismissed) >= moment(this.presentedBudgetDate));
      return !closed;
    },
    _isDormant() {
      return _.includes([SUBSCRIPTION_STATES.DORMANT_AFTER_CANCELED, SUBSCRIPTION_STATES.DORMANT_AFTER_TRIAL], this.subscriptionState);
    },
    _noCelebrationToShow() {
      const totals = _.map(this.cashflowGraphHistory, h => h.total);
      return !celebrationUtils.hasCelebrationToShow(totals);
    },
    toggleLoading() {
      if (!this.presentedBudget) {
        this.startContentLoading(this.$options.name);
      } else {
        this.doneContentLoading(this.$options.name);
      }
    },
  },
  watch: {
    cashflowGraphHistory() {
      this.showCelebrationPopup();
    },
    presentedBudget() {
      this.toggleLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../../../scss/mixins';

.past-editable-month-cashflow-view {
  padding-bottom: 80px;

  @media (min-width: 430px) {
    padding-bottom: 20px;
  }

  .past-cashflow-view-container {
    @include responsive-padding;
    text-align: right;
    width: 100%;
    margin: 0 auto;

    .card-wrapper {
      @include cashflow-card-margin;
    }
  }

  .divider {
    color: $riseup_gray_2;
    opacity: 0.5;
    margin: 25px 0;
  }
}
</style>
