<template>
  <div class="churn-step-1-money-back">
    <div class="ri-large-headline margin-bottom-ml">{{ title }}</div>
    <div class="ri-large-body text" v-if="onRefundEligibilityPeriod">
      <p class="margin-bottom-l">
        סיימת שלושה חודשים ברייזאפ בתוכנית ״לא הרווחת לא שילמת!״, ולכן במחיקת החשבון נחזיר לך את הכסף ששילמת על כל תקופת השימוש.
      </p>
      <p class="margin-bottom-xl">
        <span class="bold">ההחזר יועבר לחשבונך באופן אוטומטי לאחר המחיקה.</span>
        זה יכול לקחת כמה ימים.
      </p>
    </div>

    <div class="ri-large-body text" v-else>
      <p class="margin-bottom-l">
        מינוי הרייזאפ שלך בתוכנית ״לא הרווחת – לא שילמת!״ מציע החזר כספי מלא רק ללקוחות שיבחרו לעזוב אחרי
        <span class="bold"> שלושה חודשים.</span>
      </p>
      <p class="margin-bottom-xl">
        {{`בסיום שלושה חודשים (ב־${formattedStartOfRefundEligibilityDate}) נשלח תזכורת, ותוכלו להחליט תוך`}}
        <span class="bold"> 10 ימים</span>
        {{` אם להמשיך או למחוק את המינוי ולקבל את הכסף בחזרה.`}}
      </p>
    </div>
    <riseup-button v-if="!onRefundEligibilityPeriod" class="margin-left-sm"
                   @click="keepSubscription"
                   title="לשמור את המינוי"/>
    <separator v-if="!onRefundEligibilityPeriod" text="או" class="margin-vertical-sm" />
    <riseup-button @click="approve"
                   title="להפסיק את המינוי"
                   color="red"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import dateUtils from '@/utils/dates';
import Segment from '@/Segment';

export default {
  name: 'ChurnStep1MoneyBack',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    Separator: BaseUI.Separator,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('ChurnStep1_MoneyBack', {
      isOnRefundEligibilityPeriod: this.onRefundEligibilityPeriod,
    });
  },
  computed: {
    ...mapState('subscriptionState', ['moneyBackGuaranteeRecord']),
    title() {
      return this.onRefundEligibilityPeriod
        ? 'החזר כספי במקרה של מחיקת חשבון'
        : 'סגירת החשבון עכשיו לא תזכה אותך בהחזר כספי';
    },
    onRefundEligibilityPeriod() {
      return this.moneyBackGuaranteeRecord.isOnMoneyBackRefundEligibilityPeriod;
    },
    formattedStartOfRefundEligibilityDate() {
      return dateUtils.getShortHebrewMonthAndDay(this.moneyBackGuaranteeRecord.beginningOfRefundEligibilityPeriod);
    },
  },
  methods: {
    approve() {
      Segment.trackUserInteraction('ChurnStep1_MoneyBack_ApproveClicked');
      this.nextStep();
    },
    keepSubscription() {
      Segment.trackUserInteraction('ChurnStep1_MoneyBack_KeepSubscriptionClicked');
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.churn-step-1-money-back {
  margin-top: 40px;
  text-align: start;

  .bold {
    font-weight: bold;
  }
}
</style>
