async function serialFor(array, func) {
  let index = 0;
  for (const item of array) {
    await func(item, index++, array);
  }
}

export default {
  serialFor,
};
