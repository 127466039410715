<template>
  <div class="expense-summary-core">
    <div class="header">
      <div class="small-title ri-body">מבוסס על {{ monthName }}</div>
      <div class="big-title">ההוצאות החודשיות שלי</div>
    </div>
    <slot></slot>
    <div class="middle-content ri-large-body margin-top-ml margin-bottom-l">
      <div class="rows">
        <div class="categories-section" v-if="trackingExpenses.length > 0">
          <div class="row" v-for="category of trackingExpenses" :key="category.name" :class="{ empty: !category.amount }">
            <IconSquare :icon-name="category.icon" class="margin-left-s"/>
            <span class="text">{{ category.name }}</span>
            <span>{{ formatAmountWithFractionAndCurrency(category.amount) }}</span>
          </div>
        </div>
        <div class="categories-section" v-if="fixedExpenses.length > 0">
          <div class="row" v-for="category of fixedExpenses" :key="category.name" :class="{ empty: !category.amount }">
            <IconSquare :icon-name="category.icon" :color-variant="iconSquarePink" class="margin-left-s"/>
            <span class="text">{{ category.name }}</span>
            <span>{{ formatAmountWithFractionAndCurrency(category.amount) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="totals">
      <div class="block">
        <div class="ri-large-headline">סה״כ {{ formatAmountWithFractionAndCurrency(expenseSummary) }}</div>
        <div class="disclaimer ri-body">* לא כולל הוצאות משתנות והפקדות לחסכון</div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import cashflowHistory from '@/store/utilities/cashflow-history';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ExpenseSummaryCore',
  mixins: [TransactionDisplayUtils],
  components: {
    IconSquare: BaseUI.IconSquare,
  },
  data() {
    return {
      iconSquarePink: BaseUI.IconSquareColorVariants.PINK,
    };
  },
  props: {
    allExpenses: {
      type: Array,
      required: true,
    },
    presentedMonth: {
      type: String,
      required: true,
    },
  },
  computed: {
    prevBudgetDate() {
      return cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate());
    },
    fixedCategoryNames() {
      return _.chain(this.allExpenses)
        .flatMap(expenses => expenses.fixed.map(({ name, icon }) => { return { name, icon }; }))
        .uniqBy(({ name }) => name)
        .value();
    },
    trackingCategoryNames() {
      return _.chain(this.allExpenses)
        .flatMap(expenses => expenses.tracking.map(({ name, icon }) => { return { name, icon }; }))
        .uniqBy(({ name }) => name)
        .value();
    },
    fixedExpenses() {
      return _.sortBy(_.filter(this.getFixedExpensesOfMonth(this.presentedMonth), expense => expense.amount), c => {
        const category = _.find(this.firstDisplayedMonthFixedExpenses, ({ name }) => c.name === name) || { amount: 0 };
        return -category.amount;
      });
    },
    trackingExpenses() {
      return _.sortBy(_.filter(this.getTrackingExpensesOfMonth(this.presentedMonth), expense => expense.amount), c => {
        const category = _.find(this.firstDisplayedMonthTrackingExpenses, ({ name }) => c.name === name) || { amount: 0 };
        return -category.amount;
      });
    },
    firstDisplayedMonthFixedExpenses() {
      return this.getFixedExpensesOfMonth(this.prevBudgetDate);
    },
    firstDisplayedMonthTrackingExpenses() {
      return this.getTrackingExpensesOfMonth(this.prevBudgetDate);
    },
    monthName() {
      return cashflowHistory.getFullMonthName(this.presentedMonth);
    },
    yearName() {
      return moment(this.presentedMonth).locale('he').format('YYYY');
    },
    prevMonthEnabled() {
      return _.find(this.allExpenses, e => e.budgetDate === cashflowHistory.getPrevBudgetDate(this.presentedMonth));
    },
    expenseSummary() {
      return _.sumBy(this.fixedExpenses, c => c.amount) + _.sumBy(this.trackingExpenses, c => c.amount);
    },
  },
  methods: {
    emptyCategory(name, icon) {
      return {
        name,
        icon,
        amount: 0,
      };
    },
    getTrackingExpensesOfMonth(budgetDate) {
      const expenses = this.getExpensesOfMonth(budgetDate);
      return _.map(this.trackingCategoryNames, ({ name, icon }) => {
        const category = _.find(expenses.tracking, c => c.name === name) || this.emptyCategory(name, icon);
        return category;
      });
    },
    getFixedExpensesOfMonth(budgetDate) {
      const expenses = this.getExpensesOfMonth(budgetDate);
      return _.map(this.fixedCategoryNames, ({ name, icon }) => {
        const category = _.find(expenses.fixed, c => c.name === name) || this.emptyCategory(name, icon);
        return category;
      });
    },
    getExpensesOfMonth(budgetDate) {
      return _.find(this.allExpenses, e => e.budgetDate === budgetDate) || { fixed: [], tracking: [] };
    },
  },
};

</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '../../../../../../../scss/mixins';

.expense-summary-core {
  color: $riseup_black;
  border-radius: $card-border-radius;

  .header {
    font-weight: bold;
    margin: $ri-spacing-ml 0 $ri-spacing-l 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .big-title {
      font-size: 24px;
    }

    .small-title {
      // We use display: none here because we remove it when sharing
      display: none;
    }
  }

  .middle-content {
    text-align: right;

    .categories-section {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 22px;
      }
    }

    .rows {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: $ri-spacing-sm;
        }

        .text {
          flex-grow: 1;
          width: 125px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          vertical-align: middle;
        }
      }
    }
  }

  .divider {
    height: 1px;
    margin-top: 22px;
    background-color: $riseup_gray_0;
  }

  .totals {
    padding: 22px 0 0 0;
    display: flex;
    justify-content: flex-start;

    .block {
      display: inline-block;
      text-align: right;

      .disclaimer {
        margin-top: 6px;
      }
    }
  }
}

</style>
