<template>
  <div class="benefit-details">
    <div class="months">
      <div class="title">{{ monthsTitle }}</div>
      <div class="subtitle">חיוב חודשי</div>
    </div>
    <div class="discount">
      <div class="title">
        <span class="number">{{ newPrice }}</span>
        <span class="nis">₪</span>
      </div>
      <div class="subtitle">
        <span class="instead">במקום</span>
        <span class="number">₪45</span>
      </div>
    </div>
    <div class="discount-banner">
      <span>50%</span>
      <span> הנחה</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PercentDiscountBenefitDetails',
  props: {
    numOfMonths: {
      type: Number,
      required: true,
    },
    discountPercentage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    monthsTitle() {
      return this.numOfMonths === 1 ? 'חודש אחד' : `${this.numOfMonths} חודשים`;
    },
    newPrice() {
      return 45 - (45 * this.discountPercentage) / 100;
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.benefit-details {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 30px 24px;
  background-color: $riseup-white;
  border: 1px solid #E4E4E4;
  box-shadow: 0px 5px 10px rgba(218, 218, 218, 0.5);
  border-radius: 10px;
  .discount {
    text-align: left;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    .nis {
      font-size: 16px;
    }
  }
  .subtitle {
    font-size: 16px;
    .instead {
      color: $riseup_gray_3;
    }
    .number {
      color: $riseup_gray_3;
      text-decoration: line-through;
    }
  }
  .discount-banner {
    position: absolute;
    align-self: center;
    justify-self: center;
    padding: 11px;
    font-size: 14px;
    font-weight: bold;
    background-color: $riseup_green;
    color: $riseup_white;
    border-radius: 8px;
    top: -18px;
    left: 24px;
  }
}
</style>
