<template>
  <div class="consecutive-positive-months" :class="{ shared: sharedAsset }">
    <div v-if="sharedAsset" class="celebration-title flex-column flex-1">
      <img class="full-width" src="/images/celebration/consecutive-positive-months.png">
      <div class="number-of-months">{{ months }}</div>
    </div>
    <div v-else class="animation-container">
      <img class="background" src="/images/celebration/consecutive-positive-months-animation-background.svg" />
      <lottie-player class="animation" src="/animations/celebration/consecutive-positive-months.json" autoplay loop />
      <div class="number-of-months">{{ months }}</div>
    </div>

    <div class="ri-large-body margin-top-ml">
      <div class="ri-large-headline ri-alte margin-bottom-s">
        <span>כל הכבוד</span>
        <span>{{ householdName }}!</span>
      </div>
      <span>היינו חייבים לעצור לומר כל הכבוד על</span>
      <span v-if="months === 2">חודשיים</span>
      <template v-else>
        <span>{{ months }}</span>
        <span>חודשים</span>
      </template>
      <span> בתזרים חיובי.</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConsecutivePositiveMonthsCore',
  props: {
    months: {
      type: Number,
      required: true,
    },
    householdName: {
      type: String,
      required: true,
    },
    sharedAsset: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.consecutive-positive-months {
  text-align: center;

  &.shared {
    border-radius: $ri-border-radius-medium;
  }

  .number-of-months {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    font-family: SimplerProAlte;
    font-weight: bold;
    color: $riseup_black;
    font-size: 90px;
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: $ri-spacing-ml;
    padding-left: 16px;
  }

  .celebration-title {
    position: relative;

  }

  .animation-container {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .background {
      width: 100%;
      position: absolute;
      z-index: 1;
    }

    .animation {
      position: absolute;
      z-index: 2;
      width: 90%;
      margin-top: $ri-spacing-l;
    }

    .number-of-months {
      position: absolute;
      z-index: 3;
      margin-top: 55px;
      padding-left: 10px;
    }
  }
}

</style>
