function scrollToAndHighlighElement(elementId) {
  setTimeout(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.classList.add('highlight');
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setTimeout(() => element.classList.remove('highlight'), 1000);
    }
  }, 500);
}

function scrollToElement(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

function scrollToTopOfElement(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

export default {
  scrollToAndHighlighElement,
  scrollToElement,
  scrollToTopOfElement,
};
