<template>
  <div class="column-wrapper">
    <variable-expense-column v-if="positiveCashflow"
                             :total-height="this.expenses.totalVariableExpensesHeight"
                             :used-height="this.expenses.usedVariableExpensesHeightPercent"/>
    <table-column v-else color="yellow" :height="this.expenses.usedVariableExpensesHeight" text="הוצאות משתנות"/>
    <table-column v-if="expenses.trackedExpensesHeight !== 0"
                  color="blue" :height="this.expenses.trackedExpensesHeight" text="צפי הוצאות סופר ואחרות"/>
    <table-column color="red" :height="this.expenses.fixedExpensesHeight" text="צפי הוצאות קבועות"/>
    <div class="table-info">
      <div class="amount ri-medium-bold-headline">{{ this.format(totalExpenses )}} -</div>
      <div class="ri-large-body">סה"כ הוצאות</div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import money from '@/utils/money';
import TableColumn from './TableColumn';
import CashflowAnalytics from '../../../../generic-components/CashflowAnalytics.vue';
import VariableExpenseColumn from './VariableExpenseColumn';

const FULL_HEIGHT = 264;
const MIN_COL_HEIGHT = 75;
const MIN_USED_PERCENT = 22;

export default {
  name: 'ExpenseColumn',
  components: {
    TableColumn,
    VariableExpenseColumn,
  },
  mixins: [CashflowAnalytics],
  data() {
    return {
      expenses: {},
    };
  },
  props: {
    totalIncome: {
      type: Number,
    },
    totalExpenses: {
      type: Number,
    },
    fixedExpenses: {
      type: Number,
    },
    trackedExpenses: {
      type: Number,
    },
    variableExpenses: {
      type: Number,
    },
    positiveCashflow: {
      type: Boolean,
    },
  },
  created() {
    const columnTotal = this.positiveCashflow ? this.totalIncome : this.totalExpenses;
    this.setFixedExpensesHeight(columnTotal);
    this.setTrackedExpensesHeight(columnTotal);
    this.setVariableExpensesHeight();
    this.trimExpensesToFitMaxColumnHeight(columnTotal);
  },
  methods: {
    format(value) {
      return money.formatAmountWithFraction(value, 0);
    },
    setFixedExpensesHeight(columnTotal) {
      this.expenses.fixedExpensesHeight = _.max([(this.fixedExpenses / columnTotal) * FULL_HEIGHT, MIN_COL_HEIGHT]);
    },
    setTrackedExpensesHeight(columnTotal) {
      this.expenses.trackedExpensesHeight = this.trackedExpenses ? _.max([(this.trackedExpenses / columnTotal) * FULL_HEIGHT, MIN_COL_HEIGHT]) : 0;
      this.expensesColumnMargins = this.trackedExpenses ? 12 : 6; // If we have no tracked expenses we need less margin
    },
    setVariableExpensesHeight(columnTotal) {
      this.expenses.usedVariableExpensesHeight = _.max([(this.variableExpenses / columnTotal) * FULL_HEIGHT, MIN_COL_HEIGHT]);
      if (this.positiveCashflow) { // if some of the income is unused, then we need to show the margin
        this.expenses.totalVariableExpensesHeight = _.max([FULL_HEIGHT - this.expenses.fixedExpensesHeight
        - this.expenses.trackedExpensesHeight - this.expensesColumnMargins, this.expenses.usedVariableExpensesHeight]);
        this.expenses.usedVariableExpensesHeightPercent = _.max(
          [((this.variableExpenses / (this.totalExpenses - this.trackedExpenses - this.fixedExpenses)) * 100),
            MIN_USED_PERCENT,
          ],
        );
      }
    },
    trimExpensesToFitMaxColumnHeight() {
      const expensesOverTheHeight = FULL_HEIGHT - this.expenses.fixedExpensesHeight - this.expenses.trackedExpensesHeight
        - this.expenses.totalVariableExpensesHeight - this.expensesColumnMargins;
      if (expensesOverTheHeight < 0) {
        const maximum = _.maxBy(Object.entries(this.expenses), x => x[1]);
        this.expenses[maximum[0]] = maximum[1] + expensesOverTheHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';
.column-wrapper {
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .table-title {
    width: 100%;
    height: 100px;

    .line-wrapper{
      height: 50px;
      text-align: center;
    }
  }

  .table-info {
    text-align: center;

    .amount {
      margin-top: 25px;
      color: $riseup_red;
    }
  }
}

</style>
