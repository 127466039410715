<template>
   <div class="summary">
     <div class="circles-container">
       <div class="circle-class large-income-color income-circle-position" :class="incomeCircleSize">
         <div>
           <span class="sum">{{formattedSum(totalIncomes)}}</span><span class="currency-sign">₪</span>
         </div>
         <div class="expense">הכנסות</div>
       </div>
       <div class="circle-class large-expense-color expense-circle-position" :class="expenseCircleSize">
         <div>
           <span class="sum">{{formattedSum(totalExpenses)}}</span><span class="currency-sign">₪</span>
         </div>
         <div class="expense">הוצאות</div>
       </div>
     </div>
     <div class="explanation">
       <div v-if="positiveCashflow">
         במהלך {{this.month}}
         <span class="bold">יצא פחות כסף מאשר נכנס.</span>
         כל הכבוד, ככה יוצאים לדרך.
         <br/><br/>
         <div>אם נתמיד ונוודא שמדי חודש אנחנו מסיימים בתזרים חיובי, נגדיל את העו״ש!</div>
       </div>
       <div v-else>
        במהלך {{this.month}}
         <span class="bold">יצא יותר כסף מאשר נכנס.</span>
         זה קורה, ובדיוק בשביל זה אנחנו כאן איתך.
         <br/><br/>
        <div>זה הזמן להתחיל להסתכל קדימה ולראות איפה  אפשר להשתפר כבר החודש!</div>
       </div>
     </div>
   </div>
  </template>

<script>
import Segment from '@/Segment';
import { mapGetters, mapState } from 'vuex';
import circleVariants from '@/constants/category-sum-circle-variants';
import moneyUtils from '../../../../../../utils/money';

export default {
  name: 'Summary',
  components: {},
  props: {
    totalIncomes: { type: Number, required: true },
    totalExpenses: { type: Number, required: true },
    totalCashflow: { type: Number, required: true },
    month: { type: String, required: true },
  },
  created() {
    Segment.trackUserInteraction('Month_Review_Summary_Shown', {
      totalIncomes: this.totalIncomes,
      totalExpenses: this.totalExpenses,
      totalCashflow: this.totalCashflow,
      cashflowStatus: this.positiveCashflow ? 'positive' : 'negative',
    });
  },
  computed: {
    ...mapState('cashflowView', ['cashflow']),
    ...mapGetters('cashflowView', ['trackingCategories']),
    positiveCashflow() {
      // We currently do not support a 'perfectly' balanced cashflow (i.e: income - expense = 0) (super rare case)
      // In a balanced situation the income circle will look bigger than the expense circle
      return this.totalCashflow >= 0;
    },
    incomeCircleSize() {
      return this.positiveCashflow ? circleVariants.circleSize.LARGE : circleVariants.circleSize.SMALL;
    },
    expenseCircleSize() {
      return !this.positiveCashflow ? circleVariants.circleSize.LARGE : circleVariants.circleSize.SMALL;
    },
  },
  methods: {
    formattedSum(sum) {
      return moneyUtils.formatAmount(sum);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
.summary {

  .circles-container {
    position: relative;
    height: 375px;

    .expense-circle-position {
      position: absolute;
      left: 24px;
      bottom: 0;
    }

    .income-circle-position {
      position: absolute;
      right: 24px;
    }

    .circle-class {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 10px;
      border-radius: 50%;
      color: $riseup_white;

      .sum {
        direction: ltr;
        display: inline-block;
      }

      .currency-sign {
        font-size: 14px;
      }

      .expense {
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }

  .explanation{
    line-height: 26px;
    font-size: 18px;
    text-align: center;
    margin: 50px 30px 40px;
    .bold {
      font-weight: bold;
    }
  }

  .large {
    height: 225px;
    width: 225px;
    font-size: 32px;
  }

  .small {
    height: 150px;
    width: 150px;
    font-size: 24px;
  }

  .large-income-color {
    background-color: $income_green1;
  }

  .large-expense-color {
    background-color: $riseup_dark_red;
  }
}
</style>
