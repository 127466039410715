<template>
  <eom-celebration-generic-popup class="growing-months-celebration-popup" :segment-extra-props="segmentExtraProps" :cashflow-date="cashflowDate"
  :share-component="shareComponent" :share-component-props="shareComponentProps">
    <template v-slot>
      <growing-months-core :household-name="householdName" :months="months" ></growing-months-core>
    </template>
  </eom-celebration-generic-popup>
</template>

<script>

import { mapGetters } from 'vuex';
import EomCelebrationGenericPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/EomCelebrationGenericPopup';
import GrowingMonthsAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/growting-months/GrowingMonthsAsset';
import GrowingMonthsCore from './GrowingMonthsCore';

export default {
  name: 'GrowingMonthsCelebrationPopup',
  components: {
    EomCelebrationGenericPopup,
    GrowingMonthsCore,
  },
  props: {
    months: {
      type: Number,
      required: true,
    },
    cashflowDate: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      shareComponent: GrowingMonthsAsset,
      shareComponentProps: {},
    };
  },
  created() {
    this.shareComponentProps = {
      months: this.months,
      householdName: this.householdName,
    };
  },
  computed: {
    ...mapGetters('session', ['householdName']),
    segmentExtraProps() {
      return {
        celebrationType: 'GrowingMonthsCelebrationPopup',
        months: this.months,
      };
    },
  },
};
</script>

<style lang="scss">

.growing-months-celebration-popup {
}

</style>
