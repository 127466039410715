<template>
  <cashflow-type-card
    :is-preview-mode="isPreviewMode"
    :card-type-id="CASHFLOW_TYPE"
    v-if="showSection"
    :can-edit-category="false"
    card-title="הפקדות לחיסכון"
    :sub-tree="allSavingTransactions"
    :cashflow-category="cashflowCategory"
    :blocked="blocked"
    :previous-month="previousMonth"
    :show-warning-if-exceeding-budget="false"
    :out-of-date="outOfDate">
    <template v-slot:bottom >
      <confetti-animation v-if="!isPreviewMode" ref="confetti" class="confetti-animation" />

      <expandable-summary-row v-if="anyFixedSaving" class="expandable-category-details"
                              :class="{ 'disabled' : isPreviewMode }"
                              @expanded="expandedFixed"
                              :has-update="savingExpenses.hasUpdate && !didExpandFixed"
                              :card-type-id="CASHFLOW_TYPE"
                              header-text="הפקדות קבועות" :out-of-date="outOfDate" :type="fixedSavingCategoryName">
        <template v-slot:content>
          <categories-table-header :out-of-date="outOfDate" :is-income="false"
                                   :show-recommended-amount="!previousMonth"/>
          <separator/>
          <div v-for="savingCategory in savingExpenses.categories" :key="savingCategory.label">
            <cashflow-type-transactions :category="savingCategory"
                                        :card-type-id="CASHFLOW_TYPE"
                                        :category-name="fixedSavingCategoryName"
                                       :category-type="fixedSavingCategoryName"
                                       :out-of-date="outOfDate"
                                       :is-predicted-transaction="true"/>
            <separator/>
          </div>
        </template>
      </expandable-summary-row>
      <separator v-if="anyOneTimeSaving"/>
      <expandable-summary-row v-if="anyOneTimeSaving" class="expandable-category-details"
                              :class="{ 'disabled' : isPreviewMode }"
                              @expanded="expandedVariables"
                              :card-type-id="CASHFLOW_TYPE"
                              :has-update="oneTimeSavingCategory.hasUpdate && !didExpandVariables"
                              header-text="הפקדות חד-פעמיות" :out-of-date="outOfDate" :type="oneTimeSavingCategoryName">
        <template v-slot:content>
          <cashflow-type-transactions :category="oneTimeSavingCategory"
                                      :card-type-id="CASHFLOW_TYPE"
                                     :use-category-id="true"
                                     :category-name="oneTimeSavingCategoryName"
                                     :category-type="oneTimeSavingCategoryName"
                                     :out-of-date="outOfDate"/>
        </template>
      </expandable-summary-row>
    </template>
  </cashflow-type-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import cashflowViewConsts from '@/constants/cashflow-view';
import EventBus from '@/event-bus/event-bus';
import ConfettiAnimation from '@/base-components/ConfettiAnimation.vue';
import SavingCategory from '@/mixins/SavingCategory';
import CashflowTypeCard from './CashflowTypeCard';
import CashflowTypeTransactions from './CashflowTypeTransactions';
import ExpandableSummaryRow from './ExpandableSummaryRow';
import CategoriesTableHeader from './CategoriesTableHeader';

export default {
  name: 'CashflowTypeBaseSavingCategory',
  components: {
    CashflowTypeCard,
    CashflowTypeTransactions,
    ExpandableSummaryRow,
    CategoriesTableHeader,
    Separator: BaseUI.Separator,
    ConfettiAnimation,
  },
  data() {
    return {
      didExpandFixed: false,
      didExpandVariables: false,
    };
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [SavingCategory],
  created() {
    EventBus.$on('animatedBarAmountChanged', this.onProgressBarChanged);
    this.initBalance();
  },
  beforeDestroy() {
    EventBus.$off('animatedBarAmountChanged', this.onProgressBarChanged);
  },
  computed: {
    ...mapGetters('balance', ['totalOshBalance']),
    showSection() {
      return this.anyFixedSaving || this.anyOneTimeSaving || this.totalOshBalance > 2000;
    },
    CASHFLOW_TYPE() {
      return 'savings';
    },
    fixedSavingCategoryName() {
      return cashflowViewConsts.CATEGORY_NAMES.FIXED_SAVING;
    },
    oneTimeSavingCategoryName() {
      return cashflowViewConsts.CATEGORY_NAMES.ONE_TIME_SAVING;
    },
    cashflowCategory() {
      return cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_SAVING;
    },
  },
  methods: {
    ...mapActions('balance', ['initBalance']),
    onProgressBarChanged({ cashflowCategory, newValue, oldValue }) {
      if (cashflowCategory === this.cashflowCategory && newValue > oldValue) {
        setTimeout(() => {
          this.$refs.confetti?.startConfettiAnimation();
        }, 500);
      }
    },
    expandedFixed() {
      this.didExpandFixed = true;
    },
    expandedVariables() {
      this.didExpandVariables = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "cashflow-type-base.styles";
  .confetti-animation {
    margin-top: -115px;
  }
</style>
