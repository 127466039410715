import { render, staticRenderFns } from "./AltshulerSignupIdUpload.vue?vue&type=template&id=45054c8b&scoped=true&"
import script from "./AltshulerSignupIdUpload.vue?vue&type=script&lang=js&"
export * from "./AltshulerSignupIdUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45054c8b",
  null
  
)

export default component.exports