import { render, staticRenderFns } from "./LogoAndBackPage.vue?vue&type=template&id=2ce07c96&scoped=true&"
import script from "./LogoAndBackPage.vue?vue&type=script&lang=js&"
export * from "./LogoAndBackPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ce07c96",
  null
  
)

export default component.exports