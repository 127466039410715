<template>
  <div class="open-account-error-page">
    <action-icon class="close-button" :action-type="closeAction" @click="backToCashflow" />
    <div class="flex-1 flex-column justify-content-center align-center">
      <img :src="require('@/assets/generic-faliure.png')" class="error-image" alt="שגיאה"/>
      <div class="ri-large-headline ri-alte">{{ titleText }}</div>
      <div class="ri-large-body margin-top-xs pre-wrap">{{ subtitleText }}</div>
      <riseup-button class="margin-top-xl" :title="primaryButtonText" @click="onPrimaryClick"/>
      <div v-if="!isDataError" class="margin-top-l ri-label">
        אפשר גם לפנות
        <a class="support-link">לצ׳אט עם צוות התמיכה</a>
        שלנו
      </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import Intercom from '@/Intercom';

const INTERCOM_MESSAGE = 'היי, ניסתי לפתוח פיקדון ברגע עם אלטשולר וקיבלתי הודעה שמשהו השתבש. תוכלו לעזור לי?';

export default {
  name: 'AltshulerOpenAccountErrorPage',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    ActionIcon: BaseUI.ActionIcon,
  },
  props: {
    errorType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      closeAction: BaseUI.HeaderActions.CLOSE,
    };
  },
  created() {
    Segment.trackUserGot('Altshuler_SignupErrorPage_Shown', { errorType: this.errorType });
  },
  computed: {
    isDataError() {
      return this.errorType === 'data';
    },
    titleText() {
      return this.isDataError ? 'משהו השתבש...' : 'משהו לא עובד כרגע...';
    },
    subtitleText() {
      return this.isDataError ? 'צוות התמיכה שלנו פה בשביל לעזור'
        : 'יש בעיה בשליחת הפרטים כרגע, יכול להיות שבעוד כמה שעות זה יסתדר.\nאפשר לנסות מאוחר יותר.';
    },
    primaryButtonText() {
      return this.isDataError ? 'לצ׳אט עם הצוות' : 'חזרה לתזרים';
    },
  },
  methods: {
    onPrimaryClick() {
      if (this.isDataError) {
        this.chatWithCx();
      } else {
        this.backToCashflow();
      }
    },
    chatWithCx() {
      Segment.trackUserInteraction('Altshuler_SignupErrorPage_ChatWithCxClicked', { errorType: this.errorType });
      Intercom.showNewMessage(INTERCOM_MESSAGE);
    },
    backToCashflow() {
      Segment.trackUserInteraction('Altshuler_SignupErrorPage_BackToCashflowClicked', { errorType: this.errorType });
      this.$router.push({ path: '/sr' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.open-account-error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: $ri-spacing-ml;
  text-align: center;
  .close-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .pre-wrap {
    white-space: pre-wrap;
  }
  .error-image {
    margin-bottom: $ri-spacing-l;
    width: 50%;
  }
  .support-link {
    color: $riseup_blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
