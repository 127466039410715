import _ from 'lodash';
import moment from 'moment';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import cashflowNormalizer from '@/store/utilities/cashflow-normalizer';
import cashflowPrediction from '@/store/utilities/cashflow-prediction';

import { DateLocales } from '@riseupil/common-utils';

const FIELDS = [
  { fieldName: 'budgetDate', translation: 'שייך לתזרים חודש' },
  { fieldName: 'businessName', translation: 'שם העסק' },
  { fieldName: 'source', translation: 'אמצעי התשלום' },
  { fieldName: 'accountNumberPiiValue', translation: 'אמצעי זיהוי התשלום' },
  { fieldName: 'transactionDate', translation: 'תאריך התשלום', format: 'date' },
  { fieldName: 'transactionMonth', translation: 'חודש תאריך התשלום' },
  { fieldName: 'transactionYear', translation: 'שנת תאריך התשלום' },
  { fieldName: 'billingDate', translation: 'תאריך החיוב בחשבון', format: 'date' },
  { fieldName: 'amount', translation: 'סכום' },
  { fieldName: 'billingCurrency', translation: 'מטבע חיוב' },
  { fieldName: 'paymentNumber', translation: 'מספר התשלום' },
  { fieldName: 'totalNumberOfPayments', translation: 'מספר תשלומים כולל' },
  { fieldName: 'category', translation: 'קטגוריה בתזרים' },
  { fieldName: 'isExcluded', translation: 'האם מוחרג מהתזרים?' },
  { fieldName: 'customerComment', translation: 'הערות' },
  { fieldName: 'sourceType', translation: 'סוג מקור' },
  { fieldName: 'originalAmount', translation: 'סכום מקורי' },
];

function getArrayDataWithAnl(budgets, assetsCsv) {
  const transactionsFromAllBudgets = _getTransactionsFromAllBudgets(budgets);
  const transactionsCsv = _transactionsToArray(transactionsFromAllBudgets);
  return _mergeCsvs(transactionsCsv, assetsCsv);
}

function getArrayData(budgets) {
  const transactionsFromAllBudgets = _getTransactionsFromAllBudgets(budgets);
  return _transactionsToArray(transactionsFromAllBudgets);
}

function convertToCsv(budgets) {
  const arrayData = getArrayData(budgets);
  return toCsvString(arrayData);
}

function predictFutureAndConvertToCsv(budgets, years = 1, anonymize = false) {
  const transactionsFromAllBudgets = _getTransactionsFromAllBudgets(budgets);

  const allTransactions = cashflowPrediction.predictFuture(transactionsFromAllBudgets, years, anonymize);

  const arrayData = _transactionsToArray(allTransactions);
  return toCsvString(arrayData);
}

function _mergeCsvs(transactionsCsv, assetsCsv) {
  const spaces = [undefined, undefined, undefined];
  const emptyTransaction = _.map(_.range(transactionsCsv[0].length), i => '');
  const emptyAsset = _.map(_.range(assetsCsv[0].length), i => '');
  const maxRow = _.max([transactionsCsv.length, assetsCsv.length]);
  return _.map(_.range(maxRow), i => {
    const transaction = transactionsCsv[i] || emptyTransaction;
    const asset = assetsCsv[i] || emptyAsset;
    return _.map([...transaction, ...spaces, ...asset], x => ((x === '' || _.isNil(x)) ? undefined : x));
  });
}

function _getTransactionsFromAllBudgets(budgets) {
  const normalizedCashflows = _.chain(budgets)
    .map(budget => {
      const cashflow = reactiveCashflow.createCashflow(budget, DateLocales.EN_IL);
      return [budget.budgetDate, cashflowNormalizer.normalizeCashflow(cashflow)];
    })
    .fromPairs()
    .value();

  return _.chain(normalizedCashflows)
    .map((normalizedCashflow, budgetDate) => cashflowToTransactions(normalizedCashflow, budgetDate))
    .concat()
    .flatten()
    .value();
}

function _transactionsToArray(allTransactions) {
  const fieldNames = _.map(FIELDS, f => f.translation);
  const transactionValues = allTransactions.map(t => getRequiredFields(t));
  return [fieldNames, ...transactionValues];
}

function _extractTransactionFromCashflowType(cashflow, type, subtype) {
  const categories = subtype ? cashflow[type][subtype] : cashflow[type];
  const transactions = _.chain(categories.categories)
    .map(category => _.map(category.transactions, t => _.merge(
      t, { category: category.label },
    )))
    .flatten()
    .value();
  return addIsExceluded(transactions, false);
}

function _extractTransactionFromCashflowTypeEconomy(cashflow) {
  const categories = cashflow.economy;
  const transactions = categories ? _.chain(categories.categories)
    .map(category => _.map(category.transactions, t => _.merge(
      t, { category: `סופר ${category.label}` },
    )))
    .flatten()
    .value() : [];
  return addIsExceluded(transactions, false);
}

function _extractTransactionFromCashflowTypeFixed(cashflow) {
  const categories = cashflow.fixedExpense;
  const transactions = _.chain(categories.categories).map(category => category.transactions)
    .flatten()
    .map(t => _.merge(t.actual, { category: t.expense || t.category, customerComment: t.actual?.customerComment || t.sequenceCustomerComment }))
    .filter(t => t.businessName)
    .value();
  return addIsExceluded(transactions, false);
}

function _extractTransactionFromExcluded(cashflow) {
  const excludedTransactions = [
    ..._extractTransactionFromCashflowType(cashflow, 'excluded', 'excludedExpense'),
    ..._extractTransactionFromCashflowType(cashflow, 'excluded', 'excludedIncome'),
  ];
  return _.forEach(excludedTransactions, t => {
    t.isExcluded = true;
    t.category = `${t.category} לא תזרימיות`;
  });
}

function _extractTransactionFromTrackingCategory(cashflow) {
  return _.chain(cashflow.trackingCategory)
    .map(track => track.categories)
    .flatten()
    .map(category => _.map(category.transactions, t => _.merge(t, { category: category.parentCategory })))
    .flatten()
    .thru(transactions => addIsExceluded(transactions, false))
    .value();
}

function _extractTransactionFromIncome(cashflow) {
  const fixedIncomeEnvelopes = _extractTransactionFromCashflowType(cashflow, 'income', 'fixedIncome');
  const fixedIncomeTransactions = _.chain(fixedIncomeEnvelopes)
    .map(e => e.actual)
    .filter()
    .forEach(t => {
      t.category = 'הכנסות קבועות';
    })
    .value();
  const variableIncomeTransactions = _.chain(_extractTransactionFromCashflowType(cashflow, 'income', 'variableIncome'))
    .forEach(t => {
      t.category = 'הכנסות משתנות';
    }).value();
  return addIsExceluded([...fixedIncomeTransactions, ...variableIncomeTransactions], false);
}

function _extractTransactionFromVariableExpense(cashflow) {
  return _.chain(cashflow)
    .get('variableExpense.monthly.realExpenses.categories')
    .map(category => category.transactions)
    .flatten()
    .filter()
    .map(t => _.merge(t, { category: 'הוצאות משתנות' }))
    .thru(transactions => addIsExceluded(transactions, false))
    .value();
}

function _extractTransactionFromOneTimeSaving(cashflow) {
  return _.chain(cashflow.oneTimeSaving)
    .map(track => track.categories)
    .flatten()
    .map(category => _.map(category.transactions, t => _.merge(t, { category: 'חסכון חד פעמי' })))
    .flatten()
    .thru(transactions => addIsExceluded(transactions, false))
    .value();
}

function _extractTransactionFromFixedSaving(cashflow) {
  const categories = cashflow.fixedSaving;
  const transactions = _.chain(categories.categories).map(category => category.transactions)
    .flatten()
    .map(t => _.merge(t.actual, { category: 'חסכון קבוע' }))
    .filter(t => t.businessName)
    .value();
  return addIsExceluded(transactions, false);
}

function _extractTransactionFromSavings(cashflow) {
  return [
    ..._extractTransactionFromOneTimeSaving(cashflow),
    ..._extractTransactionFromFixedSaving(cashflow),
  ];
}

function cashflowToTransactions(cashflow, budgetDate) {
  const transactions = [
    ..._extractTransactionFromCashflowTypeEconomy(cashflow),
    ..._extractTransactionFromExcluded(cashflow),
    ..._extractTransactionFromCashflowTypeFixed(cashflow),
    ..._extractTransactionFromIncome(cashflow),
    ..._extractTransactionFromTrackingCategory(cashflow),
    ..._extractTransactionFromVariableExpense(cashflow),
    ..._extractTransactionFromSavings(cashflow),
  ];
  return _.chain(transactions)
    .reject(t => t.isPapa)
    .map(t => {
      return {
        ...t,
        amount: getAmount(t),
        originalAmount: -t.originalAmount,
        budgetDate,
      };
    })
    .value();
}

function toCsvString(arrayOfArrays) {
  return _.chain(arrayOfArrays)
    .map(array => _.map(array, value => value && _.replace(value, /,/g, '')))
    .map(array => _.map(array, value => value && _.replace(value, /"/g, '')))
    .map(array => array.join(','))
    .join('\n')
    .value();
}

function addIsExceluded(transactions, isExcluded) {
  return _.map(transactions, t => {
    return {
      ...t,
      isExcluded,
    };
  });
}

function getRequiredFields(t) {
  return _.map(FIELDS, field => {
    if (field.format === 'date') {
      return moment(t[field.fieldName]).format('DD/MM/YYYY');
    }
    return t[field.fieldName];
  });
}

function getAmount(t) {
  if (t.billingAmount) {
    return `-${t.billingAmount}`;
  }
  if (t.incomeAmount) {
    return t.incomeAmount;
  }
  return 0;
}

export default {
  convertToCsv,
  getArrayData,
  getArrayDataWithAnl,
  predictFutureAndConvertToCsv,
};
