<template>
  <div class="container" @click="scrollTo100DaysCard">
    <colored-svg class="flower" :color="flowerColor" svg-src="/images/shapes/eight-leaves-flower.svg" />
    <colored-svg class="arrow" :color="arrowColor" :svg-src="require('@/assets/down-full-arrow.svg')" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import effectsUtils from '@/store/utilities/effects';

export default {
  name: 'HundredDaysBottomPopup',
  components: {
    ColoredSvg: BaseUI.ColoredSvg,
  },
  data() {
    return {
      flowerColor: BaseUI.Colors.riseupGreen,
      arrowColor: BaseUI.Colors.riseupWhite,
    };
  },
  methods: {
    scrollTo100DaysCard() {
      effectsUtils.scrollToElement('im-on-it100-days');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/spacings.scss';

.container {
  position: absolute;
  // irregular position to match intercom floating button
  bottom: calc(48px + 20px);
  left: 20px;
  cursor: pointer;

  .flower {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
  }
  .arrow {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 13px;
    left: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
}

</style>
