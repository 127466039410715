<template>
  <sticky-cta-page :cta-props="ctaProps" @click="submit">
    <dashboard-page :image-header-props="imageHeaderProps" class="padding-bottom-ml">
      <template v-slot:header>
        <div class="header">
          <div class="ri-bold-body text">סיכום</div>
          <div class="ri-alte ri-large-headline text">מעולה! רק כדאי לוודא רגע שהכל נכון ולאשר</div>
        </div>
      </template>
      <template v-slot:dashboard>
          <highlights class="padding-horizontal-ml padding-vertical-l" :highlights="highlights" :show-icons="false" />
      </template>
    </dashboard-page>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import moneyUtils from '@/utils/money';
import Segment from '@/Segment';
import Highlights from './Highlights.vue';

export default {
  name: 'AltshulerSignupSummary',
  components: {
    Highlights,
    DashboardPage: BaseUI.Pages.DashboardPage,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  data() {
    const { bankCodePiiValue, bankBranchPiiValue, accountNumberPiiValue, depositAmount } = this.wizardState;
    return {
      highlights: [
        { primary: 'סכום ההפקדה', secondary: `${moneyUtils.formatAmountWithFraction(depositAmount, 0)} ₪` },
        { primary: 'זמינות', secondary: 'הפקדה ומשיכה בכל יום עסקים\n(ביצוע הפעולה יכול לקחת עד 5 ימי עסקים)' },
        { primary: 'ריבית', secondary: '3.75%, ריבית משתנה (P - 2.5%)' },
        { primary: 'פעולות ומעקב', secondary: 'דרך רייזאפ' },
        { primary: 'חשבון להפקדה', secondary: `${bankCodePiiValue}-${bankBranchPiiValue}-${accountNumberPiiValue}` },
      ],
      ctaProps: {
        buttonProps: {
          title: 'אישור ופתיחת פיקדון',
        },
      },
    };
  },
  computed: {
    imageHeaderProps() {
      return {
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE,
        // eslint-disable-next-line global-require
        imgSrc: require('@/assets/altshuler/dashboard-logo.svg'),
        bottomSeparator: false,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
      };
    },
  },
  methods: {
    submit() {
      Segment.trackUserInteraction('Altshuler_Summary_NextClicked');
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.headline {
  color: $riseup_blue;
}

.header {
  color: $riseup_black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: $ri-spacing-sm 0;

  .text {
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 70%;
  }
}

</style>
