import { render, staticRenderFns } from "./ExplanationBox.vue?vue&type=template&id=681fdfa6&scoped=true&"
import script from "./ExplanationBox.vue?vue&type=script&lang=js&"
export * from "./ExplanationBox.vue?vue&type=script&lang=js&"
import style0 from "./ExplanationBox.vue?vue&type=style&index=0&id=681fdfa6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681fdfa6",
  null
  
)

export default component.exports