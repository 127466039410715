<template>
  <div class="plan-summary-line" @click="$emit('plan-menu-clicked')">
    <div class="name-and-description">
      <div class="name" :class="isItemLine ? 'ri-bold-label' : 'ri-bold-body'">{{ name }}</div>
      <div class="ri-small-body margin-top-xxs">{{ date }}</div>
    </div>
    <div class="amount-and-menu-item-container">
      <div :class="['amount', isItemLine ? 'ri-bold-label' : 'ri-bold-body', { 'menu-item-shown': isItemLine }]">
        {{ formattedAmount }}<span> ₪</span>
      </div>
      <div class="menu-icon" v-if="showMenuItem">
        <img :src="require('@/assets/icons/vertical-three-dots.svg')"/>
      </div>
      <div class="menu-icon-placeholder" v-else-if="isItemLine"/>
    </div>
  </div>
</template>

<script>
import moneyUtils from '@/utils/money';
import BaseUi from '@riseupil/base-ui';

export default {
  name: 'PlanSummaryLine',
  data() {
    return {
      menuColor: BaseUi.Colors.riseupBlack,
    };
  },
  props: {
    name: {
      type: String,
      require: true,
    },
    date: {
      type: String,
      require: true,
    },
    amount: {
      type: Number,
      require: true,
    },
    lineType: {
      type: String,
      require: true,
      validator: value => ['item', 'summary'].includes(value),
    },
    showMenuItem: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.amount, 0);
    },
    isItemLine() {
      return this.lineType === 'item';
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/spacings";
@import "~@riseupil/base-ui/src/scss/mixins";

.plan-summary-line {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  padding: $ri-spacing-sm $ri-spacing-ml;

  .name-and-description {
    padding-left: $ri-spacing-xs;
    @include ellipsis-text;
    .name {
      @include ellipsis-text;
    }
  }

  .amount-and-menu-item-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .amount.menu-item-shown {
    padding-left: $ri-spacing-sm;
  }

  .icon {
    line-height: 18px;
  }

  .menu-icon-placeholder {
    width: $ri-spacing-xxs;
  }
}
</style>
