<template>
  <logo-and-back-page :background-color-variant="bgColorVariant" :text-color="textColor" >
    <div class="hever-benefit-redeemed">
      <div class="header">
        <lottie-player class="lottie-hever" src="/animations/hever-confetti.json" :autoplay="true"/>
        <div class="title">{{ title }}</div>
        <div class="description">{{ description }}</div>
      </div>
      <div class="details-wrapper">
        <percent-discount-benefit-details :discount-percentage="50" :num-of-months="6"/>
      </div>
      <div class="card-wrapper">
        <hever-ambassadors-card class="ambassadors-card-margin"/>
      </div>
      <div class="link-to-ambassadors" v-on:click="goToAmbassadors">
        עוד מידע על המלצות ברייזאפ
      </div>
    </div>
  </logo-and-back-page>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import Segment from '@/Segment';
import HeverAmbassadorsCard
  from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/HeverAmbassadorsCard.vue';
import router from '@/router';
import PercentDiscountBenefitDetails
  from '@/base-components/PercentDiscountBenefitDetails.vue';
import { mapGetters } from 'vuex';
import LogoAndBackPage from '@/base-components/app-header/LogoAndBackPage';

export default {
  name: 'HeverBenefitRedeemed',
  components: {
    LogoAndBackPage,
    HeverAmbassadorsCard,
    PercentDiscountBenefitDetails,
  },
  data() {
    return {
      bgColorVariant: BaseUi.LayoutConsts.COLOR_VARIANT.BLUE,
      textColor: BaseUi.Colors.riseupWhite,
    };
  },
  async created() {
    Segment.trackUserGot('PartnersBenefitRedeemedEntered', {});
  },
  computed: {
    ...mapGetters('subscriptionState', ['inTrial']),
    title() {
      return this.inTrial ? 'קיבלת הטבה!' : 'ההטבה פעילה!';
    },
    description() {
      return this.inTrial ? 'ההנחה תכנס לתוקף החל \n מהתשלום הראשון על המנוי. ' : 'ההנחה בתוקף החל מהחיוב הבא 👌';
    },
  },
  methods: {
    goToAmbassadors() {
      router.push('/ambassadors');
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../scss/mixins';

.hever-benefit-redeemed {
  @include white-page-frame;
  .header {
    position: relative;
    width: 100%;
    height: 150px;
    padding-top: 48px;
    color: $riseup_white;
    background-color: $riseup_blue;
    text-align: center;
    .lottie-hever {
      position: absolute;
      top: -10;
      & > #animation-container > #animation > svg > g > g:nth-child(1) > g > path {
        fill: transparent;
      }
    }
    .title {
      position: relative;
      z-index: 10;
      font-size: 22px;
      font-weight: bold;
      line-height: 28px;
    }
    .description {
      position: relative;
      z-index: 10;
      margin-top: 5px;
      font-size: 16px;
      line-height: 18px;
      white-space: pre-line;
    }
  }
  .details-wrapper {
    width: 100%;
    @include responsive-padding;
    background: linear-gradient(to bottom, $riseup_blue 50%, $riseup_white 50%);
  }

  .card-wrapper {
    width: 100%;
    margin-top: 24px;
    @include responsive-padding;
    .ambassadors-card-margin {
      width: 100%;
    }
  }
  .link-to-ambassadors {
    margin: 24px 0;
    font-size: 16px;
    font-weight: bold;
    color: $riseup_blue;
    cursor: pointer;
    text-align: center;
  }
}
</style>
