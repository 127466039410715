<template>
  <share-frame class="turn-over-asset" :background-color="backgroundColor" logoColor="white">
    <turn-over-core :amount="amount" :household-name="householdName" :cashflow-date="cashflowDate" shared-asset/>
  </share-frame>
</template>

<script>

import TurnOverCore
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/turn-over/TurnOverCore';
import ShareFrame from '@/base-components/share/ShareFrame';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'TurnOverAsset',
  components: {
    ShareFrame,
    TurnOverCore,
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    householdName: {
      type: String,
      required: true,
    },
    cashflowDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupRed,
    };
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.turn-over-asset {
  width: 500px;
  .turn-over {
    background-color: $riseup_white;
    padding: 24px 36px;
  }
}

</style>
