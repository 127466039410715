<template>
  <card class="account-setting-button" :class="{'party-button': partyButton}">
    <div class="loading-content" v-if="loading">
      <tiny-loader :variant="'secondary-black'" />
    </div>
    <div class="button-content" v-on:click="action" v-else>
      <span class="account-setting-title" :class="color">{{ title }}</span>
      <icon v-if="iconProps" v-bind="iconProps" size="18px"/>
      <span v-else :class="`button-icon fa ${icon} ${computedIconColor}`"></span>
    </div>
  </card>
</template>

<script>

import BaseUI from '@riseupil/base-ui';

export default {
  name: 'AccountSettingButton',
  components: {
    Card: BaseUI.Card,
    Icon: BaseUI.Icon,
    TinyLoader: BaseUI.TinyLoader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    partyButton: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Function,
      required: true,
    },
    color: {
      type: String,
      default: 'black',
    },
    icon: {
      type: String,
      default: 'fa-chevron-left',
    },
    iconColor: {
      type: String,
    },
    iconProps: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedIconColor() {
      return this.iconColor || this.color;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@riseupil/base-ui/src/scss/riseup-colors";

  @keyframes gradient-shift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .account-setting-button {
    height: 64px;
    margin-top: 16px;
    background-color: $riseup_white;

    &.party-button {
      background: linear-gradient(145deg, #ffb2ff, #b2ffff);
      background-size: 400% 400%;
      animation: gradient-shift 3s ease infinite;
    }

    .loading-content {
      width: 100%;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    .button-content {
      width: 100%;
      height: 100%;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .account-setting-title {
        font-size: 16px;
        font-weight: bold;

        &.red {
          color: $riseup_danger_red;
        }

        &.blue {
          color: $riseup_blue;
        }

        &.green {
          color: $riseup_green;
        }
      }

      .button-icon {
        &.red {
          color: $riseup_danger_red;
        }

        &.blue {
          color: $riseup_blue;
        }

        &.green {
          color: $riseup_green;
        }
      }
    }
  }
</style>
