<template>
  <benefit-notification-box-base :icon-path="'subscription/discount.svg'" :description="description" :title="title"/>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BenefitNotificationBoxBase from '@/base-components/BenefitNotificationBoxBase';
import dateUtils from '@/utils/dates';

export default {
  name: 'MoneyBackNotificationBox',
  components: {
    BenefitNotificationBoxBase,
  },
  computed: {
    ...mapState('subscriptionState', ['moneyBackGuaranteeRecord']),
    ...mapGetters('', ['BENEFIT_TYPES']),
    description() {
      return this.moneyBackGuaranteeRecord.isOnMoneyBackRefundEligibilityPeriod
        ? `יש לך עד ה־${this.formattedEndDate} להחליט אם להמשיך או למחוק את החשבון ולקבל את הכסף בחזרה.`
        : `ב־${this.formattedRefundEligibilityStartDate} תוכלו להחליט תוך 10 ימים אם להמשיך או למחוק את החשבון ולקבל את הכסף בחזרה.`;
    },
    title() {
      return this.moneyBackGuaranteeRecord.isOnMoneyBackRefundEligibilityPeriod
        ? 'סיימת שלושה חודשים במינוי!'
        : 'איך זה עובד?';
    },
    formattedEndDate() {
      return dateUtils.getShortHebrewMonthAndDay(this.moneyBackGuaranteeRecord.endOfRefundEligibilityPeriod);
    },
    formattedRefundEligibilityStartDate() {
      return dateUtils.getShortHebrewMonthAndDay(this.moneyBackGuaranteeRecord.beginningOfRefundEligibilityPeriod);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
