<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'AccountSettingsMixin',
  computed: {
    imageHeaderProps() {
      return {
        // eslint-disable-next-line global-require
        imgSrc: require('@/assets/subscription/account-settings-header.svg'),
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.DARK_GREEN,
        bottomSeparator: false,
        textColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        backgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM,
        title: 'הגדרות ופרטי מינוי',
      };
    },
  },
};
</script>
