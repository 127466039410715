import _ from 'lodash';
import cashflowViewConsts from '@/constants/cashflow-view';
import featureFlag from '../../../../../api/featureFlag';
import DDLogs from '../../../../../DDLogs';
import Segment from '../../../../../Segment';

const BILLS = 'חשבונות';
const HOUSING = 'דיור';
const SUPERMARKET = 'סופר';
const MISC = 'שונות';
const CATEGORY_MAPPING = {
  ארנונה: BILLS,
  חשמל: BILLS,
  גז: BILLS,
  מים: BILLS,
  דיור: HOUSING,
  'סיוע בשכר דירה': HOUSING,
  כלכלה: SUPERMARKET,
  קניות: SUPERMARKET,
  כללי: MISC,
  'לא מסווג': MISC,
  אחר: MISC,
  שונות: MISC,
};

export function parseTransactions(transactions) {
  return _.chain(transactions)
    .filter(transaction => !!transaction)
    .map(transaction => {
      return {
        amount: transaction.incomeAmount || transaction.billingAmount || 0,
        date: transaction.transactionDate,
        businessName: transaction.businessName,
        originalExpense: transaction.expense,
        expense: getValidExpense(transaction.expense),
      };
    })
    .filter(transaction => transaction.amount > 0)
    .value();
}

export function sumTransactionsByExpense(parsedTransactions) {
  return _.chain(parsedTransactions)
    .groupBy('expense')
    .map((transactions, expenseName) => {
      return { sum: Math.floor(_.sumBy(transactions, transaction => transaction.amount)), expense: expenseName };
    })
    .sortBy('sum')
    .reverse()
    .value();
}

export function markSmallSumTransactions(transactionsSums, transactions) {
  const transactionsSumsByExpense = _.keyBy(transactionsSums, 'expense');
  return _.map(
    transactions,
    transaction => (transactionsSumsByExpense[transaction.expense].sum >= 100
      ? transaction
      : { ...transaction, expense: MISC }),
  );
}

function getValidExpense(expense) {
  const expenseName = expense || MISC;
  return CATEGORY_MAPPING[expenseName] || expenseName;
}

/// /////////////////////////////////// TEMP - ONLY FOR TEST PERIOD //////////////////////////////////////

export async function chooseFlow(uiTree) {
  const coverageThresholdRes = await checkCoverageThreshold(uiTree);
  const featureFlagRes = coverageThresholdRes
    ? (await featureFlag.getTreatment('MonthReview')) === 'on'
    : false;
  Segment.trackUserGot('MonthReview choose flow', { coverageThresholdRes, featureFlagRes });
  DDLogs.log('MonthReview choose flow', { coverageThresholdRes, featureFlagRes });
  return coverageThresholdRes && featureFlagRes;
}

async function checkCoverageThreshold(uiTree) {
  const expensesTransactions = extractExpensesFromUITreeByExpense(uiTree);
  const incomesTransactions = extractIncomesFromUITreeByExpense(uiTree);

  const totalExpensesSum = _.sumBy(expensesTransactions, 'billingAmount');
  const totalIncomesSum = _.sumBy(incomesTransactions, 'incomeAmount');

  const uncoveredExpensesSum = _.sumBy((_.groupBy(expensesTransactions, 'expense')).null, 'billingAmount');
  const uncoveredIncomesSum = _.sumBy((_.groupBy(incomesTransactions, 'expense')).null, 'incomeAmount');

  const uncoveredExpenses = (uncoveredExpensesSum / totalExpensesSum) * 100;
  const uncoveredIncomes = (uncoveredIncomesSum / totalIncomesSum) * 100;

  DDLogs.log('Transactions Coverage', { uncoveredExpenses, uncoveredIncomes });
  Segment.trackUserGot('Transactions Coverage', { uncoveredExpenses, uncoveredIncomes });

  return uncoveredExpenses <= 20;
}

function extractIncomesFromUITreeByExpense(uiTree) {
  const incomesObject = uiTree[`${cashflowViewConsts.cashflowTypes.income}`];
  if (!incomesObject) {
    return [];
  }
  const fixedIncomesCategoriesObject = incomesObject[`${cashflowViewConsts.CATEGORY_NAMES.FIXED_INCOME}`]?.categories;
  const variableIncomesCategoriesObject = incomesObject[`${cashflowViewConsts.CATEGORY_NAMES.VARIABLE_INCOME}`]?.categories;
  const fixedIncomesTransactions = _.reduce(
    fixedIncomesCategoriesObject,
    (transactions, category) => [...transactions, ..._.map(category.transactions, transaction => transaction.actual)],
    [],
  );
  const variableIncomesTransactions = _.reduce(
    variableIncomesCategoriesObject,
    (transactions, category) => [...transactions, ...category.transactions],
    [],
  );
  const incomesTransactions = [...fixedIncomesTransactions, ...variableIncomesTransactions];
  return _.filter(incomesTransactions, t => !!t);
}

function extractExpensesFromUITreeByExpense(uiTree) {
  const fixedExpensesCategoriesObject = uiTree[`${cashflowViewConsts.CATEGORY_NAMES.FIXED_EXPENSE}`]?.categories;
  const trackingExpensesCategories = uiTree[`${cashflowViewConsts.CATEGORY_NAMES.TRACKING_CATEGORY}`];
  const variableExpensesCategoriesObject = uiTree[`${cashflowViewConsts.CATEGORY_NAMES.VARIABLE_EXPENSE}`]
    ?.monthly.realExpenses.categories;

  const fixedExpensesTransactions = _.reduce(
    fixedExpensesCategoriesObject,
    (transactions, category) => [...transactions, ..._.map(category.transactions, transaction => transaction.actual)],
    [],
  );
  const trackingExpensesTransactions = _.flatMap(
    trackingExpensesCategories,
    trackingCategoryObject => _.reduce(
      trackingCategoryObject.categories,
      (transactions, category) => [...transactions, ...category.transactions],
      [],
    ),
  );
  const variableExpensesTransactions = _.reduce(
    variableExpensesCategoriesObject,
    (transactions, category) => [...transactions, ...category.transactions],
    [],
  );
  const expensesTransactions = [...fixedExpensesTransactions, ...trackingExpensesTransactions, ...variableExpensesTransactions];
  return _.filter(expensesTransactions, t => !!t);
}
