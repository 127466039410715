<template>
  <div class="explanation">
    <span class="bold">לפירוט</span>
     לחצו על הקטגוריה
  </div>
</template>

<script>

export default {
  name: 'Explanation',
};
</script>

<style lang="scss" scoped>
.explanation {
  .bold {
    font-weight: bold;
  }
}
</style>
