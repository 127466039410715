<template>
  <div v-if="!contentLoading" class="month-review-flow">
      <month-review :step-name="currentStep.monthReviewStepName"
                 :title="currentStep.title"
                 :amount="currentStep.amount"
                 :button-title="currentStep.buttonTitle"
                 :button-action="next"
                 :key="currentIndex">
        <template v-slot:step-explanation>
          <component v-if="currentStep.explanationComponent" :is="currentStep.explanationComponent"/>
        </template>
        <template v-slot:step-content>
          <component :is="currentStep.component" v-bind="currentStep.props"/>
        </template>
      </month-review>
  </div>
</template>
<script>

import { mapState, mapGetters } from 'vuex';
import monthReviewConsts from '@/constants/month-review';
import Dates from '@/utils/dates';
import EventBus from '@/event-bus/event-bus';
import TourApi from '@/api/TourApi';
import Incomes from './steps/Incomes';
import Expenses from './steps/Expenses';
import Summary from './steps/Summary';
import casfhlowHistory from '../../../../../store/utilities/cashflow-history';
import router from '../../../../../router';
import Intercom from '../../../../../Intercom';
import Segment from '../../../../../Segment';
import MonthReview from './MonthReview';
import Explanation from './components/Explanation';

const HOME_PAGE = 'home';

export default {
  name: 'MonthReviewFlow',
  data() {
    return {
      loading: true,
      currentIndex: 0,
      totalIncomes: 0,
      totalExpenses: 0,
      totalCashflow: 0,
      cashflowType: '',
    };
  },
  components: {
    MonthReview,
  },
  props: {
    startIndex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  async created() {
    this.currentIndex = this.startIndex;
    this.totalIncomes = Math.floor(Math.abs(this.cashflow.incomeActualSum));
    this.totalExpenses = -1 * Math.floor(Math.abs(this.cashflow.expenseActualSum));
    this.totalCashflow = this.totalIncomes + this.totalExpenses;
    this.cashflowType = this.totalCashflow < 0 ? 'שלילי' : 'חיובי';

    Segment.trackUserInteraction('Month_Review_Entered');
  },
  computed: {
    ...mapGetters('contentLoader', ['contentLoading']),
    ...mapState('cashflowView', ['cashflow']),
    budgetDate() {
      return casfhlowHistory.getPrevBudgetDate(casfhlowHistory.getCurrentBudgetDate());
    },
    prevMonth() {
      return Dates.getHebrewMonth(casfhlowHistory.getPrevBudgetDate(casfhlowHistory.getCurrentBudgetDate()));
    },
    summaryTitle() {
      return this.totalCashflow < 0
        ? `נראה ש${this.prevMonth} הסתיים בתזרים חודשי שלילי של `
        : `מצוין! נראה ש${this.prevMonth} הסתיים בתזרים חודשי חיובי של `;
    },
    expenseCircleSizeThresholds() {
      return this.calcCircleSizeThresholds(this.totalExpenses);
    },
    incomeCircleSizeThresholds() {
      return this.calcCircleSizeThresholds(this.totalIncomes);
    },
    steps() {
      return [
        {
          component: Incomes,
          props: {
            month: this.prevMonth,
            circleSizeThresholds: this.incomeCircleSizeThresholds,
          },
          explanationComponent: Explanation,
          title: `סך הכל נכנס ב${this.prevMonth}`,
          amount: this.totalIncomes,
          buttonTitle: 'וכמה יצא בחודש הקודם?',
          monthReviewStepName: monthReviewConsts.stepNames.incomes,
        },
        {
          component: Expenses,
          props: {
            month: this.prevMonth,
            circleSizeThresholds: this.expenseCircleSizeThresholds,
          },
          explanationComponent: Explanation,
          title: `סך הכל יצא ב${this.prevMonth}`,
          amount: this.totalExpenses,
          buttonTitle: 'אז איך נגמר החודש?',
          monthReviewStepName: monthReviewConsts.stepNames.expenses,
        },
        {
          component: Summary,
          props: {
            totalIncomes: this.totalIncomes,
            totalExpenses: this.totalExpenses,
            totalCashflow: this.totalCashflow,
            month: this.prevMonth,
          },
          title: this.summaryTitle,
          amount: this.totalCashflow,
          buttonTitle: 'לחישוב התזרים של החודש >',
          monthReviewStepName: monthReviewConsts.stepNames.summary,
        },
      ];
    },
    currentStep() {
      return this.steps[this.currentIndex];
    },
  },
  methods: {
    calcCircleSizeThresholds(total) {
      const onePercent = Math.abs(total / 100);
      return {
        large: onePercent * 35,
        medium: onePercent * 2,
      };
    },
    async next() {
      EventBus.$emit('reset-scroll');
      const currentStepName = this.steps[this.currentIndex].monthReviewStepName;
      Segment.trackUserInteraction(`MonthReview_${currentStepName}_Next_Clicked`);

      if (this.currentIndex >= this.steps.length - 1) {
        this.$emit('month-review-done');
      } else {
        this.currentIndex += 1;
        this.$emit('next-clicked', { currentIndex: this.currentIndex });
      }
    },
    async goToCasfhlow() {
      await TourApi.markAsDone();
      Intercom.showLauncher();
      router.push({ path: `/${HOME_PAGE}`, query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>
  .month-review-flow{
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
</style>
