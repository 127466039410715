<template>
<div class="action-list-badge" @click="openPopup">
  <img class="actions-icon" src="/images/action-list/action-list.svg">
  <div class="text-container">
    <span>השלמת</span>
    <span class="completed-text">{{completedActionCounter}} צעדים</span>
    <span>והתחלת לצמוח!</span>
  </div>
  <img class="arrow-icon" :src="require('@/assets/icons/arrow_enabled_left.svg')">
</div>
</template>

<script>
import Segment from '@/Segment';
import { mapActions, mapGetters } from 'vuex';
import ActionListPopup from './ActionListPopup';

export default {
  name: 'ActionListBadge',
  created() {
    Segment.trackUserInteraction('ActionListBadge_Shown');
  },
  computed: {
    ...mapGetters('actions', ['actions']),
    completedActionCounter() {
      return this.actions.filter(action => action.checked).length;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openPopup() {
      Segment.trackUserInteraction('ActionListBadge_Clicked');
      this.openModal({
        component: ActionListPopup,
      });
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@riseupil/base-ui/src/scss/border-radius';
  .action-list-badge {
    display: flex;
    height: 54px;
    margin-bottom: 24px;
    padding: 16px;
    background-color: #DCE8F6;
    border-radius: $ri-border-radius-medium;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    box-shadow: 0 5px 8px rgba(186, 188, 191, 0.25);
    cursor: pointer;

    .arrow-icon {
      position: relative;
      top: 4px;
      height: 14px;
      width: 8.5px;
    }

    .actions-icon {
      height: 24px;
      width: 24px;
    }

    .text-container {
      flex: 1;
      padding-right: 10px;

      .completed-text {
        font-weight: 700;
      }
    }
  }
</style>
