<template>
  <dashboard-page :image-header-props="imageHeaderProps">
    <template v-slot:dashboard>
      <div class="margin-ml">
        <template v-if="isHundredDaysPromotion">
          <property class="margin-bottom-m" name="סוג מינוי" value="תוכנית על זה ביחד"/>
          <property class="margin-bottom-m" name="סיום תקופת התוכנית" :value="trialEndDateFormatted"/>
        </template>
        <template v-else>
          <property class="margin-bottom-m" :name="'סוג מינוי'" :value="'תקופת ניסיון'"/>
          <property class="margin-bottom-m" :name="'סיום תקופת הניסיון'" :value="trialEndDateFormatted"/>
          <div v-if="benefitId">
            <benefit-notification-box-v2 :flavor="flavors.accountSettings" />
          </div>
        </template>
      </div>

      <separator />
      <div class="margin-ml">
        <riseup-button :action="subscribeClicked" :size="'slim'" title='לבחור סוג מינוי' />
      </div>
    </template>
  </dashboard-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapGetters, mapState } from 'vuex';
import BenefitNotificationBoxV2
  from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitNotificationBox2.vue';
import BenefitMixin from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitMixin.vue';
import AccountSettingsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/AccountSettingsMixin.vue';
import dates from '../../../../../utils/dates';
import SubscribeClickHandler from './SubscribeClickHandler.vue';
import Property from './Property.vue';

export default {
  name: 'TrialSettings',
  components: {
    BenefitNotificationBoxV2,
    Property,
    RiseupButton: BaseUI.RiseupButton,
    Separator: BaseUI.Separator,
    DashboardPage: BaseUI.Pages.DashboardPage,
  },
  mixins: [SubscribeClickHandler, BenefitMixin, AccountSettingsMixin],
  computed: {
    ...mapState('benefits', ['benefitId']),
    ...mapState('subscriptionState', ['until']),
    ...mapGetters('benefits', ['isHundredDaysPromotion']),
    trialEndDateFormatted() {
      return dates.shortDateFormat(this.until);
    },
  },
};
</script>

<style scoped lang="scss">

  @import "~@riseupil/base-ui/src/scss/riseup-colors";
  @import "./settings";

  @include properties;

</style>
