<template>
  <div class="subscription-type">
    <dashboard-page :image-header-props="imageHeaderProps">
      <template v-slot:header>
        <div class="headline-container">
          <div class="ri-large-body">{{ showBenefitTitle ? 'בחירת מינוי לסיום תקופת ההטבה' : 'בחירת מינוי'}}</div>
          <div class="ri-small-display">
            <div>איזה מינוי הכי</div>
            <div>מתאים לך?</div>
          </div>
        </div>
      </template>
      <template v-slot:dashboard>
        <subscription-type-button-annual
          @subscription-type-selected="selectType"
          :selected="type === SUBSCRIPTION_TYPES.YEARLY"
        />
      </template>
      <subscription-type-button-monthly class="margin-ml"
        @subscription-type-selected="selectType"
        :selected="type === SUBSCRIPTION_TYPES.MONTHLY"
        :disabled="mode==='update'"
      />
      <div v-if="benefitId && !redeemed && mode === 'create'" class="margin-ml margin-top-xs">
        <benefit-notification-box-v2 :flavor="flavors.subscriptionType" />
      </div>
      <subscription-terms-v2 :show-benefit-term="false" :mode="mode" />
    </dashboard-page>
  </div>
</template>

<script>
import Segment from '@/Segment';
import { mapGetters, mapMutations, mapState } from 'vuex';

import { SUBSCRIPTION_TYPES } from '@/constants/subscription-state';
import SubscriptionTypeButtonAnnual
  from '@/pages/responsive-pages/authenticated/subscription/pages/SubscriptionTypeButtonAnnual.vue';
import SubscriptionTypeButtonMonthly
  from '@/pages/responsive-pages/authenticated/subscription/pages/SubscriptionTypeButtonMonthly.vue';
import { BACKGROUND_POSITION } from '@riseupil/base-ui/src/utils/layout-consts';
import BaseUi from '@riseupil/base-ui';
import SubscriptionTermsV2 from '@/pages/responsive-pages/authenticated/subscription/V2/SubscriptionTermsV2.vue';
import BenefitNotificationBoxV2
  from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitNotificationBox2.vue';
import BenefitMixin from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitMixin.vue';

export default {
  name: 'SubscriptionTypeV2',
  components: {
    BenefitNotificationBoxV2,
    SubscriptionTermsV2,
    SubscriptionTypeButtonAnnual,
    SubscriptionTypeButtonMonthly,
    DashboardPage: BaseUi.Pages.DashboardPage,
  },
  async created() {
    Segment.trackUserGot('SubscriptionTypeEntered');
  },
  mixins: [BenefitMixin],
  props: {
    mode: {
      type: String,
      default: 'create',
    },
  },
  computed: {
    ...mapGetters('subscription', ['existingRemainingIterations']),
    ...mapState('subscription', ['type']),
    ...mapState('benefits', ['benefitId', 'redeemed']),
    SUBSCRIPTION_TYPES() {
      return SUBSCRIPTION_TYPES;
    },
    imageHeaderProps() {
      return {
        // eslint-disable-next-line global-require
        imgSrc: require('@/assets/subscription/subscription-type-header.svg'),
        backgroundColor: BaseUi.LayoutConsts.COLOR_VARIANT.BLUE,
        bottomSeparator: false,
        textColor: BaseUi.LayoutConsts.COLOR_VARIANT.WHITE,
        backgroundPosition: BACKGROUND_POSITION.BOTTOM,
      };
    },
    showBenefitTitle() {
      return (this.benefitId && !this.redeemed) || this.existingRemainingIterations;
    },
  },
  methods: {
    ...mapMutations('subscription', ['setType', 'setStep']),
    selectType({ subscriptionType }) {
      Segment.trackUserGot('SubscriptionTypeSelected', { subscriptionType });
      this.setType(subscriptionType);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../scss/mixins';

  .subscription-type {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .headline-container {
      text-align: start;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex: 1;
    }

    ::v-deep .header-card {  // the dashboard page does not currently supports the discount badge or the selected outline
      overflow: visible;
      outline: unset;

      .card-body {
        overflow: visible;
      }
    }

    ::v-deep .title.with-overlay {
      align-items: unset;
    }
  }
</style>
