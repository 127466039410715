<template>
  <popup :close-action="closeActionClicked" :buttons="buttons" class="remove-100-days-card-popup">
    <template v-slot:title>
      <div class="padding-top-ml">
        <img class="cta-image margin-top-l margin-bottom-sm" src="/images/im-on-it-100-days/remove-100-days-card.png" />
        <div class="ri-large-headline">רק רגע</div>
        <div class="margin-top-s ri-large-body">הפעולה הזו אינה הפיכה. לאחר מחיקת הכרטיסיה, אי אפשר לשחזר אותה.</div>
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import Segment from '@/Segment';

export default {
  name: 'Remove100DaysCardPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  created() {
    Segment.trackUserInteraction('Remove100DaysCardPopup_Shown');
  },
  computed: {
    buttons() {
      return [{
        title: 'המשך במחיקת הכרטיסיה',
        action: async () => {
          Segment.trackUserInteraction('Remove100DaysCardPopup_RemoveButtonClicked');
          await this.removeParticipantCFCard();
          this.$nextTick(() => {
            this.popupDismissed();
          });
        },
        variant: 'primary',
        size: 'slim',
      }, {
        title: 'ביטול',
        action: () => {
          Segment.trackUserInteraction('Remove100DaysCardPopup_CancelButtonClicked');
          this.$nextTick(() => {
            this.popupDismissed();
          });
        },
        variant: 'secondary',
        size: 'slim',
      }];
    },
  },
  methods: {
    ...mapActions('imOnIt', ['removeParticipantCFCard']),
    closeActionClicked() {
      Segment.trackUserInteraction('Remove100DaysCardPopup_CloseButtonClicked');
      this.popupDismissed();
    },
    popupDismissed() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/border-radius';

  .remove-100-days-card-popup {
    border-radius: $popup-border-radius;
    background-color: $riseup_beige;

    div {
      text-align: center;
    }

    .cta-image{
      height: 100%;
      width: 100%;
    }
  }
</style>
