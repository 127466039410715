<template>
  <div class="column-wrapper">
    <table-column color="green" :height="this.incomes.incomeHeight" text="צפי הכנסות קבועות ומשתנות"/>
    <div class="table-info">
      <div class="amount ri-medium-bold-headline">{{ this.format(totalIncome) }} +</div>
      <div class="ri-large-body">סה"כ הכנסות</div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import money from '@/utils/money';
import TableColumn from './TableColumn';

const FULL_HEIGHT = 264;
const MIN_COL_HEIGHT = 75;

export default {
  name: 'IncomeColumn',
  components: {
    TableColumn,
  },
  data() {
    return {
      incomes: {},
    };
  },
  props: {
    totalIncome: {
      type: Number,
    },
    totalExpenses: {
      type: Number,
    },
    positiveCashflow: {
      type: Boolean,
    },
  },
  created() {
    this.incomes.incomeHeight = this.positiveCashflow ? FULL_HEIGHT : _.max([(this.totalIncome / this.totalExpenses) * FULL_HEIGHT, MIN_COL_HEIGHT]);
    this.incomes.minusHeight = this.positiveCashflow ? 0 : _.max([FULL_HEIGHT - this.incomes.incomeHeight, MIN_COL_HEIGHT]);
  },
  methods: {
    format(value) {
      return money.formatAmountWithFraction(value, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.column-wrapper {
  width: 130px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .table-title {
    width: 100%;
    height: 100px;
    .line-wrapper{
      height: 50px;
      text-align: center;
    }
  }
  .table-info {
    text-align: center;

    .amount {
      margin-top: 25px;
      color: $riseup_green;
    }
  }
}
</style>
