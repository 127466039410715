<template>
  <card v-if="hasAnySavings && allSavingTransactions.upToNowAmount > 0" :has-content-padding="false">
    <div class="savings-card flex-column ri-title padding-vertical-ml" :class="{ 'out-of-date': outOfDate }">
      <span class="padding-right-ml padding-left-xl"><span class="ri-font-weight-bold">כל הכבוד!</span> מתוך ההוצאות גם הפקדת לחיסכון</span>
      <div class="ri-giant-display padding-right-ml margin-top-ml">{{ savingAmount | shekel }}</div>
    </div>
    <template v-slot:footer>
      <div class="footer padding-vertical-m ri-large-body flex-row align-center space-between pointer"
           @click="pastSavingsSummary" @keyup.enter="pastSavingsSummary" tabindex="0">
        <div class="flex-row align-center">
          <icon class="margin-left-xs" icon-name="bar_chart" fill weight="bold" size="18px" :optical-size="24"/>
          לפירוט המלא
        </div>
        <icon icon-name="chevron_left" size="24px" :optical-size="24" :color="arrowColor"/>
      </div>
    </template>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import SavingCategory from '@/mixins/SavingCategory';
import Segment from '@/Segment';

export default {
  name: 'SavingsPastMonthCard',
  components: {
    Card: BaseUI.Card,
    Icon: BaseUI.Icon,
  },
  mixins: [SavingCategory],
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    arrowColor() {
      return this.outOfDate ? 'gray' : 'primary';
    },
  },
  methods: {
    pastSavingsSummary() {
      if (!this.outOfDate) {
        Segment.trackUserInteraction('PastMonth_GoToSavingsSummaryClicked');
        this.$router.push({ name: 'MySavingsProgress' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import "~@riseupil/base-ui/src/scss/spacings";

.savings-card {
  background: url("/images/past-editable-month/savings-card.svg") no-repeat bottom left;
}
.out-of-date {
  color: $riseup_gray_2;
  .confetti-img {
    filter: grayscale(100%);
    opacity: 0.5;
  }
}

.expand-footer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: $ri-spacing-sm 0;
}

</style>
