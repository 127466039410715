<template>
  <upsell-page title="אקסלנס טרייד"
               headline="פתיחת חשבון מסחר בניירות ערך"
               cta-text="לפתיחת חשבון מסחר בכמה דקות"
               :img-src="require('@/assets/xnes/xnes-intro.svg')"
               :pageBackgroundColor="backgroundColor"
               :cta-button-props="ctaButtonProps"
               @click="nextClicked">
    <div>
      מהיום זה אפשרי לסחור באופן עצמאי – באמצעות המעטפת המקצועית והטכנולוגית של אקסלנס טרייד.
      מעטפת שמצד אחד כוללת את המערכות המתקדמות מסוגן, ומצד שני מעניקה לך גישה להדרכות חינמיות,
      לכלי מסחר מקצועיים וגם לאנשי המקצוע של אקסלנס טרייד, שיוכלו ללוות אותך בצמתי ההשקעה השונים.
    </div>
  </upsell-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';

export default {
  name: 'StepOne',
  components: {
    UpsellPage: BaseUI.Pages.UpsellPage,
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupLightBlue,
      ctaButtonProps: {
        color: 'red',
      },
    };
  },
  created() {
    Segment.trackUserGot('XnesIntro_StepOne_Entered');
    EventBus.$emit('reset-scroll');
  },
  methods: {
    nextClicked() {
      Segment.trackUserInteraction('XnesIntro_StepOne_NextClicked');
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
