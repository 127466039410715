<template>
  <Popup
    :buttons="[{
          title: 'סגירה',
          action: closeModal,
          size: 'slim',
        }]">
    <template v-slot:title>
      <div class="header">
        <lottie-player src="/animations/Check.json" :autoplay="true" />
      </div>
    </template>
    <template v-slot:content>
      <div class="content">
        <span class="content-text">
          תודה!
        </span>
        <span class="content-text">
          הפרטים עודכנו
        </span>
      </div>
    </template>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUi from '@riseupil/base-ui';

export default {
  name: 'AmbassadorIdEnteredPopUp',
  components: {
    Popup: BaseUi.Popup,
  },
  data() {
    return {
      id: '',
    };
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 27px;

  .content-text {
    text-align: center;
    font-weight: bold;
    width: 100%;
    font-size: 22px;
    line-height: 127%;
  }
}
</style>
