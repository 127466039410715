<template>
  <altshuler-signup-step :cta-props="pageCtaProps" :current-step-index="currentStepIndex" current-step-name="פרטים אישיים"
                         :total-steps="totalSteps" :wizard-state="wizardState" title="אלה הפרטים שיש לנו, צריך לוודא שהכל נכון"
                         @next="nextStep">
    <text-input class="margin-top-l" label-text="שם פרטי" autocomplete="given-name"
                v-model="firstName" :onInput="setFirstName" :error-message="firstNameError" />
    <text-input class="margin-top-m" label-text="שם משפחה" autocomplete="family-name"
                v-model="lastName" :onInput="setLastName" :error-message="lastNameError"/>
    <text-input class="margin-top-m" label-text="טלפון" autocomplete="mobile tel" v-model="phoneNumber" :onInput="setPhoneNumber" disabled/>
    <email-input class="margin-top-m" label-text="אימייל"
                 autocomplete="email"
                 :value="email"
                 :onInput="setEmailAddress"
                 :onError="setEmailError"/>
  </altshuler-signup-step>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import { mapState } from 'vuex';
import phoneNumberUtils from '@/utils/phoneNumber';
import AltshulerSignupStep from './AltshulerSignupStep';

const hebrewRegex = /^[\u0590-\u05FF-'\s]*$/;

export default {
  name: 'AltshulerSignupPersonalInfo',
  components: {
    AltshulerSignupStep,
    TextInput: BaseUI.V2Components.TextInput,
    EmailInput: BaseUI.V2Components.EmailInput,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      firstName: undefined,
      firstNameError: '',
      lastName: undefined,
      lastNameError: '',
      phoneNumber: undefined,
      email: undefined,
      emailError: undefined,
      initialValues: {},
    };
  },
  created() {
    this.firstName = this.wizardState.firstNamePiiValue ?? this.activeMember.firstNamePiiValue ?? '';
    this.lastName = this.wizardState.lastNamePiiValue ?? this.activeMember.lastNamePiiValue ?? '';
    this.phoneNumber = this.wizardState.phoneNumberPiiValue ?? phoneNumberUtils.formatPhoneNumber(this.activeMember.phoneNumberPiiValue) ?? '';
    this.email = this.wizardState.emailPiiValue ?? this.activeMember.emailPiiValue ?? '';
    this.initialValues = {
      firstName: this.firstName,
      lastName: this.lastName,
      phoneNumber: this.phoneNumber,
      email: this.email,
    };
  },
  computed: {
    ...mapState('session', ['activeMember']),
    nextDisabled() {
      const nameNotHebrew = !hebrewRegex.test(this.firstName) || !hebrewRegex.test(this.lastName);
      return nameNotHebrew
        || this.firstName.length < 2
        || this.lastName.length < 2
        || _.isEmpty(this.phoneNumber)
        || _.isEmpty(this.email)
        || this.emailError;
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    setFirstName(value) {
      if (!hebrewRegex.test(value)) {
        this.firstNameError = 'עברית בלבד';
      } else {
        this.firstNameError = '';
      }
    },
    setLastName(value) {
      if (!hebrewRegex.test(value)) {
        this.lastNameError = 'עברית בלבד';
      } else {
        this.lastNameError = '';
      }
    },
    setPhoneNumber(value) {
      this.phoneNumber = value;
    },
    setEmailAddress(value) {
      this.emailError = undefined;
      this.email = value;
    },
    setEmailError(value) {
      if (_.isEmpty(this.email)) {
        return;
      }
      this.emailError = value;
    },
    nextStep() {
      const personalInfo = {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        email: this.email,
      };
      Segment.trackUserInteraction('Altshuler_PersonalInfo_NextClicked',
        { edited: !_.isEqual(personalInfo, this.initialValues) });
      this.$emit('next', personalInfo);
    },
  },
};
</script>
