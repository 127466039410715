<template>
  <div class="slice">
    <div class="remove-slice">
      <img class="icon" :src="require('@/assets/icons/white-trash.svg')" v-if="showRemove"
           v-on:click="removeSlice( { index })">
    </div>
    <div class="slice-title" :class=" { first: index === 0 } ">
       {{this.title}}
    </div>
    <amount-input
      :label="label"
      :value="slice.amount"
      :error-message="errorMessage"
      :hint="hint"
      :readonly="isLast"
      :autofocus="isAutoFocus"
      @input="onUpdateAmount"
      @focus="showHint"
      @blur="hideHint"
    />
    <div class="slice-prompt">קטגוריה</div>
    <cashflow-category-dropdown :options="splitAvailableCategories"
                                :first-selected="slice.category || selectedCategory"
                                :on-select="onCategorySelection"/>
    <default-text-input class='slice-comment'
                :label="'הערה'"
                :value="slice.comment || ''"
                @input="onUpdateComment"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import * as _ from 'lodash';
import Segment from '@/Segment';

export default {
  name: 'SplitTransactionSlice',
  components: {
    AmountInput: BaseUI.AmountInput,
    DefaultTextInput: BaseUI.DefaultTextInput,
    CashflowCategoryDropdown: BaseUI.CashflowCategoryDropdown,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  created() {
    if (this.isLast) {
      this.showHint();
    }
  },
  computed: {
    ...mapGetters('editTransaction', ['selectedCategory', 'categoryToVariant', 'splitAvailableCategories']),
    ...mapGetters('splitTransaction', ['lastSlice', 'splitLabel']),
    ...mapState('splitTransaction', ['slices', 'actionType', 'totalAmount']),
    label() {
      return ` סכום ה${this.splitLabel}`;
    },
    title() {
      return this.slice === this.lastSlice ? 'מה שנשאר' : `${this.splitLabel} ${this.index + 1}  `;
    },
    slice() {
      return this.slices[this.index];
    },
    showRemove() {
      return this.index > 0 && this.index !== this.slices.length - 1;
    },
    isLast() {
      return this.index === this.slices.length - 1;
    },
    isAutoFocus() {
      return this.index !== 0;
    },
    maximumAllowed() {
      const maximumAmountAllowed = this.totalAmount - _.chain(this.slices)
        .reject((slice, index) => index === this.index || index === this.slices.length - 1)
        .map('amount')
        .sum()
        .value();
      return _.floor(maximumAmountAllowed, 0);
    },
  },
  data() {
    return {
      errorMessage: null,
      hint: null,
    };
  },
  methods: {
    ...mapActions('splitTransaction', ['updateSlice', 'removeSlice']),
    showHint() {
      if (this.isLast) {
        this.hint = 'אי אפשר לערוך את מה שנשאר';
      } else {
        this.hint = `אפשר לשים עד ${this.maximumAllowed} ₪`;
      }
    },
    hideHint() {
      if (!this.isLast) {
        this.hint = null;
      }
    },
    onUpdateAmount(value) {
      this.errorMessage = value > this.maximumAllowed ? 'סכום ההוצאה גבוה מדי' : null;
      if (this.errorMessage) {
        Segment.trackUserInteraction('SplitTransaction_SumTooHigh');
      }
      this.updateSlice({ index: this.index, key: 'errorMessage', value: this.errorMessage });
      this.updateSlice({ index: this.index, key: 'amount', value });
    },
    onCategorySelection(value) {
      this.updateSlice({ index: this.index, key: 'category', value });
    },
    onUpdateComment(value) {
      this.updateSlice({ index: this.index, key: 'comment', value });
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.slice {
  text-align: right;
  margin-bottom: 40px;
  .remove-slice {
    position: relative;
    .icon {
      position: absolute;
      left: -15px;
      top: -26px;
      cursor: pointer;
    }
  }
  .slice-title {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin-top: 38px;
    margin-bottom: 32px;
    &.first {
      margin-top: 14px;
    }
  }
  .slice-prompt {
    font-size: 14px;
    line-height: 18px;
    margin-top: 24px;
    margin-bottom: 2px;
  }
  .slice-comment{
    margin-top: 20px;
    font-size: 18px;
    line-height: 28px;
    .input-container {
      .input {
        border: 1px solid $riseup_black;
        height: 60px;
        border-radius: 7px;
      }
    }
  }
}

</style>
