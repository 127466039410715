<template>
  <div>
    <separator/>
    <draggable v-model="plans" handle=".drag-handle" @start="start" @end="end">
      <plan-priority-item v-for="(plan, index) in plans" :key="plan._id" :plan="plan" :is-dragging="index === draggedIndex"/>
    </draggable>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import Draggable from 'vuedraggable';
import PlanPriorityItem from './PlanPriorityItem';

export default {
  name: 'PlanPriorities',
  components: {
    Separator: BaseUI.Separator,
    PlanPriorityItem,
    Draggable,
  },
  data() {
    return {
      draggedIndex: null,
    };
  },
  props: {
    plansDraft: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    plans: {
      get() {
        return this.plansDraft;
      },
      set(value) {
        this.$emit('priorities-changed', value);
      },
    },
  },
  methods: {
    start({ oldIndex }) {
      this.draggedIndex = oldIndex;
    },
    end() {
      Segment.trackUserInteraction('PlanAhead_EditPrioritiesModal_ReorderPlan');
      this.draggedIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
