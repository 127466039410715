<template>
  <share-frame class="closed-end-of-month-asset ri-alte"
               :background-color="frameColor"
               :class="{ negative: isMonthBalanceNegative }"
               :image-url="imageUrl">
    <summary-amount-card
      :amount="balance"
      :is-preview-mode="true"
      :show-footer="isMonthBalancePositive"
      :variant="variant">
      <template v-slot:title v-if="isMonthBalancePositive">
        <div class="ri-font-50 ri-alte title-text">
          <div>מעולה!</div>
          <div><span>{{ monthName }}</span> הסתיים בתזרים חיובי</div>
        </div>
      </template>
      <template v-slot:title v-else>
        <div class="ri-font-50 ri-alte title-text ">
          <span>{{ monthName }}</span> הסתיים בתזרים שלילי</div>
      </template>
    </summary-amount-card>
  </share-frame>
</template>

<script>
import SummaryAmountCard from '@/base-components/cashflow/SummaryAmountCard';
import ShareFrame from '@/base-components/share/ShareFrame';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ClosedEndOfMonthAsset',
  components: {
    SummaryAmountCard,
    ShareFrame,
  },
  props: {
    balance: {
      type: Number,
      required: true,
    },
    monthName: {
      type: String,
      required: true,
    },
  },
  computed: {
    variant() {
      if (this.isMonthBalancePositive) {
        return 'green';
      }
      return 'red';
    },
    isMonthBalancePositive() {
      return this.balance >= 0;
    },
    isMonthBalanceNegative() {
      return !this.isMonthBalancePositive;
    },
    imageUrl() {
      return this.isMonthBalancePositive ? 'past-editable-month/share-positive-cf.svg' : 'past-editable-month/share-negative-cf.svg';
    },
    frameColor() {
      return this.isMonthBalancePositive ? BaseUI.Colors.riseupYellow : BaseUI.Colors.riseupLightOrange;
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/typography';

.closed-end-of-month-asset {
  width: 600px;

  .title-text {
    width: 90%;
    color: $riseup_dark_green;
    line-height: 46px;
    font-weight: 900;
  }
  .summary-amount-card {
    position: relative;
    box-shadow: none;
    padding: 20px;

    .content.card-padding {
      padding: 30px;
    }

    .card-content {
      padding-bottom: 0;
      .title {
        font-size: 34px;
        line-height: 42px;
      }
      .amount {
        margin-top: 50px;
        font-size: 90px;
        &.long-number {
          font-size: 80px;
        }
      }
    }

    .separator {
      display: none;
    }

    .footer {
      padding: 0;
      height: 0;
      .right-img {
        position: absolute;
        right: 0;
        bottom: -1px;
      }
      .left-img {
        position: absolute;;
        left: -1px;
        bottom: 0;
      }
    }
  }
}
</style>
