<template>
  <altshuler-signup-step :cta-props="pageCtaProps" :current-step-index="currentStepIndex" current-step-name="העלאת תעודה מזהה"
                         :total-steps="totalSteps" :wizard-state="wizardState" title="איזו תעודה יש לך?"  @next="nextStep">

    <div class="margin-bottom-ml ri-large-body">
      בשביל לפתוח פיקדון, צריך להעלות תעודה מזהה
    </div>
    <selection-button :selected="this.idType === 'legacyId'"
                   @select="selectType('legacyId')"
                   title="תעודת זהות רגילה"
                   :icon-props="{ iconName: 'badge', fill: true, weight: 'bold' }"
                  class="margin-bottom-s"/>

    <selection-button :selected="this.idType === 'biometricId'"
                   @select="selectType('biometricId')"
                   title="תעודת זהות ביומטרית"
                   :icon-props="{ iconName: 'fingerprint', fill: true, weight: 'bold' }"
                   class="margin-bottom-s"/>

    <selection-button :selected="this.idType === 'passport'"
                   @select="selectType('passport')"
                   title="דרכון"
                   :icon-props="{ iconName: 'flight', fill: true, weight: 'bold' }"
                   class="margin-bottom-s"/>

    <selection-button :selected="this.idType === 'driversLicense'"
                   @select="selectType('driversLicense')"
                   title="רשיון נהיגה"
                   :icon-props="{ iconName: 'directions_car', fill: true, weight: 'bold' }"/>

  </altshuler-signup-step>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import AltshulerSignupStep from './AltshulerSignupStep';

export default {
  name: 'AltshulerSignupIdTypeSelection',
  components: {
    AltshulerSignupStep,
    SelectionButton: BaseUI.SelectionButton,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      idType: undefined,
    };
  },
  created() {
    this.idType = this.wizardState.idDocument?.idType;
  },
  computed: {
    nextDisabled() {
      return _.isEmpty(this.idType);
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    selectType(value) {
      Segment.trackUserInteraction('Altshuler_IdTypeSelection_Selected', { type: value });
      this.idType = value;
      this.nextStep();
    },
    nextStep() {
      const existingSelection = this.wizardState.idDocument?.idType;
      const typeChanged = existingSelection !== this.idType;
      Segment.trackUserInteraction('Altshuler_IdTypeSelection_NextClicked', { edited: existingSelection && typeChanged });
      this.$emit('next', {
        idDocument: {
          idType: this.idType,
          idImagePathFront: typeChanged ? '' : this.wizardState.idDocument.idImagePathFront,
          idImagePathBack: typeChanged ? '' : this.wizardState.idDocument.idImagePathBack,
          idImagePath: typeChanged ? '' : this.wizardState.idDocument.idImagePath,
        },
      });
    },
  },
};
</script>
