export default {
  circleSize: {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
  },
  circleType: {
    EXPENSE: 'expense',
    INCOME: 'income',
  },
  circleColor: {
    LARGE_EXPENSE: 'large-expense',
    MEDIUM_EXPENSE: 'medium-expense',
    SMALL_EXPENSE: 'small-expense',
    LARGE_INCOME: 'large-income',
    MEDIUM_INCOME: 'medium-income',
    SMALL_INCOME: 'small-income',
  },
};
