<template>
  <next-steps-base-card title="למה כדאי לשים לב כשהתזרים שלילי"
                        button-text="לקריאת המאמר"
                        description="5 דקות, מאמר"
                        :action-key="actionKey"
                        @click="openBlogPost"/>
</template>

<script>
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';

export default {
  name: 'NegativeCashflowBlogPostNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.NEGATIVE_CASHFLOW_BLOG_POST,
    };
  },
  methods: {
    openBlogPost() {
      console.log('NegativeCashflowBlogPostNextStep: NOT IMPLEMENTED');
    },
  },
};
</script>

<style scoped>

</style>
