<template>
  <div class="about-dynamic-saving-popup">
    <popup :close-action="closeActionClicked" :buttons="buttons" class="subscription-cta-popup">
      <template v-slot:title>
        <div class="title-content">
          <lottie-player :src="JSON.stringify(require('@/assets/dynamic-deposit/celebrate.json'))" :autoplay="true" :loop="true" class="animation"/>
          <div class="title-text">
            החיסכון הדינמי של רייזאפ מתחיל לעבוד בשבילך
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div class="content">
          המערכת שלנו לומדת את הנתונים שלך וברגע שנזהה הזדמנות להתחיל לחסוך או לחסוך יותר - נעדכן.
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions } from 'vuex';

export default {
  name: 'AboutDynamicSavingPopUp',
  components: {
    Popup: BaseUI.Popup,
  },

  computed: {
    buttons() {
      return [{
        title: 'תעדכנו אותי כשאפשר לחסוך!',
        action: this.confirm,
        variant: 'primary',
        size: 'slim',
      }];
    },
  },
  created() {
    Segment.trackUserInteraction('AboutDynamicSavingPopup_Entered');
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    closeActionClicked() {
      Segment.trackUserInteraction('AboutDynamicSavingPopup_CloseButtonClicked');
      this.closeModal();
    },
    confirm() {
      Segment.trackUserInteraction('AboutDynamicSavingPopup_Confirmed');
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.about-dynamic-saving-popup {
  .title-content {
    text-align: center;

    .title-text {
      margin-top: -45px;
      font-size: 24px;
      line-height: 32px;
    }

    .animation {
      margin-top: -44px;
      width: 95%;
    }
  }

  .content {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .more-details {
    cursor: pointer;
  }
}

</style>
