<template>
  <share-frame class="expense-summary-asset">
    <expense-summary-core :presented-month="presentedMonth" :all-expenses="allExpenses">
    </expense-summary-core>
  </share-frame>
</template>

<script>
import ExpenseSummaryCore
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/expense-summary/ExpenseSummaryCore';
import ShareFrame from '@/base-components/share/ShareFrame';

export default {
  name: 'ExpenseSummaryAsset',
  components: {
    ExpenseSummaryCore,
    ShareFrame,
  },
  props: {
    allExpenses: {
      type: Array,
      required: true,
    },
    presentedMonth: {
      type: String,
      required: true,
    },
  },
};

</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.expense-summary-asset {
  width: 500px;

  .expense-summary-core {
    background-color: $riseup_white;
    padding: 24px;

    .middle-content .rows .row .text {
      width: 190px;
    }

    .empty {
      display: none;
    }

    .disclaimer {
      display: none;
    }

    .header .small-title {
      display: block;
    }
  }
}

</style>
