<template>
  <altshuler-signup-step :cta-props="pageCtaProps" :current-step-index="currentStepIndex" current-step-name="פרטים אישיים"
                         :total-steps="totalSteps" :wizard-state="wizardState" title="צריך למלא את הפרטים האלה כמו בתעודה שהעלת"
                         @next="nextStep">
    <text-input class="margin-top-l" placeholder="מספר תעודת זהות" autocomplete="id-number" :value="idNumber"
                :onInput="setSocialIdNumber" inputType="tel" :error-message="socialIdError" />

    <div class="ri-large-bold-body margin-bottom-s margin-top-xs">
      כתובת מלאה
    </div>
    <autocomplete-input placeholder="יישוב" :options="cityNames" @change="setCity" :start-value="city"/>
    <text-input class="margin-top-ml" placeholder="רחוב ומספר בית" autocomplete="street" :value="address" :onInput="setAddress" />
    <div class="ri-large-bold-body margin-top-xs">שם מלא באנגלית</div>
    <text-input class="margin-top-s" placeholder="שם באנגלית" v-model="englishName"
                :on-input="setEnglishName" :error-message="englishNameError" />
  </altshuler-signup-step>
</template>

<script>
import Segment from '@/Segment';
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import { mapState } from 'vuex';
import { isIsraeliIdValid } from '@/rules/validators';
import AltshulerSignupStep from './AltshulerSignupStep';

const englishCharsRegex = /^[a-zA-Z-'.\s]*$/;
// At least 2 words (2 chars at least), only english letters, ".", "-" and "'" allowed.
const englishNameRegex = /^[a-zA-Z-'.]{2,}\s[a-zA-Z-'.]{2,}[a-zA-Z-'.\s]*$/;

export default {
  name: 'AltshulerSignupIdInputs',
  components: {
    AltshulerSignupStep,
    TextInput: BaseUI.V2Components.TextInput,
    AutocompleteInput: BaseUI.V2Components.AutocompleteInput,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      city: '',
      address: '',
      idNumber: '',
      englishName: '',
      englishNameError: undefined,
    };
  },
  created() {
    this.city = this.wizardState.city ?? '';
    this.address = this.wizardState.addressPiiValue ?? '';
    this.idNumber = this.wizardState.idNumberPiiValue ?? '';
    this.englishName = this.wizardState.englishNamePiiValue ?? '';
  },
  computed: {
    ...mapState('cities', ['cityNames']),
    nextDisabled() {
      return !!this.socialIdError
        || this.city.length < 2
        || this.address.length < 2
        || !!this.englishNameError
        || this.englishName.length < 3;
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
    socialIdError() {
      if (_.isEmpty(this.idNumber)) {
        return undefined;
      }
      if (this.idNumber.length < 9) {
        return 'תעודת הזהות חייבת להכיל 9 ספרות';
      }
      if (!isIsraeliIdValid(this.idNumber)) {
        return 'תעודת זהות שגויה';
      }
      return undefined;
    },
  },
  methods: {
    setSocialIdNumber(value) {
      this.idNumber = value;
    },
    setCity(value) {
      this.city = value;
    },
    setAddress(value) {
      this.address = value;
    },
    setEnglishName(value) {
      if (_.isEmpty(value)) {
        this.englishNameError = undefined;
        return;
      }
      if (!englishCharsRegex.test(value)) {
        this.englishNameError = 'השם חייב להיות באנגלית';
      } else if (!englishNameRegex.test(value)) {
        this.englishNameError = 'השם המלא לא תקין';
      } else {
        this.englishNameError = undefined;
      }
    },
    nextStep() {
      const info = {
        city: this.city,
        address: this.address,
        idNumber: this.idNumber,
        englishName: this.englishName,
      };
      Segment.trackUserInteraction('Altshuler_IdInputs_NextClicked');
      this.$emit('next', info);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
