<template>
  <div class="monthly-table">
    <div class="monthly-sub-title ri-medium-headline">
      <span>{{subTitle1}}</span>
      <span class="ri-medium-bold-headline">{{subtitleAmount}}</span>
      <span>{{subTitle2}}</span>
    </div>
    <div class="tables">
      <expense-column
        :total-income="totalIncome"
        :total-expenses="totalExpenses"
        :fixed-expenses="fixedExpenses"
        :variable-expenses="variableExpenses"
        :tracked-expenses="trackedExpenses"
        :positive-cashflow="positiveCashflow" />
      <income-column
        :total-income="totalIncome"
        :total-expenses="totalExpenses"
        :positive-cashflow="positiveCashflow" />
    </div>
  </div>
</template>
<script>
import money from '@/utils/money';
import CashflowAnalytics from '../../../../generic-components/CashflowAnalytics.vue';
import IncomeColumn from './IncomeColumn';
import ExpenseColumn from './ExpenseColumn';

export default {
  name: 'MonthlyTable',
  components: {
    IncomeColumn,
    ExpenseColumn,
  },
  mixins: [CashflowAnalytics],
  props: {
    totalIncome: {
      type: Number,
    },
    totalExpenses: {
      type: Number,
    },
    fixedExpenses: {
      type: Number,
    },
    trackedExpenses: {
      type: Number,
    },
    variableExpenses: {
      type: Number,
    },
  },
  computed: {
    positiveCashflow() {
      return this.totalIncome >= this.totalExpenses;
    },
    diff() {
      return this.totalIncome - this.totalExpenses;
    },
    subTitle1() {
      return this.positiveCashflow ? 'החודש נשאר להוציא עד ' : 'נראה שהחודש צפוי להסתיים בתזרים שלילי של';
    },
    subTitle2() {
      return this.positiveCashflow ? 'כדי לסיים בתזרים חיובי' : '';
    },
    subtitleAmount() {
      return this.positiveCashflow ? `${this.format(this.diff)} ₪` : `${this.format((this.diff))} ₪ -`;
    },
  },
  methods: {
    format(value) {
      return money.formatAmountWithFraction(value, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.monthly-table {
  color: $riseup_dark_green;

  .monthly-title {
    margin-bottom: 10px;
    padding-right: 10px;
    text-align: center;
  }

  .monthly-sub-title {
    margin-top: 16px;
    padding-right: 10px;
    padding-bottom: 40px;
    text-align: center;
  }

  .tables {
    display: flex;
    justify-content: center;
  }

}
</style>
