<template>
  <div class="subscription-terms-v2 margin-horizontal-ml">

    <div v-if="benefitId && !redeemed && showBenefitTerm" class="ri-label">
      <div class="margin-vertical-sm" v-if="isGenericFreeMonthBenefit">
        <span>המינוי יתחיל בסיום ההטבה: </span>
        <span class="ri-bold-label">{{ monthString }} בחינם,</span>
        <span> ולאחר מכן {{ subscriptionTypeText }} <span class="no-break">{{ currentPrice }} ₪.</span></span>
      </div>
      <div v-else class="margin-vertical-sm">
        <span class="ri-bold-label">ההטבה היא ל{{benefitAmount > 2 ? '־' : ''}}{{ monthsText }},</span>
        <span> ולאחר מכן {{ subscriptionTypeText }} <span class="no-break">{{ currentPrice }} ₪.</span></span>
      </div>
      <separator />
    </div>

    <div v-if="isYearlySubscriptionSelected && mode === 'update'" class="ri-label">
      <div class="margin-vertical-sm" >{{ currentText }}</div>
      <separator />
    </div>

    <div v-if="isYearlySubscriptionSelected" class="ri-label">
      <div class="margin-vertical-sm" >המינוי יתחדש ב־{{ nextYearlyRenewalDate }} (נשלח לך תזכורת לפני).</div>
      <separator />
    </div>
    <div class="ri-label">
      <div class="margin-vertical-sm">
        <span>אפשר לבטל את המינוי בכל עת ולקבל החזר יחסי.</span>
        <span class="blue link" @click="openTerms">לתנאים המלאים.</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import BenefitMixin from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitMixin.vue';
import { mapGetters, mapState } from 'vuex';
import BaseUi from '@riseupil/base-ui';
import { SUBSCRIPTION_TYPES } from '@/constants/subscription-state';

export default {
  name: 'SubscriptionTermsV2',
  components: {
    Separator: BaseUi.Separator,
  },
  mixins: [
    BenefitMixin,
  ],
  props: {
    showBenefitTerm: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: 'create',
    },
  },
  computed: {
    ...mapState('benefits', ['benefitId', 'benefitAmount', 'redeemed']),
    ...mapState('subscription', ['pricing', 'type']),
    ...mapGetters('subscription', ['formattedFirstBillingDatePlusYear', 'formattedFirstBillingDate',
      'existingRemainingIterations', 'nextFormattedPaymentDate']),
    ...mapGetters('benefits', ['monthsText']),
    isYearlySubscriptionSelected() {
      return this.type === SUBSCRIPTION_TYPES.YEARLY;
    },
    subscriptionTypeText() {
      return this.isYearlySubscriptionSelected
        ? 'תשלום שנתי אחד של'
        : 'תשלום חודשי של';
    },
    nextYearlyRenewalDate() {
      if (this.mode === 'create') {
        return this.isPercentageDiscountBenefit
          ? moment(this.formattedFirstBillingDatePlusYear, 'D.M.YYYY').add(this.benefitAmount, 'month').format('D.M.YYYY')
          : this.formattedFirstBillingDatePlusYear;
      }
      // mode === 'update'
      return moment(this.nextFormattedPaymentDate, 'D.M.YYYY').add(1, 'year').format('D.M.YYYY');
    },
    currentPrice() {
      return this.isYearlySubscriptionSelected
        ? this.pricing.annual
        : this.pricing.monthly;
    },
    currentText() {
      return this.existingRemainingIterations
        ? `המינוי השנתי יתחיל ב־${this.nextFormattedPaymentDate}, אחרי סיום ההטבה שלך.`
        : `המינוי השנתי יתחיל ב־${this.nextFormattedPaymentDate}, במועד החיוב הבא שלך`;
    },
  },
  methods: {
    openTerms() {
      window.open('https://www.riseup.co.il/termsofuse/', '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.subscription-terms-v2 {
  .blue {
    color: $riseup_blue;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  .no-break {
    white-space: nowrap;
  }
}
</style>
