<template>
  <div class="closed-end-of-month" :class="{ 'out-of-date': outOfDate }">
    <summary-amount-card
        :previous-month="true"
        :out-of-date="outOfDate"
        :amount="balance"
        :variant="variant"
        :show-footer="true"
        :share-button-props="shareButtonProps">
      <template v-slot:title>
        <div v-if="isMonthBalancePositive">
          <div>מעולה!</div>
          <div><span>{{ monthFullName }}</span> הסתיים בתזרים חיובי</div>
        </div>
        <div v-else>{{ monthFullName }} הסתיים בתזרים שלילי</div>
      </template>
      <template v-slot:footer>
        <div class="flex-column ri-label margin-ml">
          <div class="flex-row space-between margin-bottom-xs">
            <div>סה"כ הכנסות</div>
            <div class="amount" :class="{ 'positive' : isMonthBalancePositive }">{{ formattedTotalIncome }} ₪ +</div>
          </div>
          <div class="flex-row space-between">
            <div>סה"כ הוצאות</div>
            <div class="amount" :class="{ 'negative' : isMonthBalanceNegative }">{{ formattedTotalExpense }} ₪ -</div>
          </div>
        </div>
        <separator/>
        <div class="padding-vertical-m padding-horizontal-ml ri-large-body flex-row align-center space-between pointer"
             @click="pastMonthsSummary" @keyup.enter="pastMonthsSummary" tabindex="0">
          <div class="flex-row align-center">
            <icon class="margin-left-xs" icon-name="chart_data" v-bind="iconProps"/>
            ההשפעה על ההתקדמות שלי
          </div>
          <icon icon-name="chevron_left" size="24px" :optical-size="24"/>
        </div>
      </template>
    </summary-amount-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SummaryAmountCard from '@/base-components/cashflow/SummaryAmountCard';
import moneyUtils from '@/utils/money';
import cashflowHistory from '@/store/utilities/cashflow-history';
import ClosedEndOfMonthAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/past-editable-month/components/ClosedEndOfMonthAsset';
import ExpenseSummary
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/expense-summary/ExpenseSummary';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'ClosedEndOfMonth',
  components: {
    SummaryAmountCard,
    Icon: BaseUI.Icon,
    Separator: BaseUI.Separator,
  },
  props: {
    outOfDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('cashflowView', ['presentedBudgetDate', 'cashflow']),
    ...mapGetters('cashflowView', ['monthFullName', 'balance', 'budgetUpdatedAt', 'totalIncome', 'totalExpense']),
    iconProps() {
      return {
        weight: 'bold',
        size: '18px',
        opticalSize: 24,
        fill: true,
      };
    },
    variant() {
      if (this.isMonthBalancePositive) {
        return 'green';
      }
      return 'red';
    },
    formattedTotalIncome() {
      return moneyUtils.formatAmountWithFraction(this.totalIncome, 0);
    },
    formattedTotalExpense() {
      return moneyUtils.formatAmountWithFraction(this.totalExpense, 0);
    },
    isMonthBalancePositive() {
      return this.balance >= 0;
    },
    isMonthBalanceNegative() {
      return !this.isMonthBalancePositive;
    },
    shareComponent() {
      return ClosedEndOfMonthAsset;
    },
    shareComponentProps() {
      return {
        balance: this.balance,
        monthName: cashflowHistory.getFullMonthName(this.presentedBudgetDate),
      };
    },
    shareButtonProps() {
      return {
        shareComponent: this.shareComponent,
        shareComponentProps: this.shareComponentProps,
        shareName: 'ShareEomOneNumber',
        filename: 'month-number',
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openExpenseSummary() {
      this.openModal({
        component: ExpenseSummary,
        props: {
          initialBudgetDate: this.presentedBudgetDate,
        },
      });
    },
    pastMonthsSummary() {
      if (!this.outOfDate) {
        Segment.trackUserInteraction('PastMonth_GoToMyProgressClicked');
        this.$router.push({ name: 'MyProgress' });
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/riseup-vars';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '@/scss/mixins';

.closed-end-of-month {

  .positive {
    color: $riseup_green;
    font-weight: bold;
  }

  .negative {
    color: $riseup_danger_red;
    font-weight: bold;
  }

  &.out-of-date {
    color: $riseup_gray_2;
    .negative, .positive {
      color: $riseup_gray_2;
    }
  }

  .footer {
    padding: 0;
    .summary-expense-button {
      padding: $card_padding;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
</style>
