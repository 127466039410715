<template>
  <popup :close-action="() => $emit('close')" title="הקמת הרשאה לחיוב חשבון">
    <template v-slot:content>
      <div class="margin-bottom-ml ri-body">
        אם רוצים, אפשר להגביל את הסכום או התאריך להעברת הכספים אל הפיקדון החדש שיפתח
      </div>
      <div class="ri-large-bold-body">הרשאה עם הגבלת הסכום</div>
      <amount-input
        class="margin-top-xs margin-bottom-xl"
        label="סכום מקסימלי להפקדה"
        :value="limitSum.value"
        :error-message="limitSum.error"
        @input="setSumLimit"
        :autofocus="false"
      />
      <div class="ri-large-bold-body">הרשאה עם הגבלת תאריך</div>
      <div class="date-inputs margin-top-xs">
        <default-text-input style="flex: 2" label="שנה" v-model="limitDate.year" @input="setYear"
                            :error-message="limitDate.yearError || generalDateError" :max-length="4"/>
        <default-text-input style="flex: 1.3" label="חודש" v-model="limitDate.month" @input="setMonth"
                            :error-message="limitDate.monthError" :has-error="!!generalDateError" :max-length="2"/>
        <default-text-input style="flex: 1" label="יום" v-model="limitDate.day" @input="setDay"
                            :error-message="limitDate.dayError" :has-error="!!generalDateError" :max-length="2"/>
      </div>
      <riseup-button title="סיימתי" @click="onSave" :disabled="isSaveDisabled"/>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import moment from 'moment';
import { DEPOSIT_MINIMUM_AMOUNT } from './altshuler.consts';

export default {
  name: 'LimitApprovalScopePopup',
  components: {
    Popup: BaseUI.Popup,
    AmountInput: BaseUI.AmountInput,
    DefaultTextInput: BaseUI.DefaultTextInput,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    sumLimit: {
      type: Number,
      required: false,
    },
    dateLimit: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      limitSum: {
        value: undefined,
        error: undefined,
      },
      limitDate: {
        dateError: undefined,
        year: '',
        yearError: undefined,
        month: '',
        monthError: undefined,
        day: '',
        dayError: undefined,
      },
    };
  },
  created() {
    if (this.dateLimit) {
      const momentLimitDate = moment(this.dateLimit, 'D.M.YYYY');
      this.limitDate.year = momentLimitDate.format('YYYY');
      this.limitDate.month = momentLimitDate.format('M');
      this.limitDate.day = momentLimitDate.format('D');
    }
    if (this.sumLimit) {
      this.limitSum.value = this.sumLimit;
    }
  },
  methods: {
    setSumLimit(value) {
      if (_.isNil(value)) {
        this.limitSum.error = undefined;
        this.limitSum.value = undefined;
        return;
      }
      this.limitSum.value = value;
      if (value < DEPOSIT_MINIMUM_AMOUNT) {
        this.limitSum.error = `סכום ההפקדה המינימלי הוא ${DEPOSIT_MINIMUM_AMOUNT} ₪`;
      } else {
        this.limitSum.error = undefined;
      }
    },
    setYear(value) {
      if (_.isNil(value) || _.isEmpty(value)) {
        this.limitDate.year = '';
        this.limitDate.yearError = undefined;
        return;
      }
      if (!/^\d+$/.test(value)) {
        this.limitDate.yearError = 'ספרות בלבד';
      } else if (_.toInteger(value) < 2023) {
        this.limitDate.yearError = 'שנה לא תקינה';
      } else {
        this.limitDate.yearError = undefined;
        this.limitDate.year = value;
      }
    },
    setMonth(value) {
      if (_.isNil(value) || _.isEmpty(value) || value === '0') {
        this.limitDate.month = '';
        this.limitDate.monthError = undefined;
        return;
      }
      if (!/^\d+$/.test(value)) {
        this.limitDate.monthError = 'ספרות בלבד';
      } else if (_.toInteger(value) < 1 || _.toInteger(value) > 12) {
        this.limitDate.monthError = 'חודש לא תקין';
      } else {
        this.limitDate.monthError = undefined;
        this.limitDate.month = value;
      }
    },
    setDay(value) {
      if (_.isNil(value) || _.isEmpty(value) || value === '0') {
        this.limitDate.day = '';
        this.limitDate.dayError = undefined;
        return;
      }
      if (!/^\d+$/.test(value)) {
        this.limitDate.dayError = 'ספרות בלבד';
      } else if (_.toInteger(value) < 1 || _.toInteger(value) > 31) {
        this.limitDate.dayError = 'יום לא תקין';
      } else {
        this.limitDate.dayError = undefined;
        this.limitDate.day = value;
      }
    },
    onSave() {
      const limitSum = this.limitSum.value ? this.limitSum.value : undefined;
      const limitDate = this.dateValid ? `${this.limitDate.day}.${this.limitDate.month}.${this.limitDate.year}` : undefined;
      this.$emit('save', { limitSum, limitDate });
      this.$emit('close');
    },
  },
  computed: {
    dateValid() {
      const date = moment(`${this.limitDate.year}-${this.limitDate.month}-${this.limitDate.day}`, 'YYYY-MM-DD');
      return date.isValid() && date.isAfter(moment());
    },
    isSaveDisabled() {
      if (this.limitDate.day || this.limitDate.month || this.limitDate.year) {
        if (!this.dateValid || !this.limitDate.day || !this.limitDate.month || !this.limitDate.year) {
          return true;
        }
      }
      if (this.limitSum.value) {
        return !!this.limitSum.error;
      }
      return false;
    },
    generalDateError() {
      const emptyFields = !this.limitDate.day || !this.limitDate.month || !this.limitDate.year;
      const hasError = !!this.limitDate.dayError || !!this.limitDate.monthError || !!this.limitDate.yearError;
      if (emptyFields || hasError || this.dateValid) {
        return undefined;
      }
      return 'תאריך לא תקין';
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/spacings';

.date-inputs {
  display: flex;
  flex-direction: row;
  column-gap: $ri-spacing-xs;
  margin-bottom: $ri-spacing-l;
}
</style>
