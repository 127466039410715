<template>
  <div class="tracking-category-creation-section" @click="openPopup" id="tracking-category-creation-section">
    <img class="plus-image" src="/images/onboarding/icons/icon-add.svg">
    <div class="title">הוספת קטגוריה למעקב</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '../../../../../../../../../../Segment';
import CreateTrackingCategoryPopupFlow from './CreateTrackingCategoryPopupFlow.vue';

export default {
  name: 'TrackingCategoryCreationSection',
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openPopup() {
      Segment.trackUserInteraction('CreateTrackingCategory_Clicked');
      this.openModal({
        component: CreateTrackingCategoryPopupFlow,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/helpers';
  @import '~@riseupil/base-ui/src/scss/border-radius';
  @import 'src/scss/mixins';

  .tracking-category-creation-section {
    margin-bottom: 24px;
    padding: 17px 18px;
    display: flex;
    width: 100%;
    border: dashed 2px #dce3f5; // Color here is hardcoded because it is our riseup_blue with opacity. No opacity can be specified on border.
    border-radius: $card-border-radius;
    background-color: $riseup_white;
    cursor: pointer;

    .plus-image {
      margin-left: 13px;
      height: 25px;
    }

    .title {
      @include width-100-to-avoid-text-wrapping;
      font-size: 20px;
      line-height: 1.25em;
      color: $riseup_blue;
    }
  }
</style>
