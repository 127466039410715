<template>
  <div class="subscription-type-button" :class="{ 'selected': this.selected, 'disabled': this.selectionDisabled }" v-on:click="onSelect">
    <div class="extra" v-if="extraText">
      <div class="extra-text  padding-horizontal-s padding-vertical-xxs ri-bold-label"> {{ extraText }} </div>
    </div>
    <div class="content-container padding-horizontal-ml">
      <div class="right">
        <div class="main-text margin-bottom-xs"> {{ mainText }} </div>
        <div class="sub-text ri-font-weight-bold"> {{ subText }} </div>
      </div>
      <div class="left">
        <div class="price ri-font-weight-bold margin-bottom-xs">
          <slot name="price"></slot>
        </div>
        <div class="sub-price">
          <slot name="sub-price"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Segment from '@/Segment';
import { SUBSCRIPTION_TYPES } from '@/constants/subscription-state';

export default {
  name: 'SubscriptionTypeButton',
  props: {
    subscriptionType: {
      type: String,
      validator: val => _.values(SUBSCRIPTION_TYPES).includes(val),
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
    mainText: {
      type: String,
      required: true,
    },
    subText: {
      type: String,
      required: true,
    },
    extraText: {
      type: String,
      required: false,
    },
    selectionDisabled: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['subscription-type-selected'],
  methods: {
    onSelect() {
      if (!this.selectionDisabled) {
        const typeObj = { subscriptionType: this.subscriptionType };
        Segment.trackUserGot('SubscriptionTypeButton_Selected', typeObj);
        this.$emit('subscription-type-selected', typeObj);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../scss/mixins';

  .subscription-type-button {
    height: 106px;
    cursor: pointer;
    background-color: $riseup-white;
    border-radius: 18px;
    border: unset;
    outline: 2px solid $riseup_gray_10;

    &.selected {
      outline: 2px solid $riseup_blue;
      color: $riseup-black;
    }

    &.disabled {
      cursor: default;
      color: $riseup_gray_60;
      .content-container .right .sub-text {
        color: $riseup_gray_60;
      }
    }

    .extra {
      width: 0;
      height: 0;

      .extra-text {
        position: absolute;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 30px;
        top: -15px;
        left: 24px;
        background: $riseup_green;
        color: $riseup_white;
        border-radius: 8px;
      }
    }

    .content-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      line-height: 1.3;

      .right {
        .main-text {
          font-size: 22px;
          font-weight: bold;
        }

        .sub-text {
          font-size: 16px;
          text-align: start;
          color: $riseup_blue;
        }
      }

      .left {
        text-align: end;
        .prices {
          display: flex;
          .price {
            font-size: 22px;
            font-weight: bold;
          }
          .was {
            font-size: 22px;
            opacity: 60%;
          }
        }
        .sub-price {
          font-size: 16px;
          color: $riseup_gray_60;
        }
      }
    }
  }
</style>
