<template>
  <div class="month-review">
    <div class="step-header">
      <div class="title"> {{ title }} </div>
      <div class="amount" :class="amountColor"><span class="currency-sign">₪</span>{{ formattedAmount }}</div>
      <div class="explanation">
          <slot name="step-explanation"></slot>
      </div>
    </div>
    <div class="step-content">
      <slot name="step-content"></slot>
    </div>
    <div class="footer">
      <riseup-button :title="buttonTitle" :action="buttonAction" variant="primary"/>
    </div>
  </div>
</template>
<script>

import BaseUI from '@riseupil/base-ui';
import monthReviewConsts from '@/constants/month-review';
import moneyUtils from '../../../../../utils/money';

const INCOME_COLOR = 'income-color';
const EXPENSE_COLOR = 'expense-color';

export default {
  name: 'MonthReview',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    stepName: {
      type: String,
      required: true,
    },
    explanationComponent: {
      type: Object,
    },
    title: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    buttonTitle: {
      type: String,
      required: true,
    },
    buttonAction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmount(this.amount);
    },
    amountColor() {
      switch (this.stepName) {
      case monthReviewConsts.stepNames.expenses:
        return EXPENSE_COLOR;
      case monthReviewConsts.stepNames.incomes:
        return INCOME_COLOR;
      case monthReviewConsts.stepNames.summary:
        return this.amount >= 0 ? INCOME_COLOR : EXPENSE_COLOR;
      default:
        return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  @keyframes fade
  {
    from {opacity: 0;}
    to { opacity: 1;}
  }

  @keyframes enter
  {
    from {top: 100px;}
    to { top: 0;}
  }

  .month-review {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

      .step-content {
        flex-grow: 1;
      }

      .step-header {
        margin: 40px 30px 40px;
        font-family: SimplerPro;
        color: $riseup_black;
        text-align: center;

        .title {
          font-size: 24px;
          font-weight: bold;
          animation: fade 0.8s ease-in;
          line-height: 1.4;
        }

        .income-color {
          color: $riseup_green;
        }

        .expense-color {
          color: $riseup_dark_red;
        }

        .amount {
          font-size: 48px;
          animation: fade 0.8s ease-in;
          margin: 10px 0;
          direction: ltr;
          text-align: center;
          .currency-sign{
            font-size: 24px;
          }
        }

        .explanation {
          margin: 15px 0;
          font-size: 18px;
          line-height: 18px;
          position: relative;
          animation: fade 1s ease-in;
        }
      }
    }

    .footer {
      bottom: 0;
      background-color: $riseup_white;
      padding: 20px 24px;

      ::v-deep .riseup-button {
        height: 54px;
        width: 100%;
        .button-titles {
          .button-title{
            font-size: 18px;
          }
        }
      }
    }

</style>
