<template>
  <snack-bar class="failed-payment-bar" :variant="'danger'">
    <template v-slot:primary>
      <div class="bold">חיוב המינוי נכשל</div>
    </template>
    <template v-slot:secondary>
      <div class="cta underline" v-on:click="onClick">לעדכון אמצעי תשלום</div>
    </template>
  </snack-bar>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import router from '@/router';
import UpdateCreditCardHandler
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/UpdateCreditCardHandler';

const CTA_TYPES = {
  goToSettings: 'go-to-settings',
  openCardUpdate: 'open-card-update',
};

export default {
  name: 'FailedPaymentBar',
  mixins: [UpdateCreditCardHandler],
  components: {
    SnackBar: BaseUI.SnackBar,
  },
  props: {
    ctaType: {
      type: String,
      validator: val => _.values(CTA_TYPES).includes(val),
    },
  },
  methods: {
    onClick() {
      if (this.ctaType === CTA_TYPES.goToSettings) {
        this.goToAccountSettings();
        return;
      }
      this.openUpdateCreditCardPopup();
    },
    goToAccountSettings() {
      router.push({ path: '/account-settings', query: this.$route.query });
    },
  },
};

</script>

<style scoped lang="scss">
  @import '../../../../../../scss/mixins';

  .failed-payment-bar {
    @include responsive-padding;

    .bold {
      font-weight: bold;
    }

    .cta {
      color: $riseup_white;
      cursor: pointer;

      &.underline {
        text-decoration: underline;
      }
    }

  }
</style>
