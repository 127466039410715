<template>
  <div class="step">
    <img class="step-image" :src="require(`@/assets/ambassadors/step${number}.svg`)"/>
    <div class="description">
      <span class="ri-body">{{description}}</span>
      <span v-if="link" class="link ri-body" @click="link.copyToClipboard">{{link.description}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step',
  props: {
    number: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    link: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.step {
  display: flex;
  flex-direction: column;

  .step-image {
    width: 32px;
    height: 32px;
    margin-bottom: 7px;
  }

  .description {
    .link {
      cursor: pointer;
      color: $riseup_blue;
      text-decoration: underline;
    }
  }
}
</style>
