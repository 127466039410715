<template>
  <share-frame class="growing-months-asset" :background-color="backgroundColor" logoColor="white">
    <growing-months-core :months="months" :household-name="householdName" shared-asset/>
  </share-frame>
</template>

<script>

import GrowingMonthsCore
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/celebrations/growting-months/GrowingMonthsCore';
import ShareFrame from '@/base-components/share/ShareFrame';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'GrowingMonthsAsset',
  components: {
    GrowingMonthsCore,
    ShareFrame,
  },
  props: {
    months: {
      type: Number,
      required: true,
    },
    householdName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupGreen,
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.growing-months-asset {
  width: 500px;
  .growing-months {
    background-color: $riseup_white;
    padding: 24px;
  }
}

</style>
