<template>
  <div class="cashflow-app">
    <cashflow-state-bar v-if="cashflowBarState" :bar-state="cashflowBarState"/>
    <failed-payment-bar v-else-if="canceled" :cta-type="'go-to-settings'"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import FailedPaymentBar
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/components/FailedPaymentBar';
import CashflowStateBar from '../../../../../base-components/CashflowStateBar';

export default {
  name: 'CashflowAppSnackBar',
  components: {
    CashflowStateBar,
    FailedPaymentBar,
  },
  computed: {
    ...mapState('applicationState', ['cashflowState', 'notificationState']),
    ...mapGetters('applicationState', ['barTypes', 'cashflowTypes', 'notificationTypes']),
    ...mapGetters('subscriptionState', ['canceled']),
    cashflowBarState() {
      if (this.notificationState === this.notificationTypes.TRIAL_EXPIRED) {
        return this.barTypes.TRIAL_EXPIRED;
      }
      if (this.cashflowState === this.cashflowTypes.OUT_OF_DATE) {
        return this.barTypes.OUT_OF_DATE;
      }
      if (this.cashflowState === this.cashflowTypes.BUG) {
        return this.barTypes.NOT_ACCURATE;
      }
      if (this.cashflowState === this.cashflowTypes.BLOCKED) {
        return this.barTypes.NOT_AVAILABLE;
      }
      if (this.notificationState === this.notificationTypes.LOADING_CREDENTIALS) {
        return this.barTypes.NOT_ACCURATE;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
