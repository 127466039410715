import { render, staticRenderFns } from "./DaysView.vue?vue&type=template&id=805d4766&scoped=true&"
import script from "./DaysView.vue?vue&type=script&lang=js&"
export * from "./DaysView.vue?vue&type=script&lang=js&"
import style0 from "./DaysView.vue?vue&type=style&index=0&id=805d4766&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "805d4766",
  null
  
)

export default component.exports