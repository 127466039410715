<template>
  <div class="split-transaction-review">
    <div class="title">
      <div class="name">{{ transaction.businessName }}</div>
      <div class="amount">
        <span>{{ amount | formatAmountWithFraction | shekel }}</span>
      </div>
    </div>
    <div>{{formatDate(transaction.transactionDate)}}, {{ getTransactionAccount(transaction) }}</div>
    <div class="category">
      <icon-square class="margin-left-s" :icon-name="V2CategoryIcon(category)" :color-variant="category.color" />
      <div>{{category.label}}</div>
    </div>
    <div class="comment" v-if="showComment">{{comment}}</div>
    <div class="spacer" v-else/>
    <separator v-if="!last"/>
  </div>
</template>

<script>
import * as _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';

export default {
  name: 'SliceReview',
  mixins: [TransactionDisplayUtils],
  components: {
    Separator: BaseUI.Separator,
    IconSquare: BaseUI.IconSquare,
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    comment: {
      type: String,
    },
    transaction: {
      type: Object,
      required: true,
    },
    last: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    V2CategoryIcon(category) {
      return BaseUI.V2CategoryIcon(category);
    },
  },
  computed: {
    showComment() {
      return !_.isEmpty(this.comment);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.split-transaction-review {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;
  .title{
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }
  .category {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    .icon {
      margin-left: 8px;
    }
  }

  .comment {
    margin-top: 8px;
    margin-bottom: 28px;
    padding: 4px 9px;
    font-size: 14px;
    line-height: 18px;
    background: $riseup_gray_0;
    border-radius: 5px;
    width: fit-content;
  }

  .spacer {
    margin-top: 20px;
  }
  .separator {
    width: 117%;
    margin-right: -24px;
  }

}
</style>
