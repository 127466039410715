<template>
  <month-review-flow v-if="!contentLoading"></month-review-flow>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MonthReviewFlow from './MonthReviewFlow';
import casfhlowHistory from '../../../../../store/utilities/cashflow-history';

export default {
  name: 'MonthReviewFlowWrapper',

  components: {
    MonthReviewFlow,
  },
  async created() {
    this.startContentLoading(this.$options.name);
    await this.initBudgets({ budgetDate: this.budgetDate });
    this.doneContentLoading(this.$options.name);
  },
  computed: {
    ...mapGetters('contentLoader', ['contentLoading']),
    budgetDate() {
      return casfhlowHistory.getPrevBudgetDate(casfhlowHistory.getCurrentBudgetDate());
    },
  },
  methods: {
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('cashflowView', ['initBudgets']),
  },
};
</script>
