<template>
  <div>
    <div class="top">
      <div class="flex-column flex-1 justify-content-center align-center">
        <img :src="require('@/assets/altshuler/altshuler-success.png')" />
      </div>
      <div class="ri-small-display padding-horizontal-l">
        <div>{{ wizardState.firstNamePiiValue }}</div>
        <div>עשית את זה!</div>
      </div>
    </div>
    <div class="bottom">
      <div class="headline ri-bold-body">מה עכשיו?</div>
      <div class="ri-title">
        הכסף יעבור עוד כמה ימים לקופה כשתפתח, ואנחנו נעדכן אותך בוואטסאפ.
        בינתיים שלחנו לך מייל עם כל המסמכים.
      </div>

      <div class="headline ri-bold-body margin-top-l">כדי לראות את הקופה</div>
      <div class="ri-title">
        ניהול וצפייה בפיקדון אפשר לעשות דרך <span class="ri-bold-title">חסכונות עם רייזאפ</span> בתפריט הצד.
      </div>

      <div class="ri-title margin-top-l">
        <div>בכל בקשה או שאלה</div>
        <div>אפשר לפנות לצוות התמיכה שלנו.</div>
      </div>

      <riseup-button class="margin-top-l" title="הבנתי, תודה!" @click="backToCashflow" />
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'AltshulerSignupCompleted',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
  },
  methods: {
    backToCashflow() {
      Segment.trackUserInteraction('Altshuler_SignupCompleted_BackToCashflowClicked');
      this.$router.push({ path: '/sr' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: $ri-spacing-l;
  background-color: $riseup_light_blue;
  height: 400px;

  img {
    width: 210px;
  }
}

.bottom {
  padding: $ri-spacing-l;

  .headline {
    color: $riseup_blue;
  }
}
</style>
