import { client } from '../http';

export async function createOpenAccountRequest(openAccountRequest) {
  const response = await client.post('/api/altshuler/accounts', openAccountRequest);
  return response.data;
}

export async function createOpenAccountWizardState(openAccountWizardState) {
  const response = await client.post('/api/altshuler/wizard-state', openAccountWizardState);
  return response.data;
}

export async function updateOpenAccountWizardState(openAccountWizardState) {
  const response = await client.put('/api/altshuler/wizard-state', openAccountWizardState);
  return response.data;
}

export async function submitRequest() {
  const response = await client.post('/api/altshuler/submit');
  return response.data;
}

export async function getOpenAccountWizardState() {
  const response = await client.get('/api/altshuler/wizard-state');
  return response.data;
}

export async function uploadSocialId(idFile) {
  const formData = new FormData();
  formData.append('file', idFile);
  const response = await client.post('/api/altshuler/upload-id', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}
