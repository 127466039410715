<script>
import { mapActions, mapMutations } from 'vuex';
import Segment from '../../../../../Segment';
import UpdateCreditCardPopup from './UpdateCreditCardPopup.vue';

export default {
  name: 'UpdateCreditCardHandler',
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapMutations('subscription', ['setTokenizationError']),
    openUpdateCreditCardPopup() {
      Segment.trackUserInteraction('UpdateCreditCardClicked');
      this.setTokenizationError(null);
      this.openModal({
        component: UpdateCreditCardPopup,
      });
    },
  },
};
</script>
