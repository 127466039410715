import cashflowHistory from '@/store/utilities/cashflow-history';
import _ from 'lodash';
import moment from 'moment';

function predictFuture(transactionsFromAllBudgets, years, anonymize) {
  const currentBudgetDate = cashflowHistory.getCurrentBudgetDate();
  const realTransactions = _.reject(transactionsFromAllBudgets, t => t.budgetDate >= currentBudgetDate);
  const futureTransactions = _createFutureTransactions(realTransactions, currentBudgetDate, years * 12);
  const fullTransactions = [...futureTransactions, ...realTransactions];
  return anonymize ? _anonymizeTransactions(fullTransactions) : fullTransactions;
}

function _createFutureTransactions(transactions, budgetDate, months) {
  let nextBudgetDate = budgetDate;
  const futureTransactions = [];
  const monthsForFutureNonFixed = 4;
  for (let i = 0; i < months; i++) {
    futureTransactions.push(..._createFixedFutureTransactions([...transactions, ...futureTransactions], nextBudgetDate));
    if (i % monthsForFutureNonFixed === 0) {
      futureTransactions.push(..._createTrackingAndVariableFutureTransactions(
        [...transactions, ...futureTransactions], nextBudgetDate, monthsForFutureNonFixed,
      ));
    }
    nextBudgetDate = cashflowHistory.getNextBudgetDate(nextBudgetDate);
  }
  return _.sortBy(futureTransactions, t => -t.transactionDate);
}

function _createFixedFutureTransactions(transactions, budgetDate) {
  return [
    ..._createFutureFixedTransactionsFromLastMonth(transactions, budgetDate),
    ..._createFutureFixedTransactionsFromTwoMonthsAgo(transactions, budgetDate),
  ];
}

function _createFutureFixedTransactionsFromLastMonth(transactions, budgetDate) {
  const prevBudgetDate = cashflowHistory.getPrevBudgetDate(budgetDate);
  return _.chain(transactions)
    .filter(t => (
      t.budgetDate === prevBudgetDate
      && t.placement === 'fixed'
      && t.monthsInterval === 1
      && (t.totalNumberOfPayments === null || t.paymentNumber < t.totalNumberOfPayments)
    ))
    .map(t => (
      _.extend({}, t, {
        budgetDate,
        billingDate: moment(t.billingDate).add(1, 'month').format(),
        transactionDate: moment(t.transactionDate).add(1, 'month').format(),
        paymentNumber: t.paymentNumber ? t.paymentNumber + 1 : null,
      })
    ))
    .value();
}

function _createFutureFixedTransactionsFromTwoMonthsAgo(transactions, budgetDate) {
  const twoMonthsAgoBudgetDate = cashflowHistory.getPrevBudgetDate(cashflowHistory.getPrevBudgetDate(budgetDate));
  return _.chain(transactions)
    .filter(t => (
      t.budgetDate === twoMonthsAgoBudgetDate
      && t.placement === 'fixed'
      && t.monthsInterval === 2
    ))
    .map(t => (
      _.extend({}, t, {
        budgetDate,
        billingDate: moment(t.billingDate).add(2, 'month').format(),
        transactionDate: moment(t.transactionDate).add(2, 'month').format(),
      })
    ))
    .value();
}

function _createTrackingAndVariableFutureTransactions(transactions, budgetDate, months = 4) {
  const prevBudgetDates = _getPrevBudgetDates(budgetDate, months);

  const nextBudgetDates = _.range(1, months).reduce(s => (
    [...s, cashflowHistory.getNextBudgetDate(_.last(s))]
  ), [budgetDate]);

  return _.chain(transactions)
    .filter(t => (
      _.includes(prevBudgetDates, t.budgetDate)
      && _.includes(['trackingCategory', 'variable'], t.placement)
    ))
    .groupBy(t => t.businessName)
    .map(ts => {
      const COUNT_RANGE = 1;
      const maxCount = ts.length + COUNT_RANGE;
      const minCount = _.max([0, ts.length - COUNT_RANGE]);
      const tsToGenerate = _.random(minCount, maxCount);

      const transactions = _.range(0, tsToGenerate).map(() => {
        const amount = _.round(_.sample(ts).amount * _.random(0.9, 1.1));
        const newTransactionBudgetDate = _.sample(nextBudgetDates);
        const transactionDate = moment(newTransactionBudgetDate, 'YYYY-MM');
        const billingDate = moment(transactionDate).add(1, 'month').date(2);
        transactionDate.date(_.random(1, transactionDate.daysInMonth()));
        return _.extend({}, ts[0], {
          budgetDate: newTransactionBudgetDate,
          amount,
          transactionDate,
          billingDate,
          originalAmount: -amount,
        });
      });
      return transactions;
    })
    .flatten()
    .value();
}

function _getPrevBudgetDates(budgetDate, months) {
  return _.range(1, months).reduce(s => (
    [...s, cashflowHistory.getPrevBudgetDate(_.last(s))]
  ), [cashflowHistory.getPrevBudgetDate(budgetDate)]);
}

function _anonymizeTransactions(originalTransactions) {
  const transactions = _.cloneDeep(originalTransactions);
  const uniqTransactions = _.uniqBy(transactions, t => t.businessName);
  const businessNameToAnonymizedName = _.chain(uniqTransactions)
    .map((t, index) => [t.businessName, `${t.category}${index + 1}`])
    .fromPairs()
    .value();
  return _.forEach(transactions, t => {
    t.businessName = businessNameToAnonymizedName[t.businessName];
  });
}

export default {
  predictFuture,
};
