<template>
  <div class="action-list-popup">
    <colored-popup :class="className" :close-action="close" layout="no-content-padding" dark-text>
      <template v-slot:top-content>
        <div class="top-content">
          <div class="icons">
            <img v-if="!areAllActionsCompleted" src="/images/action-list/action-list-ongoing.png" class="full-width"/>
            <img v-else src="/images/action-list/action-list-done.png" class="full-width"/>
          </div>
          <div class="ri-large-body padding-vertical-s">{{title}}</div>
          <div class="bold-title">{{subTitle}}</div>
        </div>
      </template>

      <template v-slot:bottom-content>
          <action-list-block title="התחלתי לנצח את החודש" :actions="gettingStartedActions" @close="close" @action-clicked="onActionClicked"/>
          <div class="separator-block"/>
          <action-list-block title="אני לא לבד במסע שלי" :actions="communityActions" @close="close" @action-clicked="onActionClicked"/>

      </template>

    </colored-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import ACTIONS from './actions.consts.ts';
import ActionListBlock from './actionListBlock';

const communityActions = [
  ACTIONS.INVITE_MEMBER,
  ACTIONS.GO_TO_CHAT,
  ACTIONS.GO_TO_COMMUNITY,
];

const nonCommunityActions = Object.values(ACTIONS).filter(action => !communityActions.includes(action));

const defaultCheckedActions = [
  ACTIONS.FIRST_CF,
];

export default {
  name: 'ActionListPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    ActionListBlock,
  },
  computed: {
    ...mapGetters('session', ['householdName']),
    ...mapGetters('actions', ['actions']),
    communityActions() {
      return this.actions.filter(action => communityActions.includes(action.type));
    },
    gettingStartedActions() {
      return this.actions.filter(action => nonCommunityActions.includes(action.type));
    },
    areAllActionsCompleted() {
      return this.actions.every(action => action.checked);
    },
    completedActionCounter() {
      return this.actions.filter(action => !defaultCheckedActions.includes(action.type) && action.checked).length;
    },
    remainingActionsCounter() {
      return this.actions.filter(action => !action.checked).length;
    },
    className() {
      return this.areAllActionsCompleted ? 'light-green' : 'light-blue';
    },
    title() {
      return this.areAllActionsCompleted
        ? `${this.householdName}, עשית את זה!`
        : `${this.householdName}, מסע הצמיחה שלך התחיל!`;
    },
    subTitle() {
      return this.areAllActionsCompleted
        ? 'השלמת את כל הצעדים לחודש הראשון, כל הכבוד!'
        : 'בכמה צעדים אפשר לצמוח כלכלית כבר בחודש הראשון';
    },
  },
  created() {
    Segment.trackUserInteraction('ActionListPopup_Shown');
  },
  methods: {
    close() {
      Segment.trackUserInteraction('ActionListPopup_Close_Clicked');
      this.$emit('close');
    },
    onActionClicked(type) {
      Segment.trackUserInteraction('ActionList_ItemActionClicked',
        { type, completed: this.completedActionCounter, remaining: this.remainingActionsCounter });
    },
  },
};
</script>

<style scoped lang="scss">
.action-list-popup {
  ::v-deep .colored-top {
    padding: 55px 40px 45px;
    .action-icon img {
      filter: invert(1);
    }
  }

  .bold-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }

  .separator-block {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    background-color: #F1F1F1;
    height: 19px;
  }

  ::v-deep .expandable-summary-row:last-child .separator {
    display: none;
  }
}
</style>
