<template>
<div class="competition-bullet">
  <div class="bullet ri-medium-bold-headline">!</div>
  <div class="description">
    <span class="ri-body margin-bottom-ml">שלושת המשתתפים שיצרפו הכי הרבה חבר׳ה בתקופת התחרות יוכלו לזכות ב:</span>
    <span class="ri-body bold">מקום ראשון 🥇</span>
    <span class="ri-body">יזכה בשובר לאיקאה בסך <span class="bold">20,000 ₪</span></span>
    <span class="ri-body bold">מקום שני 🥈</span>
    <span class="ri-body">יזכה בשובר לאיקאה בסך <span class="bold">10,000 ₪</span></span>
    <span class="ri-body bold">מקום שלישי 🥉</span>
    <span class="ri-body"> יזכה בשובר לאיקאה בסך <span class="bold">5,000 ₪</span></span>
  </div>
</div>
</template>

<script>
export default {
  name: 'CompetitionBullet',
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";

.bullet {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: $riseup-white;
  background-color: $riseup-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  // align ! to center
  padding-top: 2px;
  margin-bottom: $ri-spacing-xxs;
}

.description {
  display: flex;
  flex-direction: column;

  .bold {
    font-weight: bold;
  }
}
</style>
