<template>
  <div :class="['update-subscription-flow-v2', {'hide-back': flowStep === SUBSCRIPTION_FLOW_STEPS.done }]" >
    <header-page :header-props="headerPageProps" class="header">
      <div v-if="flowStep === SUBSCRIPTION_FLOW_STEPS.subscriptionType">
        <subscription-type-v2 mode="update" />
        <div class="padding-ml">
          <riseup-button :title="'להמשך'" @click="onSubmit" color="blue" />
        </div>

      </div>

      <div class="summary" v-else-if="flowStep === SUBSCRIPTION_FLOW_STEPS.summary">
        <img :src="require('@/assets/subscription/credit-card-header.svg')" alt="shapes image"/>
        <div class="summary-container margin-horizontal-ml">
              <div class="ri-medium-bold-headline margin-top-l margin-bottom-xs">פרטי תשלום לרכישת מינוי שנתי</div>
              <update-credit-card-button button-size="small" />
            <payment-details :price="pricing.annual" :payment-date="nextFormattedPaymentDate" :discount="true"
                             class="margin-bottom-l margin-top-sm" :discount-text="`הנחה של ${discountText}%`"/>
          </div>
        <subscription-terms-v2 />
        <div class="padding-ml">
          <riseup-button :title="buttonText" :size="'slim'" :action="onSubmit"
                         :disabled="submitLoading || tokenizationError" :loading="submitLoading"/>
        </div>
        <tokenize-error-v2 v-if="tokenizationError" />
      </div>
      <done-v2 v-else-if="flowStep === SUBSCRIPTION_FLOW_STEPS.done" mode="update"/>
    </header-page>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import UpdateCreditCardButton
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/components/UpdateCreditCardButton';
import BaseUI from '@riseupil/base-ui';
import PaymentDetails from '@/pages/responsive-pages/authenticated/subscription/PaymentDetails';
import SubscriptionTypeV2 from '@/pages/responsive-pages/authenticated/subscription/V2/SubscriptionTypeV2.vue';
import DoneV2 from '@/pages/responsive-pages/authenticated/subscription/V2/DoneV2.vue';
import TokenizeErrorV2 from '@/pages/responsive-pages/authenticated/subscription/V2/TokenizeErrorV2.vue';
import Segment from '@/Segment';
import { ACTION_TYPE } from '@riseupil/base-ui/src/components/ActionIcon/ActionIcon.consts';
import router from '@/router';
import money from '@/utils/money';
import BenefitMixin from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitMixin.vue';
import SubscriptionTermsV2 from '@/pages/responsive-pages/authenticated/subscription/V2/SubscriptionTermsV2.vue';

export default {
  name: 'UpdateSubscriptionFlowV2',
  components: {
    SubscriptionTermsV2,
    TokenizeErrorV2,
    DoneV2,
    SubscriptionTypeV2,
    PaymentDetails,
    UpdateCreditCardButton,
    HeaderPage: BaseUI.Pages.HeaderPage,
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      submitLoading: false,
      headerPageProps: {
        rightActions: [{
          type: ACTION_TYPE.BACK,
          onClick: this.onBack,
        }],
        showLogo: true,
        backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
        logoColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        textColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        bottomBorder: true,
      },
    };
  },
  mixins: [BenefitMixin],
  created() {
    Segment.trackUserGot('UpdateSubscriptionEntered', { subscriptionVersion: 'v2' });
  },
  computed: {
    ...mapGetters('subscription', ['SUBSCRIPTION_FLOW_STEPS', 'yearlySavingAgainstMonthlyPercentage', 'nextFormattedPaymentDate']),
    ...mapState('subscription', ['firstBillingDate', 'flowStep', 'pricing', 'tokenizationError', 'nextPaymentDate']),
    buttonText() {
      return this.flowStep === this.SUBSCRIPTION_FLOW_STEPS.subscriptionType
        ? 'לבחירה והמשך'
        : 'לתשלום מאובטח';
    },
    discountText() {
      return money.formatAmountWithFraction(this.yearlySavingAgainstMonthlyPercentage, 0);
    },
  },
  methods: {
    ...mapActions('subscription', ['updateSubscriptionV2']),
    ...mapMutations('subscription', ['setStep', 'setType', 'setTokenizationError']),
    onBack() {
      Segment.trackUserGot('UpdateSubscriptionFlow_BackClicked', { currFlowStep: this.flowStep, subscriptionVersion: 'v2' });
      this.setTokenizationError(null);
      if (this.flowStep === this.SUBSCRIPTION_FLOW_STEPS.subscriptionType) {
        router.push({ path: '/account-settings', query: this.$route.query });
      } else if (this.flowStep === this.SUBSCRIPTION_FLOW_STEPS.summary) {
        this.setStep(this.SUBSCRIPTION_FLOW_STEPS.subscriptionType);
      }
    },
    async onSubmit() {
      if (this.flowStep === this.SUBSCRIPTION_FLOW_STEPS.subscriptionType) {
        Segment.trackUserGot('UpdateSubscriptionFlow_NextClicked', { subscriptionVersion: 'v2' });
        this.setStep(this.SUBSCRIPTION_FLOW_STEPS.summary);
      } else if (!this.submitLoading) {
        this.submitLoading = true;
        Segment.trackUserGot('UpdateSubscriptionFlow_SubmitClicked', { subscriptionVersion: 'v2' });
        await this.updateSubscriptionV2();
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.update-subscription-flow-v2 {
  .header {
    width: 100%;
  }
  &.hide-back ::v-deep .page-header .actions-with-logo .actions {
    visibility: hidden;
  }
  display: flex;
  height: 100%;

  .summary {
    display: flex;
    flex-direction: column;
    height: 100%;

    .summary-container {
      flex: 1;
    }
  }
}
</style>
