<template>
  <card>
    <div class="padding-vertical-m ri-large-body flex-row align-center space-between pointer" :class="{ disabled: outOfDate }"
         role="button" @click="onClick" @keyup.enter="onClick" tabindex="0">
      <div class="flex-row align-center">
        <icon class="margin-left-xs" :icon-name="iconName" v-bind="additionalIconProps"/>
        {{ text }}
      </div>
      <icon icon-name="chevron_left" size="24px" :optical-size="24" :color="arrowColor"/>
    </div>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ActionCardWithIcon',
  components: {
    Card: BaseUI.Card,
    Icon: BaseUI.Icon,
  },
  props: {
    action: {
      type: Function,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    additionalIconProps: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    outOfDate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    arrowColor() {
      return this.outOfDate ? BaseUI.Colors.riseupGray2 : BaseUI.Colors.riseupBlack;
    },
  },
  methods: {
    onClick() {
      if (!this.outOfDate) {
        this.action();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.disabled {
  color: $riseup_gray_2;
}
.pointer {
  cursor: pointer;
}
</style>
