<template>
    <div class="add-slice-section" :class="{ disabled }" @click="addSlice">
      <img class="plus-image" :src="require('@/assets/icons/icon_add.svg')">
      <div class="title">{{ label }}</div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AddTransactionSliceSection',
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('splitTransaction', ['splitLabel']),
    label() {
      return ` לפצל לעוד ${this.splitLabel}`;
    },
  },
  methods: {
    ...mapActions('splitTransaction', ['addSlice']),
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  .add-slice-section {
    &.disabled {
      opacity: 0.7;
      pointer-events: none;
    }
    border: dashed 1px $riseup_blue;
    border-radius: 8px;
    background-color: $riseup_white;
    cursor: pointer;
      padding: 60px 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 16px;
        line-height: 20px;
        margin-top: 3px;
        font-weight: bold;
        color: $riseup_blue;
      }
      .plus-image {
        margin-left: 13px;
        height: 25px;
      }
  }
</style>
