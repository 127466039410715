<template>
  <div class="demo-cashflow">
    <riseup-header />
    <snack-bar variant="danger" class="notification">
      <template v-slot:primary>חשבון לדוגמא בלבד</template>
    </snack-bar>
    <!-- cashflow-app is required for initializing stuff for cashflow-view, but we can't show it because some
    components in it are missing -->
    <insights-carousel :insights="demoInsights"/>
    <cashflow-view/>
    <cashflow-app v-show="false" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import RiseupHeader from '@/base-components/app-header/RiseupHeader';
import TransactionAndBudgetDateParamHandler from '@/mixins/TransactionAndBudgetDateParamHandler.vue';
import CashflowView from './cashflow-view/CashflowView.vue';
import InsightsCarousel from './notification/notification-types/InsightsCarousel';
import CashflowApp from './CashflowApp.vue';
import demoInsights from './insights/demo-insights';

export default {
  name: 'DemoCashflowApp',
  components: {
    RiseupHeader,
    CashflowView,
    CashflowApp,
    SnackBar: BaseUI.SnackBar,
    InsightsCarousel,
  },
  mixins: [TransactionAndBudgetDateParamHandler],
  async created() {
    Segment.trackUserGot('DemoCashflowAppEntered');
    await this.init({ incognito: true, name: 'demo' });
    if (this.$route.query.transactionId) {
      this.openTransaction(this.$route.query.transactionId);
    }
  },
  data() {
    return {
      demoInsights,
    };
  },
  methods: {
    ...mapActions('session', ['init']),
  },
  beforeRouteLeave(to, from, next) {
    // eslint-disable-next-line no-alert
    const answer = window.confirm('יציאה מתזרים דמו וחזרה לתזרים האמיתי שלך');
    if (answer) {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
  .demo-cashflow {

    .notification {
      margin-bottom: 30px;
      padding-right: 10px;
      font-size: 15px;
      font-weight: bold;
    }
  }
</style>
