<template>
  <card class="next-steps-base-card">
    <div class="flex-row margin-vertical-ml" :class="{ 'disabled-card': disabled }">
      <img class="step-image" :src="`/images/my-progress/next-steps/${actionKey}.svg`" alt="next-step-image"/>
      <div class="next-steps-card-context">
        <div class="step-description">
          <div class="ri-large-bold-body margin-bottom-xxs">{{ title }}</div>
          <div class="ri-label margin-bottom-sm gray-60-text">{{ description }}</div>
        </div>
        <div>
          <action-button v-bind="actionButtonBaseProps" :text="buttonText" @click="onClick" :disabled="disabled"/>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'NextStepsBaseCard',
  components: {
    Card: BaseUI.Card,
    ActionButton: BaseUI.ActionButton,
  },
  data() {
    return {
      actionButtonBaseProps: {
        backgroundColor: BaseUI.Colors.riseupBlue,
        borderColor: BaseUI.Colors.riseupBlue,
        textColor: BaseUI.Colors.riseupWhite,
        noIcon: true,
      },
    };
  },
  props: {
    actionKey: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        Segment.trackUserInteraction('MyProgress_NextStep_Clicked', { action: this.actionKey });
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/riseup-colors';

$step-image-height: 84px;

.next-steps-base-card {
  background: $riseup_white;
  .disabled-card  {
    opacity: 0.5;
  }
  .step-image {
    height: $step-image-height;
    margin-left: $ri-spacing-sm;
  }
  .next-steps-card-context {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .step-description {
      min-height: $step-image-height;
    }
  }
}

</style>
