<template>
  <div class="done-v2">
    <dashboard-page :image-header-props="imageHeaderProps">
      <template v-slot:header>
        <div class="headline-container">
          <div class="ri-large-body">פרטי התשלום התקבלו בהצלחה</div>
          <div class="ri-small-display">
            <div>איזה כיף </div>
            <div>{{ mode === 'create' ? 'שהצטרפת!' : 'שהשתדרגת!'}}</div>
          </div>
        </div>
      </template>
      <template v-slot:dashboard>
        <div class="margin-ml">
          <property class="margin-bottom-m" name="סוג מינוי" :value="subscriptionTypSelectedText"/>
          <property class="margin-bottom-m" name="תאריך לחיוב ראשון" :value="formattedFirstBillingDate"/>
          <next-payment-section-v2 />
          <property class="margin-bottom-m" name="כרטיס אשראי לחיוב" :value="newFormattedCardNumberMask"/>
          <property class="margin-bottom-xs" name="אימייל לשליחת קבלות" :value="activeMember.emailPiiValue"/>
          <div class="link" @click="redirectToPersonalInfo">עריכה</div>
        </div>
      </template>
    </dashboard-page>
    <div class="margin-ml">
      <riseup-button :title="backToCashflowButtonText" :action="backToCashflow" :size="'small'"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import router from '@/router';
import AmbassadorsTouchPointPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/AmbassadorsTouchPointPopup';
import { SUBSCRIPTION_TYPES } from '@/constants/subscription-state';
import { BACKGROUND_POSITION } from '@riseupil/base-ui/src/utils/layout-consts';
import Property from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/Property.vue';
import NextPaymentSectionV2 from '@/pages/responsive-pages/authenticated/subscription/V2/NextPaymentSectionV2.vue';
import Segment from '../../../../../Segment';

export default {
  name: 'DoneV2',
  components: {
    NextPaymentSectionV2,
    Property,
    RiseupButton: BaseUI.RiseupButton,
    DashboardPage: BaseUI.Pages.DashboardPage,
  },
  props: {
    mode: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      SUBSCRIPTION_TYPES,
    };
  },
  async created() {
    Segment.trackUserGot('SubscriptionDoneEntered', { subscriptionType: this.type });
    Segment.trackUserGotToPixel('SD');
    await this.fetchCreditCardMask();
  },
  computed: {
    ...mapGetters('subscription', ['newFormattedCardNumberMask', 'subscriptionTypSelectedText', 'formattedFirstBillingDate']),
    ...mapState('subscription', ['type']),
    ...mapGetters('onboardingState', ['onboardingCompleted']),
    ...mapState('session', ['activeMember']),
    backToCashflowButtonText() {
      return this.onboardingCompleted ? 'חזרה לתזרים' : 'אפשר להמשיך';
    },
    imageHeaderProps() {
      return {
        // eslint-disable-next-line global-require
        imgSrc: require('@/assets/subscription/after-payment-header.svg'),
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
        bottomSeparator: false,
        textColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        backgroundPosition: BACKGROUND_POSITION.BOTTOM,
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('subscription', ['fetchCreditCardMask']),
    ...mapActions('subscriptionState', ['initSubscriptionState']),
    async backToCashflow() {
      Segment.trackUserInteraction('SubscriptionDone_BackToCashflowClicked');
      if (this.onboardingCompleted) {
        this.openModal({
          component: AmbassadorsTouchPointPopup,
          props: {
            touchPointText: 'שמחים שבחרת להמשיך לצמוח איתנו!',
            query: { hideCta: true },
          },
        });
      } else {
        await this.initSubscriptionState();
        this.$router.push({ path: '/ob' });
      }
    },
    redirectToPersonalInfo() {
      router.push({ path: '/personal-info' });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@riseupil/base-ui/src/scss/riseup-colors";
  .done-v2 {

    .headline-container {
      text-align: start;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex: 1;
    }

    ::v-deep .title.with-overlay {
      align-items: unset;
    }

    .link {
      cursor: pointer;
      text-decoration: underline;
      color: $riseup_blue;
    }

    .riseup-button {
      width: 100%;
      margin-top: 10px;
    }
  }
</style>
