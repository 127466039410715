<script>

import { mapGetters } from 'vuex';

export default {
  name: 'PlanAheadMixin',
  computed: {
    ...mapGetters('cashflowView', ['riseupGoal']),
    ...mapGetters('planAhead', ['plans']),
    ongoingPlans() {
      const reducedPlans = this.plans.filter(plan => plan.state !== 'done').reduce((acc, plan) => {
        const isOnTrackThisMonth = acc.remaining - plan.monthlyTarget >= 0;
        const amountDiff = isOnTrackThisMonth ? plan.monthlyTarget : acc.remaining;
        const remaining = acc.remaining - amountDiff;
        return {
          plans: acc.plans.concat({
            ...plan,
            isOnTrackThisMonth,
            predictedAmountThisMonth: amountDiff > 0 ? amountDiff : 0,
          }),
          remaining,
        };
      }, { plans: [], remaining: this.riseupGoal });
      return reducedPlans.plans;
    },
  },
};
</script>
