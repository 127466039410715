<template>
  <div class="plans-total-data-section">
    <div class="cell">
      <div class="number">{{ total }}<span> ₪</span></div>
      <div class="title">{{ totalAmountText }}</div>
    </div>
    <div class="horizontal-separator-container">
      <div class="horizontal-separator"></div>
    </div>
    <div class="cell">
      <div class="number">{{ plans.length }}</div>
      <div class="title">{{ plansLengthText }}</div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import moneyUtils from '@/utils/money';

export default {
  name: 'PlansTotalDataSection',
  props: {
    plans: {
      type: Array,
      required: true,
    },
  },
  computed: {
    totalPlansMonthlyAmount() {
      return _.sumBy(this.plans, plan => plan.monthlyTarget) || 0;
    },
    total() {
      return moneyUtils.formatAmountWithFraction(this.totalPlansMonthlyAmount, 0);
    },
    totalAmountText() {
      return 'לשים בצד כל חודש';
    },
    plansLengthText() {
      return 'מספר תוכניות ';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.plans-total-data-section {
  display: flex;
  height: 120px;
  background: $riseup_white;

  .cell {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    white-space: pre;
    padding: 24px;

    .number {
      font-size: 40px;
      line-height: 46px;

      span {
        font-size: 22px;
        line-height: 22px;
      }
    }

    .title {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .horizontal-separator-container {
    margin: 24px 0;

    .horizontal-separator {
      width: 1px;
      background-color: $riseup_gray_disabled;
      height: 100%;
    }
  }
}
</style>
