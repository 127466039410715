<template>
  <popup :close-action="() => $emit('close')" title="איזה מידע רייזאפ מעבירה ל׳אלטשולר שחם פיננשייל סרביסס׳?">
    <template v-slot:content>
      <div class="ri-body">
        רייזאפ תעביר לאלטשולר את כל הפרטים שמילאת לצורך פתיחת החשבון (שם, טלפון, אימייל, תעודה מזהה ואימות הפרטים
        האישיים שבתעודה, מספר חשבון בנק וחתימה).
      </div>
      <div class="ri-body margin-top-l margin-bottom-xxl">
        בהמשך רייזאפ תעביר לאלטשולר כל בקשה שלך להפקדה בפיקדון או משיכה ממנו.
      </div>
      <riseup-button title="הבנתי, תודה" @click="() => $emit('close')"/>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'TransferredInformationPopup',
  components: {
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
  },
};
</script>

<style scoped lang="scss">
</style>
