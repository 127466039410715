<template>
  <div class="cashflow-view">
    <blocked-cashflow-view v-if="blocked" />
    <current-month-cashflow-view v-else-if="isPresentingCurrentMonth" :out-of-date="outOfDate"/>
    <past-editable-month-cashflow-view v-else-if="presentedBudgetDate" :out-of-date="outOfDate"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';

import PastEditableMonthCashflowView from './past-editable-month/PastEditableMonthCashflowView.vue';

const CurrentMonthCashflowView = () => import('./current-month/CurrentMonthCashflowView.vue');
const BlockedCashflowView = () => import('./blocked-month/BlockedCashflowView.vue');

export default {
  name: 'CashflowView',
  components: {
    CurrentMonthCashflowView,
    PastEditableMonthCashflowView,
    BlockedCashflowView,
  },
  computed: {
    ...mapGetters('cashflowView', ['isPresentingCurrentMonth', 'presentedBudget']),
    ...mapGetters('applicationState', ['cashflowTypes']),
    ...mapState('applicationState', ['cashflowState']),
    ...mapState('cashflowView', ['presentedBudgetDate']),
    outOfDate() {
      return _.includes([this.cashflowTypes.OUT_OF_DATE, this.cashflowTypes.BUG], this.cashflowState);
    },
    blocked() {
      return _.includes([this.cashflowTypes.BLOCKED], this.cashflowState);
    },
  },
};
</script>

<style lang="scss" scoped>
  .cashflow-view {
  }
</style>
