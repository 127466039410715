<template>
  <subscription-type-button
    @subscription-type-selected="(...args) => this.$emit('subscription-type-selected', ...args)"
    :selection-disabled="disabled"
    :selected="selected"
    :subscription-type="SUBSCRIPTION_TYPES.MONTHLY"
    :main-text="'מינוי חודשי'"
    :sub-text="'חיוב כל חודש'"
  >
    <template v-slot:price>
      <div>
        <span class="ri-font-20 margin-right-xxs">₪</span><span class="ri-font-24">{{pricing.monthly}}</span>
        <span v-if="abTestAnnualSubscriptionCopy" class="ri-font-14"> בחודש</span>
      </div>
    </template>
    <template v-slot:sub-price>
      <div class="ri-font-14">
        ({{totalMonthlyPricingPerYear}} ₪ לשנה)
      </div>
    </template>
  </subscription-type-button>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { SUBSCRIPTION_TYPES } from '@/constants/subscription-state';
import SubscriptionTypeButton from './SubscriptionTypeButton.vue';

export default {
  name: 'SubscriptionTypeButtonMonthly',
  components: {
    SubscriptionTypeButton,
  },
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    abTestAnnualSubscriptionCopy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SUBSCRIPTION_TYPES,
    };
  },
  computed: {
    ...mapState('subscription', ['pricing']),
    ...mapGetters('subscription', ['totalMonthlyPricingPerYear']),
  },
};
</script>

<style lang="scss" scoped>
</style>
