<template>
  <dashboard-page :image-header-props="imageHeaderProps">
    <template v-slot:dashboard>
      <div class="margin-ml">
        <property class="margin-bottom-m" :name="'סוג מינוי'" :value="subscriptionTypeText"/>
        <div v-if="hasAcceptedMoneyBackOffer && !isMbgEnded">
          <div class="mbg-note">תוכנית ״לא הרווחת לא שילמת!״</div>
          <money-back-notification-box />
        </div>
        <property class="margin-bottom-m" :name="'סכום החיוב הבא'" :value="currentPrice"/>
        <property class="margin-bottom-m" name="תאריך החיוב הבא" :value="nextPaymentDateDisplayValue"/>
        <property class="margin-bottom-m" name="כרטיס אשראי לחיוב" :value="newFormattedCardNumberMask"/>
      </div>
      <separator />
      <div class="margin-ml">
        <riseup-button :action="openUpdateCreditCardPopup" :size="'slim'" :title="'לעדכן אמצעי תשלום'"/>
      </div>
    </template>
  </dashboard-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import AccountSettingsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/AccountSettingsMixin.vue';
import UpdateCreditCardHandler
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/UpdateCreditCardHandler.vue';
import MoneyBackNotificationBox
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/components/MoneyBackNotificationBox.vue';
import Property from './Property.vue';
import dates from '../../../../../utils/dates';

export default {
  name: 'SubscribedSettings',
  components: {
    MoneyBackNotificationBox,
    Property,
    DashboardPage: BaseUI.Pages.DashboardPage,
    RiseupButton: BaseUI.RiseupButton,
    Separator: BaseUI.Separator,
  },
  mixins: [AccountSettingsMixin, UpdateCreditCardHandler],
  async created() {
    this.startContentLoading(this.$options.name);
    await this.fetchPricing();
    await this.fetchCreditCardMask();
    this.doneContentLoading(this.$options.name);
  },
  computed: {
    ...mapState('subscription', ['nextPaymentDate', 'price']),
    ...mapGetters('subscriptionState', ['hasAcceptedMoneyBackOffer', 'isMbgEnded']),
    ...mapGetters('subscription', ['newFormattedCardNumberMask', 'subscriptionTypeText']),
    nextPaymentDateDisplayValue() {
      return this.nextPaymentDate ? dates.shortDateFormat(this.nextPaymentDate) : 'בבדיקה';
    },
    currentPrice() {
      return `${this.price / 100} ₪`;
    },
  },
  methods: {
    ...mapActions('subscription', ['fetchPricing', 'fetchCreditCardMask']),
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
  },
};
</script>

<style scoped lang="scss">

@import "./settings";

@include properties;

  .mbg-note {
    margin-bottom: 20px;
    margin-top: -18px;
    font-size: 20px;
  }

</style>
