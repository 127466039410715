<template>
  <div>
    <failed-payment-bar v-if="canceled" :cta-type="'open-card-update'"/>
    <subscription-settings/>
    <div class="margin-horizontal-ml margin-bottom-ml">
      <account-setting-button v-if="showSwitchToAnnual"
                              :title="'שדרוג למינוי שנתי'" :action="openUpdateSubscriptionFlow"/>
      <account-setting-button v-if="memberIsPrimary" :title="'הוספת שותפ.ה למנוי'" :action="addMember"/>
      <account-setting-button v-bind="pauseWhatsappMessagesProps"/>
      <account-setting-button v-if="enableWhatsappAlertsSettings" title="בחירת התראות מיוחדות"
                              :action="openWhatsappAlerts" :icon-props="{ iconName: 'sms', fill: true }"/>
      <account-setting-button :title="'הורדת התזרים לאקסל'" :action="downloadExcel" :icon="'fa-download'"
                              :icon-color="'green'" :loading="downloading"/>
      <account-setting-button v-if="downloadDemoAvailable" :title="'הורדת תזרים אנונימי'" :action="downloadAnonymizeExcel" :icon="'fa-download'"
                              :icon-color="'green'" :loading="downloading"/>
      <account-setting-button v-if="subscribed" :title="'הפסקת מינוי'" :action="openCancelSubscriptionFlow" :icon="'fa-times-circle'"/>
      <account-setting-button v-else :title="'סגירת חשבון ומחיקת פרטים'" :action="openChurnFlow" :color="'red'"/>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import router from '@/router';
import ChurnPopup from '@/pages/responsive-pages/authenticated/churn/ChurnPopup';
import CancelSubscriptionPopup from '@/pages/responsive-pages/authenticated/churn/CancelSubscriptionPopup';
import AccountSettingButton
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/components/AccountSettingButton';
import cashflowHistory from '@/store/utilities/cashflow-history';
import cashflowToExcel from '@/store/utilities/cashflowForExcel';
import FailedPaymentBar
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/components/FailedPaymentBar';
import PauseMessagesBottomSheet
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/components/PuaseMesssagesBottomSheet';
import BaseUI from '@riseupil/base-ui';
import SubscriptionSettings from './SubscriptionSettings.vue';
import Segment from '../../../../../Segment';

export default {
  name: 'account-settings',
  components: {
    SubscriptionSettings,
    AccountSettingButton,
    FailedPaymentBar,
  },
  data() {
    return {
      downloading: false,
      isYearlySubscriptionPaid: false,
      showPauseMessageBottomSheet: false,
    };
  },
  async created() {
    Segment.trackUserGot('AccountSettingsEntered');
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.DARK_GREEN,
      textColor: BaseUI.Colors.riseupWhite,
      logoColor: BaseUI.Colors.riseupWhite,
      sticky: false,
    });
    this.startContentLoading(this.$options.name);
    await this.fetchSubscription();
    await this.getCommunicationState();
    this.doneContentLoading(this.$options.name);
  },
  computed: {
    ...mapGetters('subscriptionState', ['canceled', 'isMbgEnded', 'subscribed']),
    ...mapGetters('subscription', ['isMonthlySubscription']),
    ...mapGetters('subscriptionState', ['canceled', 'isMbgEnded', 'subscribed']),
    ...mapGetters('session', ['memberIsPrimary']),
    ...mapState('session', ['activeMember']),
    ...mapGetters('featureFlags', ['mbgEnableYearlySubscription', 'enableWhatsappAlertsSettings']),
    ...mapGetters('communicationState', ['memberCommunicationState']),
    backgroundColor() {
      return BaseUI.LayoutConsts.COLOR_VARIANT.DARK_GREEN;
    },
    textColor() {
      return BaseUI.LayoutConsts.COLOR_VARIANT.WHITE;
    },
    currentMemberCommunicationState() {
      return this.memberCommunicationState(this.activeMember._id);
    },
    pauseWhatsappMessagesProps() {
      return {
        title: this.currentMemberCommunicationState
          ? 'הפעלת הודעות וואטסאפ'
          : 'השתקת הודעות וואטסאפ',
        icon: this.currentMemberCommunicationState
          ? 'fa-bell'
          : 'fa-bell-slash',
        action: this.openPauseMessageMenu,
      };
    },
    showSwitchToAnnual() {
      return this.subscribed && this.isMonthlySubscription && (this.mbgEnableYearlySubscription || this.isMbgEnded);
    },
    downloadDemoAvailable() {
      return this.$route.query.downloadDemo;
    },
  },
  methods: {
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('communicationState', ['getCommunicationState']),
    ...mapActions('subscription', ['fetchSubscription']),
    openPauseMessageMenu() {
      this.openModal({
        component: PauseMessagesBottomSheet,
        props: {
          memberCommunicationState: this.currentMemberCommunicationState,
        },
        popupAlignment: 'menu',
      });
    },
    addMember() {
      Segment.trackUserInteraction('InviteMemberClicked');
      router.push({ path: '/invite-member', query: this.$route.query });
    },
    openWhatsappAlerts() {
      Segment.trackUserInteraction('WhatsappAlertsClicked');
      router.push({ name: 'WhatsappAlerts', query: this.$route.query });
    },
    openChurnFlow() {
      Segment.trackUserInteraction('ChurnButtonClicked');
      this.openModal({
        component: ChurnPopup,
      });
    },
    openCancelSubscriptionFlow() {
      Segment.trackUserInteraction('ChurnButtonClicked');
      this.openModal({
        component: CancelSubscriptionPopup,
      });
    },
    async downloadExcel() {
      Segment.trackUserInteraction('DownloadExcelClicked');
      this.downloading = true;
      const budgets = await cashflowHistory.getAllBudgets();
      const cashflowsCsv = cashflowToExcel.convertToCsv(budgets);
      await this.saveCsv(cashflowsCsv);
      this.downloading = false;
    },
    async downloadAnonymizeExcel() {
      Segment.trackUserInteraction('DownloadAnonymizeExcelClicked');
      this.downloading = true;
      const budgets = await cashflowHistory.getAllBudgets();
      const cashflowsCsv = cashflowToExcel.predictFutureAndConvertToCsv(budgets);
      await this.saveCsv(cashflowsCsv);
      this.downloading = false;
    },
    async saveCsv(content) {
      const bom = '\uFEFF';
      const format = 'data:text/csv; charset=utf-8,';
      const a = document.createElement('a');
      a.setAttribute('href', format + bom + encodeURIComponent(bom + content));
      a.setAttribute('download', 'transactions.csv');
      a.click();
    },
    openUpdateSubscriptionFlow() {
      router.push({ path: '/subscription/update' });
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../../../scss/mixins';
</style>
