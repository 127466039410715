<template>
  <div class="expand-category-modal">
    <div class="top" :class="color">
      <div class="action-icon">
        <img :src="require('@/assets/icons/close-white.svg')" v-on:click="close">
      </div>
      <div class="text">
        <span class="bold">{{expense}}</span> חודש {{month}}
        <div class="sum"><span class="currency-sign">₪</span>{{formattedAmount(sum, 0)}}</div>
      </div>
      <div class="rounded-upper-corners-fix-bug">
        <div class="rounded-upper-corners"></div>
      </div>
    </div>

    <div class="content">
      <transactions-rows :transactions="sortedTransactions"></transactions-rows>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import Segment from '../../../../../../Segment';
import moneyUtils from '../../../../../../utils/money';
import TransactionsRows from './TransactionsRows';

export default {
  name: 'ExpandCategoryModal',
  components: {
    TransactionsRows,
  },
  props: {
    color: { type: String, required: true },
    expense: { type: String, required: true },
    transactions: { type: Array, required: true },
    month: { type: String, required: true },
    sum: { type: Number, required: true },
  },
  computed: {
    sortedTransactions() {
      return _.sortBy(this.transactions, 'date');
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    formattedAmount(amount, numOfDecimalDigits) {
      return moneyUtils.formatAmountWithFraction(amount, numOfDecimalDigits);
    },
    close() {
      Segment.trackUserInteraction('Month_Review_Expand_Category_Close_Clicked');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import "~@riseupil/base-ui/src/scss/riseup-vars";
@import '../../../../../../scss/month-review-circles-mixins';

.expand-category-modal {

  @include circle-color;

  .rounded-upper-corners-fix-bug {
    height: 30px;

    .rounded-upper-corners {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom: 0;
      height: 31px;
      background: $riseup_white;
    }
  }

  .top {
    position: relative;
    text-align: right;
    color: $riseup_white;
    font-size: 16px;

    .text {
      padding: 40px 24px;
      .sum {
        margin-top: 20px;
        font-size: 48px;
      }

      .bold {
        font-weight: bold;
      }

      .currency-sign {
        font-size: 24px;
      }
    }

    .action-icon {
      position: absolute;
      display: inline-block;
      left: 16px;
      top:16px;
      height: 14px;
      width: 100%;
      text-align: left;
      line-height: 9px;
      cursor: pointer;
    }
  }
  .content {
    background-color: $riseup_white;
  }
}
</style>
