<template>
  <next-steps-base-card title="להתכונן לתוכניות גדולות ולחסוך אליהן בכל חודש"
                        button-text="לפתיחת תוכנית"
                        description="10 דקות, להסתכל קדימה"
                        :action-key="actionKey"
                        @click="goToPlanAhead"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';
import PlanAheadIntroductionPopup from '../../../plan-ahead/PlanAheadIntroductionPopup';

export default {
  name: 'PlanAheadNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.PLAN_AHEAD,
    };
  },
  computed: {
    ...mapGetters('planAhead', ['hasPlans']),
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    goToPlanAhead() {
      this.$router.push({ path: '/plan-ahead' });
      if (!this.hasPlans) {
        this.openModal({
          component: PlanAheadIntroductionPopup,
          props: { cashflowIsPositive: this.cashflowIsPositive },
          popupAlignment: 'full-screen',
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
