<template>
  <card-tour v-if="!loading"
             :explanation="currentStep.explanation"
             :title="currentStep.title"
             :button-title="currentStep.buttonTitle"
             :button-action="next"
             :key="currentIndex">
    <template v-slot:card>
      <component :is="currentStep.component"
                 :can-edit-category="false"
                 :initially-expanded="false"
                 v-bind="currentStep.props"
                 :is-preview-mode="true"
                 v-bind:class="{'inner-card': isInnerCard}"/>
    </template>
  </card-tour>
</template>
<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import cashflowViewConsts from '@/constants/cashflow-view';
import AboutDynamicSavingPopUp from '@/pages/responsive-pages/authenticated/trial/AboutDynamicSavingPopUp';
import CustomerBenefits from '@/mixins/CustomerBenefits';
import TourApi from '@/api/TourApi';
import NoTrialDiscountMixin from '@/mixins/NoTrialDiscountMixin.vue';
import CashflowTypeBaseTrackingCategory
  from '../cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseTrackingCategory';
import CashflowTypeBaseFixedExpense
  from '../cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseFixedExpense';
import CashflowTypeBaseIncome
  from '../cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseIncome';
import CashflowTypeBaseVariableExpense
  from '../cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseVariableExpense';
import CashflowTypeBaseSummaryAmount
  from '../cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseSummaryAmount';

import MonthlySummary
  from '../cashflow-app/cashflow-view/current-month/components/types/monthlyReview/MonthlySummary';
import casfhlowHistory from '../../../../../store/utilities/cashflow-history';
import router from '../../../../../router';
import CardTour from './CardTour';
import Intercom from '../../../../../Intercom';
import Segment from '../../../../../Segment';

const HOME_PAGE = 'home';

export default {
  name: 'CashflowTourFlow',
  data() {
    return {
      currentIndex: 0,
      loading: true,
    };
  },
  components: {
    CardTour,
  },
  mixins: [NoTrialDiscountMixin, CustomerBenefits],
  async created() {
    this.setAppHeaderProps({
      sticky: true,
      rightActions: [],
    });
    Intercom.update();
    Intercom.hideLauncher();
    this.startContentLoading(this.$options.name);
    this.loading = true;
    await this.initBudgets({ budgetDate: this.budgetDate });
    this.doneContentLoading(this.$options.name);
    this.loading = false;
    Segment.trackUserGot('TourIncomeEntered');
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    budgetDate() {
      return casfhlowHistory.getCurrentBudgetDate();
    },
    isPredictedCashflowPositive() {
      return this.predictedBalanceAtEndOfMonth > 0;
    },
    steps() {
      const basicSteps = [
        {
          component: CashflowTypeBaseIncome,
          title: 'אלו ההכנסות שלך החודש',
          explanation: 'אפשר לראות כמה כבר נכנס וכמה עוד צפוי להיכנס עד סוף החודש. על בסיס הסכום הזה נחשב לך כמה כסף נשאר להוציא החודש',
          buttonTitle: 'הבא',
          cardTourName: 'Income',
          shouldShow: () => true,
        },
        {
          component: CashflowTypeBaseFixedExpense,
          title: 'ההוצאות שבטוח ירדו החודש',
          explanation: 'אלו ההוצאות שבוודאות ירדו החודש. חלק מהן כבר ירדו וחלק צפויות עוד לרדת עד סוף החודש',
          buttonTitle: 'הבא',
          cardTourName: 'FixedExpenses',
          shouldShow: () => true,
        },
        {
          component: CashflowTypeBaseTrackingCategory,
          props: { subtree: this.trackingCategory },
          title: 'הוצאות ששווה לעקוב אחריהן',
          explanation: `הוצאות נוספות שחוזרות כל חודש כמו ה${this.trackingCategory && this.trackingCategory.categoryName}`
            + '  לדוגמא. יש לנו שליטה עליהן במהלך החודש ואפשר להגדיר להן יעד חודשי',
          buttonTitle: 'הבא',
          cardTourName: 'RecurringExpenses',
          shouldShow: () => this.trackingCategory,
        },
        {
          component: MonthlySummary,
          title: '',
          explanation: '',
          buttonTitle: 'הבא',
          cardTourName: 'Summary',
          shouldShow: () => true,
        },
        {
          component: CashflowTypeBaseVariableExpense,
          props: { timeFrame: 'weekly', initiallyExpanded: false },
          title: 'הסכום שנשאר להוציא החודש',
          explanation: 'אחרי שהחסרנו את כל ההוצאות שצפויות לרדת, נשאר לנו סכום להוצאות היומ-יומיות. אם נעמוד בו, נסיים את החודש בתזרים חיובי',
          buttonTitle: 'הבא',
          cardTourName: 'VariableExpenses',
          shouldShow: () => true,
        },
        {
          component: CashflowTypeBaseSummaryAmount,
          title: this.summaryTitle,
          explanation: this.summaryExplanation,
          buttonTitle: 'למסך התזרים',
          cardTourName: this.summaryCardTourName,
          shouldShow: () => true,
        },
      ];
      return _.filter(basicSteps, step => step.shouldShow());
    },
    currentStep() {
      return this.steps[this.currentIndex];
    },
    isInnerCard() {
      return this.currentStep.component !== MonthlySummary;
    },
    summaryTitle() {
      return this.isPredictedCashflowPositive
        ? 'כמה נשאר להוציא השבוע?'
        : 'איך החודש עומד להסתיים?';
    },
    trackingCategory() {
      const trackingCategories = this.cashflowUITree[cashflowViewConsts.cashflowTypes.trackingCategory];
      return trackingCategories.length > 0 ? trackingCategories[0] : null;
    },
    summaryExplanation() {
      return this.isPredictedCashflowPositive
        ? 'המספר הכי חשוב בתזרים - נמצא הכי למעלה במסך, מגיע אליך גם בוואטסאפ 3 פעמים בשבוע ועוזר לך להיות על זה בכל רגע!'
        : 'נראה שהחודש ההוצאות צפויות להיות גדולות מההכנסות, ואנחנו כאן ביחד איתך בזה. קודם כל, נעבור על התזרים ונוודא שהכל נכון';
    },
    summaryCardTourName() {
      return this.isPredictedCashflowPositive ? 'LeftThisWeek' : 'NegativeCashflow';
    },
  },
  methods: {
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('timeLimitedOffers', ['initTimeLimitedOffers']),
    ...mapActions('cashflowView', ['initBudgets']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('celebrateSaving', ['showAboutDynamicDepositPopup']),
    ...mapActions('subscriptionState', ['initSubscriptionState']),
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('timeLimitedOffers', ['createTimeLimitedOffer']),
    async next() {
      const currentStepName = this.steps[this.currentIndex].cardTourName;
      Segment.trackUserGot(`Tour${currentStepName}NextClicked`);

      if (this.currentIndex >= this.steps.length - 1) {
        await this.goToCasfhlow();
      } else {
        this.currentIndex += 1;
        const stepName = this.steps[this.currentIndex].cardTourName;
        Segment.trackUserGot(`Tour${stepName}Entered`);
      }
    },
    async goToCasfhlow() {
      const [, showAboutSavingPopup] = await Promise.all([TourApi.markAsDone(), this.showAboutDynamicDepositPopup()]);
      Intercom.showLauncher();
      if (showAboutSavingPopup && !this.registeredUsingSpecialSource) {
        (new Promise(resolve => setTimeout(resolve, 3000))).then(
          () => {
            this.openModal({
              component: AboutDynamicSavingPopUp,
            });
          },
        );
      }
      await this.initSubscriptionState();
      if (this.eligibleForNoTrialDiscountIsOn) {
        Segment.trackUserGot('NoTrialDiscountCreated');
        await this.createTimeLimitedOffer({ offerId: this.noTrialDiscountOfferId, offerValidForHours: this.noTrialDiscountValidHours });
        await this.initTimeLimitedOffers();
      }
      router.push({ path: `/${HOME_PAGE}`, query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.tour {
  display: flex;
  flex: 1;

  .inner-card {
    margin-bottom: 0;
    background-color: $riseup_white;
    border-radius: 8px;
    box-shadow: 0px 10px 10px 0 $riseup_gray_0;
  }
}

</style>
