<template>
  <sticky-cta-page :cta-props="ctaProps" @click="saveChanges">
    <div class="padding-ml ri-large-body flex-column flex-1">
      <div class="ri-bold-title margin-bottom-sm">התראות מיוחדות</div>
      <div class="margin-bottom-xl">במקרים הבאים המערכת תשלח הודעות ישירות לוואטסאפ שלך</div>
      <div v-if="!isLoading" class="ri-bold-body checkboxes">
        <toggle v-for="(value, key) in AlertGroups" :key="key" v-model="settings[key]" :label="value"
                :disabled="isSaving"/>
      </div>
      <div v-else class="flex-column full-width align-center"><tiny-loader/></div>
      <div v-if="currentMemberCommunicationState" class="margin-top-xxl">
        <div>
          <span class="ri-large-bold-body">בחרת להשתיק את הודעות הוואטסאפ שלך,</span>
          הן יתחדשו אוטומטית ב-{{pauseExpirationDate}}. כל עוד הן מושתקות, לא ישלחו גם התראות מיוחדות. להפעלת ההודעות,
          <a class="reset-notifications ri-large-bold-body" @click="resetNotifications" @keyup.enter="resetNotifications" tabindex="0">
            צריך ללחוץ כאן
          </a>
        </div>
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import Toaster from '@/base-components/Toaster';
import InsightsApi from '@/api/InsightsApi';
import { mapActions, mapGetters, mapState } from 'vuex';
import dateUtils from '@/utils/dates';
import Segment from '@/Segment';
import PauseMessagesBottomSheet from './components/PuaseMesssagesBottomSheet';

const AlertGroups = {
  DuplicateTransactions: 'חשד להוצאה כפולה',
  HighVariableExpense: 'הוצאה גבוהה במיוחד',
  HighVariableIncome: 'הכנסה מיוחדת שכדאי לדעת עליה (למשל מביטוח לאומי או מס הכנסה)',
  EnteredOverdraft: 'חשבון הבנק נכנס למינוס',
};

export default {
  name: 'WhatsappAlertsSettings',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    Toggle: BaseUI.Toggle,
    TinyLoader: BaseUI.TinyLoader,
  },
  data() {
    return {
      AlertGroups,
      initialValues: {},
      settings: {},
      isSaving: false,
      isLoading: true,
    };
  },
  async created() {
    Segment.trackUserGot('WhatsappAlertsSettings_Shown');
    this.getCommunicationState();
    const whatsappAlertsSettings = await InsightsApi.getWhatsappAlertsSettings();
    this.settings = whatsappAlertsSettings ?? {};
    this.initialValues = { ...this.settings };
    this.isLoading = false;
  },
  computed: {
    ...mapState('session', ['activeMember']),
    ...mapGetters('communicationState', ['memberCommunicationState']),
    currentMemberCommunicationState() {
      return this.memberCommunicationState(this.activeMember._id);
    },
    pauseExpirationDate() {
      if (!this.currentMemberCommunicationState?.pauseExpirationDate) {
        return null;
      }
      return dateUtils.shortDateFormat(this.currentMemberCommunicationState.pauseExpirationDate);
    },
    ctaProps() {
      return {
        buttonProps: {
          title: 'שמירת השינויים',
          loading: this.isSaving,
          disabled: !this.hasChanges,
        },
      };
    },
    hasChanges() {
      return !_.isEqual(this.settings, this.initialValues);
    },
  },
  methods: {
    ...mapActions('communicationState', ['getCommunicationState']),
    ...mapActions('modalRootStore', ['openModal']),
    async saveChanges() {
      Segment.trackUserGot('WhatsappAlertsSettings_Save', {
        disabled: _.keys(this.initialValues).filter(key => !this.settings[key]),
        enabled: _.keys(this.initialValues).filter(key => this.settings[key]),
      });
      this.isSaving = true;
      await InsightsApi.updateWhatsappAlertsSettings(this.settings);
      this.isSaving = false;
      this.initialValues = { ...this.settings };
      EventBus.$emit('open-toaster', {
        component: Toaster,
        props: {
          text: 'ההגדרות עודכנו בהצלחה!',
        },
      });
    },
    resetNotifications() {
      Segment.trackUserGot('WhatsappAlertsSettings_ResetNotifications_Clicked');
      this.openModal({
        component: PauseMessagesBottomSheet,
        props: {
          memberCommunicationState: this.currentMemberCommunicationState,
        },
        popupAlignment: 'menu',
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.checkboxes {
  color: $riseup_blue;
  display: flex;
  flex-direction: column;
  row-gap: $ri-spacing-ml;
}
.reset-notifications {
  color: $riseup_blue;
  cursor: pointer;
  text-decoration: underline;
}
</style>
