import _ from 'lodash';

export const CELEBRATION_TYPES = {
  TurnOver: 'TurnOver',
  GrowingMonths: 'GrowingMonths',
  ConsecutivePositiveMonths: 'ConsecutivePositiveMonths',
  LessNegative: 'LessNegative',
  AtLeastOnePositive: 'AtLeastOnePositive',
  NoCelebration: 'NoCelebration',
};

const celebrationMapping = {
  [CELEBRATION_TYPES.GrowingMonths]: {
    isEligible: _isEligibleForGrowingMonths,
    priority: 5,
  },
  [CELEBRATION_TYPES.ConsecutivePositiveMonths]: {
    isEligible: _isEligibleForConsecutivePositiveMonths,
    priority: 4,
  },
  [CELEBRATION_TYPES.TurnOver]: {
    isEligible: _isEligibleForTurnOver,
    priority: 3,
  },
  [CELEBRATION_TYPES.LessNegative]: {
    isEligible: _isEligibleForLessNegative,
    priority: 2,
  },
  [CELEBRATION_TYPES.AtLeastOnePositive]: {
    isEligible: _isEligibleForAtLeastOnePositive,
    priority: 1,
  },
  [CELEBRATION_TYPES.NoCelebration]: {
    isEligible: () => true,
    priority: 0,
  },
};

export function getCelebrationType(totals) {
  return _getEligibleCelebrationTypes(totals)[0];
}

export function hasCelebrationToShow(totals) {
  return getCelebrationType(totals) !== CELEBRATION_TYPES.NoCelebration;
}

export function getConsecutiveGrowingMonths(totals) {
  let upMonths = 0;
  _.forEach(totals, (t, i) => {
    if (t > 0 && (i === 0 || totals[i - 1] > t)) {
      upMonths += 1;
      return true;
    }
    return false;
  });
  return upMonths;
}

export function getConsecutivePositiveMonths(totals) {
  let positiveMonths = 0;
  _.forEach(totals, t => {
    if (t >= 0) {
      positiveMonths += 1;
      return true;
    }
    return false;
  });
  return positiveMonths;
}

export function getLessNegativeImprovement(totals) {
  return totals[0] - totals[1];
}

export function getTurnOverLastMonth(totals) {
  return totals[0];
}

function _getEligibleCelebrationTypes(totals) {
  return _.chain(CELEBRATION_TYPES)
    .filter(type => celebrationMapping[type].isEligible(totals))
    .sortBy(type => -celebrationMapping[type].priority)
    .value();
}

function _isEligibleForTurnOver(totals) {
  return totals[0] > 0 && totals[1] < 0;
}

function _isEligibleForGrowingMonths(totals) {
  return getConsecutiveGrowingMonths(totals) > 1;
}

function _isEligibleForConsecutivePositiveMonths(totals) {
  return getConsecutivePositiveMonths(totals) > 1;
}

function _isEligibleForLessNegative(totals) {
  return totals[0] > totals[1] && totals[0] < 0 && totals[1] < 0;
}

function _isEligibleForAtLeastOnePositive(totals) {
  return _.some(totals, t => t > 0);
}
