<template>
  <next-steps-base-card title="להשלים את הצעדים הראשונים לשימוש בתזרים"
                        button-text="לרשימת הצעדים"
                        description="15 דקות"
                        :action-key="actionKey"
                        @click="openActionsList"/>
</template>

<script>
import { mapActions } from 'vuex';
import NextStepsBaseCard from '../NextStepsBaseCard';
import { ActionKeys } from '../my-progress-next-steps.consts';
import ActionListPopup from '../../../cashflow-app/cashflow-view/current-month/components/action-list/ActionListPopup';

export default {
  name: 'TrialActionsNextStep',
  components: {
    NextStepsBaseCard,
  },
  data() {
    return {
      actionKey: ActionKeys.TRIAL_ACTIONS,
    };
  },
  created() {
    this.fetchActions();
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('actions', ['fetchActions']),
    openActionsList() {
      this.$router.push({ path: '/home/current' });
      this.openModal({
        component: ActionListPopup,
      });
    },
  },
};
</script>

<style scoped>

</style>
