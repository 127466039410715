<template>
  <header-page :sticky-header="stickyHeader" :header-props="headerProps" v-if="!submitting && !error" ref="headerPage">
    <component :is="step" :cta-props="ctaProps" :current-step-index="currentStepIndex" v-if="wizardState"
               :wizard-state="wizardState" :total-steps="numberOfSteps" @next="nextStep" @submit="submit" @close="close"/>
  </header-page>
  <div v-else-if="!error" class="full-width flex-column align-center justify-content-center">
    <lottie-player src="/animations/new-brand-loader-blue-variation.json" autoplay loop
                   class="margin-vertical-l" preserveAspectRatio="xMidYMax slice"/>
    <div class="flex-column align-center">
      <div class="ri-bold-title">הבקשה לפיקדון נשלחת</div>
      <div class="ri-body">זה ייקח כמה רגעים...</div>
    </div>
  </div>
  <altshuler-open-account-error-page v-else :error-type="error"/>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import * as AltshulerApi from '@/api/AltshulerApi';
import { mapActions } from 'vuex';
import DDLogs from '@/DDLogs';
import router from '@/router';
import Segment from '@/Segment';
import AltshulerSignupPersonalInfo from './AltshulerSignupPersonalInfo';
import AltshulerSignupIdTypeSelection from './AltshulerSignupIdTypeSelection';
import AltshulerSignupCompleted from './AltshulerSignupCompleted';
import AltshulerSignupSummary from './AltshulerSignupSummary';
import AltshulerSignupSignAgreement from './AltshulerSignupSignAgreement';
import AltshulerSignupSelectBankAccount from './AltshulerSignupSelectBankAccount';
import AltshulerSignupIdUpload from './AltshulerSignupIdUpload';
import AltshulerSignupIdInputs from './AltshulerSignupIdInputs';
import AltshulerSignupSelectDepositAmount from './AltshulerSignupSelectDepositAmount';
import AltshulerOpenAccountErrorPage from './AltshulerOpenAccountErrorPage';

const STEPS = [
  AltshulerSignupPersonalInfo,
  AltshulerSignupIdTypeSelection,
  AltshulerSignupIdUpload,
  AltshulerSignupIdInputs,
  AltshulerSignupSelectBankAccount,
  AltshulerSignupSelectDepositAmount,
  AltshulerSignupSignAgreement,
  AltshulerSignupSummary,
  AltshulerSignupCompleted,
];

const UNCOUNTED_STEPS = [
  AltshulerSignupSummary,
  AltshulerSignupCompleted,
];

export default {
  name: 'AltshulerSignupFlow',
  components: {
    AltshulerOpenAccountErrorPage,
    HeaderPage: BaseUI.Pages.HeaderPage,
  },
  data() {
    return {
      currentStepIndex: 0,
      wizardState: null,
      loading: false,
      submitting: false,
      error: '',
    };
  },
  async created() {
    this.loading = true;
    Segment.trackUserInteraction('Altshuler_Signup_Entered');
    this.loadAllCities().catch(err => DDLogs.info('Failed to load cities in altshuler flow', err));
    const wizardState = await AltshulerApi.getOpenAccountWizardState();
    if (!wizardState) {
      await AltshulerApi.createOpenAccountWizardState({});
      this.wizardState = await AltshulerApi.getOpenAccountWizardState();
    } else {
      this.wizardState = wizardState;
    }
    this.loading = false;
    this.initBalance();
  },
  methods: {
    ...mapActions('cities', ['loadAllCities']),
    ...mapActions('balance', ['initBalance']),
    async nextStep(updatedInfo) {
      this.loading = true;
      const newState = { ...this.wizardState, ...updatedInfo };
      await this.updateWizardState(newState);
      if (this.currentStepIndex < STEPS.length - 1) {
        ++this.currentStepIndex;
      }
      this.loading = false;
      this.scrollToTop();
    },
    scrollToTop() {
      this.$nextTick(() => {
        if (this.$refs.headerPage) {
          this.$refs.headerPage.resetScroll();
        }
      });
    },
    async submit() {
      Segment.trackUserInteraction('Altshuler_SignupSubmit_Submit');
      this.submitting = true;
      try {
        await AltshulerApi.submitRequest();
        Segment.trackUserInteraction('Altshuler_SignupSubmit_Success');
      } catch (e) {
        if (e.message === 'Network Error' || (e.response && e.response.status === 0)) {
          this.error = 'connection';
        } else {
          this.error = 'data';
        }
        Segment.trackUserInteraction('Altshuler_SignupSubmit_Error', { error: this.error });
      }
      if (this.currentStepIndex < STEPS.length - 1) {
        ++this.currentStepIndex;
      }
      this.submitting = false;
    },
    prevStep() {
      Segment.trackUserInteraction('Altshuler_SignupFlow_BackClicked',
        { stepIndex: this.currentStepIndex, stepName: STEPS[this.currentStepIndex].name });
      if (this.currentStepIndex > 0) {
        --this.currentStepIndex;
      } else {
        router.push({ path: '/altshuler' });
      }
    },
    close() {
      Segment.trackUserInteraction('Altshuler_SignupFlow_CloseClicked',
        { stepIndex: this.currentStepIndex, stepName: STEPS[this.currentStepIndex].name });
      router.push({ path: this.currentStepIndex === 8 ? '/sr' : '/altshuler' });
    },
    async updateWizardState(newState) {
      await AltshulerApi.updateOpenAccountWizardState(newState);
      this.wizardState = await AltshulerApi.getOpenAccountWizardState();
    },
  },
  computed: {
    step() {
      return STEPS[this.currentStepIndex];
    },
    numberOfSteps() {
      return STEPS.length - UNCOUNTED_STEPS.length;
    },
    prevDisabled() {
      return this.currentStepIndex === 0;
    },
    stickyHeader() {
      return !UNCOUNTED_STEPS.includes(STEPS[this.currentStepIndex]);
    },
    headerProps() {
      return {
        ...UNCOUNTED_STEPS.includes(STEPS[this.currentStepIndex]) ? {
          bottomBorder: true,
          backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        } : {
          stepperProps: {
            currentStep: this.currentStepIndex + 1,
            totalSteps: STEPS.length,
          },
        },
        leftActions: [
          {
            type: BaseUI.HeaderActions.CLOSE,
            onClick: this.close,
          },
        ],
        rightActions: this.currentStepIndex > 0 && this.currentStepIndex < (STEPS.length - 1) ? [
          {
            type: BaseUI.HeaderActions.BACK,
            onClick: this.prevStep,
            disabled: this.prevDisabled,
          },
        ] : [],
      };
    },
    ctaProps() {
      return {
        buttonProps: {
          title: 'הבא',
          loading: this.loading,
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
</style>
