<template>
  <slider v-if="!contentLoading" :key="carouselItemCount" class="insights-carousel" :slides-data="keyedInsights" v-bind="sliderProps"
          :first-slide-index="initialCarouselItemIndex" :disable-drag="carouselItemCount < 2" @on-slide="onAfterChange">
    <template v-slot:default="{ slideData: insight }">
      <insight-factory :key="insight.insightId" :insight="insight" />
    </template>
  </slider>
</template>

<script>
import Segment from '@/Segment';
import InsightsApi from '@/api/InsightsApi';
import { mapMutations, mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import InsightFactory from '../../insights/InsightFactory';

export default {
  name: 'InsightsCarousel',
  components: {
    InsightFactory,
    Slider: BaseUI.Slider,
  },
  props: {
    insights: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sliderProps: Object.freeze({
        padding: 0,
        paginationPadding: 0,
        infinite: true,
        direction: 'ltr',
        minDots: 1,
      }),
      financialProfileScore: null,
    };
  },
  computed: {
    ...mapGetters('insights', ['INSIGHT_NAMES', 'EXCLUSION_INSIGHTS']),
    ...mapGetters('featureFlags', ['isFlagIsOn']),
    ...mapGetters('contentLoader', ['contentLoading']),
    keyedInsights() {
      // carousel requires insights to have the "key" property as a unique value
      return this.insights.map(insight => { return { ...insight, key: insight.insightId }; });
    },
    carouselItemCount() {
      return this.insights.length;
    },
    initialCarouselItemIndex() {
      return this.carouselItemCount - 1;
    },
  },
  created() {
    this.sendEventCarouselItemReached(this.initialCarouselItemIndex);
  },
  methods: {
    ...mapMutations('applicationState', ['setNotificationState']),
    onAfterChange(slideIndex) {
      this.sendEventCarouselItemReached(slideIndex);
    },
    sendEventCarouselItemReached(slideIndex) {
      const currentInsight = this.keyedInsights[slideIndex];
      Segment.trackUserInteraction(`${currentInsight.insightName}_InsightReachedInCarousel`, currentInsight);
      // Instead of having an event per insight we want to have one event with the insight name as a property (this is the common convention).
      // To prevent breaking the funnel we are going to run both events together for a month and then remove the old one above.
      Segment.trackUserInteraction('InsightShownInCarousel', currentInsight);
      InsightsApi.sendInsightEvent(currentInsight, 'insightShownInCarousel');
    },
  },
  watch: {
    finishedAllItems(finished) {
      if (finished) {
        this.setNotificationState(null);
      }
    },
  },
};
</script>

<!-- This isn't scoped because we need to override some of the internal carousel styles -->
<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.insights-carousel {
  background-color: $riseup_gray_01;
  padding-bottom: 16px;
  direction: ltr;
  text-align: right;
}
</style>
