<template>
  <popup :close-action="closeActionClicked" class="eom-celebration-generic-popup">
    <template v-slot:content>
      <slot></slot>
      <share-button filename="eom" share-name="EomCelebration"
                    :share-component="shareComponent" :share-component-props="shareComponentProps"
                    :segment-extra-props="segmentExtraProps" v-slot="slotProps" :show-popup="false">
        <riseup-button variant="primary"
                       size="slim"
                       :title="cta"
                       :action="slotProps.onClick"
                       :loading="slotProps.loading"/>
      </share-button>
    </template>
    <template v-slot:footer v-if="shouldShowTouchPoint">
      <div class="touch-point-text">
        זו הזדמנות מעולה לשלוח הטבה לחבר או חברה של <span class="bold">חודש נסיון חינם + חודשיים נוספים בחצי מחיר ועל הדרך להרוויח כסף.</span>
        <div class="tell-me-more" v-on:click="goToAmbassadorPage">
          ספרו לי עוד
          <img class='image' :src="require('@/assets/regulations-arrow.svg')">
        </div>
      </div>
    </template>
  </popup>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import ShareButton from '@/base-components/share/ShareButton';
import Segment from '@/Segment';
import { mapActions, mapGetters } from 'vuex';
import router from '@/router';

export default {
  name: 'EomCelebrationGenericPopup',
  components: {
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
    ShareButton,
  },
  props: {
    segmentExtraProps: {
      type: Object,
      require: true,
    },
    cashflowDate: {
      type: String,
      require: true,
    },
    cta: {
      type: String,
      require: false,
      default: 'לשתף עם העולם',
    },
    shareComponent: {
      type: Object,
    },
    shareComponentProps: {
      type: Object,
    },
  },
  data() {
    return {
      isSharing: false,
      shareAssetPromise: null,
    };
  },
  created() {
    Segment.trackUserGot('EOMCelebrationPopupShownV2', this.segmentExtraProps);
    if (this.shouldShowTouchPoint) {
      Segment.trackUserGot('AmbassadorsPromotionEomPopupShown');
    }
  },
  computed: {
    ...mapGetters('ambassadorsTouchPoints', ['shouldShowTouchPoint']),
  },
  methods: {
    ...mapActions('share', ['setCelebrationDismissed']),
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('ambassadorsTouchPoints', ['updateLastShown']),
    closeActionClicked() {
      Segment.trackUserInteraction('EOMCelebrationPopupClosed', this.segmentExtraProps);
      this.updateLastShown();
      this.close();
    },
    close() {
      this.setCelebrationDismissed(this.cashflowDate);
      this.closeModal();
    },
    goToAmbassadorPage() {
      Segment.trackUserInteraction('AmbassadorsPromotionEomPopupClicked');
      router.push({ path: '/ambassadors' });
      this.closeActionClicked();
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';

.popup.eom-celebration-generic-popup {
  .share-button {
    margin-top: 30px;
  }
  .footer-padding {
    background-color: $riseup-light_blue;
    border-radius: 0 0 $ri-border-radius-large $ri-border-radius-large;
    .touch-point-text {
      text-align: right;
      font-size: 18px;
      line-height: 140%;
      .bold {
        font-weight: bold;
      }
      .tell-me-more {
        margin-top: 15px;
        color: $riseup-blue;
        font-weight: bold;
        cursor: pointer;
        .image {
          margin-right: 10px;
        }
      }
    }
  }
}

</style>
